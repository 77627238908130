import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon, EmailShareButton, EmailIcon } from 'react-share'

import Paper from '@mui/material/Paper'

function Share({event}) {
   if (!event) { return null }

   return (
      <div>
         <Paper style={{ marginBottom: 20 }} ><img alt={event.name} src={event.profileImage} style={{display:'block',width:'100%', borderRadius:2}} /></Paper>
         <Paper elevation={0} style={{ textAlign:'center', marginBottom: 20, padding: 0, border:'1px solid #cfd8dc', borderRadius:0 }} >
            <FacebookShareButton style={{display:'inline-block', padding:10}} url={window.location.href}>
               <FacebookIcon size={40} round={true}/>
            </FacebookShareButton>
            <TwitterShareButton style={{display:'inline-block', padding:10}} url={window.location.href}>
               <TwitterIcon size={40} round={true}/>
            </TwitterShareButton>
            <WhatsappShareButton style={{display:'inline-block', padding:10}} url={window.location.href}>
               <WhatsappIcon size={40} round={true}/>
            </WhatsappShareButton>
            <EmailShareButton style={{display:'inline-block', padding:10}} url={window.location.href}>
               <EmailIcon size={40} round={true}/>
            </EmailShareButton>
         </Paper>
      </div>
   )
}
export default Share
