import { forwardRef, useEffect, useState } from 'react'
import QRCode from 'qrcode'
import JsBarcode from 'jsbarcode'
import { createCanvas } from 'canvas'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

function IdCard({selectedOption,open,handleClose}){
   const [qrSrc, setQrSrc] = useState(null)
   const [bcSrc, setBcSrc] = useState(null)

   useEffect(() => {
      handleUpdateImages()
   }, [selectedOption])

   const handleUpdateImages = () => {
      let encoded = selectedOption.id
      QRCode.toDataURL(encoded, function (err, url) {
         setQrSrc(url)
      })
      const canvas = createCanvas()
      JsBarcode(canvas, encoded, {
         format: 'CODE128',
         width:1,
         height:40,
         fontSize: 15,
         displayValue: false 
      })
      setBcSrc(canvas.toDataURL())
   }

   return (
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth='xs' TransitionComponent={Transition} keepMounted>
     
         <DialogTitle >SecureTix</DialogTitle>
         <DialogContent>
            <DialogContentText style={{ textAlign: 'center'}}>
               <img alt={'SecureTix id: ' + selectedOption.id} src={qrSrc} style={{ width:'100%', maxWidth: 250, border: '1px solid #cccccc'}} /><br />
               <b>{selectedOption.name}</b><br />
               {selectedOption.id}<br />
               <img alt={'SecureTix id: ' + selectedOption.id} src={bcSrc} style={{ width:'100%', maxWidth: 270 }} />           
            </DialogContentText>
         </DialogContent>

         <DialogActions>
            <Button onClick={handleClose} color='primary'>Close</Button>
         </DialogActions>
      </Dialog>
   )
}
export default IdCard
