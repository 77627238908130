import { useEffect, useState } from 'react' 
import { Routes, Route, Navigate, Outlet, Link, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { setShowMessage, loadGroupSettings, addTeamMember, removeTeamMember } from '../../../redux/groupSettings/actions'
import { selectorFilterTeam } from '../../../redux/groupSettings/selectors'

import AccessDenied from '../../components/AccessDenied'
import Info from './Info'
import NewTeamMember from './NewTeamMember'
import NextUpdate from '../../components/NextUpdate'
import Plugins from './Plugins'
import Profile from './Profile'
import SubNav from '../../components/SubNav'
import Team from './Team'

import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

function GroupSettingsContainer({roleId, lastUpdated, showMessage, teamMembers, setShowMessage, loadGroupSettings, addTeamMember, removeTeamMember}) {
   const whoHasAccess = [1]
   const [loading, setLoading] = useState(true)   
   const [showInfo, setShowInfo] = useState(false)
   const [activeTab, setActiveTab] = useState(false) 
   const location = useLocation()

   useEffect(() => {
      switch (true) {
         case location.pathname.includes('/group/settings/profile'):
            setActiveTab(0)
         break
         case location.pathname.includes('/group/settings/team'):
            setActiveTab(1)
         break
         case location.pathname.includes('/group/settings/plugins'):
            setActiveTab(2)
         break
         default:
            setActiveTab(false)
      }
      handleLoadGroupSettings()
   },[location])

   const handleLoadGroupSettings = (force) => {
      setLoading(true)
      loadGroupSettings(force) 
      .finally(() => {
         setLoading(false)
      }) 
   }

   if (!roleId) return null
   if(!whoHasAccess.includes(roleId)) {
      return (<div style={{height:500}}><AccessDenied page='Group Settings'/></div>)
   }

   return (
      <Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
         <Info showInfo={showInfo} setShowInfo={setShowInfo} />
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} >
               <SubNav text='Group Settings' showInfo={true} setShowInfo={setShowInfo}/>
               <div style={{borderBottom: '2px solid #eee'}}>
                  <Tabs value={activeTab} variant='fullWidth' indicatorColor='primary' textColor='primary'
                     variant='scrollable' scrollButtons={false}>
                     <Tab label='Profile' style={{ maxWidth:200 }} component={Link} to='/group/settings/profile'/>
                     <Tab label='Team Members' style={{ maxWidth:200 }} component={Link} to='/group/settings/team' />
                     <Tab label='Plugins' style={{ maxWidth:200 }} component={Link} to='/group/settings/plugins' />
                  </Tabs>
               </div>
               <Collapse in={loading}> 
                  <Alert severity='warning'>
                     Retrieving Group Settings ...
                     <LinearProgress color='inherit' />   
                  </Alert> 
               </Collapse>
               <div>
                  <Routes>
                     <Route path='/profile' element={<Profile 
                        showMessage={showMessage}
                        setShowMessage={setShowMessage}
                     />} />
                     <Route path='/team' element={<Team 
                        teamMembers={teamMembers}
                        removeTeamMember={removeTeamMember}
                     />} />
                     <Route path='/new-team-member' element={<NewTeamMember 
                        addTeamMember={addTeamMember} 
                     />} />
                     <Route path='/plugins' element={<Plugins />} />
                     <Route path='*' element={<Navigate to='/group/settings/profile' />} />
                  </Routes>
                  <Outlet />
               </div>
            </Grid>            
         </Grid> 
         <NextUpdate lastUpdated={lastUpdated} updateNow={handleLoadGroupSettings}/>
      </Container>   
   )
}
const mapStateToProps = (state) => ({
   roleId: state.auth.loginOptions[state.auth.loginOptionID].roleId,
   lastUpdated: state.app.lastUpdated.groupSettings,
   showMessage: state.groupSettings.showMessage,
   teamMembers: selectorFilterTeam(state.groupSettings.team)
})
const mapDispatchToProps = {
   setShowMessage,
   loadGroupSettings,
   addTeamMember,
   removeTeamMember
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupSettingsContainer)
