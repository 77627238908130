import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'

function Info({showInfo, setShowInfo}){
   return (
      <Dialog open={showInfo} onClose={() => setShowInfo(!showInfo)}>
         <DialogContent>
            My Account Info Dialog<br/>
            | summary | details | faqs |<br/>
            this should be quick reference slides highlighting the features of My Account.
         </DialogContent>
         <DialogActions>
            <Button onClick={() => setShowInfo(!showInfo)} color='primary' autoFocus>
               Close
            </Button>
         </DialogActions>
      </Dialog>
   )
}
export default Info
