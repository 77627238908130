function ReturnedData({data}) {
   return (
      <div style={{paddingTop:0, paddingBottom:5, width:'100%', backgroundColor:'white'}}>
         <iframe 
            id='threedsIframe' 
            srcDoc={data} 
            style={{ height:500, width:'100%', backgroundColor:'white' }} //, backgroundPosition:'center', backgroundRepeat:'no-repeat', backgroundImage:'url(../../../images/secure-lock.gif)' 
            frameBorder={0}
            sandox='allow-same-origin'
         ></iframe>
      </div>
	)
}
export default ReturnedData		
