import { APP_LAST_UPDATED, PUBLIC_EVENTS_LOAD, PUBLIC_EVENT_DETAILS_LOAD } from '../types'
import { chkExpiredData } from '../app/actions'
import { getFunctions, httpsCallable } from 'firebase/functions'

export function loadEvents(force=false, website, country, groupId=undefined) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {   
	   const chkIfExpired = dispatch(chkExpiredData(force, 'publicEvents'))
     	chkIfExpired
     	.then((currentTime) => {
     	// ---------------------------------------------------------------------------    
	      const functions = getFunctions(firebase)
         const loadPublicEvents = httpsCallable(functions, 'publicFetchEvents')
	      loadPublicEvents({ website, country, groupId })
	      .then((returned) => {
				dispatch({type: PUBLIC_EVENTS_LOAD, payload: returned.data})
				dispatch({ type: APP_LAST_UPDATED, payload:{key:'publicEvents', value:currentTime} })
				resolve()
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
		   }) 
     	// ---------------------------------------------------------------------------
     	})
     	.catch(() => {
         // Current data still good so do nothing.
         resolve()
     	})
	})
}

export function loadEventDetails(force=false, website, eventId) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) { 
		// check if event was loaded before, if not, force check server
		if (!getstate().publicEvents.eventDetails[eventId]) {
			force = true
		}
		const lastUpdated = 'publicEvent-' + eventId
	   const chkIfExpired = dispatch(chkExpiredData(force, lastUpdated))
     	chkIfExpired
     	.then((currentTime) => {
     	// ---------------------------------------------------------------------------    
	      const functions = getFunctions(firebase)
         const loadPublicEventDetails = httpsCallable(functions, 'publicFetchEventDetails')
	      loadPublicEventDetails({website, eventId})
	      .then((returned) => {
            let event = { 
            	id:eventId, 
            	details:returned.data.details, 
               tickets:returned.data.tickets,
               items:returned.data.items
            }
				dispatch({type: PUBLIC_EVENT_DETAILS_LOAD, payload:event})
				dispatch({ type: APP_LAST_UPDATED, payload:{key:lastUpdated, value:currentTime} })
				resolve()
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
		   }) 
     	// ---------------------------------------------------------------------------
     	})
     	.catch(() => {
         // Current data still good so do nothing.
         resolve()
     	})
	})
}
