import {useEffect, useState} from 'react'

import React from 'react'
import ImageSelector from '../../../components/ImageSelector'

import Button from '@mui/material/Button'
import Text from '@mui/material/Typography'

function Step2({currentImage, images, updateImage, handleBack}){
	const [selectedImage, setSelectedImage] = useState(currentImage)

	// [START]: ImageSelector Function :::::::::
	const handleSelectImage = (src) => {
		setSelectedImage(src)
	}
	// [END]: ImageSelector Function :::::::::::
   const handleNext = () => {
		updateImage('headerImage', selectedImage)
	}
   return (
   	<div style={{ width:'100%' }}>
   		<div>
   			{/** ImageSelector Starts Here */}
          	<div align='center' style={{ maxWidth: 500, margin:'0 auto'}}>
            	<ImageSelector 
              		imgBorder='1px solid #ccc'
              		imgBorderRadius={10}
              		imgPadding={5}
              		imgRatio='16:9' 
              		images={images}
              		currentImage={selectedImage}
              		selectImage={handleSelectImage}
            	/>
          	</div>
          	{/** ImageSelector Ends Here */} 
   		</div>
		  	<div align='right' style={{ padding: 20 }}>
				<Button variant='outlined' color='primary' style={{ marginRight: 10 }} onClick={handleBack}>Back</Button>
				<Button variant='contained' color='primary' onClick={handleNext}>
					 Next
				</Button>
		  	</div>
   	</div>
   )
}
export default Step2
