import { useEffect, useState } from 'react' 
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'

import { money } from '../../../includes/functions'
import Timer from '../../components/Timer'
import Loader from '../../components/Loader'

import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import Text from '@mui/material/Typography'

const colors = {
   A:'#ec407a', B:'#ab47bc', C:'#2196f3', D:'#26a69a', E:'#4caf50', F:'#ff9800', G:'#ff5722',
   H:'#ec407a', I:'#ab47bc', J:'#2196f3', K:'#26a69a', L:'#4caf50', M:'#ff9800', N:'#ff5722',
   O:'#ec407a', P:'#ab47bc', Q:'#2196f3', R:'#26a69a', S:'#4caf50', T:'#ff9800', U:'#ff5722',
   V:'#ec407a', W:'#ab47bc', X:'#2196f3', Y:'#26a69a', Z:'#4caf50'
}

function Cart({eventId, order, orderedItems, deleteItemFromOrder, deleteOrder}) {
   const [loading, setLoading] = useState(false) 
   const [items, setItems] = useState([]) 

   useEffect(() => {
      filterOrderedItems()
   },[orderedItems])

   const filterOrderedItems = () => {
      // filter event tickets
      let filteredItems = (orderedItems) ? orderedItems.filter(rec => { return rec.eventId === eventId }) : ''
      // sort tickets then items
      setItems(filteredItems)
   }
   const handleTimerExpired = () => {
      deleteOrder()
      .then(() => {
         Swal.fire({
            position: 'top-end',
            icon: 'info',
            text: 'Order Expired',
            showConfirmButton: true
         })
      })
   }
   const deleteTicket = (orderId, orderedItemId) => {
      setLoading(true)
      deleteItemFromOrder(orderId, orderedItemId)
      .then(() => {
         setLoading(false)
      })
      .catch((e) => {
         setLoading(false)            
         Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: e.title, 
            text: e.message,
            showConfirmButton: true
         })
      })
   }
   if(!order || !items || !items.length) return null

   return (
      <Card elevation={0} style={{border: '1px solid #cfd8dc', borderRadius:0, marginBottom:20}}>
         <Loader loading={loading} title='Updating' text='Updating order, please wait...'/>
         <CardHeader title='Current Order' titleTypographyProps={{variant: 'h6'}} style={{padding:'10px 15px 10px 15px'}}/>
         <Divider variant='middle' style={{backgroundColor: '#cfd8dc'}} />
         <CardContent style={{paddingTop:0}}>
            {/* [START]: Items --------------------------- */}
               {items.map((i) => {
                  let letter = i.ownerName.charAt(0).toUpperCase()
                  return (
                     <Accordion key={i.itemId} elevation={0} style={{borderBottom:'1px solid #ddd'}}>
                        <AccordionSummary style={{padding:0, margin:0}} expandIcon={<ExpandMoreIcon />}>
                           <List style={{padding:2,margin:0}}>
                              <ListItem style={{padding:0}}>
                                <Avatar style={{backgroundColor:colors[letter]}}>{letter}</Avatar>
                                <ListItemText style={{paddingLeft:10}}>
                                    <div style={{lineHeight:1, color:'#607d8b'}}><b>{i.name}</b></div>
                                    <div style={{lineHeight:1}}>{i.ownerName}</div>
                                </ListItemText>
                              </ListItem>
                           </List>
                        </AccordionSummary>
                        <AccordionDetails>
                           <div style={{ width: '100%', color:'#546e7a'}}>
                              <Text>
                                 <b>Type:</b> {i.name}<br/>
                                 <b>Issued To:</b> {i.ownerName}<br/>
                                 <b>Cost:</b> {money(i.itemPrice, 'format-1')}<br/>                
                              </Text>
                              <div align='right'>
                                 <Button onClick={() => { deleteTicket(i.orderId, i.itemId) }}  style={{ color: '#c1c1c1'}}>Delete This Ticket</Button>
                              </div>
                           </div>
                        </AccordionDetails>
                     </Accordion>
                  )
               })}
            {/* [END]: Items ----------------------------- */}
         </CardContent>
         <CardActions style={{margin:7}}>
            <Stack style={{width:'100%'}} direction='column' justifyContent='center' alignItems='center' spacing={0}>
               <Timer variant='eventPage' endTime={order.dateTimeExpires} callback={handleTimerExpired}/>               
               <Button fullWidth component={Link} to={'/cart'} variant='contained' style={{backgroundColor:'#12bf05', color:'#ffffff'}}>Checkout</Button>
            </Stack>
         </CardActions>
      </Card>
   )
}
export default Cart
