import { MEMBER_PROFILE_ACTIVITY_LOAD, MEMBER_PROFILE_NOTIFICATIONS_LOAD } from '../types'

const initialState = {
	activity: [],
	notifications: []
}

export default function reducer(state = initialState, action) {
  	switch (action.type) {
  	   case MEMBER_PROFILE_ACTIVITY_LOAD:
         return { 
			   ...state,
			   activity: action.payload
		   }	
	   case MEMBER_PROFILE_NOTIFICATIONS_LOAD:
         return { 
				...state,
				notifications: action.payload
			}
	   default:
			return state
	}
}
