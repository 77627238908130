export function chkEmail(email) {
	let emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	if (emailRex.test(email)) {
		return true
	}
	return false
}

export function chkPassword(password) {
	let regExp = /^[a-zA-Z0-9!@#$%^&*]{6,26}$/
	if (regExp.test(password)) {
		return true
	}
	return false
}

export function money(amount, currency='USD', convert=true, formatted=true) {
	let exchangeRate = window.localStorage.getItem('appExchangeRate') || 1
	let returnCurrency = window.localStorage.getItem('appCurrency') || 'USD'
   let number

   // if convert = true - convert amount to app currency
   if (convert) {
	   // NOTE: amount is always received in USD.
      number = (amount * exchangeRate).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      number = (formatted) ? '$' + number + ' ' + returnCurrency : number
   } else {
      number = (amount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      number = (formatted) ? '$' + number + ' ' + currency : number
   }
   
   return number   
}