import { useEffect, useState } from 'react' 
import { Routes, Route, Navigate, Outlet, Link, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { 
   setShowMessage, 
   loadGroupSupport, 
   loadGroupSupportArchived,
   updateTicket, 
   closeTicket,
   addTicketNote, 
   assignTicket, 
   archiveTicket 
} from '../../../redux/groupSupport/actions'

import AccessDenied from '../../components/AccessDenied'
import Archived from './Archived'
import Info from './Info'
import NextUpdate from '../../components/NextUpdate'
import List from './List'
import Notes from './Notes'
import SubNav from '../../components/SubNav'
import Ticket from './Ticket'

import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

function GroupSupportContainer({
   memberId,
   roleId, 
   lastUpdated, 
   showMessage, 
   supportTickets, 
   archivedTickets, 
   faqs, 
   notes, 
   team, 
   setShowMessage, 
   loadGroupSupport, 
   loadGroupSupportArchived,
   updateTicket, 
   closeTicket,
   addTicketNote, 
   assignTicket, 
   archiveTicket
}) {
   const whoHasAccess = [1]
   const [loading, setLoading] = useState(true)   
   const [showInfo, setShowInfo] = useState(false)
   const [activeTab, setActiveTab] = useState(false) 
   const location = useLocation()

   useEffect(() => {
      switch (true) {
         case location.pathname.includes('/group/support/open'):
            setActiveTab(0)
         break
         case location.pathname.includes('/group/support/closed'):
            setActiveTab(1)
         break
         case location.pathname.includes('/group/support/notes'):
            setActiveTab(2)
         break
         default:
            setActiveTab(false)
      }
      handleLoadGroupSupport()
   },[location])

   const handleLoadGroupSupport = (force) => {
      setLoading(true)
      loadGroupSupport(force) 
      .finally(() => {
         setLoading(false)
      }) 
   }

   if (!roleId) return null
   if(!whoHasAccess.includes(roleId)) {
      return (<div style={{height:500}}><AccessDenied page='Support Desk'/></div>)
   }

   return (
      <Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
         <Info showInfo={showInfo} setShowInfo={setShowInfo} />
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} >
               <SubNav text='Support Desk' showInfo={true} setShowInfo={setShowInfo}/>
               <div style={{borderBottom: '2px solid #eee'}}>
                  <Tabs value={activeTab} variant='fullWidth' indicatorColor='primary' textColor='primary'
                     variant='scrollable' scrollButtons={false}>
                     <Tab label='open' style={{ maxWidth:200 }} component={Link} to='/group/support/open'/>
                     <Tab label='Closed' style={{ maxWidth:200 }} component={Link} to='/group/support/closed' />
                     <Tab label='Notes' style={{ maxWidth:200 }} component={Link} to='/group/support/notes' />
                  </Tabs>
               </div>
               <Collapse in={loading}> 
                  <Alert severity='warning'>
                     Retrieving Support Desk Records ...
                     <LinearProgress color='inherit' />   
                  </Alert> 
               </Collapse>
               <div>
                  <Routes>
                     <Route path='/open' element={<List 
                        selectedTab='open'
                        showMessage={showMessage}
                        setShowMessage={setShowMessage}
                        supportTickets={supportTickets}
                        team={team}
                     />} />
                     <Route path='/closed' element={<List 
                        selectedTab='closed'
                        showMessage={showMessage}
                        setShowMessage={setShowMessage}
                        supportTickets={supportTickets}                        
                     />} />
                     <Route path='/ticket' element={<Ticket 
                        memberId={memberId}
                        roleId={roleId}
                        tickets={supportTickets}
                        team={team}
                        updateTicket={updateTicket} 
                        closeTicket={closeTicket}
                        addTicketNote={addTicketNote} 
                        assignTicket={assignTicket} 
                        archiveTicket={archiveTicket}
                     />} />
                     <Route path='/archived' element={<Archived 
                        archivedTickets={archivedTickets}
                        loadGroupSupportArchived={loadGroupSupportArchived}                        
                     />} />
                     <Route path='/archived-ticket' element={<Ticket 
                        archivedTickets={archivedTickets}
                     />} />
                     <Route path='/notes' element={<Notes 
                        faqs={faqs}
                        notes={notes}
                     />} />
                     <Route path='*' element={<Navigate to='/group/support/open' />} />
                  </Routes>
                  <Outlet />
               </div>
            </Grid>            
         </Grid> 
         <NextUpdate lastUpdated={lastUpdated} updateNow={handleLoadGroupSupport}/>
      </Container>          
   )
}
const mapStateToProps = (state) => ({
   memberId: state.auth.userInfo.id,
   roleId: state.auth.loginOptions[state.auth.loginOptionID].roleId,
   lastUpdated: state.app.lastUpdated.groupSupport,
   showMessage: state.groupSupport.showMessage,
   supportTickets: state.groupSupport.supportTickets,
   archivedTickets: state.groupSupport.archivedTickets,
   faqs: state.groupSupport.faqs,
   notes: state.groupSupport.notes,
   team: state.groupSupport.team
})
const mapDispatchToProps = {
   setShowMessage,
   loadGroupSupport,
   loadGroupSupportArchived,
   updateTicket, 
   closeTicket,
   addTicketNote, 
   assignTicket, 
   archiveTicket

   //deleteTicketNote,
   //addTicketFaq,
   //deleteTicketFaq,
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupSupportContainer)
