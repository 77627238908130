import { GROUP_DASHBOARD_LOAD, GROUP_DASHBOARD_RESET_REDUCER, GROUP_DASHBOARD_SHOW_MESSAGE } from '../types'

const initialState = {
	groupId: null,
	showMessage: true,
	dashboard: {}
}

export default function reducer(state = initialState, action) {
  	switch (action.type) {
  	case GROUP_DASHBOARD_SHOW_MESSAGE:
         return { 
				...state,
				showMessage: action.payload
			}	
		case GROUP_DASHBOARD_LOAD:
         return { 
				...state,
				dashboard: action.payload
			}
		case GROUP_DASHBOARD_RESET_REDUCER:
			return { 
				...state,
	      groupId: action.payload,
	      dashboard: {}
			}
		default:
			return state
	}
}
