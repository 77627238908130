import Alert from '@mui/material/Alert'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function Summary(){
   return (
      <Paper style={{ padding: 10, minHeight:700 }} >
         <div>
            <Alert severity='info'>
            The members section can be accessed by Owner / Admin / Manager / CSR.
            </Alert>
         </div>

         <div style={{marginTop:20}}>
            <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
               <Text variant='body1' color='textSecondary'>
                 chart of last 30 days memberships
               </Text>
            </div>
         </div> 

         <div style={{marginTop:20}}>
            <Text variant='h5' color='textSecondary'>Signup Template</Text>
            <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
               <Text variant='body1' color='textSecondary'>
                 If the signup template is in place this would show the # of fully registered members
                 and like to them (go to filter page to display only registered member)<br/>
                 If the signup template is not in place incourage user to set it up.
               </Text>
            </div>
         </div> 

         <div style={{marginTop:20}}>
            <Text variant='h5' color='textSecondary'>Tags</Text>
            <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
               <Text variant='body1' color='textSecondary'>
                 list of top 10 tags<br/>
                 link to tags page with full list<br/>
                 popup link to create new tag<br/>
                 click on tag to go to filter page with tag members listed
               </Text>
            </div>
         </div>

         <div style={{marginTop:20}}>
            <Text variant='h5' color='textSecondary'>Lists</Text>
            <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
               <Text variant='body1' color='textSecondary'>
                 list of 5 newest lists<br/>
                 link to lists page with full list<br/>
               </Text>
            </div>
         </div>

         <div style={{marginTop:20}}>
            <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
               <Text variant='body1' color='textSecondary'>
                 link to add members
               </Text>
            </div>
         </div>

      </Paper>
   )
}
export default Summary
