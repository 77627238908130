import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import Error404 from './Error404'
import Loader from '../components/Loader'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function Load() {
   const [loading, setLoading] = useState(true)  
  const { page } = useParams()

   //location.pathname
   return (
      <div>
         <Loader loading={loading}/>
         <Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
            <Grid container justifyContent='center' spacing={2}>
               <Grid item xs={12} sm={12} md={10} >
                  <Paper style={{ padding: 10, minHeight:700 }} >
                  <div style={{textAlign:'center'}}>
                     <Text variant='h4' gutterBottom>
                        This is Load:
                     </Text> 
                     <Text variant='body1'>
                        {page}
                     </Text>  
                     </div> 
                  </Paper>
               </Grid>
            </Grid>
         </Container>
      </div>
   )
}
export default Load