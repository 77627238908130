import { useState } from 'react' 

import { chkEmail, chkPassword }  from '../../../includes/functions'
import ServiceAndPolicies from '../Legal/Index'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import LinearProgress from '@mui/material/LinearProgress'
import Stack from '@mui/material/Stack'
import Text from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

function CheckLoginStatus({logIn, emailConfirmation, emailConfirmationConfirm, setStep}) {
   const [loginStep, setLoginStep] = useState('intro') // intro, login, register, confirm, processing, error
   const [resetFormStep, setResetFormStep] = useState('login') // login, register
   const [message, setMessage] = useState('')
   const [email, setEmail] = useState('')
   const [password, setPassword] = useState('')
   const [showPassword, setShowPassword] = useState(false) 
   const [checked, setChecked] = useState(false)
   const [confirmationCode, setConfirmationCode] = useState('')

   const handleChange = (f,v) => {
      f(v)
      setMessage('')
   }
   const handleCheckboxToggle = () => {
      setChecked(!checked)
      setMessage('')
   }
   const handleSubmit = (e) => {
      e.preventDefault()
      if (!chkEmail(email)) {
         return setMessage('Sorry, please enter a valid email address.')
      }
      if (!chkPassword(password)) {
         return setMessage('Sorry, your password is invalid.')
      }
      if (!checked) {
         return setMessage('Sorry, please agree with the Terms of Service')
      }
      setLoginStep('processing')
      logIn(email.toLowerCase(), password)
      .then(() => {
         setStep('confirmProfile')
      })
      .catch((err) => {
         setMessage(err)
         setResetFormStep('login')
         setLoginStep('error')
      })
   }
   const handleRegister = (e) => {
      e.preventDefault()
      if (!chkEmail(email)) {
         return setMessage('Sorry, please enter a valid email address.')
      }
      if (!checked) {
         return setMessage('Sorry, please agree with the Terms of Service')
      }
      setLoginStep('processing') 
      emailConfirmation(email.toLowerCase())
      .then(() => {
         setLoginStep('confirm')
      })
      .catch((err) => {
         setMessage(err)
         setResetFormStep('register')
         setLoginStep('error')
      }) 
   }
   const handleConfirm = (e) => {
      e.preventDefault()
      // confirm that it is a 4-digit code here
      setLoginStep('processing')
      emailConfirmationConfirm(email.toLowerCase(), confirmationCode)
      .then(() => {
         setStep('confirmProfile')
      })
      .catch((err) => {
         setMessage(err)
         setResetFormStep('confirm')
         setLoginStep('error')
      }) 
   }
   const handleResetForm = () => {
      setMessage('') 
      setChecked(false)
      setLoginStep(resetFormStep)
   } 

   return (
      <div>

         {(loginStep === 'intro') ? (
            <div style={{paddingTop:20, paddingBottom:20, maxWidth:400, margin:'0 auto'}}>
               <Text color='text.secondary'> 
                  Please <b>Login / Register</b> to make your purchase.
                  This is required so we know where to send your Receipt.
                  It takes one minute and you would be returned right here when complete.
               </Text>
               <Stack spacing={2} style={{marginTop:20}}>
                  <Button onClick={() => { setLoginStep('login') }} variant='contained' color='secondary'>Login</Button>
                  <Button onClick={() => { setLoginStep('register') }} variant='contained' color='primary'>Register</Button> 
               </Stack>
            </div>
         ):( null )}

         {(loginStep === 'login') ? (
            <div style={{paddingTop:20, paddingBottom:20, maxWidth:400, margin:'0 auto'}}>
               <Text variant='h5'>LOGIN</Text>
               <Text color='text.secondary'> 
                  Please login if you already have an account. If you don't, or can not remember
                  your password, <Button onClick={() => { setLoginStep('register') }} variant='text' style={{padding:0}}>click here</Button> to login via your email.
               </Text>
               <form onSubmit={handleSubmit}>
                  <Stack spacing={2}>
                     <TextField fullWidth size='small' label='Email' placeholder='Enter your email address'
                        onChange={(e) => {handleChange(setEmail, e.target.value) }} type='email' margin='normal'
                     />
                     <TextField fullWidth size='small' label='Password' placeholder='Enter your password'
                        type={showPassword ? 'text' : 'password'} margin='normal'
                        onChange={(e) => {handleChange(setPassword, e.target.value) }}
                        InputProps={{
                           endAdornment: (
                            <InputAdornment position='end'>
                            <IconButton  onClick={() => { setShowPassword(!showPassword) }}>
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                            </InputAdornment>
                           )
                        }}
                     />
                     <div>
                        <Checkbox checked={checked} onChange={handleCheckboxToggle} color='primary' />
                        <ServiceAndPolicies tab={0} text={0} />
                     </div>
                     <Text style={{ marginTop: 10, color: 'red', height: 15 }} component='p' align='center'>
                        <i>{ message }</i>
                     </Text>
                     <Button variant='contained' color='secondary' type='submit'>
                        login
                     </Button>                
                  </Stack>
               </form>
            </div>            
         ):( null )}

         {(loginStep === 'register') ? (
            <div style={{paddingTop:20, paddingBottom:20, maxWidth:400, margin:'0 auto'}}>
               <Text variant='h5'>REGISTER</Text>
               <Text color='text.secondary'> 
                  Please submit your email address then check your email account for a confirmation code.
                  If you already have an account with us, <Button onClick={() => { setLoginStep('login') }} variant='text' style={{padding:0}}>click here</Button> to login.
               </Text>
               <form onSubmit={handleRegister}>
                  <Stack spacing={2}>
                     <TextField fullWidth size='small' label='Email' placeholder='Enter your email address'
                        onChange={(e) => {handleChange(setEmail, e.target.value) }} type='email' margin='normal'
                     />
                     <div>
                        <Checkbox checked={checked} onChange={handleCheckboxToggle} color='primary' />
                        <ServiceAndPolicies tab={0} text={0} />
                     </div>
                     <Text style={{ marginTop: 10, color: 'red', height: 15 }} component='p' align='center'>
                        <i>{ message }</i>
                     </Text>
                     <Button variant='contained' color='secondary' type='submit'>
                        submit
                     </Button> 
                  </Stack>
               </form>
            </div>
         ):( null )}

         {(loginStep === 'confirm') ? (
            <div style={{paddingTop:50, paddingBottom:50, maxWidth:400, margin:'0 auto'}}>
               <Text variant='h5'>CONFIRM</Text>
               <Text color='text.secondary'> 
                  Please check your email ({email}) for the confirmation code that was sent to you and enter it below.
                  If you would like to use a different email address, <Button onClick={() => { setLoginStep('register') }} variant='text' style={{padding:0}}>click here</Button>.
               </Text>
               <form onSubmit={handleConfirm}>
                  <Stack spacing={2}>
                     <TextField fullWidth size='small' label='Confirmation Code' placeholder='Enter your 4-digit confirmation code'
                        onChange={(e) => {handleChange(setConfirmationCode, e.target.value) }} type='number' margin='normal'
                     />
                     <Text style={{ marginTop: 10, color: 'red', height: 15 }} component='p' align='center'>
                        <i>{ message }</i>
                     </Text>
                     <Button variant='contained' color='secondary' type='submit'>
                        confirm account
                     </Button> 
                  </Stack>
               </form>
            </div>
         ):( null )}

         {(loginStep === 'processing') ? (
            <div style={{paddingTop:50, paddingBottom:50, maxWidth:400, margin:'0 auto'}}>
              Processing your request...
              <LinearProgress color='inherit' />
            </div>
         ):( null )}

         {(loginStep === 'error') ? (
            <div style={{paddingTop:50, paddingBottom:50, maxWidth:400, margin:'0 auto'}}>
              <Alert severity='error'>
                  <AlertTitle>Login Error</AlertTitle>
                  {message || 'Sorry, an error occured, please try again.'}
               </Alert>
               <div style={{ marginTop:20 }}><Button onClick={() => { handleResetForm() }} variant='outlined' color='secondary'>try again</Button></div>
            </div>
         ):( null )}
      </div>
   )
}
export default CheckLoginStatus
