import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function DeliveryPolicy() {
  return (
    <div>
      <Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} >
               <Paper style={{ padding: 10, minHeight:500 }} >
                  <div style={{marginTop:50, marginBottom:100}}>
                     <Text variant='h4' gutterBottom color='textSecondary'>
                        Delivery Policy
                     </Text> 
                     <Text variant='h6'>
                        Payment Receipt
                     </Text> 
                     <Text variant='body1' gutterBottom>
                        Upon the successful completion of a purchase on SecureTix.com, a Payment Receipt would be delivered to the email address associated with the account that made the purchase. This receipt will include the Invoice Id that was paid, the amount of items purchased, the amount charged to your card and the currency. This email would also <b>contain a link to your eTickets</b>.
                     </Text> 
                     <Text style={{color:'red'}}><i><b>NOTE:</b> Online Solutions Ltd would be the merchant displayed on your Credit Card statement.</i></Text>
                     <Text variant='h6' style={{marginTop:20}}>
                        eTickets
                     </Text> 
                     <Text variant='body1' gutterBottom>
                        Upon the successful completion of a purchase on SecureTix.com, your eTickets would be transferred to your <b>Tickets Folder</b> on SecureTix.com. A link to this folder would also be included in the Payment Receipt you receive via email.
                     </Text>
                     <Text variant='body1' gutterBottom>
                        You can also view your eTickets by using the navigation in the top right, to navigate to <b>MY STUFF</b> that would contain your eTickets.
                     </Text>
                  </div>  
               </Paper>
            </Grid>
         </Grid>
      </Container>
    </div>
  );
}
export default DeliveryPolicy