import { useState } from 'react' 

import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import IconClose from '@mui/icons-material/Close'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Text from '@mui/material/Typography'
import Toolbar from '@mui/material/Toolbar'

function Info({showInfo, setShowInfo}){
   const [selectedTab, setSelectedTab] = useState(0)
   
   const handleChange = (event, tabValue) => {
      setSelectedTab(tabValue)
   }

   return (
      <Dialog open={showInfo} onClose={() => setShowInfo(!showInfo)}>
   
         <AppBar style={{ position: 'relative' }} >
            <Toolbar>
               <Text variant='subtitle2' color='inherit' style={{ flex: 1 }}>
                  Group | Events Info
               </Text>
               <IconButton color='inherit' onClick={() => setShowInfo(!showInfo)}>
                  <IconClose />
               </IconButton>
            </Toolbar>
         </AppBar>
         <AppBar position='static' color='default'>
            <Tabs value={selectedTab} onChange={handleChange} variant='fullWidth' indicatorColor='primary'>
               <Tab label='About'/>
               <Tab label='FAQs'/>
               <Tab label='Activity'/>
            </Tabs>
         </AppBar>
         <DialogContent>
                Group Events Info Dialog<br/>
                about | faqs | activity<br/>
                this should be quick reference slides highlighting the features of Group Events.
         </DialogContent>
         <DialogActions>
            <Button onClick={() => setShowInfo(!showInfo)} color='primary' autoFocus>Close</Button>
         </DialogActions>
      </Dialog>
   )
}
export default Info
