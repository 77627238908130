import { useEffect, useState } from 'react' 
import { connect } from 'react-redux'
import { Routes, Route, Navigate, Outlet, Link, useLocation, useParams } from 'react-router-dom'
import { loadSummary, loadFeed, loadMemberships, adjustRole, adjustMembership } from '../../../redux/memberAccount/actions'

import Feed from './Feed'
import Info from './info'
import Memberships from './Memberships'
import SubNav from '../../components/SubNav'
import Summary from './Summary'

import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

function MemberAccountContainer({ lastUpdatedSummary, lastUpdatedFeed, lastUpdatedMemberships, summary, feed, memberships, roles, loadSummary, loadFeed, loadMemberships, adjustRole, adjustMembership }) {
   const [showInfo, setShowInfo] = useState(false)
   const [activeTab, setActiveTab] = useState(false) 
   let location = useLocation()

   useEffect(() => {
      switch (true) {
         case location.pathname.includes('/member/account/summary'):
            setActiveTab(0)
         break
         case location.pathname.includes('/member/account/feed'):
            setActiveTab(1)
         break
         case location.pathname.includes('/member/account/memberships'):
            setActiveTab(2)
         break
         default:
            setActiveTab(false)
      }
   },[location])

   return (
      <Container maxWidth='lg' style={{ padding: 10, marginBottom:50 }}>
         <Info showInfo={showInfo} setShowInfo={setShowInfo} />
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} >
               <SubNav text='My Account' showInfo={false} setShowInfo={setShowInfo}/>
               <div style={{borderBottom: '2px solid #eee'}}>
                  <Tabs value={activeTab} variant='fullWidth' indicatorColor='primary' textColor='primary'>
                     <Tab label='Summary' style={{ maxWidth:200 }} component={Link} to='/member/account/summary'/>
                     <Tab label='My Feed' style={{ maxWidth:200 }} component={Link} to='/member/account/feed' />
                     <Tab label='Memberships' style={{ maxWidth:200 }} component={Link} to='/member/account/memberships' />
                  </Tabs>
               </div>
               <div>
                  <Routes>
                     <Route path='/summary' element={<Summary 
                        lastUpdated={lastUpdatedSummary}  
                        summary={summary}
                        loadSummary={loadSummary}
                     />} />
                     <Route path='/feed' element={<Feed 
                        lastUpdated={lastUpdatedFeed}
                        feed={feed}
                        loadFeed={loadFeed}
                     />} />
                     <Route path='/memberships' element={<Memberships 
                        lastUpdated={lastUpdatedMemberships} 
                        memberships={memberships}
                        roles={roles}
                        loadMemberships={loadMemberships}
                        adjustRole={adjustRole}
                        adjustMembership={adjustMembership}
                     />} />
                     <Route path='*' element={<Navigate to='/member/account/summary' />} />
                  </Routes>
                  <Outlet />
               </div>
            </Grid>
         </Grid>
      </Container>
   )
}
const mapStateToProps = (state) => ({
   lastUpdatedSummary: state.app.lastUpdated.memberSummary,
   lastUpdatedFeed: state.app.lastUpdated.memberFeed,
   lastUpdatedMemberships: state.app.lastUpdated.memberMemberships,
   summary: state.memberAccount.summary,
   feed: state.memberAccount.feed,
   memberships: state.memberAccount.memberships,
   roles: state.memberAccount.roles
})
const mapDispatchToProps = {
   loadSummary, 
   loadFeed, 
   loadMemberships,
   adjustRole,
   adjustMembership
}
export default connect(mapStateToProps, mapDispatchToProps)(MemberAccountContainer)
