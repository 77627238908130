import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function Payins(){
   return (
      <Paper style={{ padding: 10, minHeight:700 }} >
       
         <div align='center' style={{marginTop:10, padding:50, backgroundColor:'#f1f1f1'}}>
            <Text variant='body1' color='textSecondary'>
               Graph of PayIn Activity for last 30 days.<br/>
               (Coming Soon)
            </Text>
         </div> 

         <Text variant='h5' color='textSecondary'>Payin History</Text>
         <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
            <Text variant='body1' color='textSecondary'>
               Last 25 Payins - button to view more (link to transactions filter page) and filter types of payins.
               (transfers from members, invoice payments, ticket sales)
            </Text>
         </div>
      </Paper>
   )
}
export default Payins
