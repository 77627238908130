import { MEMBER_SUPPORT_LOAD, MEMBER_SUPPORT_ADD_TICKET, MEMBER_SUPPORT_UPDATE_TICKETS } from '../types'

const initialState = {
	supportCategories: [],
	supportGroups: [],
	supportTickets: {}
}

export default function reducer(state = initialState, action) {
  	switch (action.type) {
		case MEMBER_SUPPORT_LOAD:
         return { 
				...state,
				supportCategories: action.payload.supportCategories,
				supportGroups: action.payload.supportGroups,
				supportTickets: action.payload.supportTickets
			}
		case MEMBER_SUPPORT_ADD_TICKET:
			return { 
				...state, 
				supportTickets: {
               ...state.supportTickets,
               [action.payload.id]: action.payload.info
            }
			}	
		case MEMBER_SUPPORT_UPDATE_TICKETS:
      return { 
				...state,
				supportTickets: action.payload
			}
		default:
			return state
	}
}
