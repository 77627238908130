import { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { chkEmail }  from '../../../includes/functions'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

const roles = {
   Admin: 'This is an admin. Admin have alot of power so choose whisely and even ;more if i mae it wider',
   Manager: 'this is ah manager',
   Editor:'this is ah editor',
   CSR:'this is a csr',
   Attendant:'attendant',
   Sales_Rep:'watta sales rep'
}

function NewMember({addTeamMember}){
   const [roleEl, setRoleEl] = useState(null)
   const [step, setStep] = useState('new')
   const [message, setMessage] = useState('')
   const [err, setErr] = useState('')
   const [firstName, setFirstName] = useState('')
   const [lastName, setLastName] = useState('')
   const [email, setEmail] = useState('')
   const [searchParams] = useSearchParams()
   const selectedRole = searchParams.get('role') 
   const [role, setRole] = useState(selectedRole || '')

   useEffect(() => {
      if (role) { setRole(role) }
   },[])

   const handleChange = (set,value) => {
      setMessage('')
      set(value)
   }
   const handleChangeRole = (role) => {
      setMessage('')
      setRoleEl(null)
      setRole(role)
   }
   const handleAddMember = () => {
      if(firstName === '' || lastName === '' || role === '') {
         setMessage('Sorry, all fields are required.')
         return false
      }
      if (!chkEmail(email)) {
         setMessage('Please enter a valid email address.')
         return false 
      }

      let teamMember = {firstName,lastName,email,role}
      setStep('loading')
      addTeamMember(teamMember)
      .then(() => {
         setStep('complete')
      })
      .catch((error) => {
         setErr(error)
         setStep('error')
      })
   }
   const handleAddAnotherTeamMember = () => {
      setFirstName('')
      setLastName('')
      setEmail('')
      setRole('')
      setMessage('')
      setErr('')
      setStep('new')
   }   
   return (
      <Paper style={{ padding: 20, minHeight:600 }} >
         <div style={{ maxWidth: 600, margin:'0 auto' }}>
         <Grid container spacing={2}>
            <Grid item xs={12}>
               
               {(step === 'new') ? (   
               <div>             
                  <div style={{ display: 'flex' }}>
                     <Text variant='h5' gutterBottom>Add Team Member</Text>
                  </div>
                  <Grid container justify='center' spacing={2}>
                     <Grid item xs={12} sm={6}>
                        <TextField fullWidth
                           label='First Name'
                           name='firstName'
                           value={firstName}
                           onChange={(e) => {handleChange(setFirstName,e.target.value)}}
                           //margin='normal'
                        />
                     </Grid>
                     <Grid item xs={12} sm={6}>
                        <TextField fullWidth
                           label='Last Name'
                           name='lastName'
                           value={lastName}
                           onChange={(e) => {handleChange(setLastName,e.target.value)}}
                           //margin='normal'
                        />
                     </Grid>
                     <Grid item xs={12} sm={12}>
                        <TextField fullWidth
                           label='Email Address'
                           name='email'
                           value={email}
                           onChange={(e) => {handleChange(setEmail,e.target.value)}}
                           //margin='normal'
                        />
                     </Grid>                     
                     <Grid item xs={12} sm={12}>
                        <List component='nav' style={{backgroundColor:'#f1f1f1', padding:2}}>
                           <ListItem button onClick={(event) => { setRoleEl(event.currentTarget) }} style={{padding:10}}>
                              <ListItemText primary={(role) ? 'Role: ' + role.replace(/_/g, ' ') : 'Select Role'} secondary={roles[role] || 'Select Team Member Role'} />
                           </ListItem>
                        </List>     
                        <Menu anchorEl={roleEl} open={Boolean(roleEl)} onClose={() => { setRoleEl(null) }} dense='true'>
                           <MenuItem value='Admin' selected={role === 'Admin' } onClick={() => {handleChangeRole('Admin')}}>
                              <ListItemText style={{whiteSpace: 'normal', maxWidth:400}} primary='Admin' secondary={roles['Admin']} />
                           </MenuItem>
                           <MenuItem value='Manager' selected={role === 'Manager' } onClick={() => {handleChangeRole('Manager')}}>
                              <ListItemText style={{whiteSpace: 'normal'}} primary='Manager' secondary={roles['Manager']} />
                           </MenuItem>
                           <MenuItem value='Editor' selected={role === 'Editor' } onClick={() => {handleChangeRole('Editor')}}>
                              <ListItemText style={{whiteSpace: 'normal'}} primary='Editor' secondary={roles['Editor']} />
                           </MenuItem>
                           <MenuItem value='CSR' selected={role === 'CSR' } onClick={() => {handleChangeRole('CSR')}}>
                              <ListItemText style={{whiteSpace: 'normal'}} primary='CSR' secondary={roles['CSR']} />
                           </MenuItem>
                           <MenuItem value='Attendant' selected={role === 'Attendant' } onClick={() => {handleChangeRole('Attendant')}}>
                              <ListItemText style={{whiteSpace: 'normal'}} primary='Attendant' secondary={roles['Attendant']} />
                           </MenuItem>
                           <MenuItem value='Sales_Rep' selected={role === 'Sales_Rep' } onClick={() => {handleChangeRole('Sales_Rep')}}>
                              <ListItemText style={{whiteSpace: 'normal'}} primary='Sales Rep' secondary={roles['Sales_Rep']} />
                           </MenuItem>
                        </Menu>
                     </Grid>
                  </Grid>
                  <div align='center' style={{ height: 25 }}>
                     <Text style={{ color: 'red' }}><i>{message}</i></Text>
                  </div>
                  <div align='right'>
                     <Button variant='contained' color='primary' onClick={() => { handleAddMember() }}>
                        add team member
                     </Button>
                  </div>
                  <div align='right' style={{borderTop:'2px solid #f1f1f1', marginTop:20, paddingTop:10}}>
                     <div style={{ marginTop:20 }}><Button component={Link} to='/group/settings/team' variant='outlined' color='secondary'>view team members</Button></div> 
                  </div>
               </div>
               ):( null )}

               {(step === 'loading') ? (   
               <div>             
                  <Alert severity='warning'>
                     <AlertTitle>Processing...</AlertTitle>
                     Your request is being processed. <strong>{firstName.toUpperCase()}</strong> is being added to your team.
                     <LinearProgress color='inherit' />
                  </Alert>
               </div>
               ):( null )}

               {(step === 'error') ? (   
               <div>             
                  <Alert severity='error'>
                     <AlertTitle>{err.title || 'Error'}</AlertTitle>
                     {err.message || 'Sorry, an error occured, please try again.'}
                  </Alert>
                  <div style={{ marginTop:20 }}><Button onClick={() => {handleAddAnotherTeamMember()}} variant='outlined' color='secondary'>try again</Button></div>
                  <div style={{ marginTop:20 }}><Button component={Link} to='/group/settings/team' variant='outlined' color='secondary'>return to team members</Button></div>
               </div>
               ):( null )}

               {(step === 'complete') ? (   
               <div> 
                  <Alert severity='success'>
                     <AlertTitle>Team Member Added</AlertTitle>
                     <strong>{firstName.toUpperCase()}</strong> was added to your team as an <strong>{role.replace(/_/g, ' ').toUpperCase()}</strong>. 
                     An email was sent to confirm acceptance.
                     What would you like to do next?
                  </Alert>
                  <div style={{ marginTop:20 }}><Button onClick={() => {handleAddAnotherTeamMember()}} variant='outlined' color='secondary'>add another team member</Button></div>
                  <div style={{ marginTop:20 }}><Button component={Link} to='/group/settings/team' variant='outlined' color='secondary'>view team members</Button></div>
               </div>
               ):( null )}

            </Grid>    
         </Grid>
         </div>
      </Paper> 
   )
}
export default NewMember
