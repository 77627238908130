import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function Settings() {
  return (
    <div>
      <Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} >
               <Paper style={{ padding: 10, minHeight:700 }} >
                  <div style={{textAlign:'center'}}>
                     <Text variant='h4'>
                        Settings
                     </Text> 
                     <Text variant='subtitle1' gutterBottom>
                        Web App Settings
                        <ul>
                          <li>Default Currency</li>
                          <li>Default Location</li>
                          <li>Custom page expiry settings</li>
                          <li>etc.</li>
                        </ul>
                     </Text> 
                  </div>  
               </Paper>
            </Grid>
         </Grid>
      </Container>
    </div>
  );
}
export default Settings