import { PUBLIC_EVENTS_LOAD, PUBLIC_EVENT_DETAILS_LOAD } from '../types'

const initialState = {
	events: [],             //array of public upcomming published events
	eventDetails: {},       //key = event id
	availableTickets: {},   //key = event id
	availableItems: {}		//key = event id
}

export default function reducer(state = initialState, action) {
  	switch (action.type) {
		case PUBLIC_EVENTS_LOAD:
         return { 
				...state,
				events: action.payload,
				eventDetails: {},
	         availableTickets: {},  
	         availableItems: {}
			}
		case PUBLIC_EVENT_DETAILS_LOAD:
         return { 
				...state,
				eventDetails: {
					...state.eventDetails,
					[action.payload.id]: action.payload.details					
				},
            availableTickets: {
					...state.availableTickets,
					[action.payload.id]: action.payload.tickets	        	
            }, 
            availableItems: {
					...state.availableItems,
					[action.payload.id]: action.payload.items	        	
            }
		   }
		default:
			return state
	}
}
