import { useEffect, useState } from 'react' 
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { loadEvents } from '../../redux/publicEvents/actions'

import EventsList from '../components/EventsList'
import NextUpdate from '../components/NextUpdate'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Badge from '@mui/material/Badge'
//import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Container from '@mui/material/Container'
//import Divider from '@mui/material/Divider'
import Fab from '@mui/material/Fab'
import Grid from '@mui/material/Grid'
import IconCart from '@mui/icons-material/ShoppingCartOutlined'
//import IconFilterList from '@mui/icons-material/FilterList'
import LinearProgress from '@mui/material/LinearProgress'
//import Menu from '@mui/material/Menu'
//import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'
//import Toolbar from '@mui/material/Toolbar'

const fabStyle = { position:'fixed', bottom:55, right:15 }

function PublicEventsContainer({lastUpdated, events, orderedItems, loadEvents}) {
   const website = 'listedOnSecureTix'
   const [country, setCountry] = useState('ALL')
   //const [anchorEl, setAnchorEl] = useState(null)
   const [loading, setLoading] = useState(false)
   const [filteredEvents, setFilteredEvents] = useState([]) 
   const [e, setE] = useState(null)

   useEffect(() => {
      handleFetchEvents()
   },[])

   useEffect(() => {
      handleFilterEvents()
   },[events])

   const handleFetchEvents = (force) => {
      // check user country location here
      setLoading(true)
      loadEvents(force, website, country) 
      .then(() => {
         setE(null)
         setLoading(false)
      }) 
      .catch((error) => {
         setE(error)
         setLoading(false)
      })
   }
   const handleFilterEvents = (type='list', filter='all') => {
      let allEvents = events
      // filter events here!
      setFilteredEvents(allEvents)
     // setAnchorEl(null)
      //this.props.history.push({ pathname: '/campaigns/' + type + '/' + filter }) 
   }

   return (
    <div>
      <Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} >
               <Paper style={{ padding: 10, minHeight:700 }} >
                  {/*
                  <Toolbar variant='dense' style={{ paddingLeft:5, marginBottom:20, display: 'flex', borderBottom: '2px solid #ccc'}}>
                     <Button size='small' onClick={(e) => {setAnchorEl(e.currentTarget)}}><IconFilterList style={{marginRight:5}}/>Current Filter</Button>
                     <Menu  anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => {setAnchorEl(null)}}>
                        <Text variant='overline' style={{ padding:5, color:'#999' }}>Browse Campaigns</Text>
                          <Divider />
                        <MenuItem selected={false} onClick={() => { handleFilterEvents() }}>- Recent</MenuItem>
                     </Menu>
                  </Toolbar>
                  */}
                  
                  <Collapse in={loading}> 
                     <Alert severity='warning' style={{marginBottom:20}}>
                        Retrieving Upcoming Events ...
                        <LinearProgress color='inherit' />   
                     </Alert> 
                  </Collapse>

                  <Collapse in={Boolean(e)}> 
                     <Alert severity='error' style={{marginBottom:20}}>
                        <AlertTitle style={{margin:0}}>{e && e.title}</AlertTitle>
                        {e && e.message} 
                     </Alert> 
                  </Collapse>

                  {(filteredEvents.length) ? (
                     <EventsList events={filteredEvents}/>
                  ):(
                     <div>
                        <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
                           <Text variant='body1' color='textSecondary'>
                              No Upcoming Public Events
                           </Text>
                        </div>
                     </div>
                  )}
               </Paper>
            </Grid>
         </Grid>
         <NextUpdate lastUpdated={lastUpdated} updateNow={handleFetchEvents}/>
      </Container>
      {(orderedItems.length) ? (
         <Fab sx={fabStyle} component={Link} to={'/cart'} size='medium' color='secondary'>
            <Badge badgeContent={orderedItems.length}>
               <IconCart />
            </Badge>
         </Fab>
      ):( null)}
    </div>
   )
}
const mapStateToProps = (state) => ({
   lastUpdated: state.app.lastUpdated.publicEvents || 0,
   events: state.publicEvents.events || [],
   orderedItems: state.publicStore.orderedItems || []
})
const mapDispatchToProps = {
   loadEvents
}
export default connect(mapStateToProps, mapDispatchToProps)(PublicEventsContainer)
