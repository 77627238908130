import { useEffect, useState } from 'react' 
import { useNavigate } from 'react-router-dom'
import format from 'date-fns/format'

import Alert from '@mui/material/Alert'
import Avatar from '@mui/material/Avatar'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import IconArrowForward from '@mui/icons-material/ArrowForwardIos'
import LinearProgress from '@mui/material/LinearProgress'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function GroupSupportArchived({archivedTickets, loadGroupSupportArchived}){
   const [loading, setLoading] = useState(true) 
   const navigate = useNavigate()

   useEffect(() => {
      loadGroupSupportArchived()
      .finally(() => {
         setLoading(false)
      }) 
   },[])
      
   const openTicket = (id) => {
      navigate('/group/support/ticket?id=' + id)
   }
   return (
      <Paper style={{ padding: 10, minHeight:700 }} >
         <Collapse in={loading}>
            <Alert severity='warning' style={{marginBottom:10}}>
               Retrieving Archived Tickets...
               <LinearProgress color='inherit' />   
            </Alert> 
         </Collapse>

         {(Object.keys(archivedTickets).length) ? (
            <List style={{width:'100%', padding:5}}>
               {Object.keys(archivedTickets).map((i) => {
                  return (
                     <div key={i}>
                        <ListItem button alignItems="flex-start" onClick={() => {openTicket(i)}}>
                           <ListItemAvatar>
                              <Avatar alt={archivedTickets[i].memberName} src={archivedTickets[i].memberThumb} style={{borderRadius:5}} />
                           </ListItemAvatar>
                           <ListItemText>
                              <b>{archivedTickets[i].memberName}</b>
                              <Text style={{lineHeight:.8}} variant='caption' display='block' color='textSecondary'>
                                 On: { format(new Date(archivedTickets[i].timestamp), 'eee do MMM y - h:mm a')}
                              </Text>
                              <Text style={{marginTop:5, lineHeight:1, color:'#546e7a'}}><b>{archivedTickets[i].subject}</b></Text>
                              <Text style={{lineHeight:1.5, color:'#000'}}><b>ARCHIVED</b></Text>
                           </ListItemText>
                           <ListItemSecondaryAction>
                              <IconArrowForward />
                           </ListItemSecondaryAction>
                        </ListItem>
                        <Divider/> 
                     </div>
                  )
               })}
            </List>
         ):(
            <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
               <Text variant='body1' color='textSecondary'>NO ARCHIVE TICKETS</Text>
            </div> 
         )}
      </Paper>
   )
}
export default GroupSupportArchived
