import { Routes, Route, Outlet, Navigate } from 'react-router-dom'

import Account from './member/account/_Container'
import CreateGroup from './member/account/createGroup/_Container'
import Profile from './member/profile/_Container'
import Invoice from './member/stuff/invoice/_Container'
import Setup from './member/setup/_Container'
import Stuff from './member/stuff/_Container'
import Support from './member/support/_Container'

export default function MemberRoutes() {
   return (
      <div>
         <Routes>
            <Route path='/account/creategroup' element={<CreateGroup/>} />
            <Route path='/account/*' element={<Account/>} />
            <Route path='/profile/*' element={<Profile/>} />
            <Route path='/stuff/invoice/:invoiceId' element={<Invoice/>} />
            <Route path='/stuff/*' element={<Stuff/>} />
            <Route path='/setup/*' element={<Setup/>} />
            <Route path='/support/*' element={<Support/>} />
            <Route path='*' element={<Navigate to='/member/account'/>} />
         </Routes>
         <Outlet />
      </div>
   )
}