import { useState } from 'react'
import ReactQuill from 'react-quill'

import Text from '@mui/material/Typography'

function MyTextArea({verb, message, text, action}){
   const [value, setValue] = useState(text)

   const modules = {
     toolbar: [
        //[{ header: [1, 2, 3, 4, 5] }], // custom dropdown
        [{ size: [ 'small', false, 'large', 'huge' ]}],
        [{ 'color': [] }, { 'background': [] }, 'bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'indent': '-1'}, { 'indent': '+1' },{ 'align': [] }],
        ['image', 'link'],
        ['clean'] 
     ]
   }
   const returnValue = () => {
      if(value.replace(/(<([^>]+)>)/gi, "") === ''){
         return action('')
      }
      action(value)
   }  
   return (
      <div>
         <Text style={{color:'#808080'}}>{verb}</Text>
         <ReactQuill
            theme='snow' 
            placeholder={message} 
            defaultValue={value}
            modules={modules} 
            onChange={setValue}
            onBlur={() => { returnValue() }}
         />
      </div>
   )
}
export default MyTextArea
