import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

import Loader from '../../components/Loader'

import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import Text from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

function NewTicket({categories, groups, openTicket}){
   const [loading, setLoading] = useState(false)
   const [message, setMessage] = useState('')
   const [groupId, setGroupId] = useState('')
   const [category, setCategory] = useState('')
   const [subject, setSubject] = useState('')
   const [post, setPost] = useState('')
   let navigate = useNavigate()

   const handleChange = (f,v) => {
      f(v)
      setMessage('')
   }
   const handleSubmitTicket = () => {
      if(groupId === '' || category === '' || subject === '' || post === '') {
         return setMessage('Sorry, all fields are required.')
      }
      let ticket = {
         groupId: groupId,
         category: category,
         subject: subject,
         post: post
      }
      setLoading(true)
      openTicket(ticket)
      .then(() => {
         setLoading(false)
         Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Ticket Submitted!',
            text: 'Your query was submitted successfully.',
            showConfirmButton: true
         })
         navigate('/member/support/tickets')
      })
      .catch((e) => {
         setLoading(false)
         Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: e.title, 
            text: e.message,
            showConfirmButton: true
         })
         setMessage('Please try again')
      })
   }
    
   return (
      <div>
         <Loader loading={loading} title='Submitting Ticket' text='Submitting your query, please wait...' />
         <Paper style={{padding: 20, minHeight:800 }} >
            <div style={{maxWidth:600, margin:'0px auto', marginTop:10}}>
               <Text color='textSecondary'>
                  Need Assistance? <br/>Select the group you would like assistance with and open a Support Ticket with them.
               </Text>
               <div style={{padding:10}}>
                  <FormControl style={{width:'100%', marginTop:10}}>
                     <InputLabel>Who do you need assistance from?</InputLabel>
                     <Select size='small' label='Who do you need assistance from?' value={groupId} onChange={(e)=>{setGroupId(e.target.value)}}>
                        {groups.map((group,id) => {
                           return ( <MenuItem key={id} value={group.id}>{group.name}</MenuItem> )
                        })}
                     </Select>
                  </FormControl>
                
                  <FormControl style={{width:'100%', marginTop:10}}>
                     <InputLabel>What do you need assistance with?</InputLabel>
                     <Select size='small' label='What do you need assistance with?' value={category} onChange={(e)=>{setCategory(e.target.value)}}>
                        {categories.map((category,id) => {
                           return ( <MenuItem key={id} value={category}>{category}</MenuItem> )
                        })}
                     </Select>
                  </FormControl>

                  <TextField size='small' label='Subject' style={{width:'100%', marginTop:10}}
                     value={subject} onChange={(e)=>{setSubject(e.target.value)}}
                  />    

                  <TextField fullWidth multiline size='small' margin='normal' rows='4' label='Post' name='Post'
                     value={post} onChange={(e) => {handleChange(setPost, e.target.value) }}
                  />

                  <div style={{marginTop:10, textAlign:'center'}}><Text variant='body1' style={{color:'red'}}>{message}</Text></div>
                  <div style={{marginTop:10, marginBottom:20, textAlign:'right'}}><Button onClick={handleSubmitTicket} size='small' variant='contained' color='secondary'>Submit Ticket</Button></div>
               </div>
            </div>    
         </Paper>
      </div>
   )
}
export default NewTicket
