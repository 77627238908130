import { useState } from 'react' 
import SwipeableViews from 'react-swipeable-views'

import Paper from '@mui/material/Paper'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Text from '@mui/material/Typography'

function EventDetails({event}) {
   const [activeTab, setActiveTab] = useState(0)
   if (!Object.keys(event).length) { return null }

   const handleTabChange = (event, newValue) => {
      setActiveTab(newValue)
   }
   const handleChangeIndex = index => {
      setActiveTab(index)
   }
   
   return (
      <Paper elevation={1} style={{marginTop:20, padding: 5, minHeight:225}} >
         <div style={{borderBottom: '2px solid #eee', backgroundColor:'white'}}>  
            <Tabs value={activeTab} onChange={handleTabChange} variant='fullWidth' indicatorColor='primary' textColor='primary'>
               <Tab label='Details' />
               <Tab label='Location' />
               <Tab label='FAQs' />
            </Tabs>
         </div>
         <SwipeableViews enableMouseEvents axis='x' index={activeTab} onChangeIndex={handleChangeIndex}>
            <div hidden={activeTab !== 0}>
                <Details event={event}/>
            </div>
            <div hidden={activeTab !== 1}>
               <Map event={event}/>
            </div>
            <div hidden={activeTab !== 2}>
               <FAQs event={event}/>
            </div>
         </SwipeableViews>
      </Paper> 
   )
}
export default EventDetails

function Details({event}) {
   if (!event.description) {
      return (
         <div align='center' style={{margin:0, padding:50}}>
            <Text variant='body1' color='textSecondary'>
               No Further Details Posted.
            </Text>
         </div>
      )
   } else {
      return (
         <div>
            <section
               className='MyTextArea ql-editor'
               dangerouslySetInnerHTML={{ __html: event.description }}
            />
         </div>
      )
   }
}

function Map({event}) {
   if (!event.location) {
      return (
         <div align='center' style={{margin:0, padding:50}}>
            <Text variant='body1' color='textSecondary'>
               No Location Map Posted.
            </Text>
         </div>
      )
   } else {
      return (
         <div>Google Map based on event location.</div>
      )
   }
}

function FAQs({event}) {
   if (!event.faqs) {
      return (
         <div align='center' style={{margin:0, padding:50}}>
            <Text variant='body1' color='textSecondary'>
               No Frequently Asked Questions Posted.
            </Text>
         </div>
      )
   } else {
      return (
         <div>Frequently Asked Questions for this event.</div>
      )
   }
}
