import CircularProgress from '@mui/material/CircularProgress'
import Text from '@mui/material/Typography'

const defaultTitle = 'Page Loading'
const defaultText = 'Loading content, please wait...'

function Loader({loading, title=defaultTitle, text=defaultText}){
    if (!loading) return null
    return (
      <div className='loading-ui-overlay'>
        <div className="loading-ui-wrapper">
          <div className="loading-ui-body">
            <CircularProgress color='secondary' style={{ margin: '5px auto'}} />
            <Text variant='h6'>{title}</Text>
            <Text variant='subtitle1'>{text}</Text>
          </div>
        </div>
      </div> 
    )
}
export default Loader