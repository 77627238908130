import { useEffect, useState } from 'react' 

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Text from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import IconImage from '@mui/icons-material/CropOriginal'
import IconClose from '@mui/icons-material/Close'

function GroupProfilePage({open, closeView, groupId}) {
	const theme = useTheme()
   const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

	useEffect(() => {
		console.log('oopened: ',groupId)
	},[])	

	return (
		<Dialog fullScreen={fullScreen} open={open} onClose={() => {closeView()}}>
         <AppBar style={{ position: 'relative' }} >
            <Toolbar>
               <IconImage style={{ marginRight: 5 }} />
               <div style={{ flex: 1 }}><Text variant='subtitle2' color='inherit'>Group Details</Text></div>
               <IconButton color='inherit' onClick={() => {closeView()}}><IconClose /></IconButton>
            </Toolbar>
         </AppBar>
         <DialogContent>
            <DialogContentText>
               Details Coming Soon!
            </DialogContentText>
         </DialogContent>
         <DialogActions>
          	<Button onClick={() => {closeView()}} color='primary'>Close</Button>
         </DialogActions>
      </Dialog>   


	)
}
export default GroupProfilePage
