import { useEffect, useState } from 'react' 
import { Link } from 'react-router-dom'

import NextUpdate from '../../components/NextUpdate'

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'

function Summary({lastUpdated, summary, loadSummary}) {
   const [loading, setLoading] = useState(false) 

   useEffect(() => {
      handleFetchMemberSummary()
   },[])

   const handleFetchMemberSummary = (force) => {
      setLoading(true)
      loadSummary(force) 
      .finally(() => {
         setLoading(false)
      })
   }

	return (
      <div>
   		<Paper style={{ padding: 10, minHeight:700 }} >
            <Stack spacing={3} style={{maxWidth:300, margin:'0 auto',marginTop:50}}>
               <Button fullWidth size='large' color='primary' variant='outlined' component={Link} to='/'>Get Tickets</Button>
               <Button fullWidth size='large' color='primary' variant='outlined' component={Link} to='/member/stuff/tickets'>View My Tickets</Button>
               <Button fullWidth size='large' color='primary' variant='outlined' component={Link} to='/member/profile/profile'>Update My Profile</Button>
               <Button fullWidth size='large' color='primary' variant='outlined' component={Link} to='/member/support/new'>Get Support</Button>
            </Stack>

         {/*
            <Collapse in={loading}> 
               <Alert severity='warning' style={{marginBottom:20}}>
                  Updating Account Summary ...
                  <LinearProgress color='inherit' />   
               </Alert> 
            </Collapse>

            <div>Summary
            <ul>
                  <li>membership summary with create group</li>
                  <li>tickets summary with create event</li>
                  <li>notifications</li>
                  <li><b>view my feed</b></li>
               </ul>
            </div>
         */}
         </Paper>
         <NextUpdate lastUpdated={lastUpdated} updateNow={handleFetchMemberSummary}/>
      </div>
   )
}
export default Summary
