import { Link } from 'react-router-dom'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import Text from '@mui/material/Typography'

function SubNav() {
   return (
      <AppBar position='static' color='default' elevation={1}>
         <Toolbar variant='dense'>
            <Button>
               <Text variant='h6' color='primary' style={{ paddingRight: 15 }}>
                  <b>Event</b>
               </Text>
            </Button>
            <div align='right' style={{width:'100%'}}><Button component={Link} to='/events'>View All Events</Button></div>
         </Toolbar>
      </AppBar> 
   )
}
export default SubNav