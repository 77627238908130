import Alert from '@mui/material/Alert'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function Summary(){
   return (
      <Paper style={{ padding: 10, minHeight:700 }} >

         <Text variant='h5' color='textSecondary'>Posts</Text>
         <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
            <Text variant='body1' color='textSecondary'>
               polls create a list of members that can view it.<br/> 
               they also create a link that the group can use to promote
            </Text>
         </div>

         <Text variant='h5' color='textSecondary'>Polls</Text>
         <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
            <Text variant='body1' color='textSecondary'>
               generates a list. if user is on it they see it
            </Text>
         </div>

         <Text variant='h5' color='textSecondary'>Surveys</Text>
         <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
            <Text variant='body1' color='textSecondary'>
               surveys
            </Text>
         </div>

         <Text variant='h5' color='textSecondary'>Newsletters</Text>
         <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
            <Text variant='body1' color='textSecondary'>
               newsletters
            </Text>
         </div>                  
      </Paper>
   )
}
export default Summary
