import { APP_LAST_UPDATED, GROUP_COMMUNICATE_SHOW_MESSAGE, GROUP_COMMUNICATE_LOAD, GROUP_COMMUNICATE_RESET_REDUCER } from '../types'
import { chkExpiredData, groupStatusCheck } from '../app/actions'
import { getFunctions, httpsCallable } from 'firebase/functions'

export function setShowMessage(){
	return (dispatch, getstate, firebase) => {
		dispatch({ type:GROUP_COMMUNICATE_SHOW_MESSAGE, payload:false })
	}
}

export function loadGroupCommunicate(force=false) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {    
	   const chkIfExpired = dispatch(chkExpiredData(force, 'groupCommunicate'))
     	chkIfExpired
     	.then(async (currentTime) => {
     	// ---------------------------------------------------------------------------    
	      const user = await dispatch(groupStatusCheck('groupCommunicate', GROUP_COMMUNICATE_RESET_REDUCER))  
        const teamMemberId = user.teamMemberId   
        const groupId = user.groupId

	      const functions = getFunctions(firebase)
        const fetchGroupCommunicate = httpsCallable(functions, 'groupFetchCommunicate')
	      fetchGroupCommunicate({ teamMemberId, groupId })
	      .then((returned) => {
	      	let payload = {
	      		posts:returned.data.posts
	      	}
   			  dispatch({ type: GROUP_COMMUNICATE_LOAD, payload: payload })
	  			dispatch({ type: APP_LAST_UPDATED, payload:{key:'groupCommunicate', value:currentTime} })
		  		resolve()
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      }) 
     	// ---------------------------------------------------------------------------
     	})
     	.catch(() => {
         // Current data still good so do nothing.
         resolve()
     	})
	})
}
