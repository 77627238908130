import { Link } from 'react-router-dom'

import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import IconInfo from '@mui/icons-material/InfoOutlined'
import Text from '@mui/material/Typography'
import Toolbar from '@mui/material/Toolbar'

function SubNav({text, textLink='./', buttonText, buttonLink, showInfo, setShowInfo}) {
  return (
    <AppBar position='static' color='default'>
      <Toolbar variant='dense'>
        <Button component={Link} to={textLink}>
          <Text variant='h6' color='primary' style={{ paddingRight: 15, marginRight:0 }}>
              <b>{text}</b>
          </Text>
        </Button>
        {(buttonText) ? (
          <div align='right' style={{padding: 0, flexGrow:1}}>
            <Button component={Link} to={buttonLink} variant='contained' color='secondary' size='small'>{buttonText}</Button>
          </div>
        ) : (null)}
        {(showInfo) ? (
          <div align='right' style={{padding: 0, flexGrow:1}}>
            <IconButton onClick={() => setShowInfo(true)} size='small' sx={{marginLeft:'5px', color: 'gray'}} ><IconInfo /></IconButton>
          </div>
        ) : (null)}
      </Toolbar>
    </AppBar> 
  )
}
export default SubNav
