import { useState } from 'react' 
import { connect } from 'react-redux'
import { Link, } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

import { createGroup } from '../../../../redux/memberAccount/actions' 
import SubNav from '../../../components/SubNav'
import Loader from '../../../components/Loader'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'

import Breadcrumbs from '@mui/material/Breadcrumbs'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'
import Stepper from '@mui/material/Stepper'
import Text from '@mui/material/Typography'

function CreateGroupContainer({createGroup}) {
   const [loading, setLoading] = useState(false)
   const [activeStep, setActiveStep] = useState(0)
   const [name, setName] = useState('')
   const [summary, setSummary] = useState('')
   const [type, setType] = useState('public')
   const [headerImage, setHeaderImage] = useState(null)
   const [profileImage, setProfileImage] = useState(null)
   const [headerImages, setHeaderImages] =useState([ '/images/groups/groupHeader.jpg' ])
   const [profileImages, setProfileImages] = useState([ '/images/groups/groupProfile.jpg' ])

   const updateGroupInfo = (name, summary, type) => {
      setName(name)
      setSummary(summary)
      setType(type)
      handleNext()
   }
   const updateGroupImage = (img, src) => {
      if (img === 'headerImage') {
         setHeaderImage(src)
         let images = headerImages
         if (!images.includes(src)) {
            images.push(src)
         }
         setHeaderImages(images)
      }
      if (img === 'profileImage') {
         setProfileImage(src)
         let images = profileImages
         if (!images.includes(src)) {
            images.push(src)
         }
         setProfileImages(images)
      }
      handleNext()
   }
   const handleCreateGroup = () => {
      let group = {
         name,
         summary,
         type,
         headerImage,
         profileImage
      }
      setLoading(true)
      createGroup(group)
      .then(() => {
         setLoading(false)
         Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Success!',
            text: 'Your group was created',
            showConfirmButton: true
         })
         handleNext()   
      })
      .catch((e) => {
         setLoading(false)
         Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: e.title, 
            text: e.message,
            showConfirmButton: true
         })
      })
   }
   const handleNext = () => { 
      setActiveStep(activeStep + 1 )
   }    
   const handleBack = () => {
      setActiveStep(activeStep - 1 )
   }
   return (
      <Container maxWidth='lg' style={{ padding: 10, marginBottom:60 }}>
         <Loader loading={loading}/>
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} >
               <SubNav text='Memberships' textLink='/member/account/memberships' showInfo={false}/>
               <div style={{ display: 'flex', borderBottom: '1px solid #b0bec5', marginTop:10, marginBottom: 5}}>
                  <Breadcrumbs>
                     <Link to='/member/account/memberships' style={{textDecoration:'none', color:'#78909c'}}>
                        <Text variant='button' display='block'>Memberships</Text>
                     </Link>
                     <Text display='block' style={{color: '#78909c'}}>Create Group</Text>
                  </Breadcrumbs>
               </div>
               <Paper style={{ padding: 20, minHeight:650 }} >
                  <div style={{maxWidth:500, margin:'0 auto'}}>
                     <div style={{ marginTop:10, marginBottom:10, padding:3, borderBottom:'2px solid #ccc' }}>
                        <Text variant='h5'>Create A Group</Text>
                        <Text>Hi there, lets create a group :)</Text>
                     </div>
                     <Stepper activeStep={activeStep} orientation='vertical'>
                        <Step key={1}>
                           <StepLabel>Group Details</StepLabel>
                           <StepContent>
                              <Step1 
                                 name={name}
                                 summary={summary}
                                 type={type}
                                 updateGroupInfo={updateGroupInfo}
                              />
                           </StepContent>
                        </Step>
                        <Step key={2}>
                           <StepLabel>Header Image</StepLabel>
                           <StepContent>
                              <Step2 
                                 currentImage={headerImage}
                                 images ={headerImages} 
                                 updateImage={updateGroupImage}
                                 handleBack={handleBack}
                              />
                           </StepContent>
                        </Step>
                        <Step key={3}>
                           <StepLabel>Profile Image</StepLabel>
                           <StepContent>
                              <Step3 
                                 currentImage={profileImage}
                                 images ={profileImages} 
                                 updateImage={updateGroupImage}
                                 handleBack={handleBack}
                              />
                           </StepContent>
                        </Step>
                        <Step key={4}>
                           <StepLabel>Review</StepLabel>
                           <StepContent>
                              <Step4 
                                 name={name}
                                 summary={summary}
                                 type={type}
                                 headerImage={headerImage}
                                 profileImage={profileImage}
                                 handleCreateGroup={handleCreateGroup}
                                 handleBack={handleBack}
                              />
                           </StepContent>
                        </Step>
                        <Step key={5}>
                           <StepLabel>Complete</StepLabel>
                           <StepContent>
                              <Step5/>
                           </StepContent>
                        </Step>
                     </Stepper>
                  </div>
               </Paper>
            </Grid>
         </Grid>
      </Container>   
   )
}
const mapDispatchToProps = {
   createGroup
}
export default connect(null, mapDispatchToProps)(CreateGroupContainer)
