import React from 'react'
import { Routes, Route, Navigate, useLocation, useSearchParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from './withRouter'
import { changeOption, monitorLoginStatus, stopMonitoringLoginStatus, logOut } from '../redux/auth/actions'
import { setAppDefaults } from '../redux/app/actions'

import BottomNav from './components/BottomNav/'
import Footer from './components/Footer'
import Loader from './components/Loader'
import SideNav from './components/SideNav'
import SplashScreen from './components/SplashScreen'
import TopNav from './components/TopNav'

import GroupRoutes from './GroupRoutes'
import MemberRoutes from './MemberRoutes'
import PublicRoutes from './PublicRoutes'

class App extends React.Component {
   state = { 
      loading: true,
      sideNavOpen: false
   }
   componentDidMount = () => {
      this.setState({loading:false})
      this.props.setAppDefaults()
      this.props.monitorLoginStatus()
   }
   componentWillUnmount = () => {
      this.props.stopMonitoringLoginStatus()
   }
   sideNavToggle = () => {
      this.setState({sideNavOpen: !this.state.sideNavOpen})
   }
   handleOptionChange = (option) => {
      this.props.changeOption(option)
      let page = (option > 0) ? '/group' : '/member'
      this.props.navigate(page) 
      //this uses my withRouter hook and not useNavigate
      // because Navigate can only be used in a function and this is an extended component
   }
   handleLoginChange = (option) => {
      this.props.changeOption(option)
   }
   render(){
      window.scrollTo(0, 0)
      const {loading, sideNavOpen} = this.state
      // pop comes from the wrapper component - withRouter
      const {pop, loggedIn, loginOptionID, loginOptions, selectedOption, logOut} = this.props
      return (
         <div>
            <SplashScreen />
            <Loader loading={loading}/>
            {(pop)? (
                  /* 
                    eventually change this to PopRoutes and create a whole new section for it,
                    with its own topnav and footer
                  */
               <Routes>
                  <Route path='*' element={<PublicRoutes loggedIn={loggedIn} />} />      
               </Routes>
            ):( 
               <div>          
                  <TopNav 
                     loggedIn={loggedIn} 
                     sideNavToggle={this.sideNavToggle} 
                     loginOptions={loginOptions} 
                     selectedOption={selectedOption} 
                     handleOptionChange={this.handleOptionChange}
                     logOut={logOut}
                  />
                  <nav>
                     <SideNav
                        selectedOption={selectedOption} 
                        sideNavOpen={sideNavOpen} 
                        sideNavToggle={this.sideNavToggle} 
                     />
                  </nav>
                  <main>
                     <Routes>
                        <Route path='/member/*' element={<RequireAuth loggedIn={loggedIn} loginOptionID={loginOptionID} handleLoginChange={this.handleLoginChange}><MemberRoutes /></RequireAuth>} />
                        <Route path='/group/*' element={<RequireAuth loggedIn={loggedIn} loginOptionID={loginOptionID}><GroupRoutes /></RequireAuth>} />
                        <Route path='*' element={<PublicRoutes loggedIn={loggedIn} />} />      
                     </Routes>
                  </main>        
                  <footer>
                     <Footer selectedOption={selectedOption}/>
                  </footer>
                  <BottomNav selectedOption={selectedOption }/>
               </div>
            )}
         </div>
      )
   }
}

function RequireAuth({ children, loggedIn, loginOptionID, handleLoginChange}) {
  let location = useLocation()  
  if (!loggedIn) {
    return <Navigate to={'/login'} state={{ from: location }} replace />
  }
  if(!loginOptionID && location.pathname.startsWith('/group')){
    return <Navigate to='/member' replace /> 
  }
  if(loginOptionID && location.pathname.startsWith('/member')){
    handleLoginChange(0)
  }
  return children
}
const mapStateToProps = (state) => ({
  loggedIn: state.auth.loggedIn,
  loginOptions: state.auth.loginOptions,
  loginOptionID: state.auth.loginOptionID,
  selectedOption: state.auth.loginOptions[state.auth.loginOptionID]
})
const mapDispatchToProps = {
  changeOption,
  monitorLoginStatus, 
  stopMonitoringLoginStatus,
  logOut,
  setAppDefaults
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App))
