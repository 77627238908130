import { useState } from 'react' 
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

import Loader from '../../components/Loader'

import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'

function CartCheckout({loggedIn, paymentDisabled, checkOut}) {
   const [loading, setLoading] = useState(false)
   const navigate = useNavigate()

   const handleCheckout = () => {
      setLoading(true)
      checkOut()
      .then((id) => {
         setLoading(false)
         navigate('/member/stuff/invoice/' + id  + '?force=1')
      })
      .catch((e) => {
         setLoading(false)
         Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: e.title, 
            text: e.message,
            showConfirmButton: true
         })
      })
   }   
   if (!loggedIn) { return null }
   return ( 
      <Card elevation={0} style={{border: '1px solid #cfd8dc', borderRadius:0, marginBottom:20}}>
         <Loader loading={loading} title='Processing Order' text='Preparing invoice for checkout...'/> 
         <CardHeader title='Checkout' titleTypographyProps={{variant: 'h6'}} style={{padding:'10px 15px 10px 15px'}}/>
         <Divider variant='middle' style={{backgroundColor: '#cfd8dc'}} />
         <CardContent>
            <div>
               Please review your order and check the boxes above to confirm purchase, then proceed when you are ready to pay.
            </div>
            <div align='center' style={{ paddingTop: 25}}>
               <Button 
                  variant='contained' 
                  color='secondary' 
                  disabled={paymentDisabled}
                  onClick={() => { handleCheckout() }}
               >
               proceed to checkout
               </Button>
            </div>
         </CardContent>         
      </Card>            
   )
}
export default CartCheckout
