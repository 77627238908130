import { Fragment, useEffect, useState } from 'react' 
import { connect, useSelector } from 'react-redux'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { loadInvoiceDetails, makeFacPayment, updateErrorStatus } from '../../../../redux/memberStuff/actions'

import NextUpdate from '../../../components/NextUpdate'
import OrderedItems from './OrderedItems'
import Payment from './Payment'
import PaymentHistory from './PaymentHistory'
import SubNav from '../../../components/SubNav'
import Summary from './Summary'
import Timer from '../../../components/Timer'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

const errors = [
   {errorReason:'saltfish',errorMessage:'we want some... gimmie some'},
   {errorReason:'rice',errorMessage:'if it eh white, it eh right'}
]

function InvoiceContainer({ loadInvoiceDetails, makeFacPayment, updateErrorStatus }) {
   const [step, setStep] = useState('loading') // loading, error, display  
   const [loadingError, setLoadingError] = useState({})
   const [searchParams] = useSearchParams()
   const { invoiceId } = useParams()
   const invoice = useSelector(state => state.memberStuff.invoices[invoiceId]) || {}
   const lastUpdated = useSelector(state => state.app.lastUpdated['memberInvoices-' + invoiceId]) || 0

   useEffect(() => {
      let force = (searchParams.get('force')) ? true : false
      handleFetchInvoice(force)
   },[])
   useEffect(() => {
      if (Object.keys(invoice).length && !invoice.orderedItems) {
         handleFetchInvoice(true)
      }
   },[invoice])

   const handleFetchInvoice = (force) => {
      setStep('loading')
      loadInvoiceDetails(force, invoiceId) 
      .then(() => {
         setStep('display')
      }) 
      .catch((error) => {
         setLoadingError(error)
         setStep('error')
      })
   }
   const handleTryAgain = (force) => {
      setLoadingError({})
      handleFetchInvoice(true)
   }

   return (
      <Container maxWidth='lg' style={{ padding: 10, marginBottom:50 }}>
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} >
               <SubNav text='Invoices' textLink='/member/stuff/invoices' showInfo={false}/>
               <div style={{ display: 'flex', borderBottom: '1px solid #b0bec5', marginTop:10, marginBottom: 5}}>
                  <Breadcrumbs>
                     <Link to='/member/stuff/invoices' style={{textDecoration:'none', color:'#78909c'}}>
                        <Text variant='button' display='block'>Invoices</Text>
                     </Link>
                     <Text display='block' style={{color: '#78909c'}}>Invoice #: {invoiceId}</Text>
                  </Breadcrumbs>
               </div>
            </Grid>

            {(step==='loading') ? (
               <Grid item xs={12} sm={12} md={10} >
                  <Alert severity='warning'>
                     <AlertTitle>Loading</AlertTitle>
                     Loading Invoice Details...
                     <LinearProgress color='inherit' />   
                  </Alert> 
               </Grid>
            ):(null)}

            {(step === 'error') ? (   
               <Grid item xs={12} sm={12} md={10} >            
                  <Alert severity='error'>
                     <AlertTitle>{loadingError.title || 'Error'}</AlertTitle>
                     {loadingError.message || 'Sorry, an error occured, please try again.'}
                  <div style={{ marginTop:20 }}><Button size='small' onClick={handleTryAgain} variant='outlined' color='inherit'>try again</Button></div>
                  <div style={{ marginTop:5 }}><Button size='small' component={Link} to='/member/stuff/invoices' variant='outlined' color='inherit'>return to invoices</Button></div>
                  </Alert>
               </Grid>
            ):( null )}

            {(step === 'display') ? ( 
               <Fragment>
                  <Grid item xs={12} sm={5} md={4}>
                     <Summary
                        status={invoice.orderStatus}
                        invoiceId={invoiceId}
                        orderedByName={invoice.orderedByName}
                        orderedByEmail={invoice.orderedByEmail}
                     />
                     <PaymentHistory 
                        invoiceId={invoiceId}
                        history={invoice.paymentHistory}
                        updateErrorStatus={updateErrorStatus}
                     />
                  </Grid>
                  <Grid item xs={12} sm={7} md={6}>            
                     {(invoice.orderStatus === 'pending') ? (
                        <Timer variant='cartPage' endTime={invoice.dateTimeExpires} callback={ () => {} }/> 
                     ):(null)}

                     <OrderedItems invoice={invoice} />
                     <Payment 
                        invoice={invoice}
                        makeFacPayment={makeFacPayment} 
                     />
                  </Grid>
                  <Grid item xs={12} sm={12} md={10} > 
                     <NextUpdate lastUpdated={lastUpdated} updateNow={handleFetchInvoice}/>
                  </Grid>
               </Fragment>
            ):( null )}

         </Grid>
      </Container>   
   )
}
const mapDispatchToProps = {
   loadInvoiceDetails,
   makeFacPayment,
   updateErrorStatus
}
export default connect(null, mapDispatchToProps)(InvoiceContainer)
