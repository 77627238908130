import { useEffect, useState } from 'react' 
import { Routes, Route, Navigate, Outlet, Link, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { loadGroupSettings, addTeamMember, removeTeamMember } from '../../../redux/groupSettings/actions'
import { selectorFilterTeam } from '../../../redux/groupSettings/selectors'

import Activity from './Activity'
import Boxoffice from './Boxoffice'
import Dashboard from './Dashboard'
import Info from './Info'
import NextUpdate from '../../components/NextUpdate'
import SubNav from '../../components/SubNav'

import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

function GroupDashboardContainer({sideNavToggle, roleId, lastUpdated}) {
   const whoHasAccess = [1]
   const [loading, setLoading] = useState(true)   
   const [showInfo, setShowInfo] = useState(false)
   const [activeTab, setActiveTab] = useState(0) 
   const location = useLocation()

   useEffect(() => {
      switch (true) {
         case location.pathname.includes('/group/dashboard/boxoffice'):
            setActiveTab(1)
         break
         case location.pathname.includes('/group/dashboard/activity'):
            setActiveTab(2)
         break
         default:
            setActiveTab(0)
      }
      handleLoadGroupDashboard()
   },[location])

   const handleLoadGroupDashboard = (force) => {
      //setLoading(true)
      //loadGroupSettings(force) 
      //.finally(() => {
      //   setLoading(false)
      //}) 
   }

   return (
      <Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
         <Info showInfo={showInfo} setShowInfo={setShowInfo} />
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} >
               <SubNav text='Dashboard' showInfo={true} setShowInfo={setShowInfo}/>
               <div style={{borderBottom: '2px solid #eee'}}>
                  <Tabs value={activeTab} variant='fullWidth' indicatorColor='primary' textColor='primary'
                     variant='scrollable' scrollButtons={false}>
                     <Tab label='Dashboard' style={{ maxWidth:200 }} component={Link} to='/group/dashboard'/>
                     <Tab label='Boxoffice' style={{ maxWidth:200 }} component={Link} to='/group/dashboard/boxoffice' />
                     <Tab label='Activity' style={{ maxWidth:200 }} component={Link} to='/group/dashboard/activity' />
                  </Tabs>
               </div>
               <Collapse in={loading}> 
                  <Alert severity='warning'>
                     Retrieving Dashboard Settings ...
                     <LinearProgress color='inherit' />   
                  </Alert> 
               </Collapse>
               <div>
                  <Routes>
                     <Route path='/boxoffice' element={<Boxoffice />} />
                     <Route path='/activity' element={<Activity />} />
                     <Route path='*' element={<Dashboard />} />
                  </Routes>
                  <Outlet />
               </div>
            </Grid>            
         </Grid> 
         <NextUpdate lastUpdated={lastUpdated} updateNow={handleLoadGroupDashboard}/>
      </Container>   
   )
}
const mapStateToProps = (state) => ({
   roleId: state.auth.loginOptions[state.auth.loginOptionID].roleId,
   lastUpdated: state.app.lastUpdated.groupDashboard
})
const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(GroupDashboardContainer)
