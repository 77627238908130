import { useNavigate } from 'react-router-dom'
import format from 'date-fns/format'

import Alert from '@mui/material/Alert'
import Avatar from '@mui/material/Avatar'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import IconArrowForward from '@mui/icons-material/ArrowForwardIos'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function EventsList({selectedTab, events, showMessage, setShowMessage}){
   const statusColor = {'unassigned':'#999', 'open':'#27e007', 'closed':'red', 'archived':'#000'}
   const navigate = useNavigate()

   const handleViewEvent = (id) => {
      navigate('/group/event/' + id)
   }
   return (
      <Paper style={{ padding: 10, minHeight:700 }} >
         <Collapse in={showMessage}>
            <Alert style={{marginBottom:20}} severity='info' onClose={() => setShowMessage(false)}>
               Welcome to Events, it can be accessed by the Owner, Admins, Managers and Editors.
               NOTE: Editors can help setup an event but do not have access to manage it.  
            </Alert> 
         </Collapse>

         {(Object.keys(events).length) ? (
            <List style={{width:'100%', padding:5}}>
               {Object.keys(events).map((i) => {
                  return (
                     <div key={i}>
                        <ListItem button alignItems="flex-start" onClick={() => {handleViewEvent(i)}}>
                           <ListItemAvatar>
                              <Avatar alt={events[i].name} src={events[i].profileImage} style={{borderRadius:5}} />
                           </ListItemAvatar>
                           <ListItemText>
                              <b>{events[i].name}</b>
                              <Text style={{lineHeight:1}} variant='caption' display='block' color='textSecondary'>
                                 On: { format(new Date(events[i].dateTimeStart), 'eee do MMM y')}<br/>
                                 At: { format(new Date(events[i].dateTimeStart), '- h:mm a')}
                              </Text>
                              <Text style={{lineHeight:1.5, color:(events[i].published)? 'green' : 'red' }}><b>{(events[i].published) ? 'PUBLISHED' : 'NOT PUBLISHED'}</b></Text>
                           </ListItemText>
                           <ListItemSecondaryAction>
                              <IconArrowForward />
                           </ListItemSecondaryAction>
                        </ListItem>
                        <Divider/>                          
                     </div>
                  )
               })}
            </List>
         ):(
            <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
               <Text variant='body1' color='textSecondary'>NO {selectedTab.toUpperCase()} EVENTS</Text>
            </div> 
         )}
      </Paper>
   )
}
export default EventsList
