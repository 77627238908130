import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'

function Boxoffice(){
    return (
        <div>Boxoffice</div>
    )
}
export default Boxoffice
