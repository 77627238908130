import { GROUP_SUPPORT_SHOW_MESSAGE, GROUP_SUPPORT_LOAD, GROUP_SUPPORT_UPDATE_TICKET, GROUP_SUPPORT_UPDATE_TICKETS, GROUP_SUPPORT_RESET_REDUCER } from '../types'

const initialState = {
	groupId: null,
	showMessage: true,
	faqs: {},
	notes: {},
	supportTickets: {},
	archivedTickets: {},
	team: {}
}

export default function reducer(state = initialState, action) {
  	switch (action.type) {
		case GROUP_SUPPORT_SHOW_MESSAGE:
         return { 
				...state,
				showMessage: action.payload
			}
		case GROUP_SUPPORT_LOAD:
         return { 
				...state,
				faqs: action.payload.faqs,
				notes: action.payload.notes,
				supportTickets: action.payload.supportTickets,
				team: action.payload.team
			}
		case GROUP_SUPPORT_UPDATE_TICKET:
			return { 
				...state, 
				supportTickets: {
          ...state.supportTickets,
          [action.payload.id]: action.payload.info
        }
			}	
		case GROUP_SUPPORT_UPDATE_TICKETS:
			return { 
				...state, 
				supportTickets: action.payload        
			}	
		case GROUP_SUPPORT_RESET_REDUCER:
			return { 
				...state,
	          groupId: action.payload,
	         	faqs: {},
	          notes: {},
	          supportTickets: {},
	          team: {}
			}
		default:
			return state
	}
}


