import { APP_LAST_UPDATED, GROUP_EVENTS_LOAD, GROUP_EVENTS_RESET_REDUCER, GROUP_EVENTS_SHOW_MESSAGE, GROUP_EVENTS_ADD_EVENT } from '../types'
import { chkExpiredData, groupStatusCheck } from '../app/actions'
import { getFunctions, httpsCallable } from 'firebase/functions'

export function setShowMessage(){
	return (dispatch, getstate, firebase) => {
		dispatch({ type:GROUP_EVENTS_SHOW_MESSAGE, payload:false })
	}
}

export function loadGroupEvents(force=false) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {    
	   const chkIfExpired = dispatch(chkExpiredData(force, 'groupEvents'))
     	chkIfExpired
     	.then(async (currentTime) => {
     	// ---------------------------------------------------------------------------    
	      const user = await dispatch(groupStatusCheck('groupEvents', GROUP_EVENTS_RESET_REDUCER))  
         const teamMemberId = user.teamMemberId   
         const groupId = user.groupId

	      const functions = getFunctions(firebase)
         const loadGroupEvents = httpsCallable(functions, 'groupFetchEvents')
	      loadGroupEvents({ teamMemberId, groupId })
	      .then((returned) => {
   			dispatch({ type: GROUP_EVENTS_LOAD, payload: returned.data })
	  			dispatch({ type: APP_LAST_UPDATED, payload:{key:'groupEvents', value:currentTime} })
		  		resolve()
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      }) 
     	// ---------------------------------------------------------------------------
     	})
     	.catch(() => {
         // Current data still good so do nothing.
         resolve()
     	})
	})
}

export function createEvent(eventInfo) {
	return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {   
      const user = await dispatch(groupStatusCheck('groupEvents', GROUP_EVENTS_RESET_REDUCER))  
      const teamMemberId = user.teamMemberId   
      const groupId = user.groupId
  
	   const functions = getFunctions(firebase)
	   const eventAdd = httpsCallable(functions, 'groupCreateEvent')
		eventAdd({ teamMemberId, groupId, eventInfo })
		.then((returned) => {
         let event = returned.data
         let payload = {id:event.id, event:event}
			dispatch({ type: GROUP_EVENTS_ADD_EVENT, payload: payload }) 
			resolve(event.id)		  
		})
		.catch((e) => {
	   	let error = {title:'Error',message:''}
	   	try { error = JSON.parse(e.message) } 
	   	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	   	reject(error)
	   })
	})
}
