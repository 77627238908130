import { APP_LAST_UPDATED, MEMBER_PROFILE_ACTIVITY_LOAD, MEMBER_PROFILE_NOTIFICATIONS_LOAD } from '../types'
import { chkExpiredData } from '../app/actions'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getAuth, EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth'

export function updateProfileInfo(userInfo) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {    
	   const functions = getFunctions(firebase)
      const updateProfile = httpsCallable(functions, 'updateUserProfile')
		updateProfile({ userInfo:userInfo })
		.then(() => {
		  resolve('success')
		})
		.catch((e) => {
      	let error = {title:'Error',message:''}
      	try { error = JSON.parse(e.message) } 
      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
      	reject(error)
      })
	})
}
export function updateProfilePassword(oldPassword, newPassword) {
	return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
		const auth = getAuth(firebase)
		// Reauthenticate user with current password.
		if (oldPassword !== '') {
			try {
				const userEmail = getstate().auth.userInfo.email
            const credentials = EmailAuthProvider.credential(userEmail, oldPassword)			
            await reauthenticateWithCredential(auth.currentUser, credentials)
			}
			catch (error) {
				if (error.message === 'The password is invalid or the user does not have a password.') {
					return reject('The Current Password you entered is invalid, please try again or login through your email.')
				} else {
					return reject('Error authenticating your account, please re-enter your current password or login through your email then set your password.')    
				}
			}
		} 
      // Set new password
		if(newPassword){
			updatePassword(auth.currentUser, newPassword)
			.then(function() {
				resolve('Password updated')
			})
			.catch(function(error) {
				if (error.code === 'auth/requires-recent-login') {
					reject('Please enter your Current Password.')    
				} else {
					reject('Sorry, there was an error: ', error.message)  
				}
			})
		} else {
			reject('Please enter your password.')
		}  
	})
}

export function loadNotifications(force=false, startAt=null) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {   
		const chkIfExpired = dispatch(chkExpiredData(force, 'memberNotifications'))
     	chkIfExpired
     	.then((currentTime) => {
     	// ---------------------------------------------------------------------------    
	      const functions = getFunctions(firebase)
         const getNotifications = httpsCallable(functions, 'memberFetchNotifications')
	      getNotifications({startAt})
	      .then((returned) => {
	         dispatch({type: MEMBER_PROFILE_NOTIFICATIONS_LOAD, payload: returned.data})
				dispatch({ type: APP_LAST_UPDATED, payload:{key:'memberNotifications', value:currentTime} })
				resolve(returned.data.length)
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
		   }) 
     	// ---------------------------------------------------------------------------
     	})
     	.catch(() => {
         // Current data still good so do nothing.
         resolve()
     	})
	})
}

export function loadActivity(force=false, startAt=null) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {   
		const chkIfExpired = dispatch(chkExpiredData(force, 'memberActivity'))
     	chkIfExpired
     	.then((currentTime) => {
     	// ---------------------------------------------------------------------------    
	      const functions = getFunctions(firebase)
         const getActivity = httpsCallable(functions, 'memberFetchActivity')
	      getActivity({startAt})
	      .then((returned) => {
	         dispatch({type: MEMBER_PROFILE_ACTIVITY_LOAD, payload: returned.data})
				dispatch({ type: APP_LAST_UPDATED, payload:{key:'memberActivity', value:currentTime} })
				resolve(returned.data.length)
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
		   }) 
     	// ---------------------------------------------------------------------------
     	})
     	.catch(() => {
         // Current data still good so do nothing.
         resolve()
     	})
	})
}
