import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import theme from './styles/theme-1'
import './styles/custom.css'
import 'react-quill/dist/quill.core.css' // used for formatting text edited in quill textarea 
import 'react-quill/dist/quill.snow.css' // used for formatting text edited in quill textarea

import App from './routes/App'
import store from './redux/store'

class ErrorBoundary extends React.Component {
   constructor(props) {
      super(props)
      this.state = { error:null, errorInfo:null }
   }
   componentDidCatch(error, errorInfo) {
      // Catch errors in any components below and re-render with error message
      this.setState({ error:error, errorInfo: errorInfo })
    // You can also log error messages to an error reporting service here
   }
   render() {
      if (this.state.errorInfo) {
         // Error path
         return (
            <div>
               <h2>Something went wrong.</h2>
               <details style={{ whiteSpace: 'pre-wrap' }}>
                  {this.state.error && this.state.error.toString()}<br />
                  {this.state.errorInfo.componentStack}
               </details>
            </div>
         )
      }
      // Normally, just render children
      return this.props.children
   }  
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
   <BrowserRouter>
      <Provider store={store}>
         <ThemeProvider theme={theme}>
            <CssBaseline />
            <ErrorBoundary>
              <App />
            </ErrorBoundary>  
         </ThemeProvider>
      </Provider>
   </BrowserRouter>
)
