import { APP_LAST_UPDATED, APP_LOAD_NOTIFICATIONS, APP_LOAD_SETTINGS } from '../types'

const initialState = {
	currentVersion: '12.17.23',
	version: '12.17.23',
	live: true,
	splashTitle: '',
	splashMessage: '',
	splashFooter: '',
	buttonShow: false,
	buttonText: '',
	buttonLink: '',
	updateIntervals: {   // update intervals in seconds
      memberActivity: 600,					// member/profile/activity (10 minute update interval - 600 seconds )
      memberNotifications: 600,			// member/profile/notifications (10 minute update interval - 600 seconds )
		memberProfile: 600,					// member/profile/profile (10 minute update interval - 600 seconds )




		groupCampaigns: 60,					// group/campaigns (10 minute update interval - 600 seconds )
		memberAccountSummary: 60,			// member/account (10 minute update interval - 600 seconds )
		memberCampaignsAndBoards: 60,		// member/donations (10 minute update interval - 600 seconds ) 
		publicBoards: 60,						// public/boards (10 minute update interval - 600 seconds )
		publicCampaigns: 60,					// public/campaigns (10 minute update interval - 600 seconds )
		publicView: 60,						// public/view (10 minute update interval - 600 seconds )
		paymentsCredits: 120,				// payments/paymentCredits (2 minutes update interval - 120)
		
		memberMemberships: 600,	         // member/memberships (10 minute update interval - 600 seconds )
		memberMessages: 600,					// member/messages (10 minute update interval - 600 seconds )
		memberSupport: 600,					// member/support (10 minute update interval - 600 seconds )
      

      groupDashboard: 600,					// group/dashboard (10 minute update interval - 600 seconds )
      groupEvents: 600,					   // group/events (10 minute update interval - 600 seconds )
      groupMembers: 600,					// group/members (10 minute update interval - 600 seconds )
      groupSupport: 600,					// group/support (10 minute update interval - 600 seconds )
      groupCommunicate: 600,				// group/communicate (10 minute update interval - 600 seconds )
      groupAccounts: 600,					// group/accounts (10 minute update interval - 600 seconds )
      groupSettings: 600,					// group/settings (10 minute update interval - 600 seconds )
		
      publicEvents: 600,					// /events (10 minute update interval - 600 seconds )
      order: 600,					         // /order/cart (10 minute update interval - 600 seconds )
		default: 300							// default interval (5 minute default update interval - 300 seconds - used if supplied interval is not valid)
	},
	lastUpdated: {},
	notifications: {}
}

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case APP_LAST_UPDATED:
        	return {
            ...state,
            lastUpdated: {
               ...state.lastUpdated,
               [action.payload.key]: action.payload.value
            }
        	}
      case APP_LOAD_SETTINGS:
			return { 
				...state,
				version: action.payload.version,
				live: action.payload.live,
				splashTitle: action.payload.splashTitle,
				splashMessage: action.payload.splashMessage,
				splashFooter: action.payload.splashFooter,
				buttonShow: action.payload.buttonShow,
				buttonText: action.payload.buttonText,
				buttonLink: action.payload.buttonLink
			}
	   case APP_LOAD_NOTIFICATIONS:
			return { 
				...state, 
				lastUpdated: action.payload.timestamp,
				notifications: {
               ...state.notifications,
               ...action.payload.notifications
            }
			}
		default:
			return state
	}
}
