import { Routes, Route, Outlet, Navigate } from 'react-router-dom'

import Accounts from './group/accounts/_Container'
import Communicate from './group/communicate/_Container'
import Dashboard from './group/dashboard/_Container'
import Event from './group/event/_Container'
import Events from './group/events/_Container'
import Members from './group/members/_Container'
import Settings from './group/settings/_Container'
import Support from './group/support/_Container'

export default function GroupRoutes() {
  return (
    <div>  
      <Routes>
        <Route path='/accounts/*' element={<Accounts/>} />
        <Route path='/communicate/*' element={<Communicate/>} />
        <Route path='/dashboard/*' element={<Dashboard/>} />
        <Route path='/event/:eventId' element={ <Event/>} />
        <Route path='/events/*' element={ <Events/>} />
        <Route path='/members/*' element={<Members/>} />
        <Route path='/settings/*' element={<Settings/>} />
        <Route path='/support/*' element={ <Support/>} />
        <Route path='*' element={<Navigate to='/group/dashboard' />} />
      </Routes>
      <Outlet />
    </div>  
  )
}