import { MEMBER_STUFF_INVOICES_LOAD, MEMBER_STUFF_INVOICES_LOAD_DETAILS, MEMBER_STUFF_INVOICES_UPDATE, MEMBER_STUFF_INVOICES_UPDATE_ERROR, MEMBER_STUFF_TICKETS_LOAD } from '../types'

const initialState = {
	wallet: {},
	tickets: {},
	invoices: {}
}

export default function reducer(state = initialState, action) {
  	switch (action.type) {
  	   case MEMBER_STUFF_TICKETS_LOAD:
         return { 
				...state,
				tickets: action.payload
			}
  	   case MEMBER_STUFF_INVOICES_LOAD:
         return { 
				...state,
				invoices: action.payload
			}
		case MEMBER_STUFF_INVOICES_LOAD_DETAILS:
         return { 
				...state,
				invoices: {
               ...state.invoices,
               [action.payload.id]: action.payload.details
            }

			}
  	   case MEMBER_STUFF_INVOICES_UPDATE:
         return { 
				...state,
				invoices: {
				   ...state.invoices,
				   ...action.payload
			   }
			}

      case MEMBER_STUFF_INVOICES_UPDATE_ERROR:
         return { 
            ...state, 
            invoices: {
              ...state.invoices,
              [action.payload.invoiceId]: {
                 ...state.invoices[action.payload.invoiceId],
                 paymentHistory: {
                    ...state.invoices[action.payload.invoiceId].paymentHistory,
                    [action.payload.paymentId]: {
                        ...state.invoices[action.payload.invoiceId].paymentHistory[action.payload.paymentId],
                        errorAcknowledged: true
                    }
                 }           
              }
            } 
         }


		default:
			return state
	}
}
