import { GROUP_COMMUNICATE_SHOW_MESSAGE, GROUP_COMMUNICATE_LOAD, GROUP_COMMUNICATE_RESET_REDUCER } from '../types'

const initialState = {
	groupId: null,
	showMessage: true,
	posts: {}
}

export default function reducer(state = initialState, action) {
  	switch (action.type) {
		case GROUP_COMMUNICATE_SHOW_MESSAGE:
         return { 
				...state,
				showMessage: action.payload
			}
		case GROUP_COMMUNICATE_LOAD:
         return { 
				...state,
				posts: action.payload.posts
			}
		case GROUP_COMMUNICATE_RESET_REDUCER:
			return { 
				...state,
	         groupId: action.payload,
	         posts: {}
			}
		default:
			return state
	}
}


