import {useState} from 'react'

import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Text from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

const options = {
  	public: 'Anyone can join automatically - Group can invite anyone',
  	private: 'Anyone can request to join - Group can invite anyone',
  	closed: 'Requests to join not accepted - Group can invite anyone'
}

function Step1({name, summary, type, updateGroupInfo}){
	const [typeEl, setTypeEl] = useState(null)
	const [groupName, setGroupName] = useState(name)
   const [groupSummary, setGroupSummary] = useState(summary)
   const [groupType, setGroupType] = useState(type)
   const [message, setMessage] = useState('')

   const handleChange = (f,v) => {
    	f(v)
    	setMessage('')
   }
   const handleNext = () => {
		if (groupName === '' || groupSummary === '' || groupType === '') {
			return setMessage('All fields are required.')
		}
		updateGroupInfo(groupName, groupSummary, groupType)
	}
   return (
   	<div style={{ width:'100%' }}>
   		<form noValidate autoComplete='off'>
				<TextField fullWidth size='small' margin='normal' label='Group Name'
				 	value={groupName} onChange={(e) => {handleChange(setGroupName, e.target.value) }}
				/>
				<TextField fullWidth size='small' margin='normal' label='Group Summary' multiline rows='4'
					value={groupSummary} onChange={(e) => {handleChange(setGroupSummary, e.target.value) }}
				/>
				<div style={{marginTop:20}}><Text variant='caption' display='block' style={{color:'#90a4ae'}}>Select the type of group you would like to create. You can always change this in your Group Settings.</Text></div>
				<List component='nav' style={{backgroundColor:'#f1f1f1', padding:2}}>
				 	<ListItem button onClick={(e) => { setTypeEl(e.currentTarget) }} style={{padding:10}}>
						<ListItemText primary={'Type of Group: ' + groupType.toUpperCase()} secondary={options[groupType]} />
				 	</ListItem>
				</List> 
				<Menu dense='true' anchorEl={typeEl} open={Boolean(typeEl)} onClose={() => {setTypeEl(null) }}>
				 	<MenuItem value='public' selected={groupType === 'public' } onClick={() => {setGroupType('public'); setTypeEl(null)}}>
						<ListItemText style={{whiteSpace: 'normal'}} primary='PUBLIC' secondary={options['public']} />
				 	</MenuItem>
				 	<MenuItem value='private' selected={groupType === 'private' } onClick={() => {setGroupType('private'); setTypeEl(null)}}>
						<ListItemText style={{whiteSpace: 'normal'}} primary='PRIVATE' secondary={options['private']} />
				 	</MenuItem>
				 	<MenuItem value='close' selected={groupType === 'closed' } onClick={() => {setGroupType('closed'); setTypeEl(null)}}>
						<ListItemText style={{whiteSpace: 'normal'}} primary='CLOSED' secondary={options['closed']} />
				 	</MenuItem>
				</Menu>
			</form>
			<div align='center' style={{ height: 35, padding: 10 }}>
				<Text style={{ color: 'red' }}>
					<b>{message}</b>
				</Text>
			</div>
			<div align='right'>
			 	<Button size='small' variant='contained' color='primary' onClick={handleNext}>
					next
			 	</Button>
			</div>
   	</div>
   )
}
export default Step1
