import { useEffect, useState } from 'react' 
import { Link } from 'react-router-dom'
import { format } from 'date-fns'

import { money }  from '../../../includes/functions'
import NextUpdate from '../../components/NextUpdate'
import Timer from '../../components/Timer'

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function Invoices({lastUpdated, invoices, loadInvoices}) {
   const [loading, setLoading] = useState(false) 
   const [records, setRecords] = useState([])

   useEffect(() => {
      let force = false
      if (window.localStorage.getItem('forceLoadInvoices')) {
         force = true
         window.localStorage.removeItem('forceLoadInvoices')
      }
      handleFetchMemberInvoices(force)
   },[])
   useEffect(() => {
      if (invoices && Object.values(invoices).length) {
         let recs = Object.values(invoices)   
         let filtered = recs.sort((a, b) => b.dateTimeCreated - a.dateTimeCreated)
         setRecords(filtered)
      } else {
         setRecords([])
      }
   },[invoices])

   const handleFetchMemberInvoices = (force) => {
      setLoading(true)
      loadInvoices(force) 
      .finally(() => {
         setLoading(false)
      })
   }
   const handleTimerExpired = () => {
      // an invoice expired, so we call loadInvoices which would remove expired invoices. 
      handleFetchMemberInvoices(true)
   }

   return (
      <div>
         <Paper style={{ padding: 10, minHeight:700 }} >
            <Collapse in={loading}> 
               <Alert severity='warning' style={{marginBottom:20}}>
                  Updating Your Invoices ...
                  <LinearProgress color='inherit' />   
               </Alert> 
            </Collapse>

            {(!records.length) ? (
               <div style={{marginTop:20}}>
                  <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
                     <Text variant='body1' color='textSecondary'>
                        No Outstanding Invoices
                     </Text>
                  </div>
               </div> 
            ):(
               records.map((rec,index) => { 
                  const statusText = {'hold':'ON HOLD', 'paid':'PAID IN FULL', 'pending':'PAYMENT PENDING'}
                  const statusColor = {'hold':'#d50000', 'paid':'#4caf50', 'pending':'#fbc02d'}
                  return (
                     <Paper key={index} elevation={0} style={{ padding:10, marginTop:10, border:'1px solid #b9b9b9'}}>
                     <Grid container justify='center' spacing={2}>
                        <Grid item xs={6} sm={5} md={5}>
                           <Text variant='body2'>{format(rec.dateTimeCreated, 'EEE do MMM, yyyy - h:mm a')}</Text>
                           <Text variant='body2'>Invoice #: {rec.orderId}</Text>
                           <span style={{backgroundColor:statusColor[rec.orderStatus], padding:'2px 10px', borderRadius:5, color:'white', fontSize:12, fontWeight:'bold'}}>
                              {statusText[rec.orderStatus]}
                           </span>
                        </Grid>
                        <Grid item xs={6} sm={4} md={4} style={{textAlign:'right', paddingRight:20}}>
                           <Text variant='body2' color='textSecondary'><i>{(rec.orderedTicketCount === 1) ? '1 Ticket' : rec.orderedTicketCount + ' Tickets'}</i></Text>
                           <Text variant='body2' color='textSecondary'><i>{(rec.orderedItemCount === 1) ? '1 Item' : rec.orderedItemCount + ' Items'}</i></Text>
                           <Text variant='body2'><b>{(rec.orderStatus === 'paid') ? money(rec.paymentTotal, rec.paymentCurrency) : money(rec.orderTotal)}</b></Text>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                           <Button fullWidth size='small' component={Link} to={'/member/stuff/invoice/'+rec.orderId} variant='outlined' color='secondary'>View Invoice</Button>
                        </Grid>
                     </Grid>
                     {(rec.orderStatus === 'pending') ? (
                        <Timer variant='cartInvoices' endTime={rec.dateTimeExpires} callback={handleTimerExpired}/> 
                     ):(null)}
                     </Paper>
                  )
               })
            )}
         </Paper>
         <NextUpdate lastUpdated={lastUpdated} updateNow={handleFetchMemberInvoices}/>
      </div>
   )
}
export default Invoices
