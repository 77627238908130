import { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import format from 'date-fns/format'
import Swal from 'sweetalert2'

import Loader from '../../components/Loader'

import { green } from '@mui/material/colors'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import IconArrowBack from '@mui/icons-material/ArrowBackIos'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

function Details({tickets, updateTicket, closeTicket, deleteTicket}){
   const [loading, setLoading] = useState(false)
   const [message, setMessage] = useState('')
   const [post, setPost] = useState('')
   const [searchParams] = useSearchParams()
   const ticketId = searchParams.get('id') 
   const [ticket, setTicket] = useState(tickets[ticketId])
   const navigate = useNavigate()
   let pendingResponse = true
   let threadCount = 0
  
   useEffect(() => {
      setTicket(tickets[ticketId])
   },[tickets, ticketId])

   const handleChange = (e) => {
      setMessage('')
      setPost(e.target.value)
   }
   const handleUpdateTicket = () => {
      if(post === ''){
         return setMessage('Please enter your post.')
      }
      setLoading(true)
      updateTicket(ticketId, post)
      .then(() => {
         setLoading(false)
         setMessage('')
         setPost('')
      })
      .catch((error) => {
         setLoading(false)
         setMessage(error.message)
      })
   }
   const handleCloseTicket = () => {
      Swal.fire({
            position: 'top-end',
            icon: 'question',
            title: 'Close Ticket',
            text: 'Are you sure you would like to close this ticket?',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: 'Close'
      }).then((result) => {
         if (result.isConfirmed) {
            setLoading(true)
            closeTicket(ticketId)
            .then(() => {
               setLoading(false)
            })
            .catch((e) => {
               setLoading(false)
               Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  title: e.title, 
                  text: 'Sorry, ' + e.message,
                  showConfirmButton: true
               })
            })
         }
      })
   }
   const handleDeleteTicket = () => {
      Swal.fire({
            position: 'top-end',
            icon: 'question',
            title: 'Delete Ticket',
            text: 'Are you sure you would like to delete this ticket?',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: 'Delete'
      }).then((result) => {
         if (result.isConfirmed) {
            setLoading(true)
            deleteTicket(ticketId)
            .then(() => {
               setLoading(false)
               navigate('/member/support/tickets')
            })
            .catch((e) => {
               setLoading(false)
               Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  title: e.title, 
                  text: 'Sorry, ' + e.message,
                  showConfirmButton: true
               })
            })
         }
      })
   }

   return (
      <Paper style={{ padding: 10, minHeight:700 }} >
         <Loader loading={loading} title='Updating Ticket' text='Updating ticket, please wait...' />
         <div style={{maxWidth:700, margin:'0px auto'}}>
            <div align='right' >
               <Button size='small' component={Link} to='/member/support/tickets'><IconArrowBack /> Back to List</Button>
            </div>

            <div style={{marginTop:5, padding: 5, border:'1px solid #ccc'}} >
               <List>
                  <ListItem>
                     <ListItemAvatar>
                        <Avatar alt={ticket.groupName} src={ticket.groupThumb} style={{borderRadius:5, width:70, height:70}} />
                     </ListItemAvatar>
                     <ListItemText style={{marginLeft:10, paddingTop:0, marginTop:0}}>
                        <Text variant='body1'><b>{ticket.groupName}</b></Text>
                        <Text style={{lineHeight:1}} variant='caption' display='block' color='textSecondary'>
                           On: { format(new Date(ticket.timestamp), 'eee do MMM y - h:mm a')}
                        </Text>
                        <Text variant='h6' style={{marginTop:5, lineHeight:1, color:'#546e7a'}}><b>{ticket.subject}</b></Text>
                     </ListItemText>
                     <ListItemSecondaryAction>
                        {(ticket.closed) ? (
                           <Text style={{lineHeight:1, color:'red'}}><b>CLOSED</b></Text>
                        ):(
                           <Text style={{lineHeight:1, color:green[500]}}><b>OPEN</b></Text>
                        )}
                     </ListItemSecondaryAction>
                  </ListItem>
               </List>
            </div>

            <div style={{marginTop:5, padding: 5, border:'1px solid #ccc'}} >
               <List>
               {Object.keys(ticket.thread).map((i, key) => {
                  let t = ticket.thread[i]
                  threadCount = threadCount + 1
                  pendingResponse = (ticket.memberId === t.postedById) ? true : false
                  return (
                     <div key={key}>
                        <ListItem alignItems="flex-start" style={{paddingBottom:50}}>
                           <ListItemAvatar>
                              <Avatar alt={t.postedByName} src={t.postedByThumb} style={{borderRadius:5}} />
                           </ListItemAvatar>
                           <ListItemText>
                              {t.postedByName}
                              <Text style={{lineHeight:.8}} variant='caption' display='block' color='textSecondary'>
                                 On: { format(new Date(t.timestamp), 'eee do MMM y - h:mm a')}
                              </Text>
                              <Text style={{marginTop:15, lineHeight:1, color:'#333'}}>{t.comment}</Text>
                           </ListItemText>
                        </ListItem>
                        <Divider/>
                     </div>
                  )
               })}
               </List>

               {(!ticket.closed && pendingResponse) ? (
                  <div align='center' style={{padding:20}}>
                     <Text variant='h6' style={{color:'red'}}><i>Pending Response</i></Text>
                  </div>
               ):( null )}

               {(!ticket.closed) ? (
                  <div style={{padding:20, textAlign:'center'}}>
                     <TextField multiline rows={4} style={{width:'100%', margin:'20px auto'}} variant='outlined'
                        id='post' label='Post' value={post} onChange={handleChange}
                     />
                     <div style={{textAlign:'center'}}><Text variant='overline' style={{color:'red'}}>{message}</Text></div>
                     <Button onClick={handleUpdateTicket} variant="contained" color="secondary" style={{marginBottom:20}}>Submit</Button>
                     <Divider/>
                  </div>
               ):( 
                  <div align='center' style={{padding:20}}>
                     <Text variant='h6' color='textSecondary'><i>This ticket was closed by {ticket.closedByName}</i></Text>
                     <Text style={{lineHeight:.8}} variant='caption' display='block' color='textSecondary'><i>
                        On: { format(new Date(ticket.closedTimestamp), 'eee do MMM y - h:mm a')}
                     </i></Text>
                  </div>
               )}

               <div style={{margin:5,textAlign:'right'}}>
                  {(ticket.closed) ? (
                     <Text style={{margin:10, color:'red'}}><b>CLOSED</b></Text>
                  ):(
                     <Button size='small' onClick={handleCloseTicket}>Close this Ticket</Button>
                  )}
               </div>

               {(threadCount < 2) ? (
                  <div style={{margin:5,textAlign:'right'}}>
                     <Button size='small' onClick={handleDeleteTicket}>Delete this Ticket</Button>
                  </div>
               ):(null)}

            </div>
         </div>
      </Paper>
   )
}
export default Details
