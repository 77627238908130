import { useState } from 'react' 

import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Text from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

function CcInfo({amount, currency, handleSubmitCcInfo}) {
   const [name, setName] = useState('james caesar')
   const [cc, setCc] = useState('4012000000020071')
   const [exp, setExp] = useState('2412')
   const [ccv, setCcv] = useState('122')
   const [message, setMessage] = useState('')

   const handleChange = (f,v) => {
      f(v)
      setMessage('')
   }
   const handlePayNow = (e) => {
   	e.preventDefault()

   	let creditCardInfo = {
   		name: name,
   		cc: cc,
   		exp: exp,
   		cv: ccv
   	}
   	console.log('creditCardInfo: ',creditCardInfo)
      handleSubmitCcInfo(creditCardInfo)
   }

   return (
		<div>
         <div style={{textAlign:'center', padding:10, margin:'0 auto', marginBottom:10}}>
            <Stack direction='row' spacing={2} justifyContent="space-between">
               <div>Invoice Total: {amount} {currency}</div>
               <div>[CC Logos]</div>
            </Stack>
         </div>
		
			<form onSubmit={handlePayNow}>
			<Stack spacing={2}>
			   <TextField fullWidth size='small' label='Name on Card' type='text' margin='none'
		         value={name} onChange={(e) => {handleChange(setName, e.target.value) }}
		      /> 
		      <TextField fullWidth size='small' label='Credit Card Number' type='text' margin='none'
		         value={cc} onChange={(e) => {handleChange(setCc, e.target.value) }}
		      /> 
		      <TextField fullWidth size='small' label='Expiry Date' type='text' margin='none'
		         value={exp} onChange={(e) => {handleChange(setExp, e.target.value) }}
		      /> 
		      <TextField fullWidth size='small' label='CCV' type='text' margin='none'
		         value={ccv} onChange={(e) => {handleChange(setCcv, e.target.value) }}
		      /> 
			   <Button variant='contained' color='primary' type='submit'>Pay Now</Button>
			</Stack>
			</form>
		</div>
	)
}
export default CcInfo		
