import { useEffect, useState } from 'react' 
import { Routes, Route, Navigate, Outlet, Link, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { setShowMessage, loadGroupCommunicate } from '../../../redux/groupCommunicate/actions'

import AccessDenied from '../../components/AccessDenied'
import Info from './Info'
import Summary from './Summary'
import Newsletters from './Newsletters'
import Polls from './Polls'
import Posts from './Posts'
import Active from './Active'
import Inactive from './Inactive'
import SubNav from '../../components/SubNav'
import Surveys from './Surveys'
import NextUpdate from '../../components/NextUpdate'

import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

function GroupCommunicateContainer({roleId, lastUpdated, showMessage, setShowMessage}) {
   const whoHasAccess = [1]
   const [loading, setLoading] = useState(true)   
   const [showInfo, setShowInfo] = useState(false)
   const [activeTab, setActiveTab] = useState(false) 
   const location = useLocation()

   useEffect(() => {
      switch (true) {
         case location.pathname.includes('/group/communicate/summary'):
            setActiveTab(0)
         break
         case location.pathname.includes('/group/communicate/active'):
            setActiveTab(1)
         break
         case location.pathname.includes('/group/communicate/inactive'):
            setActiveTab(2)
         break
         default:
            setActiveTab(false)
      }
      handleLoadGroupCommunicate()
   },[location])

   const handleLoadGroupCommunicate = (force) => {
      //setLoading(true)
      //loadGroupSettings(force) 
      //.finally(() => {
         setLoading(false)
      //}) 
   }

   if (!roleId) return null
   if(!whoHasAccess.includes(roleId)) {
      return (<div style={{height:500}}><AccessDenied page='Communicate'/></div>)
   }

   return (
      <Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
         <Info showInfo={showInfo} setShowInfo={setShowInfo} />
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} >
               <SubNav text='Communicate' showInfo={true} setShowInfo={setShowInfo}/>
               <div style={{borderBottom: '2px solid #eee'}}>
                  <Tabs value={activeTab} variant='fullWidth' indicatorColor='primary' textColor='primary'
                     variant='scrollable' scrollButtons={false}>
                     <Tab label='Summary' style={{ maxWidth:200 }} component={Link} to='/group/communicate/summary'/>
                     <Tab label='Active' style={{ maxWidth:200 }} component={Link} to='/group/communicate/active' />
                     <Tab label='Inactive' style={{ maxWidth:200 }} component={Link} to='/group/communicate/inactive' />
                  </Tabs>
               </div>
               <Collapse in={loading}> 
                  <Alert severity='warning'>
                     Retrieving Communications Settings ...
                     <LinearProgress color='inherit' />   
                  </Alert> 
               </Collapse>
               <div>
                  <Routes>
                     <Route path='/summary' element={<Summary />} />
                     <Route path='/active' element={<Active />} />
                     <Route path='/inactive' element={<Inactive />} />
                     <Route path='*' element={<Navigate to='/group/communicate/summary' />} />
                  </Routes>
                  <Outlet />
               </div>
            </Grid>            
         </Grid> 
         <NextUpdate lastUpdated={lastUpdated} updateNow={handleLoadGroupCommunicate}/>
      </Container>          
   )
}
const mapStateToProps = (state) => ({
   roleId: state.auth.loginOptions[state.auth.loginOptionID].roleId,
   lastUpdated: state.app.lastUpdated.groupCommunicate,
   showMessage: state.groupCommunicate.showMessage
})
const mapDispatchToProps = {
   setShowMessage,
   loadGroupCommunicate,
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupCommunicateContainer)
