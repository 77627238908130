import { Routes, Route, Outlet, useLocation, Navigate, useSearchParams } from 'react-router-dom'

import AboutUs from './public/AboutUs'
import Blog from './public/Blog'
import Branding from './public/Branding'
import Cart from './public/cart/_Container'
import DeliveryPolicy from './public/DeliveryPolicy'
import Email from './public/Email'
import EmailConfirm from './public/EmailConfirm'
import Error404 from './public/Error404'
import Event from './public/event/_Container'
import Events from './public/Events'
import Fac from './public/Fac'
import PowerTranzAuth from './components/Payments/FacPaymentWindow/AuthenticationResult'
import PowerTranzComplete from './components/Payments/FacPaymentWindow/AuthenticationComplete'
import Faqs from './public/Faqs'
import Home from './public/Home'
import Legal from './public/Legal'
import Load from './public/Load'
import Login from './public/Login'
import SecurityPolicy from './public/SecurityPolicy'
import Settings from './public/Settings'
import Solutions from './public/Solutions'
import Start from './public/Start'

export default function PublicRoutes({ loggedIn }) {
   return (
      <div>  
         <Routes>
            <Route path='/' element={<Events />} />
            <Route path='/aboutus' element={<AboutUs />} />
            <Route path='/blog' element={<Blog />} /> 
            <Route path='/branding' element={<Branding />} />
            <Route path='/delivery-policy' element={<DeliveryPolicy />} /> 
            <Route path='/fac' element={<Fac />} />
            <Route path='/faqs' element={<Faqs />} />
            <Route path='/legal' element={<Legal />} />        
            <Route path='/ptranz-auth' element={<PowerTranzAuth />} />
            <Route path='/ptranz-complete' element={<PowerTranzComplete />} />
            <Route path='/security-policy' element={<SecurityPolicy />} /> 
            <Route path='/settings' element={<Settings />} />
            <Route path='/solutions' element={<Solutions />} />
            <Route path='/start' element={<Start />} /> 
            <Route path='/email/confirm/:type' element={<CheckAuth loggedIn={loggedIn}><EmailConfirm /></CheckAuth>} />
            <Route path='/email/:type' element={<CheckAuth loggedIn={loggedIn}><Email /></CheckAuth>} />
            <Route path='/login' element={<CheckAuth loggedIn={loggedIn}><Login /></CheckAuth>} />
            <Route path='/events' element={<Events />} />        
            <Route path='/event/:eventId' element={<Event />} />


            <Route path='/cart' element={<Cart />} />  
            <Route path='/:page' element={<Load />} />
            <Route path='*' element={<Error404 />} />
         </Routes>
         <Outlet />
      </div>
   )
}

function CheckAuth({ children, loggedIn }) {
   let location = useLocation()  
   const [searchParams] = useSearchParams()
   let rURL = searchParams.get('rURL') || '/member/account' // the url to return to after signin
   let setup = searchParams.get('setup') || 0 // user login/register through email, so take them to setup page to set password
   
   if(loggedIn && location.state && location.state.from) {
      rURL = location.state.from.pathname + location.state.from.search
   }

   if(loggedIn) {
      if (setup) {
         return <Navigate to={'/member/setup?nextURL=' + rURL} replace />
      }
      if (rURL) {
         return <Navigate to={rURL} replace />
      }
      return <Navigate to={'/member/account'} replace />
   }
   return children
}
