import { GROUP_EVENT_LOAD, GROUP_EVENT_UPDATE, GROUP_EVENT_RESET_REDUCER } from '../types'

const initialState = {
	groupId: null,
	event: {}
}

export default function reducer(state = initialState, action) {
  	switch (action.type) {
      case GROUP_EVENT_LOAD:
         return { 
				...state,
				event: action.payload
			}
      case GROUP_EVENT_UPDATE:
         return { 
				...state,
				event: {
					...state.event,
					...action.payload
				}	
			}
		case GROUP_EVENT_RESET_REDUCER:
			return { 
				...state,
	         groupId: action.payload,
            event: {}
			}
		default:
			return state
	}
}
