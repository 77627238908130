import { useState } from 'react' 

import LoginPopup from '../../../components/LoginPopup'
import TicketsPublic from './TicketsPublic'

import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import Text from '@mui/material/Typography'

function TicketsContainer({isAgent, availableTickets, addTicketToOrder, loggedIn, userInfo, event}) {
   const [showLoginPopup, setShowLoginPopup] = useState(false)

   const handleLogin = () => {
      setShowLoginPopup(true)
   }
   if(!Object.keys(event).length) return null
   if(!availableTickets.length) return null   

   return (
      <Card elevation={0} style={{border: '1px solid #cfd8dc', borderRadius:0, marginBottom:20}}>
         <LoginPopup showLoginPopup={showLoginPopup} setShowLoginPopup={setShowLoginPopup} />
         <CardHeader title='Purchase Tickets' titleTypographyProps={{variant: 'h6'}} style={{padding:'10px 15px 10px 15px'}}/>
         <Divider variant='middle' style={{backgroundColor: '#cfd8dc'}} />
         <CardContent>
            {(event.type === 'public') ? (
               <TicketsPublic 
                  isAgent={isAgent}
                  availableTickets={availableTickets}
                  addTicketToOrder={addTicketToOrder} 
                  loggedIn={loggedIn} 
                  userInfo={userInfo}
                  event={event}
               />
            ):( null )}
            
            {(!loggedIn) ? (
               <div style={{textAlign:'center', marginTop:10, padding:5, backgroundColor:'#f2f2f2', border:'1px solid #ccc'}}>
                  <Text variant='body2' style={{color:'#999', lineHeight:1}}>You must be logged in to purchase tickets for this event.</Text>
                  <Button onClick={handleLogin} variant='contained' color='secondary' style={{margin:4}}>Login / Register</Button>
               </div>
            ):( null )}
         </CardContent>
      </Card>
   )
}
export default TicketsContainer
