import { Link } from 'react-router-dom'
import { format } from 'date-fns'

import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Text from '@mui/material/Typography'

function CartInvoices({loggedIn, invoices}) {
   if (!loggedIn) { return null }

   const pendingInvoices = Object.values(invoices).filter(rec => { return rec.orderStatus === 'pending' })
   if (!pendingInvoices.length) { return null }

   return ( 
      <Card elevation={0} style={{border: '1px solid #cfd8dc', borderRadius:0, marginBottom:20}}>
         <CardHeader title='Outstanding Invoices' subheader='(Invoices pending payment)' titleTypographyProps={{variant: 'h6'}} style={{padding:'10px 15px 10px 15px'}}/>
         <Divider variant='middle' style={{backgroundColor: '#cfd8dc'}} />
         {pendingInvoices.map((rec) => {
            return (
               <div key={rec.orderId} style={{padding:15}}>
                  <Text variant='body2' style={{color:'#ff0000'}}>Invoice #: <b>{rec.orderId}</b></Text>
                  <Text variant='body2' color='textSecondary'>Created: <i>{format(rec.dateTimeCreated, 'EEE do MMM, yyyy - h:mm a')}</i></Text>
                  <Text variant='body2' color='textSecondary'>
                     Contains: 
                     <i>{(rec.orderedTicketCount === 1) ? '1 Ticket ' : rec.orderedTicketCount + ' Tickets '}</i>
                     <i>{(rec.orderedItemCount === 1) ? '1 Item' : rec.orderedItemCount + ' Items'}</i>
                  </Text>                  
                  <div  style={{margin:15}}>
                  <Button component={Link} to={'/member/stuff/invoice/' + rec.orderId + '?force=1'} fullWidth size='small' variant='contained'>View Invoice</Button>
                  </div>
                  <Divider/>                
               </div>   
            )
         })}  
      </Card>
   )
}
export default CartInvoices
