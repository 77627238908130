import Text from '@mui/material/Typography'

function AddMembersUpload(){
    return (
        <div style={{width:'100%', textAlign:'center'}}>
            <Text variant='overline' gutterBottom>
                this feature is under development
            </Text>
        </div>
    )
}
export default AddMembersUpload
