import { useEffect, useState } from 'react'
import { format, isValid, isSameDay } from 'date-fns'

import Loader from '../../components/Loader'
import ImageSelector from '../../components/ImageSelector'
import countryList from '../../../includes/countryList'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import Text from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

function Profile({skip, handleShowPwBox, profileInfo, updateProfileInfo}) {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [disableButton, setDisableButton] = useState(true)
  const [firstName, setFirstName] = useState((profileInfo && profileInfo.firstName) ? profileInfo.firstName : '')
  const [lastName, setLastName] = useState((profileInfo && profileInfo.lastName) ? profileInfo.lastName : '')
  const [gender, setGender] = useState((profileInfo && profileInfo.gender) ? profileInfo.gender : '')
  const [dob, setDob] = useState((profileInfo && profileInfo.dob) ? new Date(profileInfo.dob) : null)
  const [cell, setCell] = useState((profileInfo && profileInfo.cell) ? profileInfo.cell : '')
  const [nationality, setNationality] = useState((profileInfo && profileInfo.nationality) ? profileInfo.nationality : '')
  const [location, setLocation] = useState((profileInfo && profileInfo.location) ? profileInfo.location : '')

  // [START]: ImageSelector Variables & Function :::::::::
    const [selectedImage, setSelectedImage] = useState((profileInfo.image) ? profileInfo.image : null) // ImageSelector variable
    const images = [ '/images/profile/default.jpg' ] // ImageSelector variable
    const handleSelectImage = (src) => {
      if (src !== profileInfo.image) {
        setDisableButton(false)
      } else {
        setDisableButton(true)
      } 
      setSelectedImage(src)
    }
  // [END]: ImageSelector Variables & Function :::::::::::

   useEffect(() => {
      //todo: check browser location and set user location
      let p = (profileInfo) ? profileInfo : {}
      if (
         selectedImage === p.image && 
         firstName ===  p.firstName && 
         lastName ===  p.lastName && 
         gender ===  p.gender && 
         ((dob === null && p.dob === '') || (dob !== null && isSameDay(dob, new Date(p.dob)))) && 
         cell ===  p.cell && 
         nationality === p.nationality && 
         location ===  p.location
      ) {
         setDisableButton(true)
      } else {
         setDisableButton(false)
      }
   }, [profileInfo, selectedImage, firstName, lastName, dob, gender, cell, nationality,location])

  const handleChange = (f,v) => {
    f(v)
    setMessage('')
  }
  const  updateUser = () => {
    // firstName and lastName cannot be blank
    if (firstName.length < 1 || lastName.length < 1) {
      return setMessage('Sorry, both First and Last names are required.')
    }
    // format date properly
    let d = isValid(dob) ? format(dob,'MM/dd/yyyy') : null 
    // set user variables
    let user = {
      thumb: selectedImage,
      firstName: firstName,
      lastName: lastName,
      dob: d,
      gender: gender,
      cell: cell,
      location: location,
    }
    setLoading(true)
    updateProfileInfo(user)
    .then(() => {
      setDisableButton(true)
      setLoading(false)
    })
    .catch((e) => {
      setMessage(e.message)
      setLoading(false)
    })
  }
   return (
      <Paper style={{ marginTop:20, padding: 20, minHeight:600 }} >
         <Loader loading={loading} title='Profile Update' text='Updating profile, please wait...'/>
         <Grid container spacing={2} style={{marginTop:10}}>
            <Grid item xs={12} sm={12}>
               <div style={{paddingLeft:20, maxWidth:600}}>
                  <Text variant='h5'>SecureTix Profile</Text>
                  <Text variant='subtitle1' color='textSecondary' style={{lineHeight:1.4}}>
                     Please confirm/update your profile and keep it up to date.
                     As stated in our Terms Of Service, some events may required a valid profile, and an incomplete 
                     profile may invalidate your tickets.
                  </Text>
               </div>
            </Grid>
            <Grid item xs={12} sm={6}>
               {/** ImageSelector Starts Here */}
               <div align='center' style={{ maxWidth: 250, margin:'0 auto'}}>
                  <ImageSelector 
                     imgBorder='1px solid #ccc'
                     imgBorderRadius={10}
                     imgPadding={5}
                     imgRatio='1:1' 
                     images={images}
                     currentImage={selectedImage}
                     selectImage={handleSelectImage}
                  />
               </div>
               {/** ImageSelector Ends Here */} 
               <div align='center' style={{ maxWidth: 300, margin:'0 auto'}}>
                  <Button onClick={() => {handleShowPwBox(true)}}>Set Password</Button>
               </div>
            </Grid>
            <Grid item xs={12} sm={6}>
               <div style={{ paddingLeft:20, paddingRight:20 }}>
                  <TextField fullWidth size='small' label='First Name (required)' name='firstName' margin='normal'
                     value={firstName} onChange={(e) => {handleChange(setFirstName, e.target.value) }}
                  />
                  <TextField fullWidth size='small' label='Last Name (required)' name='lastName' margin='normal'
                     value={lastName} onChange={(e) => {handleChange(setLastName, e.target.value) }}
                  />
                  <TextField fullWidth size='small' label='Gender' name='lastName' margin='normal' select 
                     value={gender} onChange={(e) => {handleChange(setGender, e.target.value) }}
                  >
                     <MenuItem value=''><em>Gender</em></MenuItem>
                     <MenuItem value='Male'>Male</MenuItem>
                     <MenuItem value='Female'>Female</MenuItem> 
                  </TextField>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                     <DatePicker label='Date of Birth' inputFormat='MM/dd/yyyy'
                        value={dob} onChange={(e) => {handleChange(setDob, e) }}
                        renderInput={(params) => <TextField fullWidth size='small' style={{marginTop:10}} {...params} />}
                     />
                  </LocalizationProvider>
                  <TextField fullWidth size='small' label='Cell' name='cell' margin='normal' type='number'
                     inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                     value={cell} onChange={(e) => {handleChange(setCell, e.target.value) }}
                  />                  
                  <TextField fullWidth size='small' label='Nationality' name='Nationality' margin='normal' select
                     value={nationality} onChange={(e) => {handleChange(setNationality, e.target.value) }}
                  >
                     <MenuItem value=''><em>Nationality</em></MenuItem>
                     {countryList.map((c, i) => {
                       return(<MenuItem key={i} value={c.code}>{c.name}</MenuItem>)
                     })}
                  </TextField> 
                  <TextField fullWidth size='small' label='Location' name='location' margin='normal' select
                     value={location} onChange={(e) => {handleChange(setLocation, e.target.value) }}
                  >
                     <MenuItem value=''><em>Location</em></MenuItem>
                     {countryList.map((c, i) => {
                       return(<MenuItem key={i} value={c.code}>{c.name}</MenuItem>)
                     })}
                  </TextField> 
               </div>
            </Grid>
            <Grid item xs={12}>
               <div align='center' style={{ padding:5 }}>
                  <Text variant='body1' style={{ color: 'red' }}><b>{message}</b></Text>
               </div>
               <div style={{margin:'0px auto', maxWidth:400, paddingBottom:100, paddingLeft:20, paddingRight:20 }}>
                  <Button onClick={updateUser} fullWidth variant='outlined' color='secondary' disabled={disableButton}>Update Profile</Button>
                  <Button fullWidth onClick={skip} style={{marginTop:20}} variant='outlined' color='secondary'>skip</Button>
               </div>
            </Grid>
         </Grid>
      </Paper>
   )
}
export default Profile
