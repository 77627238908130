import { GROUP_MEMBERS_LOAD, GROUP_MEMBERS_RESET_REDUCER, GROUP_MEMBERS_SHOW_MESSAGE } from '../types'

const initialState = {
	groupId: null,
	showMessage: true,
	members: {},
	lists: {}
}

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case GROUP_MEMBERS_SHOW_MESSAGE:
         return { 
				...state,
				showMessage: action.payload
			}
		case GROUP_MEMBERS_LOAD:
			return { 
				...state, 
				members: action.payload.members,
				lists: action.payload.lists
			}
		case GROUP_MEMBERS_RESET_REDUCER:
			return { 
				...state,
	         groupId: action.payload,
	         members: {},
	         lists: {}
			}
		default:
		  return state
	}
}
