import { format } from 'date-fns'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import IconEvent from '@mui/icons-material/EventTwoTone'
import IconLocation from '@mui/icons-material/LocationOnTwoTone'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Text from '@mui/material/Typography'

function Summary({event}) {
   if (!Object.keys(event).length) { return null }
   const eventImage = event.headerImage || ''   
   const eventName = event.name || '' 
   const eventTagline = event.tagline || ''
   const eventStart = format(new Date(event.dateTimeStart), 'EEE do MMM, yyyy - p') || ''
   const eventEnd = format(new Date(event.dateTimeEnd), 'EEE do MMM, yyyy - p') || ''
   const eventVenueName = event.venueName || 'Venue: TBA' 
   const eventVenueAddress = event.venueAddress || '' 

   return (
      <Card>
         <CardMedia component='img' image={eventImage} alt={eventName} />
         <CardContent style={{paddingBottom:0}}>
            <Text variant='h5'>{eventName}</Text>
            <Text variant='subtitle1' color='textSecondary' style={{lineHeight:1}}><i>{eventTagline}</i></Text>
            <List dense>  
               <ListItem style={{padding:'0px 10px'}}>
                 <ListItemAvatar>                
                     <IconEvent style={{backgroundColor:'#fff', color:'#546e7a'}} />
                 </ListItemAvatar>
                 <ListItemText style={{lineHeight:1}} primary={'Starts: ' + eventStart} secondary={<Text color='textSecondary' variant='caption'>{'Ends: ' + eventEnd}</Text>} />
               </ListItem>

               <ListItem style={{padding:'0px 10px'}}>
                 <ListItemAvatar>                
                     <IconLocation style={{backgroundColor:'#fff', color:'#546e7a'}} />
                 </ListItemAvatar>
                 <ListItemText style={{lineHeight:1}} primary={eventVenueName} secondary={<Text color='textSecondary' variant='caption'>{eventVenueAddress}</Text>} />
               </ListItem>
            </List>
         </CardContent>
      </Card>              
   )
}
export default Summary
