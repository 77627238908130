import { initializeApp, getApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getStorage, connectStorageEmulator } from 'firebase/storage'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'

const localConfig = {
   apiKey: "12345",
   projectId: "demo-admin",
}

const stageConfig = {
   apiKey: "AIzaSyDhUnNPc903938FO8s8rrMhHjqBpsLTQsQ",
   authDomain: "stage-646a9.firebaseapp.com",
   databaseURL: "https://stage-646a9.firebaseio.com",
   projectId: "stage-646a9",
   storageBucket: "stage-646a9.appspot.com",
   messagingSenderId: "243881228035",
   appId: "1:243881228035:web:4634f178e3293d36"
}

const liveConfig = {
  apiKey: "AIzaSyAgaL21OVWqj_GnyUhvI0AnlO4MBixn6Ec",
  authDomain: "live-5778f.firebaseapp.com",
  databaseURL: "https://live-5778f.firebaseio.com",
  projectId: "live-5778f",
  storageBucket: "live-5778f.appspot.com",
  messagingSenderId: "916820668476",
  appId: "1:916820668476:web:75f1827ff9fc509bf69339"
}

// Set variables :::::::::::::::::::::::::::::::::::::::
   let hostname = window.location.hostname
   let config = stageConfig

// Set config based on hostname ::::::::::::::::::::::::
   if (hostname.includes('localhost')) {
      config = localConfig
   } else if (hostname.includes('dev.')) {
      config = stageConfig
   } else {
      config = liveConfig
   }

// Initialize Firebase :::::::::::::::::::::::::::::::::
   const firebase = initializeApp(config)

// If on localhost, setup and use Emulator
   if (hostname.includes('localhost')) {
      const auth = getAuth(firebase)
      const db = getFirestore()
      const storage = getStorage()
      const functions = getFunctions(getApp())
      connectAuthEmulator(auth, 'http://localhost:9099')
      connectFirestoreEmulator(db, 'localhost', 8080)
      connectStorageEmulator(storage, 'localhost', 9199)
      connectFunctionsEmulator(functions, 'localhost', 5001)
   }

// Export Firebase :::::::::::::::::::::::::::::::::::::
export default firebase



/*


import { initializeApp } from 'firebase/app'

const stageConfig = {
  apiKey: "AIzaSyBUa2Bm3O_gTRo2AGKpHcYyz6MVijd6FhA",
  authDomain: "bootstrap-d19a7.firebaseapp.com",
  projectId: "bootstrap-d19a7",
  storageBucket: "bootstrap-d19a7.appspot.com",
  messagingSenderId: "50170278330",
  appId: "1:50170278330:web:4ad240ee28a057e9b96d83"
}

const liveConfig = {
  apiKey: "AIzaSyBUa2Bm3O_gTRo2AGKpHcYyz6MVijd6FhA",
  authDomain: "bootstrap-d19a7.firebaseapp.com",
  projectId: "bootstrap-d19a7",
  storageBucket: "bootstrap-d19a7.appspot.com",
  messagingSenderId: "50170278330",
  appId: "1:50170278330:web:4ad240ee28a057e9b96d83"
}

// use liveConfig on live project.
let config = liveConfig
let hostname = window.location.hostname

// use stageConfig locally and on stage project.
if (hostname.includes('dev') || hostname.includes('localhost')) {
   config = stageConfig
}

// Initialize Firebase
const firebase = initializeApp(config)
export default firebase
*/




/*
if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

const admin = require('firebase-admin');

admin.initializeApp();

const db = admin.firestore();

module.exports = { admin, db };





import { getFirestore } from "firebase/firestore";
...
const db = getFirestore(firebaseApp)
*/


/*
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaTyD2Jp9aB7ycUbW1z8QyPLmD111rHezHcOw",
  authDomain: "delete-project-c7021.firebaseapp.com",
  projectId: "delete-project-c7021",
  storageBucket: "delete-project-c7021.appspot.com",
  messagingSenderId: "688102987186",
  appId: "1:688102987186:web:e27537ggg7abc2b86b982b"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth();
export const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });
*/