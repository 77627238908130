import { useEffect, useState } from 'react' 
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import format from 'date-fns/format'

import GroupProfilePage from '../../components/GroupProfilePage/'
import Loader from '../../components/Loader'
import NextUpdate from '../../components/NextUpdate'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Text from '@mui/material/Typography'

function Memberships({lastUpdated, memberships, roles, loadMemberships, adjustRole, adjustMembership}) {
   const [loading, setLoading] = useState(false) 
   const [updating, setUpdating] = useState(false) 
   const [groupId, setGroupId] = useState(null)
   const [viewGroupProfile, setViewGroupProfile] = useState(false)
   const statusText = {'active':'ACTIVE', 'pending':'PENDING', 'member':'MEMBER', 'subscriber':'SUBSCRIBER', 'unsubscribed':'UNSUBSCRIBED', 'blocked': 'BLOCKED'}
   const statusColor = {'active':'#4caf50', 'pending':'#fbc02d', 'member':'#4caf50', 'subscriber':'#fbc02d', 'unsubscribed':'#FF0000', 'blocked': '#000000'}

   useEffect(() => {
      handleFetchMemberMemberships()
   },[])

   const handleFetchMemberMemberships = (force) => {
      setLoading(true)
      loadMemberships(force) 
      .finally(() => {
         setLoading(false)
      })
   }
   const handleGroupProfileToggle = (id) => {
      if (id) {
         setGroupId(id)
         setViewGroupProfile(true)
      } else {
         setGroupId(null)
         setViewGroupProfile(false)
      }
   }
   const handleAdjustRole = (action, teamMemberId, group, role) => {
      let verb = {
         active: {
            title:'Accept Role', 
            text: 'The group ' + group.toUpperCase() + ' has invited you to assume the position of ' + role.toUpperCase(),
            button:'Accept'
         },
         rejected: {
            title:'Refuse Role', 
            text: 'You are about to refuse the role of ' + role.toUpperCase() + ' offered to you by the group ' + group.toUpperCase(),
            button:'Refuse'
         },
         resigned: {
            title:'Resign Role', 
            text: 'You are about to resign from the role of ' + role.toUpperCase() + ' in the group ' + group.toUpperCase(),
            button:'Resign'
         }
      }
      Swal.fire({
          title: verb[action].title,
          text: verb[action].text,
          confirmButtonText: verb[action].button,
          showCancelButton: true
      })
      .then((result) => {
         if (result.isConfirmed) {
            setUpdating(true)
            adjustRole(teamMemberId, action)
            .then(() => {
               setUpdating(false)
            })
            .catch((e) => {
               setUpdating(false)
               Swal.fire(e.title, e.message, '')
            })
         }
      })
   }
   const handleAdjustMembership = (action, groupId, groupName) => {
      let verb = {
         unsubscribe: {
            title:'Unsubscribe', 
            text: 'You are about to unsubscribe from the group ' + groupName.toUpperCase() + '. You would no longer receive updates from this group. You can reverse this at any time.',
            button: 'Unsubscribe',
            action: 'unsubscribe'
         },
         accept: {
            title:'Accept Invitation', 
            text: 'You are about to accept an invitation to join the group ' + groupName.toUpperCase() + '. You may still be required to register with the group.',
            button:'Join Group',
            action: 'approve'
         },
         refuse: {
            title:'Refuse Invitation', 
            text: 'You are about to refuse an invitation to join the group ' + groupName.toUpperCase() + '. You would be marked as unsubscribed and would not receive any posts from them.',
            button:'Refuse Invitation',
            action: 'unsubscribe'
         },
         subscribe: {
            title:'Subscribe', 
            text: 'You are about to subscribe to the group ' + groupName.toUpperCase() + '. You may be required to register with the group.',
            button:'Subscribe',
            action: 'subscribe'
         }
      }
      Swal.fire({
          title: verb[action].title,
          text: verb[action].text,
          confirmButtonText: verb[action].button,
          showCancelButton: true
      })
      .then((result) => {
         if (result.isConfirmed) {
            setUpdating(true)
            adjustMembership(groupId, verb[action].action)
            .then(() => {
               setUpdating(false)
            })
            .catch((e) => {
               setUpdating(false)
               Swal.fire(e.title, e.message, '')
            })
         }
      })
   }
   const handleViewReasonBlocked = (groupName, note) => {
      Swal.fire({
          title: 'Blocked',
          text: 'The group ' + groupName.toUpperCase() + ' has blocked your membership for the following reason [ ' + note + ' ]. If you would like to query this action, open a support ticket with the group.',
          confirmButtonText: 'Close'
      })
   }

	return (
      <div>
         {(viewGroupProfile) ? (
            <GroupProfilePage open={viewGroupProfile} closeView={handleGroupProfileToggle} groupId={groupId} />    
         ) : ( null)}
         <Loader loading={updating} title='Updating' text='Updating your status...'/>
   		<Paper style={{ padding: 10, minHeight:650 }} >
            <Collapse in={loading}> 
               <Alert severity='warning' style={{marginBottom:20}}>
                  Updating Account Memberships ...
                  <LinearProgress color='inherit' />   
               </Alert> 
            </Collapse>

            {(!Object.keys(memberships).length && !Object.keys(roles).length) ? (
               <div style={{marginTop:20}}>
                  <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
                     <Text variant='body1' color='textSecondary'>
                        No Memberships
                     </Text>
                  </div>
               </div> 
            ):( 
               <div>
                  {(Object.keys(roles).length) ? ( 
                  <div>
                     <div style={{marginLeft:10, marginRight:10, padding:5, borderBottom:'1px solid #ccc'}}>
                        <Text variant='h5'>Roles</Text>
                        <Text variant='subtitle1' color='textSecondary' style={{lineHeight:1}}><i>These are the groups you have a role in.</i></Text>
                     </div>
                     {Object.keys(roles).map((i, index) => { 
                        return (
                           <Paper key={index} style={{ padding:10, margin:10, marginTop:20}}>
                              <Grid container justify='center' spacing={1}>
                                 <Grid item xs={4} sm={2} md={2}>
                                    <img alt={roles[i].groupName} src={roles[i].groupThumb} style={{borderRadius:5, width:'100%', maxWidth:100}} />
                                 </Grid>
                                 <Grid item xs={8} sm={4} md={4}>
                                    <div style={{marginLeft:10}}>
                                       <Text variant='h6' style={{lineHeight:1.2}}><b>{roles[i].groupName}</b></Text>
                                       <Text variant='body2' color='textSecondary'><i>As of { format(new Date(roles[i].timestampLastUpdated), 'eee do MMM y')}</i></Text>
                                       <Box sx={{ display: { xs: 'block', sm: 'none'} }}>
                                          <Text variant='body2'>Role: <b>{roles[i].roleName}</b></Text>
                                          <Text variant='body2'>Status:&nbsp;   
                                             <span style={{backgroundColor:statusColor[roles[i].status], padding:'2px 10px', borderRadius:5, color:'white', fontSize:12, fontWeight:'bold'}}>
                                                {statusText[roles[i].status]}
                                             </span> 
                                          </Text>
                                       </Box>
                                    </div>
                                 </Grid>
                                 <Grid item xs={12} sm={3} md={3} sx={{ display: { xs: 'none', sm: 'block' } }}>
                                    <div style={{marginLeft:10}}>
                                       <Text variant='body2'>Role: <b>{roles[i].roleName}</b></Text>
                                       <Text variant='body2'>Status:&nbsp;  
                                          <span style={{backgroundColor:statusColor[roles[i].status], padding:'2px 10px', borderRadius:5, color:'white', fontSize:12, fontWeight:'bold'}}>
                                             {statusText[roles[i].status]}
                                          </span>   
                                       </Text>
                                    </div>
                                 </Grid>
                                 <Grid item xs={12} sm={3} md={3}>
                                    <Stack spacing={1}>
                                       <Button  onClick={() => handleGroupProfileToggle(roles[i].groupId)} fullWidth size='small' variant='outlined' color='secondary'>View Group</Button>
                                       {(roles[i].status === 'pending') ? (<Button onClick={() => handleAdjustRole('active', i, roles[i].groupName, roles[i].roleName)} fullWidth size='small' variant='outlined' color='secondary'>Accept Role</Button>):(null)}
                                       {(roles[i].status === 'pending') ? (<Button onClick={() => handleAdjustRole('rejected', i, roles[i].groupName, roles[i].roleName)} fullWidth size='small' variant='outlined' color='secondary'>Refuse Role</Button>):(null)}
                                       {(roles[i].status === 'active' && roles[i].roleName !== 'Owner') ? (<Button onClick={() => handleAdjustRole('resigned', i, roles[i].groupName, roles[i].roleName)} fullWidth size='small' variant='outlined' color='secondary'>Resign Role</Button>):(null)} 
                                    </Stack>
                                 </Grid>
                              </Grid>    
                           </Paper>
                        )
                     })}
                     <div style={{marginLeft:10, marginRight:10, marginTop:50, padding:5, borderBottom:'1px solid #ccc'}}>
                        <Text variant='h5'>Memberships</Text>
                        <Text variant='subtitle1' color='textSecondary' style={{lineHeight:1}}><i>These are the groups you are a member of, or subscribe to.</i></Text>
                     </div>
                  </div>
                  ):( null )}

                  {Object.keys(memberships).map((i, index) => { 
                     return (
                        <Paper key={index} style={{ padding:10, margin:10, marginTop:20}}>
                           <Grid container justify='center' spacing={1}>
                              <Grid item xs={4} sm={2} md={2}>
                                 <img alt={memberships[i].groupName} src={memberships[i].groupThumb} style={{borderRadius:5, width:'100%', maxWidth:100}} />
                              </Grid>
                              <Grid item xs={8} sm={4} md={4}>
                                 <div style={{marginLeft:10}}>
                                    <Text variant='h6' style={{lineHeight:1.2}}><b>{memberships[i].groupName}</b></Text>
                                    <Text variant='body2' color='textSecondary'>Group Type: <b>{memberships[i].groupType}</b></Text>
                                    <Box sx={{ display: { xs: 'block', sm: 'none'} }}>
                                    
                                       <Text variant='body2'>Status:&nbsp;   
                                          <span style={{backgroundColor:statusColor[memberships[i].membershipStatus], padding:'2px 10px', borderRadius:5, color:'white', fontSize:12, fontWeight:'bold'}}>
                                             {statusText[memberships[i].membershipStatus]}
                                          </span> 
                                       </Text>
                                       <Text variant='body2' color='textSecondary'><i>As of { format(new Date(memberships[i].timestampLastUpdated), 'eee do MMM y')}</i></Text>
                                    </Box>
                                 </div>
                              </Grid>
                              <Grid item xs={12} sm={3} md={3} sx={{ display: { xs: 'none', sm: 'block' } }}>
                                 <div style={{marginLeft:10}}>
                                    <Text variant='body2'>Status:&nbsp;  
                                       <span style={{backgroundColor:statusColor[memberships[i].membershipStatus], padding:'2px 10px', borderRadius:5, color:'white', fontSize:12, fontWeight:'bold'}}>
                                          {statusText[memberships[i].membershipStatus]}
                                       </span>   
                                    </Text>
                                    <Text variant='body2' color='textSecondary'><i>As of { format(new Date(memberships[i].timestampLastUpdated), 'eee do MMM y')}</i></Text>
                                 </div>
                              </Grid>
                              <Grid item xs={12} sm={3} md={3}>
                                 <Stack spacing={1}>
                                    <Button  onClick={() => handleGroupProfileToggle(memberships[i].groupId)} fullWidth size='small' variant='outlined' color='secondary'>View Group</Button>
                                    {(memberships[i].membershipStatus === 'member') ? (<Button fullWidth size='small' variant='outlined' color='secondary' onClick={() => handleAdjustMembership('unsubscribe', memberships[i].groupId, memberships[i].groupName)}>Unsubscribe</Button>):(null)}
                                    {(memberships[i].membershipStatus === 'pending') ? (<Button fullWidth size='small' variant='outlined' color='secondary' onClick={() => handleAdjustMembership('accept', memberships[i].groupId, memberships[i].groupName)}>Accept Membership</Button>):(null)}
                                    {(memberships[i].membershipStatus === 'pending') ? (<Button fullWidth size='small' variant='outlined' color='secondary' onClick={() => handleAdjustMembership('refuse', memberships[i].groupId, memberships[i].groupName)}>Refuse Membership</Button>):(null)}  
                                    {(memberships[i].membershipStatus === 'subscriber') ? (<Button fullWidth size='small' variant='outlined' color='secondary' onClick={() => handleAdjustMembership('unsubscribe', memberships[i].groupId, memberships[i].groupName)}>Unsubscribe</Button>):(null)}
                                    {(memberships[i].membershipStatus === 'unsubscribed') ? (<Button fullWidth size='small' variant='outlined' color='secondary' onClick={() => handleAdjustMembership('subscribe', memberships[i].groupId, memberships[i].groupName)}>Subscribe</Button>):(null)}
                                    {(memberships[i].membershipStatus === 'blocked') ? (<Button fullWidth size='small' variant='outlined' color='secondary' onClick={() => handleViewReasonBlocked(memberships[i].groupName,memberships[i].membershipStatusNote)}>View Reason Blocked</Button>):(null)} 
                                 </Stack>
                              </Grid>
                           </Grid>
                        </Paper>
                     )
                  })}
               </div>
            )}
            <div style={{textAlign:'right', padding:5}}>
               {/*
               <Button size='small' color='primary' component={Link} to='/member/account/creategroup'>Create a Group</Button>
               
               */}
            </div>
         </Paper>
         <NextUpdate lastUpdated={lastUpdated} updateNow={handleFetchMemberMemberships}/>
      </div>
   )
}
export default Memberships
