import Alert from '@mui/material/Alert'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function Filter(){
   return (
      <Paper style={{ padding: 10, minHeight:700 }} >

         <div style={{marginTop:20}}>
            <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
               <Text variant='body1' color='textSecondary'>
                  Filter selection accordian.. default to newest 100 members<br/>
                  Display # of members in current filter <br/>
                  filter by tags, lists, registrations or a combination of all.
               </Text>
            </div>
         </div>

         <div style={{marginTop:20}}>
            <Text variant='h5' color='textSecondary'>Filtered Members</Text>
            <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
               <Text variant='body1' color='textSecondary'>
                 result of above filter

               </Text>
            </div>
         </div> 

         <div style={{marginTop:20}}>
            <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
               <Text variant='body1' color='textSecondary'>
                  button to save current filter as list<br/>
                  button to add members
               </Text>
            </div>
         </div>



      </Paper>
   )
}
export default Filter
