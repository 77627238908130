import { useTheme, useMediaQuery } from '@mui/material'

import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import ImageIcon from '@mui/icons-material/CropOriginal'
import Text from '@mui/material/Typography'
import Toolbar from '@mui/material/Toolbar'

function RemoveImage({ openUi, closeUi, image, borderRadius, cb }) {
   const theme = useTheme()
   const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

   return (
		<Dialog 
		   open={ openUi } 
		   fullScreen={ fullScreen } 
		   scroll = { (fullScreen) ? 'paper' : 'body' } 
         onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
               closeUi(event, reason)
            }
         }}
      >
			<AppBar style={{ position: 'relative' }} >
				<Toolbar>
					<ImageIcon style={{ marginRight: 5 }} />
					<Text variant='subtitle2' color='inherit' style={{ flex: 1 }}>Delete Image</Text>
					<IconButton color='inherit' onClick={ closeUi }><CloseIcon /></IconButton>
				</Toolbar>
			</AppBar>
			<DialogContent>
				<div align='center' style={{ maxWidth: 500, margin: '20px auto' }}>
				<div style={{ height: 15 }}/>
					<img
					   alt='Preview'
						src={ image }
						style={{borderRadius:borderRadius, width:'100%', display:'block'}}
					/>
					<Button onClick={ cb } variant='contained' color='secondary' style={{margin:20}}>
						Delete This Photo
					</Button>	
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={ closeUi } variant='contained'>Cancel</Button>
				<Button onClick={ closeUi } variant='contained' color='primary'>Close</Button>
			</DialogActions>
		</Dialog>
  	)
}
export default RemoveImage
