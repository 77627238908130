import { GROUP_SETTINGS_SHOW_MESSAGE, GROUP_SETTINGS_LOAD, GROUP_SETTINGS_RESET_REDUCER } from '../types'

const initialState = {
	groupId: null,
	showMessage: true,
	profile: {},
	team: {}
}

export default function reducer(state = initialState, action) {
  	switch (action.type) {
		case GROUP_SETTINGS_SHOW_MESSAGE:
         return { 
				...state,
				showMessage: action.payload
			}
		case GROUP_SETTINGS_LOAD:
         return { 
				...state,
				profile: action.payload.profile,
				team: action.payload.team
			}
		case GROUP_SETTINGS_RESET_REDUCER:
			return { 
				...state,
	         groupId: action.payload,
	         profile: {},
	         team: {}
			}
		default:
			return state
	}
}


