import {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import format from 'date-fns/format'

import { green } from '@mui/material/colors'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import IconArrowForward from '@mui/icons-material/ArrowForwardIos'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function SupportList({ tickets, filterSelected, handleChangeFilter, lastUpdated, loadMemberSupport }) {
	const [filteredTickets, setFilteredTickets] = useState({})
	const navigate = useNavigate()

  	useEffect(() => {
    	handleFilterTickets()
  	},[tickets, filterSelected])
  
  	const handleFilterTickets = () => {
    	let filtered = {}
    	
    	if (tickets) {
	    	// filtered - filter tickets based on filter selected
	      if(filterSelected === 'All'){
	        	filtered = tickets
	      }else{
	        	Object.keys(tickets).forEach(function (i) {
	          	if (tickets[i].groupName === filterSelected) {
	            	filtered[i] = tickets[i]
	          	}
	        	}) 
	      }
	   }	      
    	// set filtered tickets
    	setFilteredTickets(filtered)
  	}
  	const handleViewDetails = (id) => {
  		navigate('/member/support/details?id='+id)
  	}
  	
	return (
		<div>
      	<Paper style={{ padding: 10, minHeight:700 }} >
      	   {(Object.keys(filteredTickets).length) ? (
					<List style={{width:'100%', margin:'0px auto', backgroundColor:'#fff', padding:5}}>
						{Object.keys(filteredTickets).map((i, key) => {
							return (
								<div key={key}>
									<ListItem button alignItems='flex-start' onClick={() => {handleViewDetails(i)}}>
										<ListItemAvatar>
										   <Avatar alt={filteredTickets[i].groupName} src={filteredTickets[i].groupThumb} style={{borderRadius:5, width:70, height:70}} />
										</ListItemAvatar>
										<ListItemText style={{marginLeft:10, paddingTop:5}}>
											<Text style={{marginTop:5, lineHeight:1, color:'#546e7a'}}><b>{filteredTickets[i].subject}</b></Text>
											{(filteredTickets[i].closed) ? (
												<Text style={{lineHeight:1.5, color:'red'}}><b>CLOSED</b></Text>
											):(
												<Text style={{lineHeight:1.5, color:green[500]}}><b>OPEN</b></Text>
											)}
											<Text style={{lineHeight:1}} variant='caption' display='block' color='textSecondary'>
												On: { format(new Date(filteredTickets[i].timestamp), 'eee do MMM y - h:mm a')}
											</Text>
										</ListItemText>
										<ListItemSecondaryAction>
										   <IconArrowForward />
										</ListItemSecondaryAction>
									</ListItem>
									<Divider style={{height:3}}/>                
								</div>
							)
						})}
					</List>
				) : (
               <div style={{marginTop:20}}>
                  <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
                     <Text variant='body1' color='textSecondary'>
                        No Tickets Found
                     </Text>
                     {(filterSelected !== 'All') ? (
								<div>
									<Text variant='body1' color='textSecondary'><i>this is filtered results</i></Text>
									<Button size='small' variant='outlined' color='secondary' onClick={() => {handleChangeFilter('All')}}>Remove Filter</Button>
								</div>
							):(null)}
                  </div>
               </div> 
				)}  
      	</Paper>
      </div>	
   )
}
export default SupportList
