import { useEffect, useState } from 'react' 

import NextUpdate from '../../components/NextUpdate'

import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function Feed({lastUpdated, feed, loadFeed}) {
   const [loading, setLoading] = useState(false) 

   useEffect(() => {
      handleFetchMemberFeed()
   },[])

   const handleFetchMemberFeed = (force) => {
      setLoading(true)
      loadFeed(force) 
      .finally(() => {
         setLoading(false)
      })
   }

	return (
      <div>
   		<Paper style={{ padding: 10, minHeight:700 }} >
            <Collapse in={loading}> 
               <Alert severity='warning' style={{marginBottom:20}}>
                  Updating Account Feed ...
                  <LinearProgress color='inherit' />   
               </Alert> 
            </Collapse>
            
            {(!feed.length) ? (
               <div style={{marginTop:20}}>
                  <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
                     <Text variant='body1' color='textSecondary'>
                        No Posts Listed
                     </Text>
                  </div>
               </div> 
            ):(null)}

            {/* 
            <div>
               <ul>
                  <li>events and posts bookmarked by user</li>
                  <li>all posts by groups user is a member of</li>
               </ul>
            </div>
            */}  

         </Paper>
         <NextUpdate lastUpdated={lastUpdated} updateNow={handleFetchMemberFeed}/>
      </div>
   )
}
export default Feed
