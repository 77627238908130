import {useState} from 'react'

import React from 'react'
import ImageSelector from '../../../components/ImageSelector'

import Button from '@mui/material/Button'
import Text from '@mui/material/Typography'

function Step5(){
   return (
   	<div>
   		<Text>Group Created</Text>
   		<Text>Instructions on how to manage group. Next Step.</Text>
   	</div>
   )
}
export default Step5
