import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'

function Summary({status, invoiceId, orderedByName, orderedByEmail}) {
   const statusText = {'hold':'ON HOLD', 'paid':'PAID IN FULL', 'pending':'PAYMENT PENDING'}
   const statusColor = {'hold':'#d50000', 'paid':'#4caf50', 'pending':'#fbc02d'}

   return ( 
      <Card elevation={0} style={{border: '1px solid #cfd8dc', borderRadius:0, marginBottom:0}}>
         <CardHeader title='Summary' titleTypographyProps={{variant: 'h6'}} style={{padding:'10px 15px 10px 15px'}}/>
         <Divider variant='middle' style={{backgroundColor: '#cfd8dc'}} />
         <CardContent style={{paddingTop: 10, paddingBottom:15}}>              
            <div style={{display: 'flex', lineHeight:1.5}}>
               Status:
               <div style={{ marginLeft:'auto', marginRight:0, textAlign:'right'}}>
                  <span style={{backgroundColor:statusColor[status], padding:'2px 10px', borderRadius:5, color:'white', fontSize:15, fontWeight:'bold'}}>
                     {statusText[status]}
                  </span>
               </div>
            </div>
            <div style={{display: 'flex', lineHeight:1.8}}>
               Invoice #:
               <div style={{ marginLeft:'auto', marginRight:0, textAlign:'right'}}>{invoiceId}</div>
            </div>
            <div style={{display: 'flex', lineHeight:1.2}}>
               Billed To:
               <div style={{ marginLeft:'auto', marginRight:0, textAlign:'right'}}>
                  <b>{orderedByName}</b><br/>
                  <span style={{color:'#78909c', fontSize:12}}>{orderedByEmail}</span>
               </div>
            </div>
         </CardContent>
      </Card>
   )
}
export default Summary
