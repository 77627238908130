import { forwardRef, useState } from 'react'
import { format } from 'date-fns'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import LinearProgress from '@mui/material/LinearProgress'
import Slide from '@mui/material/Slide'
import Text from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

function UpdatePatron({selectedItem,open,handleClose, updateNameOnTicket}){
   const [step, setStep] = useState('default') // default, processing, error, confirm
   const [oldName, setOldName] = useState(selectedItem.ownerName)
   const [nameOnTicket, setNameOnTicket] = useState('')
   const [message, setMessage] = useState('')

   const handleSubmit = () => {
      if(!nameOnTicket || nameOnTicket === '') {
         return setMessage('Sorry, a name is required.')
      }
      setStep('processing') 
      updateNameOnTicket(selectedItem.code, nameOnTicket)
      .then(() => {
         setStep('confirm')
      })
      .catch((e) => {
         setMessage(e.message)
         setStep('error')
      }) 
   }
   const handleTryAgain = () => {
      setMessage('')
      setStep('default')    
   }

   return (
      <Dialog open={open} onClose={() => { handleClose(false) }} fullWidth={true} maxWidth='xs' TransitionComponent={Transition} keepMounted>
         <DialogTitle >SecureTix | Update Patron</DialogTitle>
         <DialogContent>
            <div  style={{ textAlign: 'center'}}>
            <Text color='textSecondary' gutterBottom>               
               <b>{selectedItem.ticketName}</b><br />
               {selectedItem.code}<br />
            </Text> 
            </div>
            <Divider style={{marginBottom:15}}/>
            <Text color='textSecondary' gutterBottom>               
               <b>Note: </b>
               The venue may require patrons to present idendification upon entry. 
               Please make sure the name on this ticket matches that ID.<br />
            </Text> 
            <Divider/>
            
            {(step === 'default') ? (
               <div style={{paddingTop:20, paddingBottom:50, maxWidth:400, margin:'0 auto'}}>
                  <Text variant='body1' color='text.secondary'>Update Name to Ticket</Text>  
                  <TextField value={nameOnTicket} onChange={(e)=>{setNameOnTicket(e.target.value)}} fullWidth size='small' id='name'/>
                  <div style={{ textAlign:'center', height:30}}><Text style={{color:'red'}}><i>{message}</i></Text></div>
                  <div style={{ textAlign:'right'}}>
                     <Button onClick={() => { handleSubmit() }} variant='contained' color='secondary'>
                        Update
                     </Button>
                  </div>    
               </div>  
            ):( null )}

            {(step === 'processing') ? (
               <div style={{paddingTop:50, paddingBottom:50, maxWidth:400, margin:'0 auto'}}>
                  Updating Patron...
                  <LinearProgress color='inherit' />
               </div>  
            ):( null )}

            {(step === 'error') ? (
               <div style={{paddingTop:50, paddingBottom:50, maxWidth:400, margin:'0 auto'}}>
                 <Alert severity='error'>
                     <AlertTitle>Error</AlertTitle>
                     {message || 'Sorry, an error occured, please try again.'}
                  </Alert>
                  <div style={{ marginTop:20 }}><Button onClick={() => { handleTryAgain() }} variant='outlined' color='secondary'>try again</Button></div>
               </div>
            ):( null )}

            {(step === 'confirm') ? (
               <div style={{paddingTop:50, paddingBottom:50, maxWidth:400, margin:'0 auto'}}>
                  <Text gutterBottom>
                    The name on this ticket was updated from {oldName.toUpperCase()} to {nameOnTicket.toUpperCase()}.
                  </Text> 
                  <div style={{width:'50%', margin:'0px auto', marginTop:30}}>
                     <Button fullWidth onClick={() => handleClose(false)} variant='contained' color='secondary'>ok</Button> 
                  </div>
               </div>  
            ):( null )}

         </DialogContent>
         <DialogActions>
            <Button onClick={() => { handleClose(false) }} color='primary'>Close</Button>
         </DialogActions>
      </Dialog>
   )
}
export default UpdatePatron
