import { useEffect, useState } from 'react' 
import { Routes, Route, Navigate, Outlet, Link, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { addMembers } from '../../../redux/groupMembers/actions'

import AccessDenied from '../../components/AccessDenied'
import Add from './Add'
import Filter from './Filter'
import Info from './Info'
import Lists from './Lists'
import NextUpdate from '../../components/NextUpdate'
import Members from './Members'
import SubNav from '../../components/SubNav'
import Summary from './Summary'
import Tags from './Tags'
import Template from './Template'

import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

function GroupMembersContainer({roleId, lastUpdated, addMembers}) {
   const whoHasAccess = [1]
   const [loading, setLoading] = useState(true)   
   const [showInfo, setShowInfo] = useState(false)
   const [activeTab, setActiveTab] = useState(false) 
   const location = useLocation()

   useEffect(() => {
      switch (true) {
         case location.pathname.includes('/group/members/summary'):
            setActiveTab(0)
         break
         case location.pathname.includes('/group/members/members'):
            setActiveTab(1)
         break
         case location.pathname.includes('/group/members/filter'):
            setActiveTab(2)
         break
         default:
            setActiveTab(false)
      }
      handleLoadGroupMembers()
   },[location])

   const handleLoadGroupMembers = (force) => {
      setLoading(false)
      //loadGroupSettings(force) 
      //.finally(() => {
      //   setLoading(false)
      //}) 
   }

   if (!roleId) return null
   if(!whoHasAccess.includes(roleId)) {
      return (<div style={{height:500}}><AccessDenied page='Members'/></div>)
   }

   return (
      <Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
         <Info showInfo={showInfo} setShowInfo={setShowInfo} />
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} >
               <SubNav text='Members' showInfo={true} setShowInfo={setShowInfo}/>
        
               <div style={{borderBottom: '2px solid #eee'}}>
                  <Tabs value={activeTab} variant='fullWidth' indicatorColor='primary' textColor='primary'
                     variant='scrollable' scrollButtons={false}>
                     <Tab label='Summary' style={{ maxWidth:200 }} component={Link} to='/group/members/summary'/>
                     <Tab label='Members' style={{ maxWidth:200 }} component={Link} to='/group/members/members' />
                     <Tab label='Filter' style={{ maxWidth:200 }} component={Link} to='/group/members/filter' />
                  </Tabs>
               </div>
               <Collapse in={loading}> 
                  <Alert severity='warning'>
                     Retrieving Members Info ...
                     <LinearProgress color='inherit' />   
                  </Alert> 
               </Collapse>
               <div>
                  <Routes>
                     <Route path='/add' element={<Add addMembers={addMembers} />} />
                     <Route path='/filter' element={<Filter/>} />
                     <Route path='/Lists' element={<Lists />} />
                     <Route path='/members' element={<Members/>} />
                     <Route path='/summary' element={<Summary/>} />
                     <Route path='/tags' element={<Tags />} />
                     <Route path='/template' element={<Template />} />
                     <Route path='*' element={<Navigate to='/group/members/summary' />} />
                  </Routes>
                  <Outlet />
               </div>
            </Grid>            
         </Grid> 
         <NextUpdate lastUpdated={lastUpdated} updateNow={handleLoadGroupMembers}/> 
      </Container>          
   )
}
const mapStateToProps = (state) => ({
   roleId: state.auth.loginOptions[state.auth.loginOptionID].roleId,
   lastUpdated: state.app.lastUpdated.groupMembers
})
const mapDispatchToProps = {
   addMembers
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupMembersContainer)
