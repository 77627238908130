import { useState } from 'react' 
import { format } from 'date-fns'
import { money }  from '../../../../includes/functions'

import PrintTicket from './PrintTicket'
import TransferTicket from './TransferTicket'
import UpdatePatron from './UpdatePatron'
import ViewHistory from './ViewHistory'
import ViewTicket from './ViewTicket'

import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Text from '@mui/material/Typography'

function TicketsCard({ticketType, tickets, message, updateNameOnTicket, transferTicket, profileLookup}) {
   const [anchorEl, setAnchorEl] = useState(null)  
   const [selectedItem, setSelectedItem] = useState(null)  
   const [openPrintTicket, setPrintTicket] = useState(false)
   const [openTransferTicket, setTransferTicket] = useState(false)
   const [openUpdatePatron, setUpdatePatron] = useState(false)
   const [openViewHistory, setViewHistory] = useState(false)
   const [openViewTicket, setViewTicket] = useState(false)

   const handleViewTicket = () => {
   	setAnchorEl(null)
      setViewTicket(true)
   }
   const handlePrintTicket = () => {
   	setAnchorEl(null)
      setPrintTicket(true)
   }
   const handleTransferTicket = () => {
   	setAnchorEl(null)
   	setTransferTicket(true)
   }
   const handleUpdatePatron = () => {
   	setAnchorEl(null)
      setUpdatePatron(true)
   }
   const handleViewHistory = () => {
   	setAnchorEl(null)
      setViewHistory(true)
   }
   const handleOpenMenu = (position, item) => {
      setAnchorEl(position)
      let selected = tickets.filter((ticket) => ticket.code === item)
      setSelectedItem(selected[0])
   }  
   const handleViewHistoryOnly = (item) => {
      setSelectedItem(item)
      setViewHistory(true)
   }

   if (!tickets.length) {
      return (
         <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
            <Text variant='body1' color='textSecondary'>{message}</Text>
         </div>
      )
   }
	return (
      <div>
         {(openPrintTicket) ? (<PrintTicket selectedItem={selectedItem} open={openPrintTicket} handleClose={setPrintTicket}/>):(null)}
         {(openTransferTicket) ? (<TransferTicket selectedItem={selectedItem} open={openTransferTicket} handleClose={setTransferTicket} transferTicket={transferTicket} profileLookup={profileLookup}/>):(null)}
         {(openUpdatePatron) ? (<UpdatePatron selectedItem={selectedItem} open={openUpdatePatron} handleClose={setUpdatePatron} updateNameOnTicket={updateNameOnTicket}/>):(null)}
         {(openViewHistory) ? (<ViewHistory selectedItem={selectedItem} open={openViewHistory} handleClose={setViewHistory} />):(null)}
         {(openViewTicket) ? (<ViewTicket selectedItem={selectedItem} open={openViewTicket} handleClose={setViewTicket} />):(null)}
         
         {tickets.map((item) => { 
            return (
               <Paper key={item.code} elevation={0} style={{ padding:10, marginTop:10, border:'1px solid #b9b9b9'}}>
                  <Grid container justify='center' spacing={1}>
                     <Grid item xs={4} sm={2} md={2}>
                        <img alt={item.ticketName} src={item.ticketImage} style={{borderRadius:5, width:'100%', maxWidth:100}} />
                     </Grid>
                     <Grid item xs={8} sm={7} md={7}>
                        <Text variant='h5'>{item.ownerName}</Text>
                        <Text><b>{item.ticketName}</b></Text>
                        <Text variant='body1' color='textSecondary'><i>{item.ticketDescription}</i></Text>
                        <Divider style={{marginTop:10, marginBottom:3}}/>
                        <Text variant='body2' color='textSecondary'><i><b>Event:</b> {item.eventName}</i></Text>
                        <Text variant='body2' color='textSecondary'><i><b>Date:</b> {format(item.eventDateTimeStart, 'EEE do MMM, yyyy - h:mm a')}</i></Text>
                        <Text variant='body2' color='textSecondary'><i><b>Venue:</b> {item.eventVenueName + ' - ' + item.eventVenueAddress}</i></Text>
                        <Text variant='body2' color='textSecondary'><i><b>Original Price:</b> {money(item.ticketPrice)}</i></Text> 
                     </Grid>
                     <Grid item xs={12} sm={3} md={3}>
                        {(ticketType === 'active') ? (
                           <div>
                              <Button value={item.code}  onClick={ (e) => { handleOpenMenu(e.currentTarget, e.target.value) }} fullWidth size='small' variant='outlined' color='secondary'>actions</Button>
                              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => { setAnchorEl(null) }} dense='true'>
                                 <MenuItem onClick={() => { handleViewTicket() }}>View Ticket</MenuItem>
                                 <MenuItem onClick={() => { handlePrintTicket() }}>Save / Print Ticket</MenuItem>
                                 <MenuItem onClick={() => { handleTransferTicket() }}>Transfer Ticket</MenuItem>
                                 <MenuItem onClick={() => { handleUpdatePatron() }}>Update Name on Ticket</MenuItem>
                                 <MenuItem onClick={(e) => { handleViewHistory() }}>View Ticket History</MenuItem>
                              </Menu>
                           </div>
                        ):(
                           <Button onClick={ (e) => { handleViewHistoryOnly(item) }} fullWidth size='small' variant='outlined' style={{color:'gray'}}>view History</Button>
                        )}
                     </Grid>
                  </Grid>      
               </Paper>
            )
         })}
      </div>
   )
}
export default TicketsCard
