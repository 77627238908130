import { useEffect, useState } from 'react' 
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'

import { processFacPayment } from '../../redux/memberStuff/actions'
import SubNav from '../components/SubNav'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'

function Fac({processFacPayment}) {
   const [loading, setLoading] = useState(true)
   const [error, setError] = useState(null)
   const [searchParams] = useSearchParams()
   const ID = searchParams.get('ID') || ''
   const navigate = useNavigate()

   useEffect(() => {
      handleSubmitFac()
   },[])

   const handleSubmitFac = () => {
      if (!ID) {
         setLoading(false)
         let e = {title:'Error',message:'Missing Payment ID'}
         return setError(e)
      }
      setLoading(true)
      processFacPayment(ID)
      .then((invoiceId) => {
         setLoading(false)
         window.localStorage.setItem('forceLoadTickets', true)
         window.localStorage.setItem('forceLoadInvoices', true)
         Swal.fire({
             title: 'Transaction Complete',
             html: 'Navigate (top right) to <b>My Stuff</b> to view your <b>Reciept</b> and <b>Tickets</b>.',
             showCancelButton: false,
             showDenyButton: true,
             denyButtonText: 'View Reciept',
             confirmButtonText: 'View Tickets',
         })
         .then((result) => {
            if (result.isConfirmed) {
               navigate('/member/stuff/tickets?force=1') 
            } else {
               navigate('/member/stuff/invoice/' + invoiceId  + '?force=1') 
            }         
         }) 
      })
      .catch((error) => {
         window.localStorage.setItem('forceLoadTickets', true)
         window.localStorage.setItem('forceLoadInvoices', true)
         setLoading(false)
         setError(error)
      })
   }
   const handleTryAgain = () => {
      setError(null)
      handleSubmitFac()
   }

   return (
      <Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} >
                  <SubNav text='Credit Card Payment' textLink={'/fac?ID='+ID} />
            </Grid>
            <Grid item xs={12} sm={12} md={10} >      
               <Paper style={{ padding: 10, minHeight:700 }} >
                  <Collapse in={loading}> 
                     <Alert severity='warning' style={{marginBottom:20}}>
                        Processing your payment...
                        <LinearProgress color='inherit' />   
                     </Alert> 
                  </Collapse>

                  <Collapse in={Boolean(error)}> 
                     <Alert severity='error'>
                        <AlertTitle>{(error && error.title) || 'Error'}</AlertTitle>
                        {(error && error.message) || 'Sorry, an error occured, please try again.'}
                        <div style={{ marginTop:20 }}><Button size='small' onClick={handleTryAgain} variant='outlined' color='inherit'>try again</Button></div>
                        <div style={{ marginTop:10 }}><Button size='small' component={Link} to='/member/stuff/invoices' variant='outlined' color='inherit'>go to invoices</Button></div>
                     </Alert>
                  </Collapse>

               </Paper>
            </Grid>
         </Grid>
      </Container>
  )
}
const mapDispatchToProps = {
   processFacPayment
}
export default connect(null, mapDispatchToProps)(Fac)
