import { APP_LAST_UPDATED, MEMBER_ACCOUNT_FEED_LOAD, MEMBER_ACCOUNT_MEMBERSHIPS_AND_ROLES_LOAD, MEMBER_ACCOUNT_MEMBERSHIPS_UPDATE, MEMBER_ACCOUNT_ROLES_UPDATE } from '../types'
import { chkExpiredData } from '../app/actions'
import { getFunctions, httpsCallable } from 'firebase/functions'

export function loadSummary(force=false) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {   
		setTimeout(() => resolve(), 4000)
	
	})
}

export function loadFeed(force=false) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {   
		const chkIfExpired = dispatch(chkExpiredData(force, 'memberFeed'))
     	chkIfExpired
     	.then(async (currentTime) => {
     	// ---------------------------------------------------------------------------    
	      const functions = getFunctions(firebase)
         const loadFeed = httpsCallable(functions, 'memberFetchFeed')
	      loadFeed()
	      .then((returned) => {
   			dispatch({ type: MEMBER_ACCOUNT_FEED_LOAD, payload: returned.data })
	  			dispatch({ type: APP_LAST_UPDATED, payload:{key:'memberFeed', value:currentTime} })
		  		resolve()
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      }) 
     	// ---------------------------------------------------------------------------
     	})
     	.catch(() => {
         // Current data still good so do nothing.
         resolve()
     	})
	})
}

export function loadMemberships(force=false) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {   
		const chkIfExpired = dispatch(chkExpiredData(force, 'memberMemberships'))
     	chkIfExpired
     	.then(async (currentTime) => {
     	// ---------------------------------------------------------------------------    
	      const functions = getFunctions(firebase)
         const loadMembershipsAndRoles = httpsCallable(functions, 'memberFetchMembershipsAndRoles')
	      loadMembershipsAndRoles()
	      .then((returned) => {
   			dispatch({ type: MEMBER_ACCOUNT_MEMBERSHIPS_AND_ROLES_LOAD, payload: returned.data })
	  			dispatch({ type: APP_LAST_UPDATED, payload:{key:'memberMemberships', value:currentTime} })
		  		resolve()
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      }) 
     	// ---------------------------------------------------------------------------
     	})
     	.catch(() => {
         // Current data still good so do nothing.
         resolve()
     	})
	})
}

export function createGroup(groupInfo) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
	   const functions = getFunctions(firebase)
	   const createNewGroup = httpsCallable(functions, 'memberCreateGroup')
		createNewGroup({ groupInfo })
		.then((returned) => {
			dispatch(loadMemberships(true))
	      resolve()			  
		})
		.catch((e) => {
	   	let error = {title:'Error',message:''}
	   	try { error = JSON.parse(e.message) } 
	   	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	   	reject(error)
	   })		
	})
}

export function adjustRole(teamMemberId, actionTaken) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
	   const functions = getFunctions(firebase)
	   const role = httpsCallable(functions, 'memberAdjustRole')
		role({ teamMemberId, actionTaken })
		.then(() => {
			dispatch(loadMemberships(true))
	      resolve('success')			  
		})
		.catch((e) => {
	   	let error = {title:'Error',message:''}
	   	try { error = JSON.parse(e.message) } 
	   	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	   	reject(error)
	   })		
	})
}

export function adjustMembership(groupId, actionTaken) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
	   const functions = getFunctions(firebase)
	   const membership = httpsCallable(functions, 'memberAdjustMembership')
		membership({ groupId, actionTaken })
		.then(() => {
			dispatch(loadMemberships(true))
	      resolve('success')			  
		})
		.catch((e) => {
	   	let error = {title:'Error',message:''}
	   	try { error = JSON.parse(e.message) } 
	   	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	   	reject(error)
	   })		
	})
}
