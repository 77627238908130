export const APP_LAST_UPDATED = 'app:lastUpdated'
export const APP_LOAD_NOTIFICATIONS = 'app:loadNotifications'
export const APP_LOAD_SETTINGS = 'app:loadSettings'

export const AUTH_CHANGE_LOGIN_OPTION = 'auth:changeLoginOption'
export const AUTH_LOAD_LOGIN_OPTIONS = 'auth:loadLoginOptions'
export const AUTH_LOAD_USER_CREDITS = 'auth:loadUserCredits'
export const AUTH_LOGIN_USER = 'auth:loginUser'
export const AUTH_LOGOUT_USER = 'auth:logoutUser'
export const AUTH_USER_INFO = 'auth:userInfo'

export const MEMBER_ACCOUNT_FEED_LOAD = 'memberAccount:feedLoad'
export const MEMBER_ACCOUNT_MEMBERSHIPS_AND_ROLES_LOAD = 'memberAccount:membershipsAndRolesLoad'
export const MEMBER_ACCOUNT_MEMBERSHIPS_UPDATE = 'memberAccount:membershipsUpdate'
export const MEMBER_ACCOUNT_ROLES_UPDATE = 'memberAccount:rolesUpdate'

export const MEMBER_PROFILE_ACTIVITY_LOAD = 'memberProfile:loadActivity'
export const MEMBER_PROFILE_NOTIFICATIONS_LOAD = 'memberProfile:loadNotifications'

export const MEMBER_STUFF_TICKETS_LOAD = 'memberStuff:ticketsLoad'
export const MEMBER_STUFF_INVOICES_LOAD = 'memberStuff:invoicesLoad'
export const MEMBER_STUFF_INVOICES_LOAD_DETAILS = 'memberStuff:invoiceDetails'
export const MEMBER_STUFF_INVOICES_UPDATE = 'memberStuff:invoicesUpdate'
export const MEMBER_STUFF_INVOICES_UPDATE_ERROR = 'memberStuff:invoicesUpdateError'

export const MEMBER_SUPPORT_LOAD = 'memberSupport:loadTickets'
export const MEMBER_SUPPORT_ADD_TICKET = 'memberSupport:addTicket'
export const MEMBER_SUPPORT_UPDATE_TICKETS = 'memberSupport:updateTickets'

export const PUBLIC_EVENT_DETAILS_LOAD = 'public:eventDetailsLoad'
export const PUBLIC_EVENTS_LOAD = 'public:eventsLoad'

export const PUBLIC_STORE_UPDATE_ORDER  = 'public:storeUpdate'
export const PUBLIC_STORE_RESET  = 'public:storeReset'

export const GROUP_ACCOUNTS_LOAD = 'group:accountsLoad'
export const GROUP_ACCOUNTS_RESET_REDUCER = 'group:accountsResetReducer'
export const GROUP_ACCOUNTS_SHOW_MESSAGE = 'group:accountsShowMessage'

export const GROUP_COMMUNICATE_LOAD = 'group:communicateLoad'
export const GROUP_COMMUNICATE_RESET_REDUCER = 'group:communicateResetReducer'
export const GROUP_COMMUNICATE_SHOW_MESSAGE = 'group:communicateShowMessage'

export const GROUP_DASHBOARD_LOAD = 'group:dashboardLoad'
export const GROUP_DASHBOARD_RESET_REDUCER = 'group:dashboardResetReducer'
export const GROUP_DASHBOARD_SHOW_MESSAGE = 'group:dashboardShowMessage'

export const GROUP_EVENT_LOAD = 'group:eventLoad'
export const GROUP_EVENT_RESET_REDUCER = 'group:eventResetReducer'
export const GROUP_EVENT_UPDATE = 'group:eventUpdate'

export const GROUP_EVENTS_LOAD = 'group:eventsLoad'
export const GROUP_EVENTS_RESET_REDUCER = 'group:eventsResetReducer'
export const GROUP_EVENTS_SHOW_MESSAGE = 'group:eventsShowMessage'
export const GROUP_EVENTS_ADD_EVENT = 'group:eventsAddEvent'

export const GROUP_MEMBERS_LOAD = 'group:membersLoad'
export const GROUP_MEMBERS_RESET_REDUCER = 'group:membersResetReducer'
export const GROUP_MEMBERS_SHOW_MESSAGE = 'group:membersShowMessage'

export const GROUP_SETTINGS_LOAD = 'group:settingsLoad'
export const GROUP_SETTINGS_RESET_REDUCER = 'group:settingsResetReducer'
export const GROUP_SETTINGS_SHOW_MESSAGE = 'group:settingsShowMessage'

export const GROUP_SUPPORT_LOAD = 'group:supportLoad'
export const GROUP_SUPPORT_RESET_REDUCER = 'group:supportResetReducer'
export const GROUP_SUPPORT_SHOW_MESSAGE = 'group:supportShowMessage'
export const GROUP_SUPPORT_UPDATE_TICKET = 'group:supportUpdateTicket'
export const GROUP_SUPPORT_UPDATE_TICKETS = 'group:supportUpdateTickets'

// ----------------------------------------------------------------
// ----------------------------------------------------------------
// ----------------------------------------------------------------
