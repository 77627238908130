import { APP_LOAD_SETTINGS } from '../types'
import { getFirestore, doc, onSnapshot } from 'firebase/firestore'

// [START]: App Settings Listener :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
	let monitorAppSettings
	export function monitorApp() {
		return (dispatch, getstate, firebase) => {   
			const db = getFirestore(firebase)
         const docRef = doc(db, 'app', 'settings')
         monitorAppSettings = onSnapshot(docRef, querySnapshot => { 
				if (querySnapshot.data()) {
					let data = querySnapshot.data()
					dispatch({ type: APP_LOAD_SETTINGS, payload: data })
				}
			}, err => {
				console.log('monitorAppSettings error:', err)
			})
		}
	}
	export function stopMonitoringApp(){
		return () => {
			monitorAppSettings && monitorAppSettings()
		}
	}
// [END]: App Settings Listener :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

// [START]: App Utility that reduces firebase calls :::::::::::::::::::::::::::::::::::::::::
	export function chkExpiredData(force=true, reducer=null){
		return (dispatch, getstate) => new Promise((resolve, reject) => {
				// TRUE - resolve if date expired
				// TRUE - resolve if force is true
				// FALSE - reject if date is still good    
				let currentTime = Math.floor(Date.now() / 1000)
				if (force) {
					 return resolve(currentTime)
				}
				if (reducer) {
					 let lastUpdated = (getstate().app.lastUpdated.hasOwnProperty(reducer)) ? getstate().app.lastUpdated[reducer] : 0
					 let updateInterval = (getstate().app.updateIntervals.hasOwnProperty(reducer)) ?  getstate().app.updateIntervals[reducer] : getstate().app.updateIntervals.default
					 let expiresAt = lastUpdated + updateInterval
					 if (currentTime < expiresAt) {
							reject('current data good')
					 } else  {    
							resolve(currentTime)
					 }
				} else {
						resolve(currentTime)
				}
		})
	}
// [END]: App Utility that reduces firebase calls :::::::::::::::::::::::::::::::::::::::::

// [START]: Make sure data in group reducer matches with user logged in groupId ::::::::::
	export function groupStatusCheck(reducer, typeConstant){
		return (dispatch, getstate) => new Promise((resolve, reject) => {

      let activeGroupId = getstate()[reducer].groupId
      let loginOptionID = getstate().auth.loginOptionID
      let loginOptions = getstate().auth.loginOptions
      let groupId = loginOptions[loginOptionID].id
      let teamMemberId = loginOptions[loginOptionID].teamMemberId

   		// reset section reducer if loginGroupId doesnt match activeGroupId
      if (activeGroupId !== groupId) {
        dispatch({type:typeConstant, payload:groupId })
      }

      resolve({groupId,teamMemberId})
		})
	}
// [END]: Make sure data in group reducer matches with user logged in groupId ::::::::::

// [START]: Set App Default Values when app starts up :::::::::::::::::::::::
	export function setAppDefaults(){
		return () => {
		   let country = 'TT'
         let currency = 'USD'
         let eventsFilter = 'ALL'
         let language = 'eng'
			let exchangeRate = 1  // this means we are using the default USD so multiply times 1

         // check user location here and update country

			if(!window.localStorage.getItem('appCountry')) {
            window.localStorage.setItem('appCountry', country)
			}         
			if(!window.localStorage.getItem('appCurrency')) {
            window.localStorage.setItem('appCurrency', currency)
			}
			if(!window.localStorage.getItem('appEventsFilter')) {
            window.localStorage.setItem('appEventsFilter', eventsFilter)
			}
			if(!window.localStorage.getItem('appLanguage')) {
            window.localStorage.setItem('appLanguage', language)
			}
			if(!window.localStorage.getItem('appExchangeRate')) {
            window.localStorage.setItem('appExchangeRate', exchangeRate)
			}			
		}
	}
// [END]: Set App Default Values when app starts up ::::::::::::::::::::::::::
