export const filterOptions = (tickets) => {
   const filtered = ['All']
   if (tickets) {
      let all = Object.values(tickets)
    	let list = []
    	all.forEach((i) => {
      	list.push(i.groupName)
    	})
    	let unique = [...new Set(list)]
    	unique.sort()
    	filtered.push(...unique)
   }
   return filtered
}
