import { money } from '../../../../includes/functions'
import { Link } from 'react-router-dom'

import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import Text from '@mui/material/Typography'

function OrderedItems({invoice}) {
   const items = invoice.orderedItems || {}
   const status = invoice.orderStatus

   if(!invoice) return null
   return ( 
      <div>
         <Card elevation={0} style={{border: '1px solid #cfd8dc', borderRadius:0, marginBottom:20}}>
            <CardHeader title='Ordered Items' titleTypographyProps={{variant: 'h6'}} style={{padding:'10px 15px 10px 15px'}}/>
            <Divider variant='middle' style={{backgroundColor: '#cfd8dc'}} />
            <CardContent style={{padding: 0}}>              
               <List style={{margin:0}}>
               { Object.keys(items).map((i, index) => {
                  return ( 
                     <div key={index}>
                        <ListItem style={{paddingRight:100}}>
                           <ListItemAvatar>
                              <Avatar alt={items[i].eventName} src={items[i].itemImage} style={{width:60, height:60, borderRadius:2}} />
                           </ListItemAvatar>
                           <Grid container justifyContent="flex-start" alignItems="center" spacing={0.5} style={{margin:5}}>
                              <Grid item xs={12} sm={12} md={8} lg={6}>
                                 <Text variant='body1'><b>{items[i].eventName}</b></Text>
                                 <Text variant='body2' color='textSecondary' style={{lineHeight:1.25}}><i>{items[i].name}</i></Text>
                              </Grid>
                              <Grid item xs={12} sm={12} md={8} lg={6}>
                                 <Text variant='body2' style={{lineHeight:.8}}>{money(items[i].itemPrice)}</Text>
                                 <Text variant='caption' color='textSecondary' style={{lineHeight:.7}}><i>Fee: {money(items[i].itemFee)}</i></Text>
                              </Grid>
                           </Grid> 
                           <ListItemSecondaryAction>
                              <Text variant='caption'><b>{money(items[i].itemTotal)}</b></Text>
                           </ListItemSecondaryAction>
                        </ListItem>
                        <div style={{ margin:15, marginTop:0, borderBottom: '2px solid #cfd8dc' }}>
                           <Text variant='caption' color='textSecondary'><i>(This ticket is for {items[i].ownerName})</i></Text>
                        </div>
                     </div>
                  )
               })}  
               </List>
               <Grid container justify='center' spacing={2} style={{padding:10}}>
                  <Grid item xs={7} sm={9} md={8} style={{textAlign:'right'}}>
                    <Text variant='body1'><i>Cart Total:</i></Text>
                  </Grid>
                  <Grid item xs={5} sm={3} md={4} style={{textAlign:'right'}}>
                    <Text variant='body1'><i>{money(invoice.orderAmount)}</i></Text>
                  </Grid>
                  <Grid item xs={7} sm={9} md={8} style={{textAlign:'right', paddingTop:0}}>
                    <Text variant='body1'><i>Service Fee:</i></Text>
                  </Grid>
                  <Grid item xs={5} sm={3} md={4} style={{textAlign:'right', paddingTop: 0}}>
                    <Text variant='body1'><i>{money(invoice.orderFee)}</i></Text>
                  </Grid>
                  <Grid item xs={7} sm={9} md={8} style={{textAlign:'right', paddingTop:0}}>
                    <Text variant='body1'><b>Order Total:</b></Text>
                  </Grid>
                  <Grid item xs={5} sm={3} md={4} style={{textAlign:'right', paddingTop: 0}}>
                    <Text variant='body1'><b>{money(invoice.orderTotal)}</b></Text>
                  </Grid>
               </Grid> 
            </CardContent>
         </Card>
         {(status === 'paid') ? (
            <div align='right'>
               <Button size='small' component={Link} to='/member/stuff/tickets' variant='outlined' color='inherit'>View Tickets</Button>
            </div>
         ):( null )}
      </div>
   )
}
export default OrderedItems
