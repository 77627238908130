import Text from '@mui/material/Typography'

function Privacy() {
   return (
      <div>
         <div style={{textAlign:'center', marginTop:10}}><Text variant='h4'>Privacy Policy</Text></div>
         <div> 
            <Text variant='body1' style={{marginTop:15}}><b>Security</b></Text> 
            <Text variant='body2'>
               Several methods of security are used including, encryption, firewalls and other technology and security procedures to help protect the accuracy and security of your personal information and prevent unauthorized access or improper use. For example, you will note that while using some features of the web site and online services, you will need to submit a password or some other type of authenticating information. 
            </Text>

            <Text variant='body1' style={{marginTop:15}}><b>Privacy Statement</b></Text> 
            <Text variant='body2'>
               Your privacy is respected and we work hard to safeguard the privacy of your personal data. We realize that the Internet raises questions concerning the nature, use and confidentiality of information collected about consumers. We want you to know that we apply our long-standing commitment to safeguarding privacy to our Internet activities.
            </Text>

            <Text variant='body1' style={{marginTop:15}}><b>Personal Information</b></Text> 
            <Text variant='body2'>
               Your information is not shared and we do not disclose or sell any personally information (such as your name, address, telephone number or e-mail address) collected online on via the web site with other unaffiliated companies or organizations. In the future, should we decide to share such data with third parties for marketing, it will provide notification and the opportunity for its users to indicate whether they would prefer that the Company not provide such information about them to third parties. Because this information is a critical part of our business, it would be treated like our other assets in the context of a merger, sale or other corporate reorganization or legal proceeding.
            </Text>

            <Text variant='body1' style={{marginTop:15}}><b>How We Use Information</b></Text> 
            <Text variant='body2'>
               Information we collect is used principally to provide you with the service(s) and product(s) you have ordered and to let you know about other offerings which may be of interest to you. Postal and e-mail address information, for example, enables communication about services offered and ordered, as well as the proper provisioning and billing of those services. Information will be provided in response to subpoenas and other legal demands, and where we believe that disclosing this information is necessary to identify, contact or bring legal action against individuals who may be endangering public safety or interfering with property or services, or with our customers’ or others’ use of them.
            </Text>

            <Text variant='body1' style={{marginTop:15}}><b>How We Collect Information</b></Text> 
            <Text variant='body2'>
               Information is collected online by asking you for it, for example, when sign up for service. A customer’s name, address (postal and e-mail) and telephone number are the most important pieces of information, but we might request other information, based on, for example, individual organization marketing services.
            </Text>

            <Text variant='body1' style={{marginTop:15}}><b>Password Protected/Secure Area Access</b></Text> 
            <Text variant='body2'>
               Accessibility and use of any password protected or secure areas of the website, is restricted to authorized users only. Unauthorized users that attempt to access any of these restricted areas of the website may be subject to prosecution.
            </Text>

            <Text variant='body1' style={{marginTop:15}}><b>Services Offered by Company</b></Text> 
            <Text variant='body2'>
               When you apply and register on the website you accept the terms and conditions that are applicable to that particular service. We do not warrant that that any service descriptions and/or content that is contained in the website is accurate, current, complete, reliable, or error-free.
            </Text>

            <Text variant='body1' style={{marginTop:15}}><b>Termination</b></Text> 
            <Text variant='body2'>
               Access may be terminated to the website along with any services with or without any cause, at any time. Any termination is to be accompanied by a written or electronic notice. We are not liable to you or any third party for service termination. Upon termination of any and all access to the website and services, your right to use the website and/or services is immediately revoked.
            </Text>

            <Text variant='body1' style={{marginTop:15}}><b>Internet Abuse</b></Text> 
            <Text variant='body2'>
               You are prohibited from engaging in any form of Internet or website abuse, including but not limited to the following: Distributing, publishing, or sending any kind of unsolicited or unwelcome email to any number of network users (commonly referred to as “junk mail” or “spam”), including, without limitation, mass promotions, unauthorized or unsolicited commercial advertising, and informational announcements, anywhere on the Internet. Posting a single article or substantially similar articles to an excessive number of newsgroups or mailing lists. Repeated or deliberate posting of articles that are off-topic according to the charter of the newsgroup or mail list where such articles are posted. Posting commercial advertising in a conference or newsgroup, unless it is specifically permitted to be posted within that group; or (e) violating the CAN-SPAM Act.
            </Text>

         </div>
      </div>
   )
}
export default Privacy