import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'

function Tags(){
    return (
        <div>Tags</div>
    )
}
export default Tags
