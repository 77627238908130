import Alert from '@mui/material/Alert'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function Members(){
   return (
      <Paper style={{ padding: 10, minHeight:700 }} >

         <div style={{marginTop:20}}>
            <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
               <Text variant='body1' color='textSecondary'>
                  ### Members | ### Confirmed | ### Registered
               </Text>
            </div>
         </div>

         <div style={{marginTop:20}}>
            <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
               <Text variant='body1' color='textSecondary'>
                  3 pie charts<br/>
                  Members/Confirmed/Registered  | Male/Female | Age Groups
               </Text>
            </div>
         </div>

         <div style={{marginTop:20}}>
            <Text variant='h5' color='textSecondary'>Newest Members</Text>
            <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
               <Text variant='body1' color='textSecondary'>
                 list of 25 newest members.<br/>
                 link to add members<br/>
                 Membership Categories<br/>
                 Member - All members (uploaded email, registered and unregistered)<br/>
                 Confirmed - members that accept membership<br/>
                 Registered - members that completed groups registration form

               </Text>
            </div>
         </div> 

      </Paper>
   )
}
export default Members
