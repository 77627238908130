import Alert from '@mui/material/Alert'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function Summary(){
   return (
      <Paper style={{ padding: 10, minHeight:700 }} >

         <div style={{marginBottom:20}}>
            <Alert severity='info'>
               Only Admins and the group Owner have access to this page.
            </Alert> 
         </div>

         <Text variant='h5' color='textSecondary'>Account Summary</Text>
         <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
            <Text variant='body1' color='textSecondary'>
               total payins - total payouts - total credits
            </Text>
         </div>
   
         <Text variant='h5' color='textSecondary' style={{marginTop:10}}>Outstanding Invoices</Text>
         <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
            <Text variant='body1' color='textSecondary'>
               Invoices owed to app by group
            </Text>
         </div>

         <Text variant='h5' color='textSecondary' style={{marginTop:10}}>transactions</Text>
         <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
            <Text variant='body1' color='textSecondary'>
               last 10 transactions - link to transactions filter page
            </Text>
         </div>

      </Paper>
   )
}
export default Summary
