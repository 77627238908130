import { Fragment, useEffect, useState } from 'react' 
import { connect, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { loadEventDetails } from '../../../redux/publicEvents/actions'
import { loadOrder, addItemToOrder, addTicketToOrder, deleteOrder, deleteItemFromOrder } from '../../../redux/publicStore/actions'

import Cart from './Cart'
import Details from './Details'
import Items from './Items'
import Share from './Share'
import Sponsors from './Sponsors'
import SubNav from './SubNav'
import Summary from './Summary'
import Tickets from './tickets/_Container'
import NextUpdate from '../../components/NextUpdate'
     
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Badge from '@mui/material/Badge'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Fab from '@mui/material/Fab'
import Grid from '@mui/material/Grid'
import IconCart from '@mui/icons-material/ShoppingCartOutlined'
import LinearProgress from '@mui/material/LinearProgress'

const fabStyle = { position:'fixed', bottom:55, right:15 }

function PublicEventsContainer({ loggedIn, userInfo, order, orderedItems, loadEventDetails, loadOrder, addItemToOrder, addTicketToOrder, deleteOrder,  deleteItemFromOrder }) {
   const [step, setStep] = useState('loading') // loading, error, display  
   const [loadingError, setLoadingError] = useState({})
   const { eventId } = useParams()
   const event = useSelector(state => state.publicEvents.eventDetails[eventId]) || {}
   const availableTickets = useSelector(state => state.publicEvents.availableTickets[eventId]) || {}
   //const availableItems = useSelector(state => state.publicEvents.availableItems[eventId]) || {}
   const lastUpdated = useSelector(state => state.app.lastUpdated['publicEvent-' + eventId]) || 0

   useEffect(() => {
      loadOrder()
      handleFetchEvent()
   },[])

   const handleFetchEvent = (force) => {
      setStep('loading')
      loadEventDetails(force, 'listedOnSecureTix', eventId) 
      .then(() => {
         setStep('display')
      }) 
      .catch((error) => {
         setLoadingError(error)
         setStep('error')
      })
   }
   const handleTryAgain = (force) => {
      setLoadingError({})
      handleFetchEvent(true)
   }

   return (
      <Container maxWidth='lg' style={{ padding:10, marginBottom:50, minHeight:700 }}>
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} >
               <SubNav/>
            </Grid>
            
            {(step==='loading') ? (
               <Grid item xs={12} sm={12} md={10} >
                  <Alert severity='warning'>
                     <AlertTitle>Loading</AlertTitle>
                     Loading Event Details...
                     <LinearProgress color='inherit' />   
                  </Alert> 
               </Grid>
            ):(null)}
              
            {(step === 'error') ? (   
               <Grid item xs={12} sm={12} md={10} >            
                  <Alert severity='error'>
                     <AlertTitle>{loadingError.title || 'Error'}</AlertTitle>
                     {loadingError.message || 'Sorry, an error occured, please try again.'}
                  <div style={{ marginTop:20 }}><Button size='small' onClick={handleTryAgain} variant='outlined' color='inherit'>try again</Button></div>
                  <div style={{ marginTop:5 }}><Button size='small' component={Link} to='/events' variant='outlined' color='inherit'>return to upcoming events</Button></div>
                  </Alert>
               </Grid>
            ):( null )}

            {(step === 'display') ? ( 
               <Fragment>
                  <Grid item xs={12} sm={7} md={6}>            
                     <Summary event={event}/>  
                     <Details event={event}/>  
                  </Grid>
                  <Grid item xs={12} sm={5} md={4}>
                     <Cart
                        eventId={event.id}
                        order={order}
                        orderedItems={orderedItems}
                        deleteItemFromOrder={deleteItemFromOrder}
                        deleteOrder={deleteOrder}
                     />

                     <Tickets 
                        isAgent={false} // true if (affilate, committee, box office) making sale to a customer
                        availableTickets={availableTickets}
                        addTicketToOrder={addTicketToOrder} 
                        loggedIn={loggedIn} 
                        userInfo={userInfo}
                        event={event}
                     />
                     <Share event={event}/>           
                      
                  </Grid>
                  <Grid item xs={12} sm={12} md={10} > 
                     <Items/>
                     <Sponsors/>
                     <NextUpdate lastUpdated={lastUpdated} updateNow={handleFetchEvent}/>
                  </Grid>
                  {(orderedItems.length) ? (
                     <Fab sx={fabStyle} component={Link} to={'/cart'} size='medium' color='secondary'>
                        <Badge badgeContent={orderedItems.length}>
                           <IconCart />
                        </Badge>
                     </Fab>
                  ):( null)}
               </Fragment>
            ):( null )} 
         </Grid>       
      </Container>
   )
}
const mapStateToProps = (state) => ({
   loggedIn: state.auth.loggedIn || false,
   userInfo: state.auth.userInfo || {},
   order: state.publicStore.order || null,
   orderedItems: state.publicStore.orderedItems || []
})
const mapDispatchToProps = {
   loadEventDetails, 
   loadOrder,
   addItemToOrder, 
   addTicketToOrder,
   deleteOrder,
   deleteItemFromOrder 
}
export default connect(mapStateToProps, mapDispatchToProps)(PublicEventsContainer)
