import { useState } from 'react'
import { connect } from 'react-redux'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { emailLogIn } from '../../redux/auth/actions'
import { chkEmail }  from '../../includes/functions'
import ServiceAndPolicies from '../components/Legal/Index'

import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import IconChecked from '@mui/icons-material/Check'
import InputAdornment from '@mui/material/InputAdornment'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

function Email({ emailLogIn }) {
  const [sent, setSent] = useState(false)
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [copied, setCopied] = useState(false)
  const [checked, setChecked] = useState(false)
  const [btnText, setBtnText] = useState('submit')
  const [btnDisabled, setBtnDisabled] = useState(false)  
  const { type } = useParams()
  let location = useLocation()
  let navigate = useNavigate()

  const handleCopyLink = () => {
    let copyText = document.querySelector('#copiedText')
    copyText.select()
    document.execCommand('copy')
    setCopied(true)
  }
  const handleChange = (f,v) => {
    f(v)
    setMessage('')
  }
  const handleCheckboxToggle = () => {
    setChecked(!checked)
  }
  const handleClickLink = (link) => {
    navigate(link, {state: location.state}) 
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    if (!chkEmail(email)) {
      return setMessage('Please enter a valid email address.')
    }
    if (!checked) {
      return setMessage('Sorry, please agree with the TOS (Terms of Service)')
    }
    let rURL=''
    if(location.state && location.state.from) {
      rURL = '?rURL=' + location.state.from.pathname
    }
    setBtnText('Processing Request')
    setBtnDisabled(true)
    emailLogIn(email.toLowerCase(), type, rURL)
    .then(() => {
      setSent(true)
    })
    .catch((err) => {
      setBtnText('Submit')
      setBtnDisabled(false)
      setMessage(err)
    })
  }
  return (
    <Container maxWidth='xl' style={{ padding: 10, marginBottom: 150, minHeight:700, marginTop:80 }}>
      <Grid container justifyContent='center' spacing={2}>
        <Grid item xs={12} sm={5}>
          { sent ? (
              <Paper style={{ padding: 20 }} >
                <Text variant='h5' gutterBottom>
                  Email Confirmation.
                </Text>
                <Text style={{ marginTop: 10, color: 'red' }} variant='button' component='p' align='center'>
                  Check Your Email
                </Text>
                <Text style={{ marginTop: 10 }} variant='body1'>
                  A Login Link was sent to your email address - <b>{email}</b>.<br />
                  Welcome to SecureTix
                </Text>
                <Divider style={{margin:10}}/>
                <Text style={{ marginTop: 10, color: 'red' }} variant='button' component='p' align='center'>
                  Check Your Spam / Promotions Folders
                </Text>
                <Text style={{ marginTop: 10 }} variant='body1'>
                  <i>The Login Link might end up in your SPAM / PROMOTIONS folder. Please 
                  copy and add the following email address to your contacts so emails from us always go to your inbox.</i>
                </Text>
                <div style={{padding:20, textAlign:'center', maxWidth:400, margin:'10px auto'}}>
                  <TextField
                    fullWidth
                    label='Copy Email Address'
                    value='noreply@mail.securetix.com'
                    id='copiedText'
                    size='small'
                    InputProps={{
                      endAdornment: 
                      <InputAdornment position='end'>
                        {(copied) ? (
                          <Button startIcon={<IconChecked />} size='small' variant='contained' style={{backgroundColor:'#04b312', color:'#fff' }}>Copied!</Button>
                        ) : (
                          <Button onClick={handleCopyLink} size='small' variant='contained' color='secondary'>copy</Button>
                        )}
                      </InputAdornment>,
                    }}
                  />
                </div>
              </Paper>
          ):(
            <Paper style={{ padding: 20 }} >
              <Text variant='h5' gutterBottom>{ type.toUpperCase() }</Text>
              <Text variant='caption' component='p'>{type.charAt(0).toUpperCase()+type.substring(1)} through your email account.</Text>
              <Text style={{ marginTop: 10, color: 'red' }} variant='caption' component='p' align='center'>{ message }</Text>
              <form onSubmit={ handleSubmit }>
                  <TextField
                    type='email'
                    id='email'
                    label='Email'
                    placeholder='Enter your email address'
                    margin='normal'
                    fullWidth={true}
                    onChange={(e) => {handleChange(setEmail, e.target.value) }}
                  />
                  <Checkbox checked={checked} onChange={handleCheckboxToggle} color='primary'/>
                  <ServiceAndPolicies tab={0} text={0} /><br/>
                  <div style={{ height: 20 }} />
                  <Button disabled={ btnDisabled } variant='contained' color='primary'  type='submit'>{ btnText }</Button>
                  <div style={{ height: 20 }} />
                  { (type === 'register')
                    ? (<Button style={{marginRight:25}} size='small' color='primary' onClick={() => { handleClickLink('/email/login') }}>Login through Email</Button>)
                    : (<Button style={{marginRight:25}} size='small' color='primary' onClick={() => { handleClickLink('/email/register') }}>Register a New Account</Button>)
                  }                    
                  <Button size='small' color='primary' onClick={() => { handleClickLink('/login') }}>Login with Email & Password</Button>
              </form>
            </Paper>      
          )}  
        </Grid>
      </Grid>
    </Container>  
  )
}
const mapDispatchToProps = {
  emailLogIn
}
export default connect(null, mapDispatchToProps)(Email)
