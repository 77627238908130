import Text from '@mui/material/Typography'

function Fees() {
   return (
      <div>
         <div style={{textAlign:'center', marginTop:10}}><Text variant='h4'>Fees & Charges</Text></div>
         <div> 
            <Text variant='body1' gutterBottom>
            Fees & Charges
            </Text> 
         </div>
      </div>
   )
}
export default Fees