import { APP_LAST_UPDATED, GROUP_MEMBERS_LOAD, GROUP_MEMBERS_RESET_REDUCER, GROUP_MEMBERS_SHOW_MESSAGE } from '../types'
import { chkExpiredData, groupStatusCheck } from '../app/actions'
import { getFunctions, httpsCallable } from 'firebase/functions'

export function setShowMessage(){
	return (dispatch, getstate, firebase) => {
		dispatch({ type:GROUP_MEMBERS_SHOW_MESSAGE, payload:false })
	}
}

export function loadGroupMembers(force=false) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {    
	   const chkIfExpired = dispatch(chkExpiredData(force, 'groupMembers'))
     	chkIfExpired
     	.then(async (currentTime) => {
     	// ---------------------------------------------------------------------------    
	      const user = await dispatch(groupStatusCheck('groupMembers', GROUP_MEMBERS_RESET_REDUCER))  
        const teamMemberId = user.teamMemberId   
        const groupId = user.groupId

	      const functions = getFunctions(firebase)
        const fetchGroupMembers = httpsCallable(functions, 'groupFetchMembers')
	      fetchGroupMembers({ teamMemberId, groupId })
	      .then((returned) => {
	      	let payload = {
	      		members:returned.data.faqs,
	      		lists:returned.data.lists
	      	}
   			  dispatch({ type: GROUP_MEMBERS_LOAD, payload: payload })
	  			dispatch({ type: APP_LAST_UPDATED, payload:{key:'groupMembers', value:currentTime} })
		  		resolve()
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      }) 
     	// ---------------------------------------------------------------------------
     	})
     	.catch(() => {
         // Current data still good so do nothing.
         resolve()
     	})
	})
}

export function addMembers(listType, list) {
	return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {   
      const user = await dispatch(groupStatusCheck('groupMembers', GROUP_MEMBERS_RESET_REDUCER))  
      const teamMemberId = user.teamMemberId   
      const groupId = user.groupId

	   const functions = getFunctions(firebase)
	   const membersAdd = httpsCallable(functions, 'groupAddMembers')
		membersAdd({ teamMemberId, groupId, listType, list })
		.then((returned) => {
			// # of members added
			resolve(returned.data)		  
		})
		.catch((e) => {
	   	let error = {title:'Error',message:''}
	   	try { error = JSON.parse(e.message) } 
	   	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	   	reject(error)
	   })	
	})
}

