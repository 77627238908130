import { Fragment, useEffect, useState } from 'react' 
import { ReactSortable } from 'react-sortablejs'
import Swal from 'sweetalert2'

import Loader from '../../components/Loader'

import { useTheme } from '@mui/material/styles'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Collapse from '@mui/material/Collapse'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import IconClose from '@mui/icons-material/Close'
import IconExpandLess from '@mui/icons-material/ExpandLess'
import IconExpandMore from '@mui/icons-material/ExpandMore'
import IconImage from '@mui/icons-material/CropOriginal'
import LinearProgress from '@mui/material/LinearProgress'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Text from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Toolbar from '@mui/material/Toolbar'
import useMediaQuery from '@mui/material/useMediaQuery'

function EventRegistration({eventId, event, editEventRegistration, removeEventRegistration, fetchEventRegistration}){
   const [loading, setLoading] = useState(false) 
   const theme = useTheme()
   const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
   const [showRegistration, setShowRegistration] = useState(true)
   const [expandForm, setExpandForm] = useState(false)
   const [openDialog, setOpenDialog] = useState(false)
   const [expandRegistrationDetails, setExpandRegistrationDetails] = useState(false)
   
   const initialDescription = {
      id: undefined,
      name: 'Active Registration Form',
      description: 'This is a default Registration Form'
   }
   const initialQuestion = {
      question: '',
      type: 'input', // input, select, check
      required: false,
      options: '' // (for select and check questions)
   }
   const questionType = {
      input: 'Attendee gives short answer.',
      select:'Attendee can choose one option.',
      check:'Attendee can choose multiple options.',
   }
   // [START]: Form Display Variables & Functions ::::::::::::::::::::::::
   const [dialogPage, setDialogPage] = useState('intro') // intro, loading, error, display
   const [editFormDescription, setEditFormDescription] = useState(false)
   const [formDescription, setFormDescription] = useState(initialDescription)
   const [formQuestions, setFormQuestions] = useState([])
   const [question, setQuestion] = useState(initialQuestion)
   const [descriptionMessage, setDescriptionMessage] = useState('')
   const [questionMessage, setQuestionMessage] = useState('')
   const [err, setErr] = useState('') // error message
   
   const handleDescriptionChange = (name, value) => {
      setFormDescription({ ...formDescription, [name]: value })
      setDescriptionMessage('')
   }
   const handleQuestionChange = (name, value) => {
      setQuestion({ ...question, [name]: value })
      setQuestionMessage('')
   }   
   const handleSaveFormDescription = () => {
      if(!formDescription.name.length || !formDescription.description.length){
         return setDescriptionMessage('Sorry, Name and Description are required.')  
      }
      setDescriptionMessage('')
      setEditFormDescription(false)
   }
   const handleSaveFormQuestion = () => {
      if(!question.question.length){
         return setQuestionMessage('Sorry, Question is required.')  
      }
      if(question.type !== 'input' && !question.options.length){
         return setQuestionMessage('Please enter the Answer Options.')  
      }  
      let questions = formQuestions
      let count = Math.floor(Date.now() / 1000)
      let temp = question
      temp.id = count
      questions.push(temp)
      setFormQuestions(questions)
      setQuestion(initialQuestion)
      setQuestionMessage('')
   }
   const handleDeleteFormQuestion = (id) => {
      let questions = [...formQuestions]      
      questions.splice(id,1)
      setFormQuestions(questions)
   }
   const handleSaveRegistrationForm = () => {
      if(!formQuestions.length){
         return Swal.fire({
            icon: 'warning',
            title: 'Questions Required',
            text: 'Sorry, the registration form must have at least one question.',
            showConfirmButton: true
         })
      }
      let registration = {
         id:formDescription.id,
         name:formDescription.name,
         description:formDescription.description, 
         questions:formQuestions
      }
      setDialogPage('loading')
      editEventRegistration(eventId, registration)
      .then(() => {
         Swal.fire({
            icon: 'success',
            title: 'Complete!',
            text: 'Action completed successfully.',
            showConfirmButton: true
         })
         handleCancelUpdate()
      })
      .catch((e) => {
         setDialogPage('error')
         setErr(e)
      })
   }
   const handleRemoveRegistrationForm = (registrationFormId) => {
      setLoading(true)
      removeEventRegistration(eventId, registrationFormId)
      .then(() => {
         setLoading(false)
      })
      .catch((e) => {
         setLoading(false)
         Swal.fire({
            icon: 'error',
            title: e.title,
            text: e.message,
            showConfirmButton: true
         })
      })
   }
   // [END]: Form Display Variables & Functions ::::::::::::::::::::::::::

   const handleResetForm = () => {
      setFormDescription(initialDescription)
      setFormQuestions([])
      setQuestion(initialQuestion)
      setDescriptionMessage('')
      setQuestionMessage('')
      setDialogPage('intro')
   }   
   const handleCancelUpdate = () => {
      setOpenDialog(false)      
      handleResetForm()
   }
   const handleOpenDialog = () => {
      if(event.registration && Object.keys(event.registration).length){
         handleDisplayForm(event.registration)   
      }      
      setOpenDialog(true)
   }
   const handleLoadForm = (id) => {
      setDialogPage('loading') 
      fetchEventRegistration(id)
      .then((data) => {
         handleDisplayForm(data)
      })
      .catch((e) => {
         setDialogPage('error')
         setErr(e)
      })
   }
   const handleDisplayForm = (form=undefined) => {
      if(form){
         setFormDescription({id:form.id, name:form.name, description: form.description})
         setFormQuestions(form.questions)
      }
      setDialogPage('display')
   }
   return (
      <div style={{marginTop:20}}>
         <Loader loading={loading}/>
         <List sx={{ padding:0, width: '100%', borderBottom:'1px solid gray' }}>
             <ListItem 
                alignItems='flex-start' 
                style={{padding:0}}
                secondaryAction={
                   <IconButton edge='end' onClick={() => { setShowRegistration(!showRegistration) }}>
                      {showRegistration ? <IconExpandLess /> : <IconExpandMore />} 
                   </IconButton>
                }
             >
             <ListItemText primary={ <Button style={{textTransform:'none'}} onClick={() => { setShowRegistration(!showRegistration) }}><Text variant='h5' color='text.secondary'>Registration</Text></Button>} />
            </ListItem>
         </List> 
         <Collapse in={showRegistration}> 
            <Paper style={{padding:5}}>
               <div  style={{maxWidth:700, margin:'0 auto'}} >
                  {(event.registration && Object.keys(event.registration).length) ? (
                     <List component='nav' style={{padding:2}}>
                        <ListItem button 
                           style={{marginTop:2, padding:10, flexGrow: 1, backgroundColor:'#f1f1f1'}}
                           onClick={() => { setExpandRegistrationDetails(!expandRegistrationDetails) }}
                        >
                           <ListItemText primary={event.registration.name} secondary={event.registration.description} />   
                           {(expandRegistrationDetails) ? <IconExpandLess /> : <IconExpandMore/>}
                        </ListItem>
                        <Collapse in={expandRegistrationDetails} style={{padding:5, width: '100%', backgroundColor:'#f1f1f1', marginBottom:12 }}>
                           {event.registration.questions.map((q, index) => {
                              let options = q.options.split('^')
                              return (
                                 <div key={index} style={{margin:5, padding:5, borderBottom:'2px solid #ddd'}}>
                                    <Text variant='body1' style={{color:'#999'}} gutterBottom><strong>Question #{index+1}</strong></Text>
                                    <Grid container justifyContent='center' direction='row' alignItems='flex-start' spacing={0}>
                                       <Grid item xs={3} style={{textAlign:'right'}} >
                                          <Text variant='body1'>Question:&nbsp;</Text>
                                       </Grid>
                                       <Grid item xs={9}>                     
                                          <Text variant='body1' color='text.secondary'><strong>{q.question}</strong></Text>                  
                                       </Grid>
                                       {(q.type !== 'input') ? (
                                          <Fragment>
                                             <Grid item xs={3} style={{textAlign:'right'}} >
                                                <Text variant='body1'>Options:&nbsp;</Text>
                                             </Grid>
                                             <Grid item xs={9}>                     
                                                {options.map((o,i) => {
                                                   return (
                                                      <Text key={i} variant='body1' color='text.secondary'>{o}</Text>
                                                   )
                                                })}                                                        
                                             </Grid>
                                          </Fragment>
                                       ):( null )} 
                                       <Grid item xs={12} style={{padding:10}}>                     
                                          <Divider style={{margin:10}}/>            
                                          <Stack direction='row' justifyContent='center' spacing={1}>
                                             <Chip label={(q.required) ? 'Required' : 'Not Required'} color={(q.required) ? 'success' : 'warning'} size='small' />
                                             <Chip label={questionType[q.type]} size='small' variant='outlined' />
                                           </Stack>  
                                       </Grid>
                                    </Grid>   
                                 </div>  
                              )
                           })}

                           <Stack style={{padding:5}} spacing={5} direction='row' justifyContent='flex-end' alignItems='center'>
                              <Button onClick={() => { handleRemoveRegistrationForm(event.registration.id) }} size='small' style={{color:'#999'}}>Remove Form</Button>
                              <Button onClick={() => { handleOpenDialog() }} size='small' style={{color:'#999'}}>Edit Form</Button>
                           </Stack>
                        </Collapse>
                     </List>
                  ):(
                     <div style={{ padding:20, margin:3, backgroundColor:'#f1f1f1', textAlign:'center' }}>No Registration Required</div>
                  )}
               </div>
               <div style={{textAlign:'right'}}><Button onClick={() => {handleOpenDialog()}}>Edit</Button></div>
            </Paper>
         </Collapse>

{/* *************************************************************************************************** */}               

         <Dialog fullWidth={true} maxWidth={'xs'} scroll={'body'} fullScreen={fullScreen} open={openDialog} onClose={handleCancelUpdate}>
            <AppBar position='relative'>
               <Toolbar variant='dense'>
                  <IconImage/>
                  <div style={{ flex: 1, marginLeft: 5 }}><Text variant='subtitle2' color='inherit'>Event Registration</Text></div>
                  <IconButton color='inherit' onClick={handleCancelUpdate}><IconClose /></IconButton>
               </Toolbar>
            </AppBar>
            <DialogContent>
               {(dialogPage === 'intro') ? (
                  <div style={{marginBottom:50}}>
                     <Text variant='h5' gutterBottom>
                        Event Registration
                     </Text>
                     <Text variant='body1' gutterBottom>
                        Collect additional information from your patrons during the ticket purchasing process.
                        By default we collect First Name, Last Name and Email Address. Use this form to collect
                        additional information.
                     </Text>
                     <Divider style={{margin:20}} />

                     {(event.registrationForms && Object.keys(event.registrationForms).length) ? ( 
                        <Text variant='h6' gutterBottom>Select Existing / Create New Form</Text>
                     ):(null)} 
                     <List style={{ width: '100%', padding:5 }}>
                        {event.registrationForms && Object.keys(event.registrationForms).map(i => {
                           let form = event.registrationForms[i]
                           return (
                              <div key={i}>
                                 <ListItemButton>
                                    <ListItemText onClick={() => { handleLoadForm(i) }} primary={form.name} secondary={form.description} />
                                 </ListItemButton>
                                 <Divider/>
                              </div>
                           )
                        })}                          
                        <ListItemButton>
                           <ListItemText onClick={() => { handleDisplayForm() }} primary='New Form' secondary='Create a New Registration Form' />
                        </ListItemButton>
                        <Divider/>
                     </List>
                  </div>
               ):( null )}

               {(dialogPage === 'loading') ? (
                  <div style={{marginBottom:50}}>
                     <Alert severity='warning'>
                        <AlertTitle>Loading...</AlertTitle>
                        Your request is being processed.
                        <LinearProgress color='inherit' />
                     </Alert>
                  </div>
               ):( null )}

               {(dialogPage === 'error') ? (   
                  <div>             
                     <Alert severity='error'>
                        <AlertTitle>{err.title || 'Error'}</AlertTitle>
                        {err.message || 'Sorry, an error occured, please try again.'}
                     </Alert>
                     <div style={{ marginTop:20 }}><Button onClick={() => {handleCancelUpdate()}} variant='outlined' color='secondary'>close and try again</Button></div>
                  </div>
               ):( null )}

               {(dialogPage === 'display') ? ( 
                  <div style={{marginBottom:50}}>
                     {(editFormDescription) ? ( 
                        <div>
                           <Text variant='h6' gutterBottom>Registration Form</Text>
                           <div style={{marginBottom:20, marginTop:10}}>
                              <Stack spacing={2}>
                              <TextField fullWidth 
                                 size='small'
                                 label='Name' 
                                 value={formDescription.name} 
                                 onChange={(e) => { handleDescriptionChange('name', e.target.value) }}
                              />
                              <TextField fullWidth
                                 size='small'
                                 label='Description'
                                 value={formDescription.description}
                                 onChange={(e) => { handleDescriptionChange('description', e.target.value) }}
                                 multiline
                                 rows='2'
                              />
                              </Stack>
                              <div style={{textAlign:'center', marginBottom:10}}><Text style={{color:'red'}}>{descriptionMessage}</Text></div>
                           </div>
                           <div style={{textAlign:'right'}}>
                              <Button variant='outlined' size='small' color='secondary' onClick={() => { handleSaveFormDescription() }}>save description</Button> 
                              <Divider style={{height:3, margin:10}}/>
                           </div> 
                        </div>
                     ):(
                        <div>
                           <Text variant='h6' gutterBottom>Registration Form</Text>
                           <div style={{marginBottom:20}}>
                              <Text variant='body1' component='span' gutterBottom><strong>Name:&nbsp;</strong></Text>
                              <Text variant='body1' component='span' gutterBottom>{formDescription.name}</Text><br/>
                              <Text variant='body1' component='span' gutterBottom><strong>Description:&nbsp;</strong></Text>
                              <Text variant='body1' component='span' gutterBottom>{formDescription.description}</Text>
                           </div>
                           <div style={{textAlign:'right'}}>
                              <Button variant='outlined' size='small' color='secondary' onClick={() => { setEditFormDescription(true)}}>edit description</Button> 
                              <Divider style={{height:3, margin:10}}/>
                           </div> 
                        </div>
                     )}
                     
                     <ReactSortable list={formQuestions} setList={setFormQuestions}>
                     {formQuestions.map((q, index) => {
                        let options = q.options.split('^')
                        return (
                           <div key={q.id} style={{margin:5, padding:5, borderBottom:'2px solid #ddd'}}>
                              <Text variant='body1' style={{color:'red'}} gutterBottom><strong>Question #{index+1}</strong></Text>
                              <Grid container justifyContent='center' direction='row' alignItems='flex-start' spacing={0}>
                                 <Grid item xs={3} style={{textAlign:'right'}} >
                                    <Text variant='body1'>Question:&nbsp;</Text>
                                 </Grid>
                                 <Grid item xs={9}>                     
                                    <Text variant='body1' color='text.secondary'><strong>{q.question}</strong></Text>                  
                                 </Grid>
                                 {(q.type !== 'input') ? (
                                    <Fragment>
                                       <Grid item xs={3} style={{textAlign:'right'}} >
                                          <Text variant='body1'>Options:&nbsp;</Text>
                                       </Grid>
                                       <Grid item xs={9}>                     
                                          {options.map((o,i) => {
                                             return (
                                                <Text key={i} variant='body1' color='text.secondary'>{o}</Text>
                                             )
                                          })}                                                        
                                       </Grid>
                                    </Fragment>
                                 ):( null )} 
                                 <Grid item xs={12} style={{padding:10}}>                     
                                    <Divider style={{margin:10}}/>            
                                    <Stack direction='row' justifyContent='center' spacing={1}>
                                       <Chip label={(q.required) ? 'Required' : 'Not Required'} color={(q.required) ? 'success' : 'warning'} size='small' />
                                       <Chip label={questionType[q.type]} size='small' variant='outlined' />
                                     </Stack>  
                                 </Grid>
                              </Grid>                              
                              <div style={{textAlign:'right'}}>
                                 <Button onClick={() => { handleDeleteFormQuestion(index) }} style={{marginLeft:10}} color='secondary' variant='outlined' size='small'>delete</Button> 
                              </div>  
                           </div>
                        )
                     })}
                     </ReactSortable>

                     <div style={{padding:5, backgroundColor:'#f1f1f1'}}>
                        <Text variant='h6' gutterBottom>Add A Question</Text>
                        <Divider/>
                        <Stack spacing={2} style={{padding:5}}>
                           <TextField fullWidth
                              style={{backgroundColor:'#fff'}}
                              size='small'
                              label='Question'
                              value={question.question}
                              onChange={(e) => { handleQuestionChange('question', e.target.value) }}
                           />
                           <FormControl component='fieldset'>
                              <FormControlLabel 
                                 style={{color:(question.required) ? 'black' : '#999'}}
                                 labelPlacement='start'
                                 label={(question.required) ? 'Answer is required for this question.' : 'Answer is optional for this question.'}
                                 control={ <Switch 
                                    color='success'
                                    checked={question.required}
                                    onChange={(e) => { handleQuestionChange('required', !question.required) }}
                                 />} 
                              />
                           </FormControl> 
                           <TextField fullWidth
                              style={{backgroundColor:'#fff'}}
                              size='small'
                              select
                              label='Type of Answer Required'
                              value={question.type}
                              onChange={(e) => { handleQuestionChange('type', e.target.value) }}
                           >
                             <MenuItem value='input'>Short Answer (Input Box)</MenuItem>
                             <MenuItem value='select'>Choose One (Select List)</MenuItem>
                             <MenuItem value='check'>Choose Multiple (Check List)</MenuItem>
                           </TextField>
                           {(question.type !== 'input') ? (
                              <div>
                              <TextField fullWidth
                                 style={{backgroundColor:'#fff'}}
                                 size='small'
                                 label='Answer Options'
                                 
                                 value={question.options}
                                 onChange={(e) => { handleQuestionChange('options', e.target.value) }}
                              />
                              <Alert severity='warning'>
                                 Please use the (^) symbol to separate your options.
                                 (eg: Small^Medium^Large)
                              </Alert>
                              </div>
                           ):( null )}
                           <div style={{textAlign:'center', marginBottom:10}}>
                              <Text style={{color:'red'}}>{questionMessage}</Text>
                           </div>
                           <div style={{textAlign:'right'}}>
                              <Button variant='outlined' size='small' color='secondary' onClick={() => { handleSaveFormQuestion()}}>add question</Button> 
                           </div> 
                        </Stack>   
                     </div>
                  </div> 
               ):( null )}
            </DialogContent>
            <DialogActions>
               <Button onClick={handleCancelUpdate} color='primary'>Cancel</Button>
               {(dialogPage === 'display') ? (
                  <Button onClick={() => { handleSaveRegistrationForm() }} variant='contained'>save registration form</Button>
               ):( null )}
            </DialogActions>
         </Dialog>
      </div>
   )
}
export default EventRegistration
