import { Fragment, useEffect, useState } from 'react' 

import { money } from '../../../../includes/functions'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import IconButton from '@mui/material/IconButton'
import IconClose from '@mui/icons-material/Close'
import LinearProgress from '@mui/material/LinearProgress'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Text from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

function TicketsPublic({isAgent, availableTickets, addTicketToOrder, loggedIn, userInfo, event}) {
   const [menuEl, setMenuEl] = useState(null)
   const [openDialog, setOpenDialog] = useState(false)
   const [ticketType, setTicketType] = useState('select')
   const [selectedTicket, setSelectedTicket] = useState('')
   const [amount, setAmount] = useState(' ')
   const [step, setStep] = useState('default') // default, processing, error, confirm
   const [addTicketMessage, setAddTicketMessage] = useState('')
   const [message, setMessage] = useState('')
   const [nameOnTicket, setNameOnTicket] = useState('')
   const [questions, setQuestions] = useState(event.registration)

   useEffect(() => {
      if (event.ticketLimit === 1) {
         setAmount(' You are allowed to purchase 1 ticket for this event. ')
      } else if (event.ticketLimit > 1) {
         setAmount(' You are allowed to purchase up to ' + event.ticketLimit + ' tickets for this event. ')
      }
   },[])

   const handleChange = (f,v) => {
      f(v)
      setMessage('')
   }
   const handleSelectTicket = (value) => {
      setAddTicketMessage('')
      setTicketType(value)
      setSelectedTicket(availableTickets[value])
      setMenuEl(null)
   }
   const handleAddTicket = () => {
      if(ticketType === 'select') {
         return setAddTicketMessage('Please Select Ticket')
      }
      setMessage('')
      setStep('default') 
      setNameOnTicket('')   
      setOpenDialog(true)   
   }
   const handleTryAgain = () => {
      setMessage('')
      setStep('default')    
   }    
   const handleSubmit = (e) => {
      e.preventDefault()
      setMessage('')
      let formError = false
      let eventId = selectedTicket.eventId
      let ticketId = selectedTicket.ticketId
      let ticketInfo = {
         ownerType: 'patron',
         ownerId: userInfo.id,
         ownerName: '',
         ownerQuestions: null         
      }
      
      // [START]: Make sure there is a name for the ticket .............
         if (e.target.name.value === '') {
            return setMessage('Please enter a name for this ticket.')
         }
         ticketInfo.ownerName = e.target.name.value
         setNameOnTicket(e.target.name.value)
      // [END]: Make sure there is a name for the ticket ...............
      
      // [START]: Process Registration questions .......................
         if (selectedTicket.registration && questions.length) {
            let question, answer
            ticketInfo.ownerQuestions = []
            questions.map((q, i) => {
               question = q
               switch(q.type) {
                  case 'select':
                     answer = e.target['input-'+q.id].value
                     if (q.required && !answer.length) {
                        formError = true     
                        return setMessage('Please answer question #'+(i+1)+'.') 
                     }
                     question.answer = answer
                     ticketInfo.ownerQuestions.push(question)
                  break
                  case 'check':
                     let options = q.options.split('^')
                     let answerArray = []
                     options.map((o, i) => { 
                        if (e.target['input-'+q.id][i].checked){
                           answerArray.push(options[i])
                        }
                     })
                     if (q.required && !answerArray.length) {
                        formError = true
                        return setMessage('Please answer question #'+(i+1)+'.') 
                     }
                     question.answer = answerArray.join('^')
                     ticketInfo.ownerQuestions.push(question)
                  break
                  default:
                     answer = e.target['input-'+q.id].value
                     if (q.required && !answer.length) {
                        formError = true
                        return setMessage('Please answer question #'+(i+1)+'.') 
                     }
                     question.answer = answer
                     ticketInfo.ownerQuestions.push(question)
               }
            })
         }
         if (formError) { return false }
      // [END]: Process Registration questions .........................
      
      setStep('processing')
      addTicketToOrder(eventId, ticketId, ticketInfo) 
      .then(() => {
         setStep('confirm')
      }) 
      .catch((error) => {
         setMessage(error.message)
         setStep('error')
      })
   }

   return (
      <div>
         <Alert icon={false} severity='warning'>
            <Text variant='body2'>
               This is a PUBLIC event produced by {event.groupName.toUpperCase()}.
               {amount}
            </Text>  
         </Alert>

         <div style={{marginTop:10}}>
            <Text variant='subtitle2'>Select Ticket</Text>
            <List style={{backgroundColor:'#fff', border:'1px solid #cccccc', padding:2}}>
               <ListItem button onClick={(e) => { setMenuEl(e.currentTarget) }} style={{padding:10}}>
               <ListItemText 
                  primary={(ticketType !== 'select') ? availableTickets[ticketType].name : 'Click Here To Select Ticket'} 
                  secondary={(ticketType !== 'select') ? availableTickets[ticketType].description + ' [' + money(availableTickets[ticketType].price) + ']' : ''} />
               {Boolean(menuEl) ? <ExpandLess /> : <ExpandMore />}
               </ListItem>
            </List>

            <Menu value={ticketType} dense='true' anchorEl={menuEl} open={Boolean(menuEl)} onClose={() => {setMenuEl(null)}}>
               {availableTickets.map((ticket,index) => (
                  <MenuItem key={index} selected={ticketType === index } onClick={() => { handleSelectTicket(index) }}>
                     <ListItemText style={{whiteSpace: 'normal', maxWidth:400, width:'100%'}} 
                        primary={ticket.name} 
                        secondary={
                           <Fragment>
                              <Text component='span' style={{display:'block'}}>{ticket.description}</Text>
                              <Text component='span' variant='body2'>{money(ticket.price)}</Text>
                           </Fragment>
                        } 
                     />
                  </MenuItem>
               ))}
            </Menu>
         </div>
         <div style={{ textAlign:'center', padding:5}}><Text variant='body2' style={{color:'red'}}><i>{addTicketMessage}</i></Text></div>
                        
         {(loggedIn) ? (
            <div style={{textAlign:'center', marginTop:10}}>
               <Button onClick={handleAddTicket} fullWidth variant='contained'>Add Ticket To Order</Button>
            </div>
         ):( null )}

{/* ---------------------------------------------------------------------------------------------- */}
         
         <Dialog scroll='body' open={openDialog} onClose={() => setOpenDialog(false)} style={{maxWidth:'100%', margin:0}}>
            <DialogTitle>
               <Text style={{color:'#999'}}>Add Ticket</Text>
               <IconButton onClick={() => setOpenDialog(false)} sx={{ position: 'absolute', right: 8, top: 8, color: '#999'}}>
                  <IconClose />
               </IconButton>
            </DialogTitle >
            <DialogContent style={{padding:20}} dividers>
               {(step === 'default') ? (
                  <div>
                  <form onSubmit={handleSubmit}>
                     <Text gutterBottom>Add 1 <b>{selectedTicket.name}</b> to your order.</Text>
                        <Stack spacing={3}> 
                           <div style={{marginTop:10}}>
                              <Text variant='body1' color='text.secondary'>Add Name to Ticket</Text>  
                              <TextField fullWidth size='small' id='name'/>
                           </div>
                           {(selectedTicket.registration) ? (
                              <div>
                                 <Alert icon={false} severity='info' style={{marginBottom:20}}>
                                    <strong>Additional Info Required</strong><br/>
                                    This ticket requires some Additional infomation be submitted. 
                                 </Alert>
                                 {(questions.map((q, i) => {
                                    let options = q.options.split('^')
                                    switch(q.type) {
                                       case 'select':
                                          return (
                                             <div key={q.id} style={{marginBottom:20}}>
                                                <Text component='span' variant='body1'>#{i+1}. {q.question}</Text>
                                                {(q.required) ? (
                                                   <Text component='span' variant='caption' style={{color:'red'}}><i> (required)</i></Text>
                                                ):(null)}
                                                <TextField id={'input-'+q.id} name={'input-'+q.id} select fullWidth size='small' defaultValue='' >
                                                  {(options.map((o, i) => { 
                                                      return ( 
                                                         <MenuItem key={i} value={o}>{o}</MenuItem>
                                                      )
                                                  }))}
                                                </TextField>
                                             </div>
                                          )
                                          break
                                       case 'check':
                                          return (
                                             <div key={q.id} style={{marginBottom:20}}>
                                                <Text component='span' variant='body1'>#{i+1}. {q.question}</Text>
                                                {(q.required) ? (
                                                   <Text component='span' variant='caption' style={{color:'red'}}><i> (required)</i></Text>
                                                ):(null)}
                                                <FormGroup style={{marginLeft:20}}>
                                                   {(options.map((o, i) => { 
                                                      return ( 
                                                         <FormControlLabel key={i} control={<Checkbox style={{padding:3}} id={'input-'+q.id} name={'input-'+q.id} size='small' />} label={o} />
                                                      )
                                                   }))}
                                                </FormGroup>
                                             </div>
                                          )
                                          break
                                       default:
                                          return (
                                             <div key={q.id} style={{marginBottom:20}}>
                                                <Text component='span' variant='body1'>#{i+1}. {q.question}</Text> 
                                                {(q.required) ? (
                                                   <Text component='span' variant='caption' style={{color:'red'}}><i> (required)</i></Text>
                                                ):(null)}                                                                             
                                                <TextField id={'input-'+q.id} name={'input-'+q.id} fullWidth size='small'/>
                                             </div>
                                          )
                                    }                              
                                 }))}
                              </div>
                           ):(null)}
                        </Stack>
                        <div style={{ textAlign:'center', height:30}}><Text style={{color:'red'}}><i>{message}</i></Text></div>
                        <div style={{marginBottom:20, textAlign:'right'}}>
                        <Button  type='submit' variant='contained' color='secondary'>
                           Add Ticket
                        </Button>
                        </div>
                  </form>      
                  </div>
               ):( null )}
               
               {(step === 'processing') ? (
                  <div style={{paddingTop:50, paddingBottom:50, maxWidth:400, margin:'0 auto'}}>
                    Processing your request...
                    <LinearProgress color='inherit' />
                  </div>  
               ):( null )}
    
               {(step === 'error') ? (
                  <div style={{paddingTop:50, paddingBottom:50, maxWidth:400, margin:'0 auto'}}>
                    <Alert severity='error'>
                        <AlertTitle>Error</AlertTitle>
                        {message || 'Sorry, an error occured, please try again.'}
                     </Alert>
                     <div style={{ marginTop:20 }}><Button onClick={() => { handleTryAgain() }} variant='outlined' color='secondary'>try again</Button></div>
                  </div>
               ):( null )}

               {(step === 'confirm') ? (
                  <div style={{paddingTop:50, paddingBottom:50, maxWidth:400, margin:'0 auto'}}>
                     <Text gutterBottom>
                       1 <b>{selectedTicket.name}</b> ticket with the name <b>{nameOnTicket}</b>, was added to your order.
                     </Text> 
                     <div style={{width:'50%', margin:'0px auto', marginTop:30}}>
                        <Button fullWidth onClick={() => setOpenDialog(false)} variant='contained' color='secondary'>ok</Button> 
                     </div>
                  </div>  
               ):( null )}

            </DialogContent>
            <DialogActions>
               <Button onClick={() => setOpenDialog(false)} color='secondary'>
                  Close
               </Button>
            </DialogActions>
         </Dialog>

      </div>
   ) 
}
export default TicketsPublic
