import { combineReducers } from 'redux'
import { AUTH_LOGOUT_USER } from './types'
import appReducer from './app/reducer'
import authReducer from './auth/reducer'
import memberAccountReducer from './memberAccount/reducer'
import memberProfileReducer from './memberProfile/reducer'
import memberStuffReducer from './memberStuff/reducer'
import memberSupportReducer from './memberSupport/reducer'
import publicEventsReducer from './publicEvents/reducer'
import publicStoreReducer from './publicStore/reducer'


import groupAccountsReducer from './groupAccounts/reducer'
import groupCommunicateReducer from './groupCommunicate/reducer'
import groupDashboardReducer from './groupDashboard/reducer'
import groupEventReducer from './groupEvent/reducer'
import groupEventsReducer from './groupEvents/reducer'
import groupMembersReducer from './groupMembers/reducer'
import groupSettingsReducer from './groupSettings/reducer'
import groupSupportReducer from './groupSupport/reducer'

const allReducers = combineReducers({
	app: appReducer,
	auth: authReducer,
  memberAccount:memberAccountReducer,
  memberProfile:memberProfileReducer,
  memberStuff:memberStuffReducer,
	memberSupport:memberSupportReducer,
	publicEvents:publicEventsReducer,
	publicStore:publicStoreReducer,

	groupAccounts:groupAccountsReducer,
	groupCommunicate:groupCommunicateReducer,
	groupDashboard:groupDashboardReducer,
	groupEvent:groupEventReducer,
	groupEvents:groupEventsReducer,
	groupMembers:groupMembersReducer,
	groupSettings:groupSettingsReducer,
	groupSupport:groupSupportReducer
})

const rootReducer = (state, action) => {
	if (action.type === AUTH_LOGOUT_USER) {
		// set the reducers you want to delete, to undefined, when user logs out
    // NOTE: do not just use (state = undefined) as this will also delete the public reducers
    state.memberAccount = undefined
    state.memberProfile = undefined
    state.memberStuff = undefined
    state.memberSupport = undefined
	}
	return allReducers(state, action)
}
export default rootReducer
