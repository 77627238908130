import { PUBLIC_STORE_UPDATE_ORDER, PUBLIC_STORE_RESET } from '../types'

const initialState = {
   order: null,  // order details for current order
   orderedItems: [] // order items in current order
}

export default function reducer(state = initialState, action) {
   switch (action.type) {
      case PUBLIC_STORE_UPDATE_ORDER:
         return { 
            ...state, 
            order: action.payload.order,
            orderedItems: action.payload.orderedItems
         }   
      case PUBLIC_STORE_RESET:
         return { 
            ...initialState           
         } 
	   default:
	      return state
   }
}
