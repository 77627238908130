import { APP_LAST_UPDATED, PUBLIC_STORE_UPDATE_ORDER, PUBLIC_STORE_RESET, MEMBER_STUFF_INVOICES_UPDATE } from '../types'
import { chkExpiredData } from '../app/actions'
import { getFunctions, httpsCallable } from 'firebase/functions'

export function loadOrder(force=false) {
   return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {   
      // if not logged in, clear order and resolve
      if (!getstate().auth.loggedIn) {
         dispatch({ type: PUBLIC_STORE_RESET })
         dispatch({ type: APP_LAST_UPDATED, payload:{key:'order', value:0} })
         return resolve() 
      }
      // user logged in, so update order
      const chkIfExpired = dispatch(chkExpiredData(force, 'order'))
         chkIfExpired
         .then((currentTime) => {
         // ---------------------------------------------------------------------------    
            const functions = getFunctions(firebase)
            const loadOrder = httpsCallable(functions, 'publicFetchOrder')
            loadOrder()
            .then((returned) => {
               let payload = {
                  order: returned.data.order,
                  orderedItems: returned.data.orderedItems,
               }
               dispatch({ type: PUBLIC_STORE_UPDATE_ORDER, payload: payload })
               dispatch({ type: MEMBER_STUFF_INVOICES_UPDATE, payload: returned.data.invoices }) 
               dispatch({ type: APP_LAST_UPDATED, payload:{key:'order', value:currentTime} })
               resolve()
            })
            .catch((e) => {
               let error = {title:'Error',message:''}
               try { error = JSON.parse(e.message) } 
               catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
               reject(error)
            }) 
         // ---------------------------------------------------------------------------
         })
         .catch(() => {
         // Current data still good so do nothing.
         resolve()
      })
   })
}
export function addTicketToOrder(eventId, ticketId, ticketInfo) {
   return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) { 
      const via = 'SecureTix Directory' 
      const functions = getFunctions(firebase)
      const addTicket = httpsCallable(functions, 'publicAddTicketToOrder')
      addTicket({via, eventId, ticketId, ticketInfo})
      .then((returned) => {
         let order = {  
            order: returned.data.order,
            orderedItems: returned.data.orderedItems
         }
         dispatch({type: PUBLIC_STORE_UPDATE_ORDER, payload:order})
         resolve()
      })
      .catch((e) => {
         let error = {title:'Error',message:''}
         try { error = JSON.parse(e.message) } 
         catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
         reject(error)
      }) 
   })
}
export function addItemToOrder(force=false, eventId) {
   return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) { 
      console.log('addItemToOrder')
      resolve()
   })
}
export function deleteItemFromOrder(orderId, orderedItemId) {
   return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
      const functions = getFunctions(firebase)
      const itemDelete = httpsCallable(functions, 'publicDeleteItemFromOrder')
      itemDelete({ orderId, orderedItemId })
      .then((returned) => {
         // orderedItem not found so nothing was done.    
         if (returned.data.message && returned.data.message === 'orderNotFound') {
            resolve()
         }
         // order updated now remove orderedItem.
         let orderedItems = [...getstate().publicStore.orderedItems]
         let filteredItems = orderedItems.filter(rec => { return rec.itemId !== orderedItemId })
         let payload = { 
            order: returned.data.order,
            orderedItems: filteredItems
         }
         dispatch({type: PUBLIC_STORE_UPDATE_ORDER, payload:payload})
         resolve()
      })
      .catch((e) => {
         let error = {title:'Error',message:''}
         try { error = JSON.parse(e.message) } 
         catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
         reject(error)
      })
   })
}
export function deleteOrder() {
   return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) { 
      const functions = getFunctions(firebase)
      const deleteThisOrder = httpsCallable(functions, 'publicDeleteOrder') 
      deleteThisOrder()
      .then((returned) => {
         dispatch({type: PUBLIC_STORE_RESET})
         dispatch({ type: APP_LAST_UPDATED, payload:{key:'order', value:0} })
         resolve()
      })
      .catch((e) => {
         let error = {title:'Error',message:''}
         try { error = JSON.parse(e.message) } 
         catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
         reject(error)
      })
   })
}
export function checkOut() {
   return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) { 
      const functions = getFunctions(firebase)
      const checkOutOrder = httpsCallable(functions, 'publicCheckoutOrder')
      checkOutOrder() // returns orderId
      .then((returned) => {
         dispatch({type: PUBLIC_STORE_RESET})
         dispatch({ type: APP_LAST_UPDATED, payload:{key:'order', value:0} })
         resolve(returned.data)
      })
      .catch((e) => {
         let error = {title:'Error',message:''}
         try { error = JSON.parse(e.message) } 
         catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
         reject(error)
      })   
   })
}
