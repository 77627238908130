import { useEffect, useState } from 'react' 
import { useNavigate, useSearchParams } from 'react-router-dom'

import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Text from '@mui/material/Typography'

function AuthenticationResult() {
   const errors = {
      0:'',
      1:'Sorry, could not complete card authentication. Please try again or try another card.',
      2:'Sorry, this site only accepts Visa or MasterCard. Please try another card.',
      3:'Sorry, your card failed validation, please re-enter your card details or try another card.',
      4:'Sorry, your card is expired. Please try another card.',
      5:'Sorry, your card number is invalid. Please try again or try another card.',
      6:'Sorry, your card provider did not allow this transaction. Please try another card.',
      7:'Sorry, your card failed authentication. Please try again or use another card.',
      8:'Sorry, your card issuer has denied this transaction. Please try another card.',
      9:'Sorry, card authentication could not be completed, please try again.',
      10:'Sorry, transaction could not be authenticated, please try again or try another card.'
   }
   const [showError, setShowError] = useState(false)
   const [searchParams] = useSearchParams()
   const spiToken = searchParams.get('id') || 0
   const errorId = searchParams.get('e') || 0
   const invoiceId = searchParams.get('i') || ''
   const navigate = useNavigate()

   useEffect(() => {
      if(errorId < 1) {
      	let url = window.location.origin+'/ptranz-complete?id='+spiToken
         window.parent.location.assign(url) 
      } else {
         setShowError(true)
      }
   },[])

   const handleReturnToInvoice = () => {
   	let url = window.location.origin + '/member/stuff/invoice/' + invoiceId + '?force=1'
   	window.parent.location.assign(url)
   }

   return (
      <div>
   	{(showError) ? (
	      <div style={{paddingLeft:10, paddingTop:50, paddingBottom:50, width:'100%'}}>
	         <Stack spacing={2}>
	            <Text variant='h6' style={{color:'red'}}>{errors[errorId]}</Text>
	            <Text variant='body2'><i>Transaction has been cancelled, please return to invoice.</i></Text>
	            <div><Button variant='contained' color='secondary' onClick={handleReturnToInvoice}>Return to Invoice</Button></div>
	         </Stack>
	      </div>
      ):( <div></div> )}
      </div>
	)
}
export default AuthenticationResult		
