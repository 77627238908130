import { useEffect, useState } from 'react' 
import { useNavigate } from 'react-router-dom'
import format from 'date-fns/format'

import NextUpdate from '../../components/NextUpdate'

import Alert from '@mui/material/Alert'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import LinearProgress from '@mui/material/LinearProgress'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function Notifications({lastUpdated, notifications, loadNotifications}) {
   const [loading, setLoading] = useState(false) 
   let navigate = useNavigate()

   useEffect(() => {
      handleFetchMemberNotifications()
   },[])

   const goTo = (path) => {
      navigate(path)
   }
   const handleFetchMemberNotifications = (force) => {
      setLoading(true)
      loadNotifications(force) 
      .finally(() => {
         setLoading(false)
      })
   }

   return (
      <div>
         <Paper style={{ padding: 10, minHeight:700 }} >
            <Collapse in={loading}> 
               <Alert severity='warning' style={{marginBottom:20}}>
                  Updating Your Notifications ...
                  <LinearProgress color='inherit' />   
               </Alert> 
            </Collapse>

            {(notifications.length) ? (             
               <List style={{padding:5, maxWidth:600, margin:'0 auto'}}>
                  {notifications.map((note, key) => {
                     return (
                        <Paper key={key} style={{marginBottom:20}}>
                           <ListItem alignItems='flex-start'>
                              <ListItemAvatar>
                                 <Avatar alt={note.subject} src={note.image} style={{borderRadius:5}} />
                              </ListItemAvatar>
                              <ListItemText>
                                 {note.senderName}
                                 <Text variant='h6' style={{ lineHeight:1.1}}>{note.subject}</Text>
                                 <Text variant='body2'>{note.message}</Text>
                                 <Text variant='caption' display='block' color='textSecondary'>
                                    On: { format(new Date(note.timestamp), 'eee do MMM y - h:mm a')}
                                 </Text>
                                 {(note.actionText) ? (
                                    <div align='center' style={{padding:10}}><Button onClick={() => { goTo(note.actionLink) }} size='small' variant='contained' color='secondary'>{note.actionText}</Button></div>
                                 ) : ( null)}
                              </ListItemText>
                           </ListItem>             
                        </Paper>
                     )
                  })}
               </List>
            ) : (
               <div style={{marginTop:20}}>
                  <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
                     <Text variant='body1' color='textSecondary'>
                        No Notifications Recorded
                     </Text>
                  </div>
               </div> 
            )}                   
         </Paper>
         <NextUpdate lastUpdated={lastUpdated} updateNow={handleFetchMemberNotifications}/>
      </div>
   )
}
export default Notifications
