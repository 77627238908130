import { Fragment, useState } from 'react' 
import { useNavigate } from 'react-router-dom'
import swal from 'sweetalert2'
import { chkEmail }  from '../../../../includes/functions'
import Loader from '../../../components/Loader'

import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import IconDelete from '@mui/icons-material/Clear'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import Text from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

function AddMembersMultiple({addMembers}){
   const [loading, setLoading] = useState(false)
   const [step, setStep] = useState('new')
   const [emailString, setEmailString] = useState('')
   const [emails, setEmails] = useState('')
   const [submitButtonText, setSubmitButtonText] = useState('Add Members')
   const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)
   const [message, setMessage] = useState('')
   const [newUsers, setNewUsers] = useState('')
   let  [,setState] = useState() // used to re-render component
   let navigate = useNavigate()

   const handleChange = (value) => {
      setEmailString(value)
      setMessage('')
   }
   const handleReviewMembers = () => {
      let emails = []
      emails = emailString.split(/[\s,;\r|\n]+/) // split by(space, commer, exclamation, new line)
      emails = [...new Set(emails)] // remove duplicates
      emails = emails.filter((email) => { return chkEmail(email)  }) // check each value in array for email

      // ::::::::::::::::::::::::::::::::::::::::::::::::::
      // here we would filter out any known invalid emails
      // if invalid emails are found set message (the following emails are invalid)
      // ::::::::::::::::::::::::::::::::::::::::::::::::::
      
      if (!emails.length) {
         setMessage('Please enter email addresses seperated by a commer,')
         return false
      }
      setEmails(emails)
      setStep('review')
   }
   const handleEditEmails = () => {
      let e = emails.toString().replace(/,/g,'\n')
      setEmailString(e)
      setStep('new')
   }

   const handleDeleteEmail = (index) => {
      let allEmails = emails
      allEmails.splice(index, 1)
      setEmails(allEmails)
      if (!allEmails.length) {
         setEmailString('')
         setStep('new')
      }
      return setState({})
   }
   const handleAddMembers = () => {
      let list = emails.join()
      setSubmitButtonDisabled(true)
      setLoading(true)
      addMembers('multiple', list)
      .then((newUsers) => {
         if(newUsers === 1) {
            setNewUsers('1 NEW MEMBER ADDED')
         } else {
            setNewUsers(newUsers + ' NEW MEMBERS ADDED')
         }
         setStep('saved')
         setLoading(false)
      })
      .catch((error) => {
         setLoading(false)
         setSubmitButtonDisabled(false)
         setSubmitButtonText('Error Updating New Members')
         swal.fire({
            position: 'top-end',
            type: 'error',
            title: 'sorry there was an error: ' + error,
            showConfirmButton: false,
            timer: 2000
         })
      })
   /*   
   */
   }

   const addMoreMembers = () => {
      setSubmitButtonDisabled(false)
      setEmailString('')
      setEmails('')
      setStep('new')
   }
   const goToMembers = () => {
      let path = '/group/members/members'
      navigate(path)
   }

   return (
      <Fragment> 
      <Loader loading={loading} />
      {(step === 'new') ? ( 
         <div style={{ margin: '0 auto', width: '100%'}}>
            <Text variant='h5' gutterBottom>ADD MEMBERS</Text>
            <Grid container justify='center'>
               <Grid item xs={12} style={{ padding: 5, textAlign: 'center' }}>
                  <TextField
                     style={{ padding: 5, border: '#1c1c1c', maxWidth: 400}}
                     fullWidth
                     label='Enter email addresses'
                     multiline
                     rows='6'
                     margin='normal'
                     helperText='enter each email on a new line or seperate with a commer,'
                     value={emailString}
                     onChange={(e) => {handleChange(e.target.value)}}
                  />
               </Grid>
            </Grid>
               <div align='center' style={{ height: 25 }}>
                  <Text variant='caption' style={{ color: 'red' }}>{message}</Text>
               </div>
               <div align='right'>
                  <Button disabled={submitButtonDisabled} onClick={() => {handleReviewMembers()}} variant='contained' color='secondary'>
                     {submitButtonText}
                  </Button>
               </div>
         </div>
      ) : ( null )}

      {(step === 'review') ? (
         <div style={{ margin: '0 auto', textAlign:'center' }}>
            <Text variant='h6' gutterBottom>Review Emails</Text>        
            <List style={{maxWidth:350, margin:'50 auto'}}>
               {emails.map((email, key) => {
                  return (
                     <div key={key}>
                        <ListItem>
                           <ListItemText primary={email} />
                           <ListItemSecondaryAction>
                              <IconButton edge='end' onClick={() => {handleDeleteEmail(key)}}>
                                 <IconDelete />
                              </IconButton>
                           </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                     </div>
                  )
               })}
            </List>
            <div align='center' style={{ height: 25 }}>
               <Text variant='caption' style={{ color: 'red' }}>{message}</Text>
            </div>
            <div style={{marginTop:100}}>
               <Button color='primary' variant='outlined' onClick={() => {handleEditEmails()}} disabled={submitButtonDisabled} style={{margin:15}}>
                  edit emails    
               </Button>
               <Button color='secondary' variant='contained' onClick={() => {handleAddMembers()}} disabled={submitButtonDisabled} style={{margin:15}}>
                  add emails     
               </Button>
            </div>
         </div>
      ) : ( null )}

      {(step === 'saved') ? (
         <div style={{ margin: '0 auto', textAlign:'center' }}>
            <Text variant='h6' gutterBottom>
               {newUsers}
            </Text>           
            <Button color='primary' variant='outlined' onClick={() => {goToMembers()}} style={{margin:15}}>
               view members      
            </Button>
            <Button color='secondary' variant='contained' onClick={() => {addMoreMembers()}} style={{margin:15}}>
               add more members     
            </Button>
         </div>
      ) : ( null )}
      </Fragment> 
   )
}
export default AddMembersMultiple
