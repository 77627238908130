import { Fragment } from 'react' 
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Text from '@mui/material/Typography'

function EventsList({events}) {
   const theme = useTheme()
   const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

   if (!events.length) return null

   return (
      <div>
         {(fullScreen) ? (
            <SmallScreen events={events}/>         
         ):(
            <BigScreen events={events}/>
         )}  
      </div>
   )
}
export default EventsList

function SmallScreen({events}) {
   const navigate = useNavigate()
   return (
      Object.keys(events).map((i) => {
         let event = events[i]
         return (
            <Card key={i} style={{ maxWidth:350, margin:'20px auto', marginBottom:40 }}>
               <CardActionArea onClick={() => { navigate('/event/' + event.eventId) }}>
                  <CardMedia style={{ height:0, paddingTop: '60%' }} image={event.headerImage} title={event.name}/>
                  <CardContent style={{display: 'flex', padding:4}}>
                     <span style={{padding:'3px 10px',display:'flex', flexDirection:'column',textAlign:'center'}}>
                        <Text variant='button' style={{color:'#b0bec5', lineHeight:1}}>{format(new Date(event.dateTimeStart), 'EEE').toUpperCase()}</Text>
                        <Text variant='h6' style={{color:'#b0bec5', lineHeight:1.5}}><b>{format(new Date(event.dateTimeStart), 'do').toUpperCase()}</b></Text>
                        <Text variant='button' style={{color:'#b0bec5', lineHeight:1}}>{format(new Date(event.dateTimeStart), 'MMM').toUpperCase()}</Text>
                     </span>
                     <span style={{padding:0, margin:0, width:'100%'}}>                       
                        <Text gutterBottom variant='h5' component='h2' style={{paddingTop:0,marginTop:0,marginBottom: -5}}>
                           {event.name}
                        </Text>
                        <Text gutterBottom variant='caption' component='i'>
                           {event.tagline}
                        </Text>
                        <Text variant='body2' color='textSecondary' component='p'>
                           {event.summary}
                        </Text>
                        <div style={{padding:'0px 10px', textAlign:'right'}}> 
                           <Text variant='overline' display='block'>View Details / Get Tickets</Text>
                        </div>
                     </span>                     
                  </CardContent>
               </CardActionArea>
            </Card>
         )
      })
   )
}

function BigScreen({events}) {
   const navigate = useNavigate()
   return (
      <List style={{ width:'100%' }}>
         {Object.keys(events).map((i) => {
            let event = events[i]
            return (
               <Fragment key={i}>
                  <ListItem style={{padding:20}} button onClick={() => {navigate('/event/' + event.eventId) }}>
                     <ListItemAvatar>
                        <img src={event.profileImage} alt={event.name} style={{ width:150, height:150, borderRadius:2}} />
                     </ListItemAvatar>
                     <div style={{padding: 0, display:'flex'}}>
                          <span style={{padding:'0px 20px',display:'flex', flexDirection:'column',textAlign:'center'}}>
                           <Text variant='button' style={{color:'#b0bec5', lineHeight:1}}>{format(new Date(event.dateTimeStart), 'EEE').toUpperCase()}</Text>
                           <Text variant='h6' style={{color:'#b0bec5', lineHeight:1.5}}><b>{format(new Date(event.dateTimeStart), 'do').toUpperCase()}</b></Text>
                           <Text variant='button' style={{color:'#b0bec5', lineHeight:1}}>{format(new Date(event.dateTimeStart), 'MMM').toUpperCase()}</Text>
                        </span>
                        <span style={{display:'flex', flexDirection: 'column',}}>
                           <Text gutterBottom variant='h5' component='h2' style={{marginBottom: -5}}>{event.name}</Text>
                           <Text gutterBottom variant='caption' component='i'>{event.tagline}</Text>
                           <Text variant='body2' color='textSecondary' component='p' style={{lineHeight:1.25, maxWidth:500}}>
                              {event.summary}
                           </Text>
                           <div style={{marginTop:20}}>
                              <Button variant='outlined' color='primary' style={{padding:'0px 20px', borderRadius:1, margin:5}}>   View Details   </Button>
                              <Button variant='outlined' color='primary' style={{padding:'0px 20px', borderRadius:1, margin:5, backgroundColor:'#eceff1'}}>Get Tickets</Button>
                           </div>                          
                        </span>
                     </div>
                  </ListItem>
                  <Divider variant='middle' component='li' style={{backgroundColor:'#cfd8dc', border: '1px solid #cfd8dc'}} />
               </Fragment>
            )
         })}
      </List>
   )
}
