import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function SecurityPolicy() {
  return (
    <div>
      <Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} >
               <Paper style={{ padding: 10, minHeight:500 }} >
                  <div style={{marginTop:50, marginBottom:100}}>
                     <Text variant='h4' gutterBottom color='textSecondary'>
                        Security Policy
                     </Text> 
                     <Text variant='h6'>
                        Credit Card Details
                     </Text> 
                     <Text variant='body1' gutterBottom>
                        Online Solutions Limited (SecureTix.com) does not store or retain your credit card details in a database or any file system.  Your Credit Card information is processed by the Credit Card Payment Gateway Provider, First Atlantic Commerce, which implements the strictest Payment Card Industry Data Security Standard (PCI DSS) standards.
                     </Text> 
                     <Text variant='h6'>
                        Data Encryption
                     </Text> 
                     <Text variant='body1' gutterBottom>
                        We encrypt certain sensitive information (such as credit card information) using Secure Sockets Layer (SSL) technology to ensure that your Personally Identifiable Information is safe as it is transmitted.
                     </Text> 
                  </div>  
               </Paper>
            </Grid>
         </Grid>
      </Container>
    </div>
  );
}
export default SecurityPolicy