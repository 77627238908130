import { Fragment, useState } from 'react' 
import { format, isValid, isAfter } from 'date-fns'
import Swal from 'sweetalert2'

import Loader from '../../components/Loader'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useTheme } from '@mui/material/styles'

import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import IconClose from '@mui/icons-material/Close'
import IconExpandLess from '@mui/icons-material/ExpandLess'
import IconExpandMore from '@mui/icons-material/ExpandMore'
import IconImage from '@mui/icons-material/CropOriginal'
import InputAdornment from '@mui/material/InputAdornment'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Text from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Toolbar from '@mui/material/Toolbar'
import useMediaQuery from '@mui/material/useMediaQuery'

function EventTickets({eventId, event, ticketTypeAddEdit, ticketTypeCopyDelete}){
   const initialState = {
      ticketId: null,
      position: 1,
      name: '',
      description: '',
      amountAvailable: 10,
      price: 0,
      dateTimeStart: new Date(),
      dateTimeEnd: new Date(event.dateTimeEnd),
      availability: 'both',
      registrationRequired: false
   }
   const availability = {
      both: 'Online & Box Office',
      online:'Online Only',
      boxOffice:'Box Office Only',
      hidden:'Through Invoices Only'
   }
   const theme = useTheme()
   const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
   const [loading, setLoading] = useState(false) 
   const [showTickets, setShowTickets] = useState(true)
   const [openDialog, setOpenDialog] = useState(false)
   const [message, setMessage] = useState('')
   const [disableButtons, setDisableButtons] = useState(false)
   const [expandedTicketDetails, setExpandedTicketDetails] = useState(false)
   const [values, setValues] = useState(initialState)

   const handleChange = (name, value) => {
      setValues({ ...values, [name]: value })
      setMessage('')
   }

   const handleCancelUpdate = () => {
      setMessage('')
      setValues(initialState)
      setOpenDialog(false)
   }

   const handleOpenTicket = (t) => {
      let ticket = {
         ticketId: t.ticketId,
         position: t.position,
         name: t.name,
         description: t.description,
         amountAvailable: t.amountAvailable,
         price: t.price,
         dateTimeStart: new Date(t.dateTimeStart),
         dateTimeEnd: new Date(t.dateTimeEnd),
         availability: t.availability,
         registrationRequired: t.registrationRequired
      }
      setValues(ticket)
      setOpenDialog(true)
   }

   const handleSaveTicket = () => {
      if (!values.name.length) {
         return setMessage('Ticket name is required.') 
      }
      if (!values.amountAvailable || isNaN(values.amountAvailable) || values.amountAvailable < 1) {
         return setMessage('You must make at least 1 ticket available.')   
      }
      if (values.price === '' || isNaN(values.price) || values.price > 2000) {
         return setMessage('Your ticket price cannot exceed $2,000.')   
      }
      if (!isValid(new Date(values.dateTimeStart))) {
         return setMessage('Ticket sales start date not valid.')  
      }
      // ticket sales cannot start after event
      if (isAfter(new Date(values.dateTimeStart), new Date(event.dateTimeStart))) {
         return setMessage('Ticket sales cannot start after event start time.')  
      }
      if (!isValid(new Date(values.dateTimeEnd))) {
         return setMessage('Ticket sales end date not valid.')  
      }
      // ticket sales cannot start after event
      if (isAfter(new Date(values.dateTimeEnd), new Date(event.dateTimeEnd))) {
         return setMessage('Ticket sales must end before event ends.')  
      }
      setLoading(true)
      ticketTypeAddEdit(eventId, values)
      .then(() => {
         setLoading(false)
         Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Ticket Created!',
            text: 'Your ticket was created successfully.',
            showConfirmButton: true
         })
      })
      .catch((e) => {
         setLoading(false)
         handleCancelUpdate()
         Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: e.title,
            text: e.message,
            showConfirmButton: true
         })
      })

      setValues(initialState)
      setOpenDialog(false)
   }

   const handleCopyDeleteTicket = (action, ticketId) => {
      let title = (action === 'Copy') ? 'Copy this Ticket?' : 'Delete this Ticket?'
      let text = (action === 'Copy') ? 'You can edit its details after.' : 'You won\'t be able to revert this!'    
      let icon = (action === 'Copy') ? 'info' : 'warning'
      let buttonText = (action === 'Copy') ? 'Copy Ticket' : 'Delete Ticket'

      Swal.fire({
         title: title,
         text: text,
         icon: icon,
         showCancelButton: true,
         confirmButtonText: buttonText
      }).then((result) => {
         if (result.value) {
            setLoading(true)
            ticketTypeCopyDelete(action,ticketId)
            .then(() => {
               setLoading(false)
               Swal.fire({
                  icon: 'success',
                  title: 'Complete!',
                  text: 'Action completed successfully.',
                  showConfirmButton: true
               })
               setExpandedTicketDetails(false)
            })
            .catch((e) => {
               setLoading(false)
               Swal.fire({
                  icon: 'error',
                  title: e.title,
                  text: e.message,
                  showConfirmButton: true
               })
            })
         }
      })
   }

   return (
      <div style={{marginTop:20}}>
         <Loader loading={loading}/>
         <List sx={{ padding:0, width: '100%', borderBottom:'1px solid gray' }}>
             <ListItem 
                alignItems='flex-start' 
                style={{padding:0}}
                secondaryAction={
                   <IconButton edge='end' onClick={() => { setShowTickets(!showTickets) }}>
                      {showTickets ? <IconExpandLess /> : <IconExpandMore />} 
                   </IconButton>
                }
             >
             <ListItemText primary={ <Button style={{textTransform:'none'}} onClick={() => { setShowTickets(!showTickets) }}><Text variant='h5' color='text.secondary'>Tickets</Text></Button>} />
            </ListItem>
         </List> 
         <Collapse in={showTickets}> 
            <Paper style={{padding:5}}>
               <div  style={{maxWidth:700, margin:'0 auto'}} >
                  {(event.tickets && Object.keys(event.tickets).length) ? (
                     <List component='nav' style={{padding:2}}>
                        {Object.keys(event.tickets).map(i => {
                           let ticket = event.tickets[i]
                           return (
                              <div key={i}>
                                 <ListItem button 
                                    style={{marginTop:2, padding:10, flexGrow: 1, backgroundColor:'#f1f1f1'}}
                                    onClick={() => { (expandedTicketDetails === ticket.ticketId) ? setExpandedTicketDetails(false) : setExpandedTicketDetails(ticket.ticketId)} }
                                 >
                                    <ListItemText 
                                       primary={ticket.name} 
                                       secondary={
                                          <Fragment>
                                             <span style={{display:'block'}}>{ticket.description}</span>
                                             <span>{ticket.amountAvailable + ' @ $' + (ticket.price)}</span>                            
                                          </Fragment>
                                       }
                                    />   
                                    {(expandedTicketDetails === ticket.ticketId) ? <IconExpandLess /> : <IconExpandMore/>}
                                 </ListItem>
                                 <Collapse in={expandedTicketDetails === ticket.ticketId} style={{padding:5, width: '100%', backgroundColor:'#f1f1f1', marginBottom:12 }}>
                                    <Grid container justifyContent='center' direction='row' alignItems='center' spacing={0}>
                                       <Grid item xs={4} style={{textAlign:'right'}} >
                                          <Text variant='body1'>Sale Starts:&nbsp;</Text>
                                       </Grid>
                                       <Grid item xs={8}>                     
                                          <Text variant='body1' color='text.secondary'> { format(new Date(ticket.dateTimeStart), 'eee do MMM y - h:mm a')}</Text>                  
                                       </Grid>
                                       <Grid item xs={4} style={{textAlign:'right'}} >
                                          <Text variant='body1'>Sale Ends:&nbsp;</Text>
                                       </Grid>
                                       <Grid item xs={8}>                     
                                          <Text variant='body1' color='text.secondary'> { format(new Date(ticket.dateTimeEnd), 'eee do MMM y - h:mm a')}</Text>                  
                                       </Grid>
                                       <Grid item xs={4} style={{textAlign:'right'}} >
                                          <Text variant='body1'>Total Tickets:&nbsp;</Text>
                                       </Grid>
                                       <Grid item xs={8}>                     
                                          <Text variant='body1' color='text.secondary'> { ticket.amountAvailable }</Text>                  
                                       </Grid>
                                       <Grid item xs={4} style={{textAlign:'right'}} >
                                          <Text variant='body1'>Ticket Price:&nbsp;</Text>
                                       </Grid>
                                       <Grid item xs={8}>                     
                                          <Text variant='body1' color='text.secondary'> { '$'+ticket.price }</Text>                  
                                       </Grid>
                                       <Grid item xs={4} style={{textAlign:'right'}} >
                                          <Text variant='body1'>Available:&nbsp;</Text>
                                       </Grid>
                                       <Grid item xs={8}>                     
                                          <Text variant='body1' color='text.secondary'> { availability[ticket.availability] }</Text>                  
                                       </Grid>
                                       <Grid item xs={4} style={{textAlign:'right'}} >
                                          <Text variant='body1'>Registration:&nbsp;</Text>
                                       </Grid>
                                       <Grid item xs={8}>                     
                                          <Text variant='body1' color='text.secondary'> { (ticket.registrationRequired) ? 'YES' : 'NO' }</Text>                  
                                       </Grid>
                                       <Grid item xs={12}><Divider style={{ marginTop:5, marginBottom:5}}/></Grid>
                                    </Grid>                                     
                                    <Stack style={{padding:5}} spacing={5} direction='row' justifyContent='space-evenly' alignItems='center'>
                                       <Button onClick={() => { handleOpenTicket(ticket)}} variant='contained' size='small' color='primary'>edit</Button>
                                       <Button onClick={() => { handleCopyDeleteTicket('Copy',ticket.ticketId)}} variant='outlined' size='small' style={{color:'#999'}}>copy</Button>
                                       <Button onClick={() => { handleCopyDeleteTicket('Delete',ticket.ticketId)}} variant='outlined' size='small' style={{color:'#999'}}>delete</Button>
                                    </Stack>
                                 </Collapse>
                              </div>
                           )
                        })}
                     </List>
                  ):(
                     <div style={{ padding:20, margin:3, backgroundColor:'#f1f1f1', textAlign:'center' }}>No Tickets Listed</div>
                  )}
               </div>
               <div style={{textAlign:'right'}}><Button onClick={() => {setOpenDialog(true)}}>Add Ticket</Button></div>
            </Paper>
         </Collapse>

{/* *************************************************************************************************** */}               

         <Dialog fullWidth={true} maxWidth={'xs'} scroll={'body'} fullScreen={fullScreen} open={openDialog} onClose={handleCancelUpdate}>
            <AppBar position='relative'>
               <Toolbar variant='dense'>
                  <IconImage/>
                  <div style={{ flex: 1, marginLeft: 5 }}><Text variant='subtitle2' color='inherit'>Edit Event Tickets</Text></div>
                  <IconButton color='inherit' onClick={handleCancelUpdate}><IconClose /></IconButton>
               </Toolbar>
            </AppBar>
            <DialogContent>
               <Text variant='h5' gutterBottom>Event Tickets</Text>
               <Stack spacing={2}>
                  <Divider />
                  <TextField fullWidth 
                     size='small'
                     label='Ticket Name' 
                     value={values.name} 
                     onChange={(e) => { handleChange('name', e.target.value) }}
                  />
                  <TextField fullWidth
                     size='small'
                     label='Ticket Description'
                     value={values.description}
                     onChange={(e) => { handleChange('description', e.target.value) }}
                     multiline
                     rows='2'
                  />
                  <TextField fullWidth
                     size='small'
                     label='Amount of Tickets Available'
                     value={values.amountAvailable}
                     onChange={(e) => { handleChange('amountAvailable', e.target.value) }}
                  />
                  <TextField fullWidth
                     size='small'
                     label='Ticket Price'
                     value={values.price}
                     onChange={(e) => { handleChange('price', e.target.value) }}
                     InputProps={{
                       startAdornment: (<InputAdornment position='start'>$</InputAdornment>),
                     }}
                  />
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                     <Stack spacing={3}>
                        <DateTimePicker
                           fullWidth                        
                           id='startDateTime'
                           label='Start Date & Time'
                           minutesStep={5}
                           minDate={new Date()}
                           value={values.dateTimeStart}
                           onChange={date => { handleChange('dateTimeStart', date) }}
                           renderInput={(params) => <TextField size='small' {...params} />}
                        />
                        <DateTimePicker
                           fullWidth
                           id='endDateTime'
                           label='End Date & Time'
                           minutesStep={5}
                           minDate={values.dateTimeStart}
                           value={values.dateTimeEnd}
                           onChange={date => { handleChange('dateTimeEnd', date) }}
                           renderInput={(params) => <TextField size='small' {...params} />}
                        />
                     </Stack>
                  </LocalizationProvider>                  
                  <TextField fullWidth
                    size='small'
                    select
                    label='Availability'
                    value={values.availability}
                    onChange={(e) => { handleChange('availability', e.target.value) }}
                  >
                    <MenuItem value='both'>Online & Box Office</MenuItem>
                    <MenuItem value='online'>Online Only</MenuItem>
                    <MenuItem value='boxOffice'>Box Office Only</MenuItem>
                    <MenuItem value='hidden'>Hidden (Available Through Invoices Only)</MenuItem>
                  </TextField>
                  <FormControl component='fieldset'>
                     <FormControlLabel 
                        style={{color:(values.registrationRequired) ? 'black' : '#999'}}
                        labelPlacement='start'
                        label={(values.registrationRequired) ? 'Registration required for this ticket.' : 'Registration not required for this ticket.'}
                        control={ <Switch 
                           color='success'
                           checked={values.registrationRequired}
                           onChange={(e) => { handleChange('registrationRequired', !values.registrationRequired) }}
                        />} 
                     />
                  </FormControl>
               </Stack>   
               <div style={{ textAlign:'center', height:14}}><Text style={{color:'red'}}><i>{message}</i></Text></div>
            </DialogContent>
            <DialogActions>
               <Button onClick={handleCancelUpdate} color='primary'>Cancel</Button>
               <Button disabled={disableButtons} onClick={handleSaveTicket} variant='contained'>{(values.ticketId) ? 'Update Ticket' : 'Save Ticket'}</Button>
            </DialogActions>
         </Dialog>

      </div>
   )
}
export default EventTickets
