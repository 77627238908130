import { Fragment, useEffect, useState } from 'react' 
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { connect } from 'react-redux'
import { loadOrder, deleteOrder, deleteItemFromOrder, checkOut } from '../../../redux/publicStore/actions'
import { selectorPendingInvoices } from '../../../redux/memberStuff/selectors'

import CartCheckout from './CartCheckout'
import CartConfirm from './CartConfirm'
import CartInvoices from './CartInvoices'
import CartOrder from './CartOrder'
import LoginPopup from '../../components/LoginPopup'
import NextUpdate from '../../components/NextUpdate'
import SubNav from './SubNav'
import Timer from '../../components/Timer'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function CartContainer({loggedIn, order, orderedItems, invoices, lastUpdated, loadOrder, deleteOrder, deleteItemFromOrder, checkOut}) {
   const [step, setStep] = useState('display') // loading, error, display  
   const [loadingError, setLoadingError] = useState({})
   const [paymentDisabled, setPaymentDisabled] = useState(true)
   const [showLoginPopup, setShowLoginPopup] = useState(false)

   useEffect(() => {
      if(loggedIn) {
         handleFetchOrder()
      }
   },[loggedIn])

   const handleFetchOrder = (force) => {
      setStep('loading')
      loadOrder(force)
      .then(() => {
         setStep('display')
      })
      .catch((e) => {
         setLoadingError(e)
         setStep('error')
      })
   }
   const handleTryAgain = (force) => {
      setLoadingError({})
      handleFetchOrder()
   }
   const handleLogin = () => {
      setShowLoginPopup(true)
   }
   const handleTimerExpired = () => {
      deleteOrder()
      .then(() => {
         Swal.fire({
            position: 'top-end',
            icon: 'info',
            text: 'Order Expired',
            showConfirmButton: true
         })
      })
   }

   if (!loggedIn) {
      return (
         <Container maxWidth='lg' style={{ padding:10, marginBottom:50, minHeight:700 }}>
            <LoginPopup showLoginPopup={showLoginPopup} setShowLoginPopup={setShowLoginPopup} />
            <Grid container justifyContent='center' spacing={2}>
               <Grid item xs={12} sm={12} md={10} >
                  <SubNav/>
               </Grid>
               <Paper style={{ padding:20, margin:20, marginTop:50 }} >
                  <Text variant='h6'>
                     Log in to view your cart.
                  </Text>
                  <Text variant='body1'>
                     You must be logged in to view the contents of your cart.<br/>
                     If you would like to check on the status of an order, log in and go to <b>Invoices</b> in <b>My Stuff</b>.
                  </Text>
                  <div style={{textAlign:'center', marginTop:10, padding:5}}>
                     <Button onClick={handleLogin} variant='contained' color='secondary' style={{margin:4}}>Login / Register</Button>
                  </div>
               </Paper>
            </Grid>         
         </Container>            
      )
   }

   return ( 
      <Container maxWidth='lg' style={{ padding:10, marginBottom:50, minHeight:700 }}>
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} >
               <SubNav/>
            </Grid>
   
            {(step==='loading') ? (
               <Grid item xs={12} sm={12} md={10} >
                  <Alert severity='warning'>
                     <AlertTitle>Loading</AlertTitle>
                     Loading Cart Details...
                     <LinearProgress color='inherit' />   
                  </Alert> 
               </Grid>
            ):(null)}

            {(step === 'error') ? (   
               <Grid item xs={12} sm={12} md={10} >            
                  <Alert severity='error'>
                     <AlertTitle>{loadingError.title || 'Error'}</AlertTitle>
                     {loadingError.message || 'Sorry, an error occured, please try again.'}
                  <div style={{ marginTop:20 }}><Button onClick={handleTryAgain} variant='outlined'>try again</Button></div>
                  <div style={{ marginTop:20 }}><Button component={Link} to='/events' variant='outlined'>return to upcoming events</Button></div>
                  </Alert>
               </Grid>
            ):( null )}

            {(step === 'display' && !order) ? ( 
               <Fragment>
                  <Paper style={{ padding:20, margin:20, marginTop:50 }} >
                     <Text variant='h6'>
                        Your cart is empty.
                     </Text>
                     <Text variant='body1'>
                        If you had Tickets or Items in your cart, your order has expired.<br/>
                        If you attempted to make a payment, your order may be pending under 
                        <Button component={Link} to='/member/stuff/invoices' variant='text'>Invoices</Button>  
                         in <b>My Stuff</b>.
                     </Text>
                     <div align='center' style={{ paddingTop: 80}}>
                        <Button component={Link} to='/events' variant='contained' color='secondary'>Shop for Tickets</Button>
                     </div>
                  </Paper>
                  <NextUpdate lastUpdated={lastUpdated} updateNow={handleFetchOrder}/>
               </Fragment>
            ):( null )} 

            {(step === 'display' && order) ? ( 
               <Fragment>
                  <Grid item xs={12} sm={7} md={6}>            
                     <Timer variant='cartPage' endTime={order.dateTimeExpires} callback={handleTimerExpired}/>     
                     <CartOrder
                        loggedIn={loggedIn} 
                        order={order}
                        orderedItems={orderedItems}
                        deleteItemFromOrder={deleteItemFromOrder}
                     />
                  </Grid>
                  <Grid item xs={12} sm={5} md={4}>
                     <CartConfirm 
                        loggedIn={loggedIn} 
                        setPaymentDisabled={setPaymentDisabled}
                     />
                     <CartCheckout 
                        loggedIn={loggedIn} 
                        paymentDisabled={paymentDisabled} 
                        checkOut={checkOut}
                     />
                     <CartInvoices
                        loggedIn={loggedIn} 
                        invoices={invoices}
                     /> 
                  </Grid> 
                  <NextUpdate lastUpdated={lastUpdated} updateNow={handleFetchOrder}/>
               </Fragment>   
            ):( null )}  
         </Grid>         
      </Container>
   )
}
const mapStateToProps = (state) => ({
   lastUpdated: state.app.lastUpdated.order || 0,
   loggedIn: state.auth.loggedIn,
   order: state.publicStore.order,
   orderedItems: state.publicStore.orderedItems || [],
   invoices: selectorPendingInvoices(state.memberStuff.invoices)
})
const mapDispatchToProps = {
   loadOrder,
   deleteOrder,
   deleteItemFromOrder,
   checkOut
}
export default connect(mapStateToProps, mapDispatchToProps)(CartContainer)
