import { APP_LAST_UPDATED, MEMBER_STUFF_INVOICES_LOAD, MEMBER_STUFF_INVOICES_LOAD_DETAILS, MEMBER_STUFF_INVOICES_UPDATE_ERROR, MEMBER_STUFF_TICKETS_LOAD } from '../types'
import { chkExpiredData } from '../app/actions'
import { getFunctions, httpsCallable } from 'firebase/functions'

export function loadWallet(force=false) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {   
		setTimeout(() => resolve(), 4000)
	})
}

export function loadTickets(force=false) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {   
	   const chkIfExpired = dispatch(chkExpiredData(force, 'memberTickets'))
     	chkIfExpired
     	.then(async (currentTime) => {
     	// ---------------------------------------------------------------------------    
	      const functions = getFunctions(firebase)
         const loadMemberTickets = httpsCallable(functions, 'memberFetchTickets')
	      loadMemberTickets()
	      .then((returned) => {
  			   dispatch({ type: MEMBER_STUFF_TICKETS_LOAD, payload: returned.data })
	  			dispatch({ type: APP_LAST_UPDATED, payload:{key:'memberTickets', value:currentTime} })
		  		resolve()
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      }) 
     	// ---------------------------------------------------------------------------
     	})
     	.catch(() => {
         // Current data still good so do nothing.
         resolve()
     	})
	})
}

export function loadInvoices(force=false) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {   
	   const chkIfExpired = dispatch(chkExpiredData(force, 'memberInvoices'))
     	chkIfExpired
     	.then(async (currentTime) => {
     	// ---------------------------------------------------------------------------    
	      const functions = getFunctions(firebase)
         const loadMemberInvoices = httpsCallable(functions, 'memberFetchInvoices')
	      loadMemberInvoices()
	      .then((returned) => {
  			   dispatch({ type: MEMBER_STUFF_INVOICES_LOAD, payload: returned.data })
	  			dispatch({ type: APP_LAST_UPDATED, payload:{key:'memberInvoices', value:currentTime} })
		  		resolve()
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      }) 
     	// ---------------------------------------------------------------------------
     	})
     	.catch(() => {
         // Current data still good so do nothing.
         resolve()
     	})
	})
}

export function loadInvoiceDetails(force=false, invoiceId) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) { 
		// check if invoice was loaded before, if not, force check server
		if (!getstate().memberStuff.invoices[invoiceId]) {
			force = true
		}
		const lastUpdated = 'memberInvoices-' + invoiceId
	   const chkIfExpired = dispatch(chkExpiredData(force, lastUpdated))
     	chkIfExpired
     	.then((currentTime) => {
     	// ---------------------------------------------------------------------------    
	      const functions = getFunctions(firebase)
         const loadMemberInvoiceDetails = httpsCallable(functions, 'memberFetchInvoiceDetails')
	      loadMemberInvoiceDetails({invoiceId})
	      .then((returned) => {
            let payload = { 
            	id:invoiceId, 
            	details:returned.data
            }
				dispatch({type: MEMBER_STUFF_INVOICES_LOAD_DETAILS, payload:payload})
				dispatch({ type: APP_LAST_UPDATED, payload:{key:lastUpdated, value:currentTime} })
				resolve()
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
		   }) 
     	// ---------------------------------------------------------------------------
     	})
     	.catch(() => {
         // Current data still good so do nothing.
         resolve()
     	})
	})
}

export function wakeupPowertransFunctions() {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) { 
     /*
      let server
	   if (window.location.hostname.includes('localhost')) {
	      server = 'localhost'
	   } else if (window.location.hostname.includes('dev.')) {
	      server = 'dev'
	   } else {
	      server = 'live'
	   }
      const functions = getFunctions(firebase)
      const powertransPayment = httpsCallable(functions, 'powertransPayment')
      powertransPayment({invoiceId, currency, amount, creditCardInfo, server})
      .then((response) => {
         resolve(response.data)
      })
      .catch((e) => {
	    	let error = {title:'Error', message:''}
	      try { error = JSON.parse(e.message) } 
	      catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	     	reject(error)
		})    
   */
   })
}

export function makePowertransPayment(invoiceId, currency, amount) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) { 
      let server
	   if (window.location.hostname.includes('localhost')) {
	      server = 'localhost'
	   } else if (window.location.hostname.includes('dev.')) {
	      server = 'dev'
	   } else {
	      server = 'live'
	   }
      const functions = getFunctions(firebase)
      const powertransPayment = httpsCallable(functions, 'powertransPayment')
      powertransPayment({invoiceId, currency, amount, server})
      .then((response) => {
         resolve(response.data)
      })
      .catch((e) => {
	    	let error = {title:'Error', message:''}
	      try { error = JSON.parse(e.message) } 
	      catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	     	reject(error)
		})    
   })
}
export function processPowertransPayment(tokenId) {
   return (dispatch, getState, firebase) => new Promise(function (resolve, reject) {
      const hostname = window.location.hostname
      const mode = (hostname.includes('dev') || hostname.includes('localhost')) ? 'dev' : 'live'
      const functions = getFunctions(firebase)
      const ptranzResult = httpsCallable(functions, 'powertransResult')
      ptranzResult({tokenId, mode})
      .then((response) => {
         resolve(response.data)
      })
      .catch((e) => {
	    	let error = {title:'Error', message:''}
	      try { error = JSON.parse(e.message) } 
	      catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	     	reject(error)
		}) 
   })
}





export function makeFacPayment(invoiceId, currency) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) { 
      let returnUrl = 'https://'+window.location.hostname+'/fac'
      if (window.location.hostname === 'localhost') {
         returnUrl = 'http://localhost:3000/fac'
      }
      const functions = getFunctions(firebase)
      const facPayment = httpsCallable(functions, 'facPayment')
      facPayment({invoiceId, currency, returnUrl})
      .then((response) => {
         resolve(response.data)
      })
      .catch((e) => {
	    	let error = {title:'Error', message:''}
	      try { error = JSON.parse(e.message) } 
	      catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	     	reject(error)
		}) 
   })
}
export function processFacPayment(tokenId) {
   return (dispatch, getState, firebase) => new Promise(function (resolve, reject) {
      const hostname = window.location.hostname
      const mode = (hostname.includes('dev') || hostname.includes('localhost')) ? 'dev' : 'live'
      const functions = getFunctions(firebase)
      const facResponse = httpsCallable(functions, 'facResponse')
      facResponse({tokenId, mode})
      .then((response) => {
         resolve(response.data)
      })
      .catch((e) => {
	    	let error = {title:'Error', message:''}
	      try { error = JSON.parse(e.message) } 
	      catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	     	reject(error)
		}) 
   })
}
export function updateErrorStatus(invoiceId, paymentId) {
   return (dispatch, getState, firebase) => {
      const functions = getFunctions(firebase)
      const errorUpdate = httpsCallable(functions, 'memberUpdateInvoiceError')     
      errorUpdate({invoiceId, paymentId})
      .then((response) => {
         let payload = {
            invoiceId: invoiceId,
            paymentId: paymentId
         }
         dispatch({ type: MEMBER_STUFF_INVOICES_UPDATE_ERROR, payload: payload })
      })
      .catch((error)=>{
         console.log('error: ',error)
      })
      return null
    }
}

export function updateNameOnTicket(ticketId, name) {
   return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) { 
      const functions = getFunctions(firebase)
      const updateName = httpsCallable(functions, 'memberUpdateNameOnTicket')     
      updateName({ticketId, name})
      .then((response) => {
      	let tickets = {...getstate().memberStuff.tickets}
         tickets[ticketId].ownerName = name
         tickets[ticketId].history.push(response.data)
         dispatch({ type: MEMBER_STUFF_TICKETS_LOAD, payload: tickets })
         resolve()
      })
      .catch((e) => {
      	let error = {title:'Error', message:''}
	      try { error = JSON.parse(e.message) } 
	      catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	     	reject(error)
		}) 
   })
}

export function transferTicket(ticketId, memberId) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {   
      const functions = getFunctions(firebase)
      const transfer = httpsCallable(functions, 'memberTransferTicket')     
      transfer({ticketId, memberId})
      .then((response) => {
      	let tickets = {...getstate().memberStuff.tickets}
         tickets[ticketId].status = 'transfered'
         tickets[ticketId].history.push(response.data)
         dispatch({ type: MEMBER_STUFF_TICKETS_LOAD, payload: tickets })
         resolve()
      })
      .catch((e) => {
      	let error = {title:'Error', message:''}
	      try { error = JSON.parse(e.message) } 
	      catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	     	reject(error)
		}) 
	})
}

export function profileLookup(email) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {   
      // Get Member Email Address, and If equal to email, reject ...
      if (getstate().auth.userInfo.email === email) {
         reject({title:'Error', message:'Sorry, can not transfer to yourself.'})
      }
      // ...........................................................
      const functions = getFunctions(firebase)
      const lookup = httpsCallable(functions, 'profileLookup')     
      lookup({email})
      .then((response) => {
         resolve(response.data)
      })
      .catch((e) => {
      	let error = {title:'Error', message:''}
	      try { error = JSON.parse(e.message) } 
	      catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	     	reject(error)
		}) 
	})
}
