import { useEffect, useState } from 'react' 
import Swal from 'sweetalert2'

import Loader from '../../components/Loader'

import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import IconClose from '@mui/icons-material/Close'
import IconExpandLess from '@mui/icons-material/ExpandLess'
import IconExpandMore from '@mui/icons-material/ExpandMore'
import IconImage from '@mui/icons-material/CropOriginal'
import InputAdornment from '@mui/material/InputAdornment'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Text from '@mui/material/Typography'

import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'


function EventStatus({eventId, event, editEventStatus}){
   const [loading, setLoading] = useState(false) 
   const [showStatus, setShowStatus] = useState(true)
   const [published, setPublished] = useState(event.published)
   const [listedOnGroupPage, setListedOnGroupPage] = useState(event.listedOnGroupPage)
   const [listedOnSecureTix, setListedOnSecureTix] = useState(event.listedOnSecureTix)

   useEffect(() => {
      setPublished(event.published)
      setListedOnGroupPage(event.listedOnGroupPage)
      setListedOnSecureTix(event.listedOnSecureTix)
   },[event])

   const handleChangeStatus = (option, status) => {
      setLoading(true)
      editEventStatus(eventId, option, status)
      .then(() => {
         setLoading(false)
         Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Status Updated!',
            text: 'Your event status was updated successfully.',
            showConfirmButton: true
         })
      })
      .catch((e) => {
         setLoading(false)
         Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: e.title,
            text: e.message,
            showConfirmButton: true
         })
      })
   }

   return (
      <div style={{marginTop:20}}>
         <Loader loading={loading}/>
         <List sx={{ padding:0, width: '100%', borderBottom:'1px solid gray' }}>
             <ListItem 
                alignItems='flex-start' 
                style={{padding:0}}
                secondaryAction={
                   <IconButton edge='end' onClick={() => { setShowStatus(!showStatus) }}>
                      {showStatus ? <IconExpandLess /> : <IconExpandMore />} 
                   </IconButton>
                }
             >
             <ListItemText primary={ <Button style={{textTransform:'none'}} onClick={() => { setShowStatus(!showStatus) }}><Text variant='h5' color='text.secondary'>Status</Text></Button>} />
            </ListItem>
         </List> 
         <Collapse in={showStatus}> 
            <Paper style={{padding:10}}>
               <div  style={{maxWidth:600, margin:'0 auto'}} >
                  {(published) ? (
                     <div>
                        <Text variant='h6' style={{color:'green'}}>PUBLISHED</Text>
                        <Text>This event is currently published, which means it can be viewed via the event link or the selected options below.</Text>
                     </div>
                  ):(
                     <div>
                        <Text variant='h6' style={{color:'red'}}>NOT PUBLISHED</Text>
                        <Text>This event is currently in draft mode, which means it can not be viewed by the public anywhere.</Text>
                     </div>
                  )}
                  <Divider style={{marginTop:10}}/>
               </div>
               <div  style={{maxWidth:300, margin:'0 auto'}} >
                  <FormGroup>
                     <FormControlLabel 
                        //style={{ margin:'0 auto' }} 
                        labelPlacement='start'
                        label='Publish this Event' 
                        control={<Switch color='success' checked={published} onChange={() => { handleChangeStatus('published', !published) }}/>} 
                     />
                     <Divider/>
                     <FormControlLabel 
                        //style={{ margin:'0 auto' }} 
                        labelPlacement='start'
                        label='List on Group Page' 
                        control={<Switch color='success' checked={listedOnGroupPage} onChange={() => { handleChangeStatus('listedOnGroupPage', !listedOnGroupPage) }}/>} 
                     />
                     <Divider/>
                     <FormControlLabel 
                        //style={{ margin:'0 auto' }} 
                        labelPlacement='start'
                        label='List on SecureTix' 
                        control={<Switch color='success' checked={listedOnSecureTix} onChange={() => { handleChangeStatus('listedOnSecureTix', !listedOnSecureTix) }}/>} 
                     />
                     <Divider/>
                  </FormGroup>
               </div>
            </Paper>
         </Collapse>

      </div>
   )
}
export default EventStatus
