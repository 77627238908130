import { useEffect, useState } from 'react' 
import Swal from 'sweetalert2'

import { money } from '../../../../includes/functions'
import Loader from '../../../components/Loader'
import FacPaymentWindow from '../../../components/Payments/FacPaymentWindow/index'

import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import IconCreditCard from '@mui/icons-material/CreditCard'
import IconExpandMore from '@mui/icons-material/ExpandMore'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Text from '@mui/material/Typography'

function Payment({invoice, makeFacPayment}) {
   const [expanded, setExpanded] = useState('panel1')
   const [loading, setLoading] = useState(false)
   const [facFee, setFacFee] = useState(0)
   const [facDue, setFacDue] = useState(0)
   const currency = window.localStorage.getItem('appCurrency') || 'USD'
   const [showFacPaymentWindow, setShowFacPaymentWindow] = useState(false)

   useEffect(() => {
      if (invoice && Object.keys(invoice).length) {
         let facOrderTotal = invoice.orderTotal
         let facFeeFlat = 3
         let facFeePercent = (facOrderTotal + facFeeFlat) * 0.035
         let facFeeTotal = Number((facFeeFlat + facFeePercent).toFixed(2))
         let facDue = Number((facOrderTotal + facFeeTotal).toFixed(2)) 
         setFacFee(facFeeTotal)
         setFacDue(facDue)
      }
   },[invoice])

   const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false)
   }

   const payNow = (method) => {
      setShowFacPaymentWindow(true)
   }
   
   const payNowXXX = (method) => {
      setLoading(true)
      makeFacPayment(invoice.orderId, currency)  
      .then(paymentUrl => {
         setLoading(false)
         window.location.assign(paymentUrl)
      })       
      .catch(e => {
         setLoading(false)
         Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: e.title,
            text: e.message,
            showConfirmButton: true,
            confirmButtonText: 'Close'              
         })                    
      })  
   }
   if(invoice && invoice.orderStatus === 'paid') return null
   return ( 
      <>
      {(showFacPaymentWindow) ? (
         <FacPaymentWindow 
            showFacPaymentWindow={showFacPaymentWindow}
            setShowFacPaymentWindow={setShowFacPaymentWindow}
            invoiceId={invoice.orderId}
            currency={currency}
            amount={facDue}
         />
      ):( null )}
      <Card elevation={0} style={{border: '1px solid #cfd8dc', borderRadius:0, marginBottom:20}}>
         <Loader loading={loading} title='Processing' text='Preparing invoice for payment, please wait...' />
         <CardHeader title='Make Payment' titleTypographyProps={{variant: 'h6'}} style={{padding:'10px 15px 10px 15px'}}/>
         <Divider variant='middle' style={{backgroundColor: '#cfd8dc'}} />
         <CardContent style={{padding: 0}}>              
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
               <AccordionSummary style={{ height: 75 }} expandIcon={<IconExpandMore />}>
                  <List>
                     <ListItem>
                        <ListItemAvatar>
                           <Avatar style={{backgroundColor:'#4caf50', color:'#fff'}}>
                              <IconCreditCard />
                           </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary='Credit Card Payment' secondary={'Fee: ' + money(3) + ' + 3.5%'} />
                     </ListItem>
                  </List>
              </AccordionSummary>
              <AccordionDetails>
                  <Grid container justify='center' spacing={2} style={{padding:10}}>
                     <Grid item xs={6} sm={9} md={8} style={{textAlign:'right'}}>
                       <i>Order Total:</i>
                     </Grid>
                     <Grid item xs={6} sm={3} md={4} style={{textAlign:'right'}}>
                       <i>{money(invoice.orderTotal)}</i>
                     </Grid>
                     <Grid item xs={6} sm={9} md={8} style={{textAlign:'right', paddingTop:0}}>
                       <i>Processing Fee:</i>
                     </Grid>
                     <Grid item xs={6} sm={3} md={4} style={{textAlign:'right', paddingTop: 0}}>
                       <i>{money(facFee)}</i>
                     </Grid>
                     <Grid item xs={6} sm={9} md={8} style={{textAlign:'right', paddingTop:0}}>
                       <b>Total Due:</b>
                     </Grid>
                     <Grid item xs={6} sm={3} md={4} style={{textAlign:'right', paddingTop: 0}}>
                       <b>{money(facDue)}</b>
                     </Grid>
                     <Grid item xs={12} sm={12} md={12} style={{textAlign:'right', paddingTop: 5}}>
                         <Button style={{backgroundColor:'#4caf50', color:'#fff'}} fullWidth variant='contained' color='primary' onClick={() => {payNow('fac')}}>Pay Now</Button>
                     </Grid>
                  </Grid>
              </AccordionDetails>
            </Accordion>
         </CardContent>
      </Card>
      </>
   )
}
export default Payment
