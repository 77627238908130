import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function Payouts(){
   return (
      <Paper style={{ padding: 10, minHeight:700 }} >
         <Text variant='h5' color='textSecondary'>Payout History</Text>
         <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
            <Text variant='body1' color='textSecondary'>
               Last 5 Payouts - button to Payout History Dialogue.
               (withdrawls to bank, credit Transfers, subscription Payments)
            </Text>
         </div>

         <Text variant='h5' color='textSecondary'>Payout Requests</Text>
         <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
            <Text variant='body1' color='textSecondary'>
               Pending Payout Requests - button to Payout Request Dialogue.
            </Text>
         </div>

         <Text variant='h5' color='textSecondary'>Payout Methods</Text>
         <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
            <Text variant='body1' color='textSecondary'>
               Listed Payout Methods (ACH) - button to Add Payout Methods (Dialogue).
            </Text>
         </div>

          <Text variant='h5' color='textSecondary'>Credit Card </Text>
         <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
            <Text variant='body1' color='textSecondary'>
               Credit Card on file for subscription and invoice payments
            </Text>
         </div>

         <Text variant='h5' color='textSecondary'>Transfer Credits</Text>
         <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
            <Text variant='body1' color='textSecondary'>
               Transfer Credits to other members.
            </Text>
         </div>

      </Paper>
   )
}
export default Payouts
