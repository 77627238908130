import { useState } from 'react' 
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'

import Loader from '../../components/Loader'
import { money } from '../../../includes/functions'

import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import IconDelete from '@mui/icons-material/DeleteForeverOutlined'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import Stack from '@mui/material/Stack'
import Text from '@mui/material/Typography'

function CartOrder({loggedIn, order, orderedItems, deleteItemFromOrder}) {
   const [loading, setLoading] = useState(false)

   const deleteItem = (orderId, orderedItemId) => {
      setLoading(true)
      deleteItemFromOrder(orderId, orderedItemId)
      .then(() => {
         setLoading(false)
      })
      .catch((e) => {
         setLoading(false)            
         Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: e.title, 
            text: e.message,
            showConfirmButton: true
         })
      })
   }
   if (!loggedIn) { return null }
   return ( 
      <Card elevation={0} style={{border: '1px solid #cfd8dc', borderRadius:0}}>
         <Loader loading={loading} title='Updating Order' text='Removing item from order...'/> 
         <CardHeader title='Current Order' titleTypographyProps={{variant: 'h6'}} style={{padding:'10px 15px 10px 15px'}}/>
         <Divider variant='middle' style={{backgroundColor: '#cfd8dc'}} />
         <CardContent style={{paddingTop: 10, paddingBottom:15}}>
            <div style={{backgroundColor:'#cfd8dc', padding:4, color:'#fff', borderRadius:2}}>Event eTickets</div>
            <List>
               { orderedItems.map((i) => {
                  return (
                     <div key={i.itemId}>
                        <ListItem style={{paddingRight:100}}>
                           <ListItemAvatar>
                              <Avatar alt={i.eventName} src={i.itemImage} style={{width:60, height:60, borderRadius:2}} />
                           </ListItemAvatar>
                  
                           <Grid container justifyContent="flex-start" alignItems="center" spacing={0.5} style={{margin:5}}>
                              <Grid item xs={12} sm={12} md={8} lg={6}>
                                 <Text variant='body1'><b>{i.eventName}</b></Text>
                                 <Text variant='body2' color='textSecondary' style={{lineHeight:1.25}}><i>{i.name}</i></Text>
                              </Grid>
                              <Grid item xs={12} sm={12} md={8} lg={6}>
                                 <Text variant='body2' style={{lineHeight:.8}}>{money(i.itemPrice)}</Text>
                                 <Text variant='caption' color='textSecondary' style={{lineHeight:.7}}><i>Fee: {money(i.itemFee)}</i></Text>
                              </Grid>
                           </Grid> 

                           <ListItemSecondaryAction>
                              <Stack direction='column' spacing={0.5} alignItems='center'>
                              <Text variant='caption'><b>{money(i.itemTotal)}</b></Text>
                              <IconButton aria-label='Delete' onClick={() => {deleteItem(i.orderId, i.itemId)}}>
                                 <IconDelete style={{ fontSize: 20 }}  fontSize='inherit' />
                              </IconButton>
                              </Stack>
                           </ListItemSecondaryAction>
                        </ListItem>
                        <div>
                           <Text component='span' variant='caption' color='textSecondary'><i>(This ticket is for {i.ownerName})</i></Text>
                           <div style={{float:'right'}} align='right'>
                              <Button component={Link} to={'/event/'+i.eventId} style={{fontSize:10, padding:3, color:'#9c27b0'}}>return to this event</Button>
                           </div>
                        </div>
                        <Divider component='li' variant='middle' style={{backgroundColor: '#cfd8dc'}} />
                     </div>
                  )
               })}
            </List>
            <Grid container justify='center' spacing={2} style={{padding:10}}>
               <Grid item xs={7} sm={9} md={8} style={{textAlign:'right'}}>
                 <Text variant='body1'><i>Cart Total:</i></Text>
               </Grid>
               <Grid item xs={5} sm={3} md={4} style={{textAlign:'right'}}>
                 <Text variant='body1'><i>{money(order.orderAmount)}</i></Text>
               </Grid>
               <Grid item xs={7} sm={9} md={8} style={{textAlign:'right', paddingTop:0}}>
                 <Text variant='body1'><i>Service Fee:</i></Text>
               </Grid>
               <Grid item xs={5} sm={3} md={4} style={{textAlign:'right', paddingTop: 0}}>
                 <Text variant='body1'><i>{money(order.orderFee)}</i></Text>
               </Grid>
               <Grid item xs={7} sm={9} md={8} style={{textAlign:'right', paddingTop:0}}>
                 <Text variant='body1'><b>Order Total:</b></Text>
               </Grid>
               <Grid item xs={5} sm={3} md={4} style={{textAlign:'right', paddingTop: 0}}>
                 <Text variant='body1'><b>{money(order.orderTotal)}</b></Text>
               </Grid>
            </Grid> 
         </CardContent>         
      </Card>            
   )
}
export default CartOrder
