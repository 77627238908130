import { forwardRef, useState } from 'react'
import { format } from 'date-fns'
import { chkEmail }  from '../../../../includes/functions'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Slide from '@mui/material/Slide'
import Stack from '@mui/material/Stack'
import Text from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

function TransferTicket({selectedItem, open, handleClose, transferTicket, profileLookup}){
   const [step, setStep] = useState('lookup') // lookup, processing, profile, confirmation, complete, error
   const [emailAddress, setEmailAddress] = useState('')
   const [profile, setProfile] = useState({})
   const [message, setMessage] = useState('')
   const [processingMessage, setProcessingMessage] = useState('')

   const handleLookup = () => {
      setMessage('')
      if(!chkEmail(emailAddress)) {
         return setMessage('Sorry, please enter a valid email address.')
      }
      setProcessingMessage('Looking Up Member Profile...')
      setStep('processing') 
      profileLookup(emailAddress)
      .then((profile) => {
         setProfile(profile)
         setStep('profile')
      })
      .catch((e) => {
         setMessage(e.message)
         setStep('error')
      }) 
   }
   const handleTransferTicket = () => {
      setProcessingMessage('Transfering Ticket...')
      setStep('processing') 
      transferTicket(selectedItem.code, profile.id)
      .then(() => {
         setStep('complete')
      })
      .catch((e) => {
         setMessage(e.message)
         setStep('error')
      }) 
   }
   const handleTryAgain = () => {
      setMessage('')
      setStep('lookup')    
   }

   return (
      <Dialog open={open} onClose={() => { handleClose(false) }} fullWidth={true} maxWidth='xs' TransitionComponent={Transition} keepMounted>
         <DialogTitle >SecureTix | Transfer Ticket</DialogTitle>
         <DialogContent>
            <div  style={{ textAlign: 'center'}}>
            <Text color='textSecondary' gutterBottom>               
               <b>{selectedItem.ticketName}</b><br />
               {selectedItem.code}<br />
            </Text> 
            </div>
            <Divider style={{marginBottom:15}}/>
            
            <Text color='textSecondary' gutterBottom>               
               <b>Note: </b>
               This can not be reversed. Once a ticket is transfered it will no longer belong to you. Please ensure that you are transfering to the correct person.<br />
            </Text> 
            <Divider/>
            
            {(step === 'lookup') ? (
               <div style={{paddingTop:20, paddingBottom:50, maxWidth:400, margin:'0 auto'}}>
                  <Text variant='h6' color='text.secondary'>Member Lookup</Text>
                  <Text variant='body1' color='text.secondary'>
                     Enter the email address of the SecureTix Member you would like to transfer this ticket to.
                  </Text> 
                  <Divider style={{marginTop:5, marginBottom:10}}/>
                  <TextField value={emailAddress} onChange={(e)=>{setEmailAddress(e.target.value)}} fullWidth size='small' id='name'/>
                  <div style={{ textAlign:'center', height:30}}><Text style={{color:'red'}}><i>{message}</i></Text></div>
                  <div style={{ textAlign:'right'}}>
                     <Button onClick={() => { handleLookup() }} variant='contained' color='secondary'>
                        lookup
                     </Button>
                  </div>    
               </div>  
            ):( null )}

            {(step === 'processing') ? (
               <div style={{paddingTop:50, paddingBottom:50, maxWidth:400, margin:'0 auto'}}>
                  {processingMessage}
                  <LinearProgress color='inherit' />
               </div>  
            ):( null )}

            {(step === 'profile') ? (
               <div style={{paddingTop:20, paddingBottom:50, maxWidth:400, margin:'0 auto'}}>
                  <Text variant='h6' color='text.secondary'>Member Lookup</Text>
                  <Text variant='body1' color='text.secondary'>
                     Please confirm that this is the member you would like to transfer this ticket to.
                  </Text>
                  <Divider style={{marginTop:5, marginBottom:10}}/>

                  <Grid container justify='center' spacing={1}>
                     <Grid item xs={4}>
                        <img alt={profile.name} src={profile.image} style={{borderRadius:5, width:'100%', maxWidth:150}} />
                     </Grid>
                     <Grid item xs={8}>
                        <Text variant='h6'>{profile.name}</Text>
                        <Text variant='body1' color='text.secondary'>{profile.email}</Text>
                     </Grid>
                  </Grid>      
                  <Stack style={{marginTop:20}} direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
                     <Button onClick={() => { handleTryAgain() }} variant='outlined' color='secondary'>try again</Button>
                     <Button onClick={() => { setStep('confirmation') }} variant='contained' color='secondary'>Confirm</Button>
                  </Stack>
               </div>  
            ):( null )}

            {(step === 'confirmation') ? (
               <div style={{paddingTop:20, paddingBottom:50, maxWidth:400, margin:'0 auto'}}>
                  <Text variant='h6' color='text.secondary'>Transfer Confirmation</Text>
                  <Text variant='body1' color='text.secondary'>
                     You are about to transfer a ticket (<b>{selectedItem.ticketName}</b>) for the event <b>{selectedItem.eventName}</b> to <b>{profile.name}</b> (<b>{profile.email}</b>).
                     This action can not be reversed. Click Transfer Ticket to complete transfer.
                  </Text>
                  <Divider style={{marginTop:5, marginBottom:10}}/>
                  <Stack style={{marginTop:20}} direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
                     <Button onClick={() => { handleClose(false) }} variant='outlined' color='secondary'>cancel</Button>
                     <Button onClick={() => { handleTransferTicket() }} variant='contained' color='secondary'>Transfer Ticket</Button>
                  </Stack>
               </div>  
            ):( null )}

            {(step === 'error') ? (
               <div style={{paddingTop:50, paddingBottom:50, maxWidth:400, margin:'0 auto'}}>
                 <Alert severity='error'>
                     <AlertTitle>Error</AlertTitle>
                     {message || 'Sorry, an error occured, please try again.'}
                  </Alert>
                  <div style={{ marginTop:20 }}><Button onClick={() => { handleTryAgain() }} variant='outlined' color='secondary'>try again</Button></div>
               </div>
            ):( null )}

            {(step === 'complete') ? (
               <div style={{paddingTop:50, paddingBottom:50, maxWidth:400, margin:'0 auto', textAlign:'center'}}>
                  <Text gutterBottom>
                    Ticket was transfered to <b>{profile.name}</b> (<b>{profile.email}</b>).
                  </Text> 
                  <div style={{width:'50%', margin:'0px auto', marginTop:30}}>
                     <Button fullWidth onClick={() => handleClose(false)} variant='contained' color='secondary'>ok</Button> 
                  </div>
               </div>  
            ):( null )}

         </DialogContent>
         <DialogActions>
            <Button onClick={() => { handleClose(false) }} color='primary'>Close</Button>
         </DialogActions>
      </Dialog>
   )
}
export default TransferTicket
