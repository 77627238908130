import { APP_LAST_UPDATED, GROUP_SETTINGS_SHOW_MESSAGE, GROUP_SETTINGS_LOAD, GROUP_SETTINGS_RESET_REDUCER } from '../types'
import { chkExpiredData, groupStatusCheck } from '../app/actions'
import { getFunctions, httpsCallable } from 'firebase/functions'

export function setShowMessage(){
	return (dispatch, getstate, firebase) => {
		dispatch({ type:GROUP_SETTINGS_SHOW_MESSAGE, payload:false })
	}
}

export function loadGroupSettings(force=false) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {    
	   const chkIfExpired = dispatch(chkExpiredData(force, 'groupSettings'))
     	chkIfExpired
     	.then(async (currentTime) => {
     	// ---------------------------------------------------------------------------    
	      const user = await dispatch(groupStatusCheck('groupSettings', GROUP_SETTINGS_RESET_REDUCER))  
        const teamMemberId = user.teamMemberId   
        const groupId = user.groupId

	      const functions = getFunctions(firebase)
        const fetchGroupSettings = httpsCallable(functions, 'fetchGroupSettings')
	      fetchGroupSettings({ teamMemberId, groupId })
	      .then((returned) => {
	      	let payload = {team:returned.data}
   			  dispatch({ type: GROUP_SETTINGS_LOAD, payload: payload })
	  			dispatch({ type: APP_LAST_UPDATED, payload:{key:'groupSettings', value:currentTime} })
		  		resolve()
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      }) 
     	// ---------------------------------------------------------------------------
     	})
     	.catch(() => {
         // Current data still good so do nothing.
         resolve()
     	})
	})
}

export function addTeamMember(teamMember) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {   
      setTimeout(() => {
         console.log("Delayed for 5 second.");
         resolve()
         //let error = {title:'Error',message:'Error connecting to server.'}
	      	//try { error = JSON.parse(e.message) } 
	      	//catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	     // 	reject(error)
      }, 5000)
	})
}

export function removeTeamMember(teamMemberId, reason) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {   
		resolve()
	})
}