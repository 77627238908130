import { useEffect, useState } from 'react' 
import { connect } from 'react-redux'
import { Routes, Route, Navigate, Outlet, Link, useLocation } from 'react-router-dom'
import { loadWallet, loadTickets, loadInvoices, updateNameOnTicket, transferTicket, profileLookup } from '../../../redux/memberStuff/actions'

import Info from './info'
import Invoices from './Invoices'
import SubNav from '../../components/SubNav'
import Tickets from './Tickets'
import Wallet from './Wallet'

import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

function MemberStuffContainer({ lastUpdatedWallet, lastUpdatedTickets, lastUpdatedInvoices, wallet, tickets, invoices, loadWallet, loadTickets, loadInvoices, updateNameOnTicket, transferTicket, profileLookup }) {
   const [showInfo, setShowInfo] = useState(false)
   const [activeTab, setActiveTab] = useState(false) 
   let location = useLocation()

   useEffect(() => {
      switch (true) {
         case location.pathname.includes('/member/stuff/wallet'):
            setActiveTab(0)
         break
         case location.pathname.includes('/member/stuff/tickets'):
            setActiveTab(1)
         break
         case location.pathname.includes('/member/stuff/invoices'):
            setActiveTab(2)
         break
         default:
            setActiveTab(false)
      }
   },[location])

   return (
      <Container maxWidth='lg' style={{ padding: 10, marginBottom:50 }}>
         <Info showInfo={showInfo} setShowInfo={setShowInfo} />
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} >
               <SubNav text='My Stuff' showInfo={false} setShowInfo={setShowInfo}/>
               <div style={{borderBottom: '2px solid #eee'}}>
                  <Tabs value={activeTab} variant='fullWidth' indicatorColor='primary' textColor='primary'>
                     <Tab label='Wallet' style={{ maxWidth:200 }} component={Link} to='/member/stuff/wallet'/>
                     <Tab label='Tickets' style={{ maxWidth:200 }} component={Link} to='/member/stuff/tickets' />
                     <Tab label='Invoices' style={{ maxWidth:200 }} component={Link} to='/member/stuff/invoices' />
                  </Tabs>
               </div>
               <div>
                  <Routes>
                     <Route path='/wallet' element={<Wallet 
                        lastUpdated={lastUpdatedWallet}  
                        wallet={wallet}
                        loadWallet={loadWallet}
                     />} />
                     <Route path='/tickets' element={<Tickets 
                        lastUpdated={lastUpdatedTickets}
                        tickets={tickets}
                        loadTickets={loadTickets}
                        updateNameOnTicket={updateNameOnTicket}
                        transferTicket={transferTicket}
                        profileLookup={profileLookup}
                     />} />
                     <Route path='/invoices' element={<Invoices 
                        lastUpdated={lastUpdatedInvoices} 
                        invoices={invoices}
                        loadInvoices={loadInvoices}
                     />} />                     
                     <Route path='*' element={<Navigate to='/member/stuff/wallet' />} />
                  </Routes>
                  <Outlet />
               </div>
            </Grid>
         </Grid>
      </Container>
   )
}

const mapStateToProps = (state) => ({
   lastUpdatedWallet: state.app.lastUpdated.memberWallet,
   lastUpdatedTickets: state.app.lastUpdated.memberTickets,
   lastUpdatedInvoices: state.app.lastUpdated.memberInvoices,
   wallet: state.memberStuff.wallet,
   tickets: state.memberStuff.tickets,
   invoices: state.memberStuff.invoices
})
const mapDispatchToProps = {
   loadWallet, 
   loadTickets, 
   loadInvoices,
   updateNameOnTicket, 
   transferTicket,
   profileLookup
}
export default connect(mapStateToProps, mapDispatchToProps)(MemberStuffContainer)
