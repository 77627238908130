import { useEffect, useState } from 'react' 
import { connect } from 'react-redux'
import { changeOption } from '../../../redux/auth/actions'
import { logIn, emailConfirmation, emailConfirmationConfirm, updateProfileInfo } from '../../../redux/auth/actions'

import CheckLoginStatus from './CheckLoginStatus'
import ConfirmProfile from './ConfirmProfile'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import IconClose from '@mui/icons-material/Close'
import Text from '@mui/material/Typography'
import LinearProgress from '@mui/material/LinearProgress'

function Index({showLoginPopup, setShowLoginPopup, loggedIn, profileInfo, changeOption, logIn, emailConfirmation, emailConfirmationConfirm, updateProfileInfo}){
   const [step, setStep] = useState('checkLoginStatus') // checkLoginStatus, confirmProfile

   useEffect(() => {
      checkLoginStatus()
   },[loggedIn])

   const checkLoginStatus = () => {
      if(loggedIn){
         changeOption(0) // this is just makeing sure user is logged into their user account and not a group
         setStep('confirmProfile')
      }else{
         setStep('checkLoginStatus')
      }
   }
   const handleCloseDialog = () => {
      setShowLoginPopup(false)
   }
   return (
      <Dialog 
         fullWidth
         scroll='body'
         open={showLoginPopup} 
         onClose={() => setShowLoginPopup(false)}
         style={{maxWidth:'100%', margin:0}}
      >

         <DialogTitle>
            <Text style={{color:'#999'}}>Login / Register</Text>
            <IconButton onClick={() => setShowLoginPopup(false)} sx={{ position: 'absolute', right: 8, top: 8, color: '#999'}}>
               <IconClose />
            </IconButton>
         </DialogTitle >
         <DialogContent style={{padding:10}} dividers>
            
            {(step === 'checkLoginStatus') ? (
               <CheckLoginStatus 
                  logIn={logIn} 
                  emailConfirmation={emailConfirmation} 
                  emailConfirmationConfirm={emailConfirmationConfirm}
                  setStep={setStep}
               />
            ):( null )}

            {(step === 'confirmProfile') ? (
               <ConfirmProfile
                  profileInfo={profileInfo}
                  updateProfileInfo={updateProfileInfo}
                  handleCloseDialog={handleCloseDialog}
               />
            ):( null )}

         </DialogContent>
         <DialogActions>
            <Button onClick={() => setShowLoginPopup(false)} color='secondary' autoFocus>
               Close
            </Button>
         </DialogActions>
      </Dialog>
   )
}
const mapStateToProps = (state) => ({
   loggedIn: state.auth.loggedIn || false,
   profileInfo: state.auth.userInfo || {}
})
const mapDispatchToProps = {
   changeOption,
   logIn,
   emailConfirmation, 
   emailConfirmationConfirm,
   updateProfileInfo
}
export default connect(mapStateToProps, mapDispatchToProps)(Index)
