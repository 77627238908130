import { useEffect, useState } from 'react' 

import NextUpdate from '../../components/NextUpdate'

import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function Wallet({lastUpdated, wallet, loadWallet}) {
   const [loading, setLoading] = useState(false) 

   useEffect(() => {
      //handleFetchMemberWallet()
   },[])

   const handleFetchMemberWallet = (force) => {
      setLoading(true)
      loadWallet(force) 
      .finally(() => {
         setLoading(false)
      })
   }

	return (
      <div>
   		<Paper style={{ padding: 10, minHeight:700 }} >

            <div style={{marginTop:20}}>
		         <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
		            <Text variant='body1' color='textSecondary'>
		               Nothing to see here yet...
		            </Text>
		         </div>
		      </div>       

         {/*
            <Collapse in={loading}> 
               <Alert severity='warning' style={{marginBottom:20}}>
                  Updating Your Wallet ...
                  <LinearProgress color='inherit' />   
               </Alert> 
            </Collapse>

            <div style={{marginTop:20}}>
		         <Text variant='h5' color='textSecondary'>Summary</Text>
		         <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
		            <Text variant='body1' color='textSecondary'>
		               Credits Balances
		            </Text>
		         </div>
		      </div> 

		      <div style={{marginTop:20}}>
		         <Text variant='h5' color='textSecondary'>Transactions</Text>
		         <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
		            <Text variant='body1' color='textSecondary'>
		               List of all transactions for Credits, Payments and Withdrawls 
		            </Text>
		         </div>
		      </div> 

		      <div style={{marginTop:20}}>
		         <Text variant='h5' color='textSecondary'>Settings</Text>
		         <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
		            <Text variant='body1' color='textSecondary'>
		               Stuff like set default Credit Card, Banking Details Etc.
		            </Text>
		         </div>
		      </div> 
      */}
         </Paper>
         <NextUpdate lastUpdated={lastUpdated} updateNow={handleFetchMemberWallet}/>
      </div>
   )
}
export default Wallet
