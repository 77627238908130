import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import Text from '@mui/material/Typography'
import Paper from '@mui/material/Paper'

function Profile({showMessage, setShowMessage}){
   return (
      <Paper style={{ padding: 10, minHeight:700 }} >
         <Collapse in={showMessage}>
               <Alert style={{marginBottom:20}} severity='info' onClose={() => setShowMessage(false)}>
                  This is your Group Settings, it can only be accessed by the Owner and Admins. 
                  Setup your Group Profile, assign Team Member Roles and expand your app by adding Plugins. 
               </Alert> 
         </Collapse>

         <Text variant='h5' color='textSecondary'>Group Profile</Text>
         <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
            <Text variant='body1' color='textSecondary'>
               group summary<br/> 
               group description
            </Text>
         </div>
         <Text variant='h5' color='textSecondary'>Group Images</Text>
         <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
            <Text variant='body1' color='textSecondary'>
               header image<br/> 
               Profile image
            </Text>
         </div>
         <Text variant='h5' color='textSecondary'>Security</Text>
         <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
            <Text variant='body1' color='textSecondary'>
               password Settings<br/> 
               Pin Settings
            </Text>
         </div>
         <Text variant='h5' color='textSecondary'>Verification</Text>
         <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
            <Text variant='body1' color='textSecondary'>
               Verify Group<br/>
            </Text>
         </div>
         <Text variant='h5' color='textSecondary'>Backup</Text>
         <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
            <Text variant='body1' color='textSecondary'>
               instructions on backing up data locally<br/>
            </Text>
         </div>
      </Paper>
   )
}
export default Profile
