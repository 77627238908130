import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { isValid, add } from 'date-fns'
import Swal from 'sweetalert2'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Button from '@mui/material/Button'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Text from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import ListItemText from '@mui/material/ListItemText';

function NewEvent({createEvent}){
   const [step, setStep] = useState('new')
   const [message, setMessage] = useState('')
   const [name, setName] = useState('')
   const [dateTimeStart, setDateTimeStart] = useState(new Date())
   const [dateTimeEnd, setDateTimeEnd] = useState(add(new Date(), {hours:4}))
   const [err, setErr] = useState('')
   const navigate = useNavigate()

   const handleChange = (f,v) => {
       f(v)
       setMessage('')
       if(f===setDateTimeStart){
          setDateTimeEnd(add(dateTimeStart, {hours:4}))
       }
   }

   const handleCreateEvent = () => {
      if (name === '') {
         return setMessage('Please Enter Event Name')
      }
      if (!isValid(dateTimeStart)) {
         return setMessage('Invalid Start Date Format - Click Calendar Icon')
      }
      if (!isValid(dateTimeEnd)) {
         return setMessage('Invalid End Date Format - Click Calendar Icon')
      }
      if (dateTimeEnd <= dateTimeStart ) {
         return setMessage('Event Must End After It Starts')
      }
      let eventInfo = {
         website: 'secureTix',
         name: name,
         dateTimeStart: dateTimeStart,
         dateTimeEnd: dateTimeEnd
      } 
      setStep('loading')
      createEvent(eventInfo)
      .then((eventId) => {
         Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Event Created!',
            text: 'Your event was created successfully.',
            showConfirmButton: true
         })
         navigate('/group/event/'+eventId)
      })
      .catch((e) => {
         setErr(e)
         setStep('error')
      })
   }   

   const handleResetForm = () => {
      setName('')
      setDateTimeStart('')
      setDateTimeEnd('')
      setMessage('')
      setErr('')
      setStep('new')
   } 

   return (
      <div style={{ padding: 10, minHeight:700 }}>
      
      <List sx={{ padding:0, width: '100%' }}>
         <ListItem alignItems="flex-start" style={{padding:0}}>
            <ListItemText
               primary={ <Text variant='h5' color='text.secondary'>Create Event</Text>}
               secondary='Lets create a new event'
            />
         </ListItem>
      </List>   

      <Paper style={{ padding: 10 }} >
         <div style={{ maxWidth:400, padding:15, margin:'0 auto'}}> 
            {(step === 'new') ? (
            <div>
               <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack spacing={3}>
                     <TextField fullWidth 
                        id='name' 
                        label='Name' 
                        value={name} 
                        onChange={(e) => { handleChange(setName, e.target.value) }}
                     />
                     <DateTimePicker
                        fullWidth
                        id='startDateTime'
                        label='Start Date & Time'
                        minutesStep={5}
                        minDate={new Date()}
                        value={dateTimeStart}
                        onChange={date => { handleChange(setDateTimeStart, date) }}
                        renderInput={(params) => <TextField {...params} />}
                     />
                     <DateTimePicker
                        fullWidth
                        id='endDateTime'
                        label='End Date & Time'
                        minutesStep={5}
                        minDate={dateTimeStart}
                        value={dateTimeEnd}
                        onChange={date => { handleChange(setDateTimeEnd, date) }}
                        renderInput={(params) => <TextField {...params} />}
                     />
                  </Stack>
               </LocalizationProvider>
               <div style={{ textAlign:'center', height:20}}><Text style={{color:'red'}}><i>{message}</i></Text></div>
               <div style={{ textAlign:'right', marginTop:5}}><Button onClick={handleCreateEvent} variant='contained'>Create Event</Button></div>
            </div>
            ):( null )}

            {(step === 'loading') ? (   
            <div>             
               <Alert severity='warning'>
                  <AlertTitle>Processing...</AlertTitle>
                  Your request is being processed. The event <strong>{name.toUpperCase()}</strong> is being created.
                  <LinearProgress color='inherit' />
               </Alert>
            </div>
            ):( null )}

            {(step === 'error') ? (   
            <div>             
               <Alert severity='error'>
                  <AlertTitle>{err.title || 'Error'}</AlertTitle>
                  {err.message || 'Sorry, an error occured, please try again.'}
               </Alert>
               <div style={{ marginTop:20 }}><Button onClick={() => {handleResetForm()}} variant='outlined' color='secondary'>try again</Button></div>
            </div>
            ):( null )}

         </div>                          
      </Paper>
      </div>
   )
}
export default NewEvent
