import { APP_LAST_UPDATED, GROUP_SUPPORT_SHOW_MESSAGE, GROUP_SUPPORT_LOAD, GROUP_SUPPORT_UPDATE_TICKET, GROUP_SUPPORT_UPDATE_TICKETS, GROUP_SUPPORT_RESET_REDUCER } from '../types'
import { chkExpiredData, groupStatusCheck } from '../app/actions'
import { getFunctions, httpsCallable } from 'firebase/functions'

export function setShowMessage(){
	return (dispatch, getstate, firebase) => {
		dispatch({ type:GROUP_SUPPORT_SHOW_MESSAGE, payload:false })
	}
}

export function loadGroupSupport(force=false) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {    
	   const chkIfExpired = dispatch(chkExpiredData(force, 'groupSupport'))
     	chkIfExpired
     	.then(async (currentTime) => {
     	// ---------------------------------------------------------------------------    
	      const user = await dispatch(groupStatusCheck('groupSupport', GROUP_SUPPORT_RESET_REDUCER))  
        const teamMemberId = user.teamMemberId   
        const groupId = user.groupId

	      const functions = getFunctions(firebase)
        const fetchGroupSupport = httpsCallable(functions, 'groupFetchSupport')
	      fetchGroupSupport({ teamMemberId, groupId })
	      .then((returned) => {
	      	let payload = {
	      		faqs:returned.data.faqs,
	      		notes:returned.data.notes,
	      		supportTickets:returned.data.supportTickets,
	      		team:returned.data.team
	      	}
   			  dispatch({ type: GROUP_SUPPORT_LOAD, payload: payload })
	  			dispatch({ type: APP_LAST_UPDATED, payload:{key:'groupSupport', value:currentTime} })
		  		resolve()
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      }) 
     	// ---------------------------------------------------------------------------
     	})
     	.catch(() => {
         // Current data still good so do nothing.
         resolve()
     	})
	})
}

export function loadGroupSupportArchived(){
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) { 
		//dispatch({ type:GROUP_SUPPORT_SHOW_MESSAGE, payload:false })
		console.log('loadGroupSupportArchived')
		resolve()
	})
}
export function updateTicket(ticketId, post) {
	return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) { 
      const user = await dispatch(groupStatusCheck('groupSupport', GROUP_SUPPORT_RESET_REDUCER))  
      const teamMemberId = user.teamMemberId   
      const groupId = user.groupId

      const functions = getFunctions(firebase)
      const assignGroupSupportTicket = httpsCallable(functions, 'groupUpdateTicket')
	   assignGroupSupportTicket({ teamMemberId, groupId, ticketId, post })
      .then((returned) => {
	      let timestamp = returned.data
	      let user = getstate().auth.userInfo
	      let ticket = getstate().groupSupport.supportTickets[ticketId] || {}
         ticket.thread[timestamp] = {
           	timestamp: timestamp,
            comment: post,
            postedById: user.id,
            postedByName: user.name,
            postedByThumb: user.image
         }
         let payload = {
           	id: ticketId,
           	info: ticket
         }
	      dispatch({type: GROUP_SUPPORT_UPDATE_TICKET, payload: payload})	      	
	      resolve()
      })
      .catch((e) => {
	    	let error = {title:'Error',message:''}
	     	try { error = JSON.parse(e.message) } 
	     	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	     	reject(error)
	   }) 
	})
}
export function closeTicket(ticketId){
	return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) { 
      const user = await dispatch(groupStatusCheck('groupSupport', GROUP_SUPPORT_RESET_REDUCER))  
      const teamMemberId = user.teamMemberId   
      const groupId = user.groupId

      const functions = getFunctions(firebase)
      const closeGroupSupportTicket = httpsCallable(functions, 'groupCloseTicket')
	   closeGroupSupportTicket({ teamMemberId, groupId, ticketId })
      .then((returned) => {
	      let timestamp = returned.data
	      let user = getstate().auth.userInfo
	      let ticket = getstate().groupSupport.supportTickets[ticketId] || {}
         ticket.status = 'closed'
			ticket.closed = true
			ticket.closedById = user.id
			ticket.closedByName = user.name
			ticket.closedTimestamp = timestamp
			ticket.notes.push({ timestamp:timestamp, note: 'Ticket Closed by ' + user.name }) 
         let payload = {
           	id: ticketId,
           	info: ticket
         }
	      dispatch({type: GROUP_SUPPORT_UPDATE_TICKET, payload: payload})	      	
	      resolve()
      })
      .catch((e) => {
	    	let error = {title:'Error',message:''}
	     	try { error = JSON.parse(e.message) } 
	     	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	     	reject(error)
	   }) 
	})
}

export function addTicketNote(){
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) { 
		//dispatch({ type:GROUP_SUPPORT_SHOW_MESSAGE, payload:false })
		console.log('addTicketNote')
		resolve()
	})
}
export function assignTicket(ticketId, memberId, memberName){
	return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) { 
      const user = await dispatch(groupStatusCheck('groupSupport', GROUP_SUPPORT_RESET_REDUCER))  
      const teamMemberId = user.teamMemberId   
      const groupId = user.groupId

      const functions = getFunctions(firebase)
      const assignGroupSupportTicket = httpsCallable(functions, 'groupAssignTicket')
	   assignGroupSupportTicket({ 
	   	teamMemberId:teamMemberId, 
	   	groupId:groupId,
	   	ticketId:ticketId, 
	   	assignedTeamMemberId:memberId,
	   	assignedTeamMemberName:memberName
	   })
      .then((returned) => {
	      let timestamp = Math.floor(Date.now() / 1000)
	      let name = getstate().auth.userInfo.name
	      let ticket = getstate().groupSupport.supportTickets[ticketId] || {}
	      ticket.status = 'open'
	      ticket.assignedToId = memberId
         ticket.assignedToName = memberName
         if (!ticket.notes) {
				ticket.notes = []
         }
         ticket.notes.push({ timestamp:timestamp, note: 'Ticket assigned to ' + memberName + ' by ' + name + '.' })
         let payload = {
           	id: ticketId,
           	info: ticket
         }
         dispatch({type: GROUP_SUPPORT_UPDATE_TICKET, payload: payload})	      	
	      resolve()
      })
      .catch((e) => {
	    	let error = {title:'Error',message:''}
	     	try { error = JSON.parse(e.message) } 
	     	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	     	reject(error)
	   }) 
	})
}
export function archiveTicket(ticketId){
	return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) { 
      const user = await dispatch(groupStatusCheck('groupSupport', GROUP_SUPPORT_RESET_REDUCER))  
      const teamMemberId = user.teamMemberId   
      const groupId = user.groupId

      const functions = getFunctions(firebase)
      const archiveGroupSupportTicket = httpsCallable(functions, 'groupArchiveTicket')
	   archiveGroupSupportTicket({ teamMemberId, groupId, ticketId })
      .then((returned) => {
	      let tickets = getstate().groupSupport.supportTickets || {}
         delete tickets[ticketId]
	      dispatch({type: GROUP_SUPPORT_UPDATE_TICKETS, payload: tickets})	      	
	      resolve()
      })
      .catch((e) => {
	    	let error = {title:'Error',message:''}
	     	try { error = JSON.parse(e.message) } 
	     	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	     	reject(error)
	   }) 
	})
}












