import { useEffect, useState } from 'react' 
import Swal from 'sweetalert2'

import Loader from '../../components/Loader'
import ImageSelector from '../../components/ImageSelector'

import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import IconClose from '@mui/icons-material/Close'
import IconExpandLess from '@mui/icons-material/ExpandLess'
import IconExpandMore from '@mui/icons-material/ExpandMore'
import IconImage from '@mui/icons-material/CropOriginal'
import InputAdornment from '@mui/material/InputAdornment'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Text from '@mui/material/Typography'

import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'


function EventImages({eventId, event, editEventImages}){
   const [loading, setLoading] = useState(false) 
   const [showImages, setShowImages] = useState(true)
   const [disableButton, setDisableButton] = useState(true)

  // [START]: ImageSelector Variables & Function :::::::::
    const [headerImage, setHeaderImage] = useState((event.headerImage) ? event.headerImage : null) // ImageSelector variable
    const headerImages = [ '/images/events/defaultHeader.jpg' ] // ImageSelector variable
    const handleSelectHeaderImage = (src) => {
      if (src !== event.headerImage) {
        setDisableButton(false)
      } else {
        setDisableButton(true)
      } 
      setHeaderImage(src)
    }
  // [END]: ImageSelector Variables & Function :::::::::::

  // [START]: ImageSelector Variables & Function :::::::::
    const [profileImage, setProfileImage] = useState((event.profileImage) ? event.profileImage : null) // ImageSelector variable
    const profileImages = [ '/images/events/defaultProfile.jpg' ] // ImageSelector variable
    const handleSelectProfileImage = (src) => {
      if (src !== event.profileImage) {
        setDisableButton(false)
      } else {
        setDisableButton(true)
      } 
      setProfileImage(src)
    }
  // [END]: ImageSelector Variables & Function :::::::::::

   useEffect(() => {
      setHeaderImage(event.headerImage)
      setProfileImage(event.profileImage)
   },[event])

   const handleUpdateImages = () => {
      setLoading(true)
      editEventImages(eventId, profileImage, headerImage)
      .then(() => {
         setLoading(false)
         setDisableButton(true)
         Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Images Updated!',
            text: 'Your event images were updated successfully.',
            showConfirmButton: true
         })
      })
      .catch((e) => {
         setLoading(false)
         Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: e.title,
            text: e.message,
            showConfirmButton: true
         })
      })
   }   

   return (
      <div style={{marginTop:20}}>
         <Loader loading={loading}/>
         <List sx={{ padding:0, width: '100%', borderBottom:'1px solid gray' }}>
             <ListItem 
                alignItems='flex-start' 
                style={{padding:0}}
                secondaryAction={
                   <IconButton edge='end' onClick={() => { setShowImages(!showImages) }}>
                      {showImages ? <IconExpandLess /> : <IconExpandMore />} 
                   </IconButton>
                }
             >
             <ListItemText primary={ <Button style={{textTransform:'none'}} onClick={() => { setShowImages(!showImages) }}><Text variant='h5' color='text.secondary'>Images</Text></Button>} />
            </ListItem>
         </List> 
         <Collapse in={showImages}> 
            <Paper style={{padding:10}}>
               <div style={{maxWidth:700, margin:'0 auto'}} >
                  <Grid container justifyContent='center' direction='row' alignItems='center' spacing={4}>
                     <Grid item xs={12} sm={6} >
                        {/** ImageSelector Starts Here */}
                        <div style={{ maxWidth: 300, margin:'0 auto'}}>
                           <Text variant='body1' color='text.secondary'>Header Image</Text>
                           <ImageSelector 
                             imgBorder='1px solid #ccc'
                             imgBorderRadius={10}
                             imgPadding={5}
                             imgRatio='16:9' 
                             images={headerImages}
                             currentImage={headerImage}
                             selectImage={handleSelectHeaderImage}
                           />
                        </div>
                        {/** ImageSelector Ends Here */} 
                     </Grid>
                     <Grid item xs={12} sm={6}>                     
                        {/** ImageSelector Starts Here */}
                        <div style={{ maxWidth: 300, margin:'0 auto'}}>
                           <Text variant='body1' color='text.secondary'>Profile Image</Text>
                           <ImageSelector 
                             imgBorder='1px solid #ccc'
                             imgBorderRadius={10}
                             imgPadding={5}
                             imgRatio='1:1' 
                             images={profileImages}
                             currentImage={profileImage}
                             selectImage={handleSelectProfileImage}
                           />
                        </div>
                        {/** ImageSelector Ends Here */}                      
                     </Grid>
                  </Grid>   
               </div> 
               <div style={{textAlign:'right', marginTop:10}}><Button disabled={disableButton} variant='contained' onClick={handleUpdateImages}>Save Images</Button></div>   
            </Paper>
         </Collapse>
      </div>
   )
}
export default EventImages
