import { AUTH_CHANGE_LOGIN_OPTION, AUTH_LOAD_LOGIN_OPTIONS, AUTH_LOAD_USER_CREDITS, AUTH_LOGIN_USER, AUTH_LOGOUT_USER, AUTH_USER_INFO } from '../types'

const initialState = {
	loggedIn: false,
	userInfo: null,
	loginOptionID: 0,
	loginOptions: [{
		login: 'member',
		id: null,
		name: 'Anonymous',
		role: '',
		roleId: 0,
		thumb: '/images/profile/default.jpg',
		initials: 'A'
	}]
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
		case AUTH_LOGIN_USER:
			return { 
				...state,
				loggedIn: true          
			}
		case AUTH_LOGOUT_USER:
			// this is also called in the reducers.js to reset the state completely
			return { 
				...initialState           
			}
		case AUTH_CHANGE_LOGIN_OPTION:
			return { 
				...state, 
				loginOptionID: action.payload
			}	
		case AUTH_USER_INFO:
			return { 
				...state,
				userInfo: action.payload
			}
		case AUTH_LOAD_LOGIN_OPTIONS:
			return { 
				...state, 
				loginOptions: action.payload
			}
		case AUTH_LOAD_USER_CREDITS:
			return { 
				...state, 
				userInfo: {
					...state.userInfo,
					credits: action.payload
				}
			}
		default:
			return state
	}
}
