import format from 'date-fns/format'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { loadEvent, editEventDetails, editEventImages, ticketTypeAddEdit, ticketTypeCopyDelete, editEventStatus, editEventRegistration, removeEventRegistration, fetchEventRegistration } from '../../../redux/groupEvent/actions'

import Info from './Info'
import EventDetails from './EventDetails'
import EventTickets from './EventTickets'
import EventImages from './EventImages'
import EventRegistration from './EventRegistration'
import EventStatus from './EventStatus'
import SubNav from '../../components/SubNav'

import Collapse from '@mui/material/Collapse'
import Container from '@mui/material/Container'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'



import Avatar from '@mui/material/Avatar'


import IconArrowForward from '@mui/icons-material/ArrowForwardIos'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'

import Text from '@mui/material/Typography'


function GroupEventContainer({event, loadEvent, editEventDetails, editEventImages, ticketTypeAddEdit, ticketTypeCopyDelete, editEventStatus, editEventRegistration, removeEventRegistration, fetchEventRegistration}) {
	const [step, setStep] = useState('display') //loading, error, display  
   const [showInfo, setShowInfo] = useState(false)
   const [loadingError, setLoadingError] = useState('')
   const { eventId } = useParams()

   useEffect(() => {
      handleFetchEvent()
   },[])
	
   const handleFetchEvent = (force) => {
      setStep('loading')
      loadEvent(eventId) 
      .then(() => {
         setStep('display')
      })
      .catch((error) => {
         setLoadingError(error)
         setStep('error')
      })
   }

   if(!event) return null
	return (
      <Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
         <Info showInfo={showInfo} setShowInfo={setShowInfo} />
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} >
               <SubNav text='Event Details' showInfo={true} setShowInfo={setShowInfo}/>
               <div style={{ textAlign:'right', paddingTop:15, paddingBottom:15 }}>
                  <Button variant='outlined' component={ Link } to={'/group/events'}>Return to Events List</Button>
               </div>

               {(step==='loading') ? (
                  <Alert severity='warning'>
                     <AlertTitle>Loading</AlertTitle>
                     Loading Event Details...
                     <LinearProgress color='inherit' />   
                  </Alert> 
               ):(null)}

               {(step === 'error') ? (   
                  <div>             
                     <Alert severity='error'>
                        <AlertTitle>{loadingError.title || 'Error'}</AlertTitle>
                        {loadingError.message || 'Sorry, an error occured, please try again.'}
                     <div style={{ marginTop:20 }}><Button onClick={handleFetchEvent} variant='outlined' color='secondary'>try again</Button></div>
                     <div style={{ marginTop:20 }}><Button component={Link} to='/group/events' variant='outlined' color='secondary'>return to upcoming events</Button></div>
                     </Alert>
                  </div>
               ):( null )}

               {(step === 'display' && !event.name) ? ( 
                  <div>             
                     <Alert severity='error'>
                        <AlertTitle>Loading Error</AlertTitle>
                        Sorry, an error occured, please try again.
                     <div style={{ marginTop:20 }}><Button onClick={handleFetchEvent} variant='outlined' color='secondary'>try again</Button></div>
                     <div style={{ marginTop:20 }}><Button component={Link} to='/group/events' variant='outlined' color='secondary'>return to upcoming events</Button></div>
                     </Alert>
                  </div> 
               ):( null )}                  

               {(step === 'display' && event.name) ? ( 
                  <div>
                     <Paper>
                     <List style={{width:'100%', padding:5}}>
                        <ListItem alignItems='flex-start'>
                           <ListItemAvatar>
                              <Avatar alt={event.name} src={event.profileImage} style={{borderRadius:5, width:90, height:90, marginRight:10}} />
                           </ListItemAvatar>
                           <ListItemText>
                              <b>{event.name}</b>
                              <Text style={{lineHeight:1}} variant='caption' display='block' color='textSecondary'>
                                 On: { format(new Date(event.dateTimeStart), 'eee do MMM y')}<br/>
                                 At: { format(new Date(event.dateTimeStart), '- h:mm a')}
                              </Text>
                              <Text style={{lineHeight:1.5, color:(event.published)? 'green' : 'red' }}><b>{(event.published) ? 'PUBLISHED' : 'NOT PUBLISHED'}</b></Text>
                           </ListItemText>
                        </ListItem>
                     </List>
                     </Paper>

                     <Grid container justifyContent='center' direction='row' spacing={2}>
                        <Grid item xs={12} sm={12} md={8} >
                           <EventDetails 
                              eventId={eventId} 
                              event={event} 
                              editEventDetails={editEventDetails}
                           />
                           <EventImages
                              eventId={eventId} 
                              event={event} 
                              editEventImages={editEventImages}
                           />
                           <EventTickets  
                              eventId={eventId} 
                              event={event}
                              ticketTypeAddEdit={ticketTypeAddEdit} 
                              ticketTypeCopyDelete={ticketTypeCopyDelete}
                           /> 
                           <EventRegistration
                              eventId={eventId} 
                              event={event} 
                              editEventRegistration={editEventRegistration}
                              removeEventRegistration={removeEventRegistration}
                              fetchEventRegistration={fetchEventRegistration}
                           />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} >
                           <EventStatus 
                              eventId={eventId}
                              event={event}
                              editEventStatus={editEventStatus}
                           />   
                        </Grid>
                     </Grid> 
                  </div>                
               ):( null )}
         
      {/*

TextEditor
 - TUTORIAL - https://blog.bitsrc.io/getting-started-with-rich-text-editors-in-react-aeb7112c68d5
 - https://www.npmjs.com/package/react-quill#custom-toolbar
 - https://www.npmjs.com/package/react-draft-wysiwyg
 - https://www.npmjs.com/package/lexical






         PREVIEW EVENT | MANAGE EVENT


            RESTRICTIONS
            (have restctions on how many tickets someone can buy? put your tickets into categories
             and set restrictions on how many each purchaser can buy
              restction type - categories
              restction type - list
             )

            LOCATION
            ITEMS
            GALLERY
            FAQS 
            SPONSORS 
            TESTOMINIALS 
      */}


            </Grid>
         </Grid>
      </Container>  



   )
}
const mapStateToProps = (state) => ({
   event: state.groupEvent.event
})
const mapDispatchToProps = {
	loadEvent,
   editEventDetails,
   editEventStatus,
   ticketTypeAddEdit, 
   ticketTypeCopyDelete,
   editEventImages,
   editEventRegistration,
   removeEventRegistration,
   fetchEventRegistration
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupEventContainer)
