import { useEffect, useState } from 'react' 
import { intervalToDuration } from 'date-fns'

import Button from '@mui/material/Button'

function NextUpdate({lastUpdated, updateNow}){
   const [message, setMessage] = useState(null)
   const [lastTimeUpdate, setLastTimeUpdate] = useState(0)

   useEffect(() => {
      const interval = setInterval(() => tick(), 1000)
      return () => clearInterval(interval)
   }, [lastUpdated])

   const tick = () => {
		let currentTime = Math.floor(Date.now() / 1000)
		let updated = (lastUpdated) ? lastUpdated : currentTime
      let difference = currentTime - updated
      let minutes = intervalToDuration({ start: 0, end: difference * 1000 }).minutes  
		let seconds = intervalToDuration({ start: 0, end: difference * 1000 }).seconds 
		let mins = (minutes < 1) ? '' : ((minutes === 1) ? minutes+'min' : minutes+'mins')
		let secs = (seconds === 1) ? seconds+'sec' : seconds+'secs'
		let time = mins +' '+secs
      setMessage(null)
      setLastTimeUpdate(time)
	}
   const handleUpdateNow = () => {
		setMessage('updating now...')
		updateNow(true)
	}
   return (
		<div style={{textAlign:'center'}}>
			<Button onClick={() => {handleUpdateNow()}} style={{ color: '#78909c', fontStyle:'italic', textTransform: 'none', fontSize: 11, padding:2 }}>
				{(message) ? message : 'Last updated ' + lastTimeUpdate + ' ago. Click to update now.'}
 			</Button>
		</div>
   )
}
export default NextUpdate
