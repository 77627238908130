import { useState } from 'react' 
import Swal from 'sweetalert2'
import Loader from '../../../components/Loader'

import Button from '@mui/material/Button'
import Text from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Paper from '@mui/material/Paper'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

function TicketAssignTo({ticketId, ticket, team, assignTicket}){
   const [loading, setLoading] = useState(false)
   const [assignedTo, setAssignedTo] = useState('')
   const [assignedToMessage, setAssignedToMessage] = useState('')

   const handleAssignTicket = () => {
      if (assignedTo === ''){
         return setAssignedToMessage('Please select assigned Team Member.')
      }      
      Swal.fire({
         title: 'Assign Ticket',
         text: 'Assign this ticket to ' + assignedTo.memberName + '?',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Confirm'
      }).then((result) => {
         if (result.value) {
            setLoading(true)
            assignTicket(ticketId, assignedTo.memberId, assignedTo.memberName)
            .then(() => {
               setLoading(false)
               setAssignedTo('')
               setAssignedToMessage('')
            })
            .catch((error) => {
               setLoading(false)
               setAssignedTo('')
               setAssignedToMessage(error.message)
            })            
         }
      })   
   }

   return (
      <Paper style={{marginTop:20, padding: 10, backgroundColor:'#fafafa'}} >
         <Loader loading={loading} />
         <Text variant='h5'>Assign Ticket</Text>
         {(ticket.status === 'unassigned') ? (
            <Text variant='subtitle1' color='textSecondary'><i>This ticket is currently unassigned.</i></Text> 
         ):( 
            <Text variant='subtitle1' color='textSecondary'><i>This ticket is currently assigned to {ticket.assignedToName}</i></Text>
         )}   
         <FormControl fullWidth>
            <InputLabel id='Team Member'>Team Member</InputLabel>
            <Select labelId='Team Member' label='Team Member' value={assignedTo} onChange={(e)=>{setAssignedTo(e.target.value)}}>
               <MenuItem value=''>Select Team Member</MenuItem>
               {Object.keys(team).map((i) => {
                  return (
                     <MenuItem key={team[i].memberId} value={team[i]}>{team[i].memberName}</MenuItem>
                  )
               })}
            </Select>
         </FormControl>
         <div style={{textAlign:'center'}}><Text variant="overline" style={{color:'red'}}>{assignedToMessage}</Text></div>
         <Button onClick={handleAssignTicket} variant="contained" color="secondary" style={{marginTop:10, marginBottom:10}}>Assign Ticket</Button>
      </Paper>
   )
}
export default TicketAssignTo
