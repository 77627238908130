import { useState } from 'react' 
import Alert from '@mui/material/Alert'

function Template(){
   const [showMessage, setShowMessage] = useState(true)
   return (
      <div>
      {(showMessage) ? (
         <Alert severity='info' onClose={() => {setShowMessage(false)}}>
            This is your member's signup template. Once active, members would be required to fill it out to
            be fully registered.
         </Alert>    
      
      ) : ( null )} 
      </div>
   )
}
export default Template
