import { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'

import Fees from '../components/Legal/Fees'
import Privacy from '../components/Legal/Privacy'
import Refunds from '../components/Legal/Refunds'
import Terms from '../components/Legal/Terms'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

function Legal() {
   const theme = useTheme()
   const fullWidth = useMediaQuery(theme.breakpoints.up('sm'))
   const [searchParams] = useSearchParams()
   const page = searchParams.get('page')    // the url to return to after signin
   const [activeTab, setActiveTab] = useState(0) 
  
   useEffect(() => {
     let tabNames = {'terms':0, 'privacy':1, 'refunds':2, 'fees':3}
     let tabPath = page.replace(/\/\s*$/, '') // this removes any trailing / from the pathname.
     let currentTab = (tabNames[tabPath]) ? tabNames[tabPath] : 0   
     setActiveTab(currentTab)
   },[page]) 

   const buttons = [
      <Button style={{textAlign:'center'}} key='terms' to='/legal?page=terms' component={Link}>Terms of Service</Button>,
      <Button style={{textAlign:'center'}} key='privacy' to='/legal?page=privacy' component={Link}>Privacy Policy</Button>,
      <Button style={{textAlign:'center'}} key='refunds' to='/legal?page=refunds' component={Link}>Refund Policy</Button>,
      <Button style={{textAlign:'center'}} key='fees' to='/legal?page=fees' component={Link}>Fees & Charges</Button>,
   ]

   return (
      <div>
         <Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
            <Grid container justifyContent='center' spacing={2}>
               <Grid item xs={12} sm={12} md={10} >
                  <Paper style={{ padding: 10, minHeight:700 }} >
                     {(fullWidth) ? (
                     <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={activeTab} variant='fullWidth' indicatorColor='primary' textColor='primary'>
                           <Tab label='Terms of Service' to='/legal?page=terms' component={Link} />
                           <Tab label='Privacy Policy' to='/legal?page=privacy' component={Link} />
                           <Tab label='Refund Policy' to='/legal?page=refunds' component={Link} />
                           <Tab label='Fees & Charges' to='/legal?page=fees' component={Link} />
                        </Tabs>
                     </Box>
                     ):(
                     <Box sx={{ width: '100%', textAlign:'center' }}>
                        <ButtonGroup size='small' variant='text'>
                          {buttons}
                        </ButtonGroup>
                     </Box>
                     )}
                   
                     {(activeTab === 0) ? (
                        <Terms />
                     ):( null )} 
                     
                     {(activeTab === 1) ? (
                        <Privacy />
                     ):( null )} 

                     {(activeTab === 2) ? (
                        <Refunds />
                     ):( null )} 

                     {(activeTab === 3) ? (
                        <Fees />
                     ):( null )}   
                  </Paper>
               </Grid>
            </Grid>
         </Container>
      </div>
   )
}
export default Legal