import { useEffect, useState } from 'react' 
import { format, isValid, isSameDay } from 'date-fns'
    
import ImageSelector from '../../components/ImageSelector'
import Loader from '../../components/Loader'
import countryList from '../../../includes/countryList'
import { chkPassword }  from '../../../includes/functions'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import IconInfo from '@mui/icons-material/InfoOutlined'
import InputAdornment from '@mui/material/InputAdornment'
import LinearProgress from '@mui/material/LinearProgress'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

function Profile({profile, updateProfileInfo, updateProfilePassword}) {
   const [loading, setLoading] = useState(false) 
   const [message, setMessage] = useState('')
   const [disableButton, setDisableButton] = useState(true)
   const [firstName, setFirstName] = useState((profile && profile.firstName) ? profile.firstName : '')
   const [lastName, setLastName] = useState((profile && profile.lastName) ? profile.lastName : '')
   const [gender, setGender] = useState((profile && profile.gender) ? profile.gender : '')
   const [dob, setDob] = useState((profile && profile.dob) ? new Date(profile.dob) : null)
   const [cell, setCell] = useState((profile && profile.cell) ? profile.cell : '')
   const [nationality, setNationality] = useState((profile && profile.nationality) ? profile.nationality : '')
   const [location, setLocation] = useState((profile && profile.location) ? profile.location : '')

   const [disablePasswordButton, setDisablePasswordButton] = useState(true)
   const [getOldPassword, setGetOldPassword] = useState(false)
   const [oldPassword, setOldPassword] = useState('')
   const [showPassword, setShowPassword] = useState(false) 
   const [password, setPassword] = useState('')
   const [confirm, setConfirm] = useState('')
   const [showInfo, setShowInfo] = useState(false)
   const [securityMessage, setSecurityMessage] = useState('')

  // [START]: ImageSelector Variables & Function :::::::::
    const [selectedImage, setSelectedImage] = useState((profile && profile.image) ? profile.image : '/images/profile/default.jpg') // ImageSelector variable
    const images = [ '/images/profile/default.jpg' ] // ImageSelector variable
    const handleSelectImage = (src) => {
      if (profile.image !== src) {
        setDisableButton(false)
      } else {
        setDisableButton(true)
      } 
      setSelectedImage(src)
    }
  // [END]: ImageSelector Variables & Function ::::::::

   useEffect(() => {
      let p = (profile) ? profile : {}
      if (
         selectedImage === p.image && 
         firstName ===  p.firstName && 
         lastName ===  p.lastName && 
         gender ===  p.gender && 
         ((dob === null && p.dob === '') || (dob !== null && isSameDay(dob, new Date(p.dob)))) && 
         cell ===  p.cell && 
         nationality === p.nationality && 
         location ===  p.location
      ) {
         setDisableButton(true)
      } else {
         setDisableButton(false)
      }

      if (password === '') {
         setDisablePasswordButton(true)
      } else {
         setDisablePasswordButton(false)
      }
   }, [profile, selectedImage, firstName, lastName, gender, dob, cell, nationality, location, password])

   const handleChange = (f,v) => {
      f(v)
      setMessage('')
   }
   const updateUser = () => {
      // firstName and lastName cannot be blank
         if (firstName.length < 1 || lastName.length < 1) {
            return setMessage('Sorry, both First and Last Names are required.')
         }
      // format date properly
         let d = null
         if (isValid(dob)) {
            d = format(dob,'MM/dd/yyyy')
         } else {
            return setMessage('Please enter a valid Date of Birth.')         
         }
      // set user variables
         let user = {
            image: selectedImage,
            firstName: firstName,
            lastName: lastName,
            gender: gender,
            dob: d,
            cell: cell,
            nationality: nationality,
            location: location
         }
      setLoading(true)
      updateProfileInfo(user)
      .then(() => {
         setDisableButton(true)
         setLoading(false)
      })
      .catch((e) => {
         setMessage(e.message)
         setLoading(false)
      })
   }   
   const updatePassword = () => {
      if (getOldPassword && !chkPassword(oldPassword)) {
         return setSecurityMessage('Please enter your Current Password.')
      }
      if (!chkPassword(password)) {
         return setSecurityMessage('Please enter a valid Password.')
      }
      if (password !== confirm) {
         return setSecurityMessage('Password and Confirm must match.')
      }
      setLoading(true)
      updateProfilePassword(oldPassword, password)
      .then(() => {
         setLoading(false)
         setOldPassword('')
         setPassword('')
         setConfirm('')
         setSecurityMessage('Password Updated')
      })
      .catch((error) => {
         setLoading(false)
         setSecurityMessage(error)
         if (error === 'Please enter your Current Password.') {
           setGetOldPassword(true)
         }
      })
   }
   if (!profile) return null
   return (
      <div>
         <Loader loading={loading} title='Profile Update' text='Updating profile, please wait...'/>
         <Paper style={{ padding: 10, minHeight:700 }} >
            <Grid container spacing={2} style={{marginTop:10}}>
               <Grid item xs={12} sm={12}>
                  <div style={{paddingLeft:20, maxWidth:600}}>
                     <Text variant='h5'>SecureTix Profile</Text>
                     <Text variant='subtitle1' color='textSecondary' style={{lineHeight:1.4}}>
                        Please confirm/update your profile and keep it up to date.
                        As stated in our Terms Of Service, some events may required a valid profile, and an incomplete 
                        profile may invalidate your tickets.
                     </Text>
                  </div>
               </Grid>
               <Grid item xs={12} sm={6}>
                  {/** ImageSelector Starts Here */}
                  <div align='center' style={{ maxWidth: 250, margin:'0 auto'}}>
                     <ImageSelector 
                        imgBorder='1px solid #ccc'
                        imgBorderRadius={10}
                        imgPadding={5}
                        imgRatio='1:1' 
                        images={images}
                        currentImage={selectedImage}
                        selectImage={handleSelectImage}
                     />
                  </div>
                  {/** ImageSelector Ends Here */} 
               </Grid>
               <Grid item xs={12} sm={6}>
                  <div style={{paddingLeft:20, paddingRight:20}}>
                     <TextField fullWidth size='small' label='First Name (required)' name='firstName' margin='normal'
                        value={firstName} onChange={(e) => {handleChange(setFirstName, e.target.value) }}
                     />
                     <TextField fullWidth size='small' label='Last Name (required)' name='lastName' margin='normal'
                        value={lastName} onChange={(e) => {handleChange(setLastName, e.target.value) }}
                     />
                     <TextField fullWidth size='small' label='Gender' name='lastName' margin='normal' select 
                        value={gender} onChange={(e) => {handleChange(setGender, e.target.value) }}
                     >
                        <MenuItem value=''><em>Gender</em></MenuItem>
                        <MenuItem value='Male'>Male</MenuItem>
                        <MenuItem value='Female'>Female</MenuItem> 
                     </TextField>
                     <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker label='Date of Birth' inputFormat='MM/dd/yyyy'
                           value={dob} onChange={(e) => {handleChange(setDob, e) }}
                           renderInput={(params) => <TextField fullWidth size='small' style={{marginTop:10}} {...params} />}
                        />
                     </LocalizationProvider>
                     <TextField fullWidth size='small' label='Cell' name='cell' margin='normal' type='number'
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        value={cell} onChange={(e) => {handleChange(setCell, e.target.value) }}
                     />
                     <TextField fullWidth size='small' label='Nationality' name='Nationality' margin='normal' select
                        value={nationality} onChange={(e) => {handleChange(setNationality, e.target.value) }}
                     >
                        <MenuItem value=''><em>Nationality</em></MenuItem>
                        {countryList.map((c, i) => {
                          return(<MenuItem key={i} value={c.code}>{c.name}</MenuItem>)
                        })}
                     </TextField> 
                     <TextField fullWidth size='small' label='Location' name='location' margin='normal' select
                        value={location} onChange={(e) => {handleChange(setLocation, e.target.value) }}
                     >
                        <MenuItem value=''><em>Location</em></MenuItem>
                        {countryList.map((c, i) => {
                          return(<MenuItem key={i} value={c.code}>{c.name}</MenuItem>)
                        })}
                     </TextField> 
                  </div>
               </Grid>
               <Grid item xs={12}>
                  <div align='center'>
                     <Text variant='body1' style={{ color: 'red' }}>
                        <i>{message}</i>
                     </Text>
                  </div>
                  <div style={{margin:'0px auto', maxWidth:400}}><Button onClick={updateUser} fullWidth variant='outlined' color='secondary' disabled={disableButton}>Update Profile</Button></div>
                  <div style={{width:'100%', heigh:2, borderBottom: '2px solid #ccc', marginTop:30}}>&nbsp;</div>
               </Grid>
               <Grid item xs={12} sm={12}>
                  <div style={{paddingLeft:20, marginTop:10, maxWidth:600}}>
                     <Text variant='h5'>Security</Text>
                     {(getOldPassword)?(
                        <Text variant='subtitle1' color='textSecondary' style={{lineHeight:1.4}}>
                           To set your password you must either enter your current password or re-login through your email.
                        </Text>
                     ):(
                        <Text variant='subtitle1' color='textSecondary' style={{lineHeight:1.4}}>
                           Set an optional password to log into your account. If not you can always login to your account 
                           through your email.
                        </Text>
                     )}
                  </div>
               </Grid> 
               <Grid item xs={12} sm={12}>
                  <div style={{maxWidth:500, margin:'0 auto', paddingLeft:20, paddingRight:20}}>
                     <Grid container justifyContent='center'>
                        {(getOldPassword) ? (
                        <Grid item xs={12} sm={12}>
                           <TextField fullWidth size='small' margin='normal' label='Current Password'
                              value={oldPassword} type={showPassword ? 'text' : 'password'}
                              onChange={(e) => { handleChange(setOldPassword, e.target.value) }}
                              InputProps={{
                                 endAdornment: (
                                    <InputAdornment position='end'>
                                       <IconButton  onClick={() => { setShowPassword(!showPassword) }}>
                                       {showPassword ? <Visibility /> : <VisibilityOff />}
                                       </IconButton>
                                    </InputAdornment>
                                 )
                              }}
                           />
                        </Grid>
                        ):( null )}
                        <Grid item xs={12} sm={5}>
                           <TextField fullWidth size='small' margin='normal' label='New Password'
                              value={password} type={showPassword ? 'text' : 'password'}
                              onChange={(e) => { handleChange(setPassword, e.target.value) }}
                              InputProps={{
                                 endAdornment: (
                                    <InputAdornment position='end'>
                                       <IconButton  onClick={() => { setShowPassword(!showPassword) }}>
                                       {showPassword ? <Visibility /> : <VisibilityOff />}
                                       </IconButton>
                                    </InputAdornment>
                                 )
                              }}
                           />
                        </Grid>
                        <Grid item xs={0} sm={2}></Grid>
                        <Grid item xs={12} sm={5}>
                           <TextField fullWidth size='small' margin='normal' label='Confirm'
                              value={confirm} type={showPassword ? 'text' : 'password'}
                              onChange={(e) => { handleChange(setConfirm, e.target.value) }}
                              InputProps={{
                                 endAdornment: (
                                    <InputAdornment position='end'>
                                       <ClickAwayListener onClickAway={() => { setShowInfo(false) }}>
                                          <span>
                                             <Tooltip
                                                PopperProps={{ disablePortal: false }}
                                                onClose={() => { setShowInfo(false) }}
                                                open={showInfo}
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                                placement='top-end'
                                                title='password must be at least 6 characters long.'
                                             > 
                                                <IconButton aria-label='password must be at least 6 characters long.' onClick={() => { setShowInfo(!showInfo) }}>
                                                   <IconInfo />
                                                </IconButton>
                                             </Tooltip>
                                          </span>
                                       </ClickAwayListener>
                                    </InputAdornment>
                                 )
                              }}
                           />                    
                        </Grid>
                        <Grid item xs={12} sm={12}>
                           
                           <div align='center' style={{paddingTop:5}}>
                               <Text variant='body1' style={{ color: 'red' }}>{securityMessage}</Text>
                             </div>
                             <div align='center' style={{paddingTop:20}}>
                               <Button onClick={updatePassword} disabled={disablePasswordButton} variant='outlined' color='secondary'>Save Password</Button>
                             </div>

                        </Grid>  
                     </Grid>
                  </div>
               </Grid> 
            </Grid>
            <div style={{width:'100%', heigh:2, borderBottom: '2px solid #ccc', marginTop:30}}>&nbsp;</div>

         </Paper>
      </div>
   )
}
export default Profile
