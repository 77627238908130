import { useState } from 'react' 
import Loader from '../../../components/Loader'

import Button from '@mui/material/Button'
import Text from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

function TicketPost({ticketId, updateTicket}){
   const [loading, setLoading] = useState(false)
   const [post, setPost] = useState('')
   const [message, setMessage] = useState('')
   const [disabled, setDisabled] = useState(false)
   
   const handleChange = (e) => {
      setMessage('')
      setPost(e.target.value)
   }

   const handleUpdateTicket = () => {
      if(post === ''){
         return setMessage('Please enter your post.')
      }
      setLoading(true)
      setDisabled(true)
      setMessage('Processing...')
      updateTicket(ticketId, post)
      .then(() => {
         setLoading(false)
         setDisabled(false)
         setMessage('')
         setPost('')
      })
      .catch((error) => {
         setLoading(false)
         setDisabled(false)
         setMessage(error.message)
      })
   }

   return (
      <div style={{padding:20, textAlign:'center'}}>
         <Loader loading={loading} /> 
         <TextField
               style={{width:'100%', margin:'20px auto'}}
               id='post'
               label='Post'
               multiline
               rows={4}
               variant='outlined'
               value={post}
                onChange={handleChange}
         />
         <div style={{textAlign:'center'}}><Text variant='overline' style={{color:'red'}}>{message}</Text></div>
         <Button disabled={disabled} onClick={handleUpdateTicket} variant='contained' color='secondary' style={{marginBottom:20}}>Submit</Button>
      </div>
   )
}
export default TicketPost
