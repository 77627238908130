import { isAfter, isBefore } from 'date-fns'

export const selectorFilterEvents = (events, filter) => {
   let filtered = {}
   let current = new Date()

   if (filter === 'upcomming') {
      if (events && Object.keys(events).length) {
         Object.keys(events).forEach(function (i) {
            if(isBefore(current, new Date(events[i].dateTimeEnd))) {
               filtered[i] = events[i]
            }
         })
      }
   }

   if (filter === 'past') {
      if (events && Object.keys(events).length) {
         Object.keys(events).forEach(function (i) {
            if(isAfter(current, new Date(events[i].dateTimeEnd))) {
               filtered[i] = events[i]
            }
         })
      }   	
   }

   return filtered
}
