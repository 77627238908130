import { useEffect, useState } from 'react' 
import { connect } from 'react-redux'
import { makePowertransPayment } from '../../../../redux/memberStuff/actions'

import CcInfo from './CcInfo'
import ReturnedData from './ReturnedData'
import Progress from './Progress'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import IconClose from '@mui/icons-material/Close'
import Text from '@mui/material/Typography'

function FacPaymentWindow({makePowertransPayment, showFacPaymentWindow, setShowFacPaymentWindow, invoiceId, currency, amount}) {
   const [step, setStep] = useState('Processing') // CcInfo, Processing, Returned, Error
   const [showProcessingTransaction, setShowProcessingTransaction] = useState(false)
   const [disableButtons, setDisableButtons] = useState(false)
   const [returnedData, setReturnedData] = useState('')
   const [errorMessage, setErrorMessage] = useState(false)

   useEffect(() => {
      handlePayNow()
   },[])

   const handlePayNow = () => {
      setShowProcessingTransaction(true)
      makePowertransPayment(invoiceId, currency, amount)
      .then(response => {
         //setDisableButtons(true)
         setReturnedData(response)
         setStep('Returned')
      })       
      .catch(e => {
         setDisableButtons(false)
         setShowProcessingTransaction(false)
         setErrorMessage(e.message)
         setStep('Error')                 
      })
   }


// START: These functions are not for HPP and are not being used.
   const handleSubmitCcInfo = (creditCardInfo) => {
      setShowProcessingTransaction(true)
      setStep('Processing')        
      makePowertransPayment(invoiceId, currency, amount, creditCardInfo)
      .then(response => {
         setDisableButtons(true)
         setReturnedData(response)
         setStep('Returned')
      })       
      .catch(e => {
         setDisableButtons(false)
         setShowProcessingTransaction(false)
         setErrorMessage(e.message)
         setStep('Error')                 
      })
   }

   const handleCloseFacPaymentWindow = (event,reason) => {
      if(reason === 'backdropClick') {
         return false
      }   
      setShowFacPaymentWindow(false)
   }
// START: These functions are not for HPP and are not being used.


   return (
      <Dialog open={showFacPaymentWindow} onClose={handleCloseFacPaymentWindow} fullWidth scroll='body' style={{maxWidth:'100%', margin:0}}>
         <DialogTitle>
            <Text style={{color:'#999'}}>Make Payment</Text>
            <IconButton disabled={disableButtons} onClick={handleCloseFacPaymentWindow} sx={{ position: 'absolute', right: 8, top: 8, color: '#999'}}>
               <IconClose />
            </IconButton>
         </DialogTitle >
         <DialogContent style={{padding:5}} dividers>
            {(showProcessingTransaction) ? (
               <Progress />
            ):( null )}

            {(step === 'CcInfo') ? (
               <CcInfo 
                  amount={amount} 
                  currency={currency}
                  handleSubmitCcInfo={handleSubmitCcInfo}
               />
            ):( null )}

            {(step === 'Processing') ? (
               <div style={{textAlign:'center', padding:5, margin:'0 auto', width:250}}>                  
               </div>
            ):( null )}

            {(step === 'Returned') ? (
               <ReturnedData
                  data={returnedData}
               />
            ):( null )}
            
            {(step === 'Error') ? (
               <div style={{paddingLeft:20, paddingTop:50, paddingBottom:50, width:'100%'}}>
                  <Text variant='h6' style={{color:'red'}} gutterBottom>{errorMessage}</Text>
                  <Text variant='body2'><i>Please click CANCEL and try again.</i></Text>
               </div>
            ):( null )}
         </DialogContent>
         <DialogActions>
            <Button disabled={disableButtons} onClick={handleCloseFacPaymentWindow} color='secondary' autoFocus>
               Cancel
            </Button>
         </DialogActions>
      </Dialog>
   )
}
const mapDispatchToProps = {
   makePowertransPayment
}
export default connect(null, mapDispatchToProps)(FacPaymentWindow)
