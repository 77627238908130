import { useEffect, useState } from 'react' 
import { intervalToDuration } from 'date-fns'

import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function Timer({variant, endTime, callback}) {
   const [expiresIn, setExpiresIn] = useState(0)
   let interval

   useEffect(() => {
      interval = setInterval(() => tick(), 1000)
      return () => clearInterval(interval)
   }, [endTime])

   const tick = () => {
      let currentTime = Date.now()
      let difference = endTime - currentTime
      if (difference >= 0) {
         let minutes = intervalToDuration({ start: 0, end: difference }).minutes  
         let seconds = intervalToDuration({ start: 0, end: difference }).seconds 
         let mins = (minutes < 1) ? '' : ((minutes === 1) ? minutes+'min' : minutes+'mins')
         let secs = (seconds === 1) ? seconds+'sec' : seconds+'secs'
         let time = mins +' '+secs
         setExpiresIn(time)
      }else{
         clearInterval(interval)
         setExpiresIn('Expired')
         callback()
      }
   }

   if (variant === 'eventPage') {
      return (
         <Text variant='caption'><i>This order expires in: {expiresIn}</i></Text>
      )    
   }
   if (variant === 'cartInvoices') {
      return (
         <Text variant='caption'><i>Expires in: {expiresIn}</i></Text>
      )    
   }
   if (variant === 'cartPage') {
      return (
         <Paper elevation={0} style={{backgroundColor:'#fff59d', border:'1px solid #ceaa00', marginBottom:10, padding:10, borderRadius:2}}>
            <Text style={{ color:'#ceaa00'}}>
              <i>This Order expires in: <b>{expiresIn}</b></i>
            </Text>
          </Paper>
      )    
   }
   return null
}
export default Timer
