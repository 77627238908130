import { useEffect, useState } from 'react' 

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Text from '@mui/material/Typography'

function LinearProgressWithLabel(props) {
   return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
         <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress variant='determinate' {...props} />
         </Box>
         <Box sx={{ minWidth: 35 }}>
            <Text variant='body2' color='text.secondary'>
               {`${Math.round(props.value,)}%`}
            </Text>
         </Box>
      </Box>
   )
}

function Progress() {
   const [progress, setProgress] = useState(5)

   useEffect(() => {
      const timer = setInterval(() => {
         setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 5))
      }, 4000)
      return () => {
         clearInterval(timer)
      }
   },[])  

   return (
      <div style={{paddingTop:0, paddingBottom:5, backgroundColor:'white'}}>
         <Grid container justifyContent='center' spacing={0}>
            <Grid item xs={12} sm={6}>
               <div style={{textAlign:'center'}}>
                  <Text variant='body2' color='text.secondary'>Processing Transaction</Text>
               </div>
            </Grid>
            <Grid item xs={12} sm={6}>
               <div style={{textAlign:'center'}}>
                  <LinearProgressWithLabel value={progress} />
               </div>
            </Grid>
         </Grid>
      </div>
	)
}
export default Progress		
