export const selectorFilterTeam = (members) => {
	let team = {
		Owner: {
			activeCount: 0,
			pendingCount: 0,
			totalCount: 0,
			active:{},
			pending:{}
		},
		Admin: {
			activeCount: 0,
			pendingCount: 0,
			totalCount: 0,
			active:{},
			pending:{}
		},
		Manager: {
			activeCount: 0,
			pendingCount: 0,
			totalCount: 0,
			active:{},
			pending:{}
		},
		Editor: {
			activeCount: 0,
			pendingCount: 0,
			totalCount: 0,
			active:{},
			pending:{}
		},
		CSR: {
			activeCount: 0,
			pendingCount: 0,
			totalCount: 0,
			active:{},
			pending:{}
		},
		Team_Member: {
			activeCount: 0,
			pendingCount: 0,
			totalCount: 0,
			active:{},
			pending:{}
		},
		Sales_Rep: {
			activeCount: 0,
			pendingCount: 0,
			totalCount: 0,
			active:{},
			pending:{}
		},
      Door_Manager: {
			activeCount: 0,
			pendingCount: 0,
			totalCount: 0,
			active:{},
			pending:{}
		},
		Door_Attendant: {
			activeCount: 0,
			pendingCount: 0,
			totalCount: 0,
			active:{},
			pending:{}
		},
	}

	if (members && Object.keys(members).length) {
      Object.keys(members).forEach(function (i) {
         let role = members[i].roleName
         if (members[i].status === 'active') {
            team[role].activeCount = team[role].activeCount + 1 
				team[role].totalCount = team[role].totalCount + 1
				team[role].active[i] = members[i]
			} else {
				team[role].pendingCount = team[role].pendingCount + 1 
				team[role].totalCount = team[role].totalCount + 1
				team[role].pending[i] = members[i]
         }
      })
   }
   return team
}
