import React from 'react'
import Button from '@mui/material/Button'

function ImagePreview(props) {
    return (
      <div align='center' style={{ maxWidth: 500, margin: '20px auto' }}>
        <div style={{ height: 15 }}/>
        <img
          alt='Preview'
          src={props.image}
          width={props.width}
          height={props.height}
          style={{borderRadius: props.borderRadius}}
        />
        <div style={{ height: 20 }}/>
        <Button variant='outlined' color='secondary' size='small' style={{ width: 250, marginTop: 20 }} onClick={props.changeImage}>
          Change Image
        </Button>
        <br/>
        <Button variant='contained' color='secondary' size='small' style={{ width: 250, marginTop: 20 }} onClick={props.saveImage}>
          Select Image
        </Button>
      </div>
    )
}
export default ImagePreview
