import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function GroupSupportNotes({faqs, notes}){
    return (
        <Paper style={{ padding: 10, minHeight:700 }} >
            <div>
            <Text variant='h5' color='textSecondary'>FAQs</Text>
            <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
               <Text variant='body1' color='textSecondary'>NO FAQs</Text>
            </div> 

            <Text variant='h5' color='textSecondary' style={{marginTop:10}}>Notes</Text>
            <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
               <Text variant='body1' color='textSecondary'>NO NOTES</Text>
            </div> 
         </div>
        </Paper>
    )
}
export default GroupSupportNotes
