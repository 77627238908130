import { APP_LAST_UPDATED, AUTH_CHANGE_LOGIN_OPTION, AUTH_LOAD_LOGIN_OPTIONS, AUTH_LOAD_USER_CREDITS, AUTH_LOGIN_USER, AUTH_LOGOUT_USER, AUTH_USER_INFO } from '../types'
import { monitorApp, stopMonitoringApp, chkExpiredData } from '../app/actions'
import { getAuth, onAuthStateChanged, signInWithEmailLink, signInWithEmailAndPassword, signOut, EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth'
import { getFirestore, doc, onSnapshot } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'

// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// [START]: ALL LISTENERS HERE :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

	// [START]: Auth Listener ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
		let monitorLogin
		let monitorUser
		export function monitorLoginStatus(){
			return (dispatch, getstate, firebase) => {
				const auth = getAuth(firebase)
			   console.log('monitorLoginStatus')
				dispatch(monitorApp())
				monitorLogin = onAuthStateChanged(auth, user => {
					if (user) {
						dispatch({ type: AUTH_LOGIN_USER })
						dispatch(monitorUserInfo(user.uid))
					} else {
						dispatch({ type: AUTH_LOGOUT_USER })
					}
				})
			}
		}
		export function stopMonitoringLoginStatus(){
			return (dispatch) => {
			console.log('stopMonitoringLoginStatus')
				dispatch(stopMonitoringApp())
				monitorLogin && monitorLogin()
				monitorUser && monitorUser()
			}
		}
	// [END]: Auth Listener :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

	// [START]: Member Profile Listener :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
		export function monitorUserInfo(uid) {
			return (dispatch, getstate, firebase) => {  
				const db = getFirestore(firebase)
				const docRef = doc(db, 'members', uid)
            monitorUser = onSnapshot(docRef, querySnapshot => { 
					if (querySnapshot.data()) {
						let data = querySnapshot.data()

						// save userInfo to reducer
						dispatch({ type: AUTH_USER_INFO, payload: data })
						
						// get user roles and load login options.
						dispatch(loadLoginOptions(data))

						// get user credits.
						dispatch(getUserCredits(true))

					}
				}, err => {
					console.log('monitorUserInfo error:', err)
				})
			}
		}
	// [END]: Member Profile Listener :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

// [END]: ALL LISTENERS HERE :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::


// [START]: Login & Logout Process :::::::::::::::::::::::::::::::::::::::::::::::::::::::
// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
	export function logIn(email, password) {
		return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
         const auth = getAuth(firebase)
         signInWithEmailAndPassword(auth, email.toLowerCase(), password)
			.then(() => {
				dispatch({ type: AUTH_LOGIN_USER })  
				resolve()
			})
			.catch((e) => {
	   		reject(e.message)
	      })
		})
	}

   export function logOut(){
		return (dispatch, getstate, firebase) => {
			const auth = getAuth(firebase)
			signOut(auth)
			.then(() => {
				dispatch({ type: AUTH_LOGOUT_USER })   
				// reducers are reset in reducers.js
				// '''''''''''''''''''''''''''''''''   
			}).catch((err) => {
				dispatch({ type: AUTH_LOGOUT_USER })
			})
		}
	}

	export function emailLogIn(email, type, rURL) {
		return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
			const functions = getFunctions(firebase)
			// Setup return link
			let link = 'https://'+window.location.hostname+'/email/confirm/'
			if (window.location.hostname === 'localhost') {
				link = 'http://localhost:3000/email/confirm/'
			}
			if(rURL === ''){
				rURL = '?rURL='
			}
			// Setup actionCodeSettings
			let actionCodeSettings = {
				url: link + type + rURL + '&setup=1',
				handleCodeInApp: true
			}
	      // Send Email
	      const sendEmail = httpsCallable(functions, 'emailLogin')
	      sendEmail({ email:email.toLowerCase(), actionCodeSettings:actionCodeSettings })
	      .then(() => {
	         window.localStorage.setItem('emailForSignIn', email.toLowerCase())
	         resolve('complete')
	      })
	      .catch((e) => {
			   reject(e.message)
			})
		})
	}

	export function emailLogInConfirm(email, url){
		return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
			const auth = getAuth(firebase)
         if (email && url) {
            signInWithEmailLink(auth, email.toLowerCase(), url)
				.then(function(result) {
					window.localStorage.removeItem('emailForSignIn')
					dispatch({ type: AUTH_LOGIN_USER })
					resolve()
				})
				.catch(function(e) {
					reject(e.message)
				})
         } else {
				reject('Please enter your email address')
			}
		})
	}
	export function emailConfirmation(email){
      return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
	      const functions = getFunctions(firebase)
         // Setup return link
			let link = 'https://'+window.location.hostname
			if (window.location.hostname === 'localhost') {
				link = 'http://localhost:3000'
			}
			// Setup actionCodeSettings
			let actionCodeSettings = {
				url: link,
				handleCodeInApp: true
			}

         const submitEmailConfirmation = httpsCallable(functions, 'emailConfirmation')
	      submitEmailConfirmation({email:email.toLowerCase(), actionCodeSettings:actionCodeSettings})
	      .then(() => {
            resolve()
	      })
	      .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error.message)
	      })
      })  	
	}
	export function emailConfirmationConfirm(email, code){
      return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
	      const functions = getFunctions(firebase)
	      const auth = getAuth(firebase)
         const submitEmailConfirmationConfirm = httpsCallable(functions, 'emailConfirmationConfirm')
	      submitEmailConfirmationConfirm({email, code})
	      .then((returned) => {
	      	// -----
	      	let link = returned.data
            signInWithEmailLink(auth, email.toLowerCase(), link)
				.then(function(result) {
					dispatch({ type: AUTH_LOGIN_USER })
					resolve()
				})
				.catch(function(e) {
					reject(e.message)
				})
				// -----
	      })
         .catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error.message)
	      })
      }) 
	}	
// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::	
// [END]: Login & Logout Process :::::::::::::::::::::::::::::::::::::::::::::::::::::::::

// [START]: Update Profile Functions :::::::::::::::::::::::::::::::::::::::::::::::::::::
// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
	export function updateProfileInfo(userInfo) {
		return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {    
		   const functions = getFunctions(firebase)
         const updateProfile = httpsCallable(functions, 'updateUserProfile')
			updateProfile({ userInfo:userInfo })
			.then(() => {
			  resolve('success')
			})
			.catch((e) => {
	      	let error = {title:'Error',message:''}
	      	try { error = JSON.parse(e.message) } 
	      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	      	reject(error)
	      })
		})
	}
	export function updateProfilePassword(oldPassword, newPassword) {
		return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {
			const auth = getAuth(firebase)
			// Reauthenticate user with current password.
			if (oldPassword !== '') {
				try {
					const userEmail = getstate().auth.userInfo.email
               const credentials = EmailAuthProvider.credential(userEmail, oldPassword)			
               await reauthenticateWithCredential(auth.currentUser, credentials)
				}
				catch (error) {
					if (error.message === 'The password is invalid or the user does not have a password.') {
						return reject('The Current Password you entered is invalid, please try again or login through your email.')
					} else {
						return reject('Error authenticating your account, please re-enter your current password or login through your email then set your password.')    
					}
				}
			} 
         // Set new password
			if(newPassword){
				updatePassword(auth.currentUser, newPassword)
				.then(function() {
					resolve('Password updated')
				})
				.catch(function(error) {
					if (error.code === 'auth/requires-recent-login') {
						reject('Please enter your Current Password.')    
					} else {
						reject('Sorry, there was an error: ', error.message)  
					}
				})
			} else {
				reject('Please enter your password.')
			}  
		})
	}
// ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// [END]: Update Profile Functions ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

	export function loadLoginOptions(user) {
		return (dispatch, getstate, firebase) => {
         // Create USER login option
			   let options = []
			   let data = []
			   let roles
				let userLogin = {
					login: 'member',
					id: user.id,
					name: user.name,
					role: '',
					roleId: 0,
					thumb: user.image,
					initials: user.initials        
				}
				options.push(userLogin)				
			// Check Server for user roles	
				const functions = getFunctions(firebase)
            const loadRoles = httpsCallable(functions, 'fetchUserLoginOptions')
		      loadRoles()
		      .then((returned) => {
		      	data = returned.data
		      })
		      .catch((e) => {
		      	console.log('loadLoginOptions Error:', e.message)
		      })
		      .finally(() => {
		      	roles = [...options, ...data]
		      	dispatch({ type: AUTH_LOAD_LOGIN_OPTIONS, payload: roles })
		      }) 
		}
	}

	export function changeOption(option) {
		return (dispatch, getstate) => {
			let optionsCount = getstate().auth.loginOptions.length
			if (option >= optionsCount) {
				option = 0
			}
			dispatch({ type: AUTH_CHANGE_LOGIN_OPTION, payload: option })
    	}
	}

	export function getUserCredits(force=false){
		return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) { 
		   const chkIfExpired = dispatch(chkExpiredData(force, 'paymentsCredits'))
	     	chkIfExpired
	     	.then((currentTime) => {
	     	// ---------------------------------------------------------------------------  
		      const fetchCredits = firebase.functions().httpsCallable('fetchUserCredits')
		      fetchCredits()
		      .then((response) => {
		      	dispatch({ type: AUTH_LOAD_USER_CREDITS, payload: response.data })
		      	dispatch({ type: APP_LAST_UPDATED, payload:{key:'paymentsCredits', value:currentTime} })
		      	resolve()
		      })
		      .catch((e) => {
		      	let error = {title:'Error',message:''}
		      	try { error = JSON.parse(e.message) } 
		      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
		      	reject(error)
		      }) 
		   // ---------------------------------------------------------------------------
	     	})
	     	.catch(() => {
	         // Current data still good so do nothing.
	         resolve()
	     	})
		})
	}

