import { useState, useEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { emailLogInConfirm } from '../../redux/auth/actions'
import { chkEmail }  from '../../includes/functions'

import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

function EmailConfirm({ emailLogInConfirm }) {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [btnText, setBtnText] = useState('Confirm')
  const [btnDisabled, setBtnDisabled] = useState(false)  
  let location = useLocation() 

  {useEffect(() => {
    logInUser()
  }, [])}

  const handleChange = (f,v) => {
    f(v)
    setMessage('')
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    if (!chkEmail(email)) {
      return setMessage('Please enter your email address to confirm.')
    }
    logInUser()
  }
  const logInUser = () => {
    // check storage for user email address
    let userEmail = (window.localStorage.getItem('emailForSignIn')) ? window.localStorage.getItem('emailForSignIn') : ''

    // if user email supplied use it
    if (email) {
      userEmail = email  
    }

    // confirm user login
    setBtnText('Processing...')
    setBtnDisabled(true)
    emailLogInConfirm(userEmail, window.location.href)
    .catch((err) => {
      setBtnText('Confirm')
      setBtnDisabled(false)
      setMessage(err)
    })    
  }

  return (
    <Container maxWidth='xl' style={{ padding: 10, marginBottom: 150, minHeight:700, marginTop:80 }}>
      <Grid container justifyContent='center' spacing={2}>
        <Grid item xs={12} sm={5}>
          <Paper style={{ padding: 20 }} >
            <Text variant='h5' gutterBottom>CONFIRM EMAIL ADDRESS</Text>
            <Text variant='caption' component='p'>Enter your email address to confirm</Text>
            <Text style={{ marginTop: 10, color: 'red' }} variant='caption' component='p' align='center'>{ message }</Text>
            <form onSubmit={ handleSubmit }>
              <TextField
                type='email'
                id='email'
                label='Email'
                placeholder='Enter your email address'
                margin='normal'
                fullWidth={true}
                onChange={(e) => {handleChange(setEmail, e.target.value) }}
              />
              <Button disabled={ btnDisabled } variant='contained' color='primary'  type='submit'>
                { btnText }
              </Button>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
const mapDispatchToProps = {
  emailLogInConfirm
}
export default connect(null, mapDispatchToProps)(EmailConfirm)
