import { useEffect, useState } from 'react' 
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import format from 'date-fns/format'

import Loader from '../../components/Loader'
import TicketAssignTo from './components/TicketAssignTo'
import TicketClose from './components/TicketClose'
import TicketNotes from './components/TicketNotes'
import TicketPost from './components/TicketPost'

import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import IconArrowBack from '@mui/icons-material/ArrowBackIos'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function GroupSupportTicket({memberId, roleId, tickets, team, updateTicket, closeTicket, addTicketNote, assignTicket, archiveTicket}){
   const statusColor = {'unassigned':'#999', 'open':'#27e007', 'closed':'red', 'archived':'#000'}
   const [loading, setLoading] = useState(false)
   const [searchParams] = useSearchParams()
   const ticketId = searchParams.get('id') 
   const [ticket, setTicket] = useState(null)
   const [selectedTab, setSelectedTab] = useState('open')
   const navigate = useNavigate()

   useEffect(() => {
      let ticket = tickets[ticketId]
      if(!ticket){ goToList() }
      let selected = (ticket.status === 'closed') ? 'closed' : 'open'
      setTicket(ticket)
      setSelectedTab(selected)   
   },[ticketId])

   const goToList = () => {
      navigate('/group/support/' + selectedTab)
   }

   const handleArchive = () => {
      if(ticket.status === 'closed'){
         setLoading(true)
         archiveTicket(ticketId)
         .then(() => {
            setLoading(false)
            goToList()
         })
         .catch((error) => {
            setLoading(false)
            Swal.fire({
               title: error.title,
               text: error.message,
               confirmButtonColor: '#3085d6',
               confirmButtonText: 'Ok'
            })
         })
      }
   }
   
   if (!ticket) return null
   return (
      <Paper style={{ padding: 10, minHeight:700 }} >
         <Loader loading={loading} />
         <div align='right' style={{marginTop:5}} >
            <Button onClick={goToList}>
               <IconArrowBack /> Back to List
            </Button>
         </div>

         <Paper style={{marginTop:5, padding: 5, backgroundColor:'#fafafa'}} >
            <List>
               <ListItem>
                  <ListItemAvatar>
                     <Avatar alt={ticket.memberName} src={ticket.memberImage} style={{borderRadius:5}} />
                  </ListItemAvatar>
                  <ListItemText>
                     <Text variant='h6'>{ticket.memberName}</Text>
                     <Text style={{lineHeight:.8}} variant='caption' display='block' color='textSecondary'>
                        Submitted on: { format(new Date(ticket.timestamp), 'eee do MMM y - h:mm a')}
                     </Text>
                     <Text style={{marginTop:5, lineHeight:1, color:'#546e7a'}}><b>{ticket.subject}</b></Text>
                  </ListItemText>
                  <ListItemSecondaryAction>
                     <Text style={{lineHeight:1.5, color:statusColor[ticket.status]}}><b>{ticket.status.toUpperCase()}</b></Text>
                  </ListItemSecondaryAction>
               </ListItem>
            </List>
         </Paper>

         <Paper style={{marginTop:20, padding: 5, backgroundColor:'#fafafa'}} >
            <List>
               {Object.keys(ticket.thread).map((i, key) => {
                  let t = ticket.thread[i]
                  return (
                     <div key={key}>
                        <ListItem alignItems="flex-start" style={{paddingBottom:50}}>
                           <ListItemAvatar>
                              <Avatar alt={t.postedByName} src={t.postedByThumb} style={{borderRadius:5}} />
                           </ListItemAvatar>
                           <ListItemText>
                              {t.postedByName}
                              <Text style={{lineHeight:.8}} variant='caption' display='block' color='textSecondary'>
                                 { format(new Date(t.timestamp), 'eee do MMM y - h:mm a')}
                              </Text>
                              <Text style={{marginTop:15, lineHeight:1.25, color:'#546e7a', whiteSpace:'pre-wrap'}}>{t.comment}</Text>
                           </ListItemText>
                        </ListItem>
                        <Divider/>
                     </div>
                  )
               })}

               {(ticket.status === 'open' && ticket.assignedToId === memberId) ? (
                  <div>
                     <TicketPost ticketId={ticketId} updateTicket={updateTicket}/>
                     <TicketClose ticketId={ticketId} closeTicket={closeTicket}/>
                  </div>
               ):( null )}
               
               {(ticket.status === 'closed' || ticket.status === 'archived') ? (
                  <div align='center'style={{padding:10}}><Text variant='h6'>Ticket Closed by {ticket.closedByName}</Text></div>
               ):( null )}
            </List>
         </Paper>

         {(ticket.notes) ? (
            <TicketNotes ticketId={ticketId} notes={ticket.notes} addTicketNote={addTicketNote} status={ticket.status}/>
         ):( null )}   

         {(ticket.status === 'unassigned' || ticket.status === 'open') ? (
            <TicketAssignTo ticketId={ticketId} ticket={ticket} team={team} assignTicket={assignTicket}/>
         ):( null )}   

         {(ticket.status === 'closed' && roleId <= 2) ? (
            <div align='center' style={{padding:10}}><Button onClick={handleArchive}>Archive this Ticket</Button></div>
         ):( null )}

      </Paper>
   )
}
export default GroupSupportTicket
