import Alert from '@mui/material/Alert'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function Plugins(){
   return (
      <Paper style={{ padding: 10, minHeight:700 }} >
         <div>
            <Alert severity='info'>
            Plugins are paid add-ons that groups can subscribe to.
            </Alert>
         </div>
      
         <div style={{marginTop:20}}>
            <Text variant='h5' color='textSecondary'>Website Plugin</Text>
            <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
               <Text variant='body1' color='textSecondary'>
                 group single page Website free for first 3 months with discount code. 
               </Text>
            </div>
         </div>   
      </Paper>
   )
}
export default Plugins
