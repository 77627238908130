import { useState } from 'react'
import Swal from 'sweetalert2'
import Loader from '../../../components/Loader'

import Button from '@mui/material/Button'
import Text from '@mui/material/Typography'

function TicketClose({ticketId, closeTicket}){
   const [loading, setLoading] = useState(false)
   const [message, setMessage] = useState('')
   const [disabled, setDisabled] = useState(false)
   const [closeText, setCloseText] = useState('Close this Ticket')   

   const handleCloseTicket = () => {
      Swal.fire({
         title: 'Close Ticket',
         text: 'Are you sure you want to close this ticket?',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Yes, close it!'
      }).then((result) => {
         if (result.value) {
            setLoading(true)
            setDisabled(true)
            setCloseText('Closing Ticket...')
            closeTicket(ticketId)
            .then(() => {
               setLoading(false)
               setDisabled(false)
            })
            .catch((error) => {
               setLoading(false)
               setDisabled(false)
               setMessage(error.message)
            })
         }
      })   
   } 
   return (
      <div style={{margin:5,textAlign:'right'}}>
         <Loader loading={loading} />
         <div style={{textAlign:'center'}}><Text variant="overline" style={{color:'red'}}>{message}</Text></div>
         <Button onClick={handleCloseTicket} disabled={disabled}>{closeText}</Button>
      </div>
   )
}
export default TicketClose
