import { useEffect, useState } from 'react' 
import { Routes, Route, Navigate, Outlet, Link, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { setShowMessage, loadGroupEvents, createEvent } from '../../../redux/groupEvents/actions'
import { selectorFilterEvents } from '../../../redux/groupEvents/selectors'

import AccessDenied from '../../components/AccessDenied'
import Info from './Info'
import List from './List'
import NewEvent from './NewEvent'
import NextUpdate from '../../components/NextUpdate'
import SubNav from '../../components/SubNav'

import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

function GroupEventsContainer({lastUpdated, roleId, showMessage, upcommingEvents, pastEvents, setShowMessage, loadGroupEvents, createEvent}) {
   const whoHasAccess = [1]
   const [loading, setLoading] = useState(true)   
   const [showInfo, setShowInfo] = useState(false)
   const [activeTab, setActiveTab] = useState(false) 
   const location = useLocation()

   useEffect(() => {
      switch (true) {
         case location.pathname.includes('/group/events/upcomming'):
            setActiveTab(0)
         break
         case location.pathname.includes('/group/events/past'):
            setActiveTab(1)
         break
         case location.pathname.includes('/group/events/new'):
            setActiveTab(2)
         break
         default:
            setActiveTab(false)
      }
      handleLoadGroupEvents()
   },[location])

   const handleLoadGroupEvents = (force) => {
      setLoading(true)
      loadGroupEvents(force) 
      .finally(() => {
         setLoading(false)
      }) 
   }

   if (!roleId) return null
   if(!whoHasAccess.includes(roleId)) {
      return (<div style={{height:500}}><AccessDenied page='Events'/></div>)
   }

   return (
      <Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
         <Info showInfo={showInfo} setShowInfo={setShowInfo} />
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} >
               <SubNav text='Events' showInfo={true} setShowInfo={setShowInfo}/>
               <div style={{borderBottom: '2px solid #999'}}>
                  <Tabs value={activeTab} variant='fullWidth' indicatorColor='primary' textColor='primary'>
                     <Tab label='Upcomming' style={{ maxWidth:200 }} component={Link} to='/group/events/upcomming'/>
                     <Tab label='Past' style={{ maxWidth:200 }} component={Link} to='/group/events/past' />
                     <Tab label='New' style={{ maxWidth:200 }} component={Link} to='/group/events/new' />
                  </Tabs>
               </div>
               <Collapse in={loading}> 
                  <Alert severity='warning'>
                     Retrieving Group Events ...
                     <LinearProgress color='inherit' />   
                  </Alert> 
               </Collapse>
               <div>
                  <Routes>
                     <Route path='/upcomming' element={<List 
                        selectedTab='upcomming'
                        events={upcommingEvents}
                        showMessage={showMessage}
                        setShowMessage={setShowMessage}
                     />} />
                     <Route path='/past' element={<List 
                        selectedTab='past'
                        events={pastEvents} 
                        showMessage={showMessage}
                        setShowMessage={setShowMessage}                  
                     />} />
                     <Route path='/new' element={<NewEvent
                        createEvent={createEvent}
                     />} />
                     <Route path='*' element={<Navigate to='/group/events/upcomming' />} />
                  </Routes>
                  <Outlet />
               </div>
            </Grid>            
         </Grid> 
         <NextUpdate lastUpdated={lastUpdated} updateNow={handleLoadGroupEvents}/>
      </Container>  
   )
}
const mapStateToProps = (state) => ({
   roleId: state.auth.loginOptions[state.auth.loginOptionID].roleId,
   lastUpdated: state.app.lastUpdated.groupEvents,
   showMessage: state.groupEvents.showMessage,
   upcommingEvents: selectorFilterEvents(state.groupEvents.events, 'upcomming'),
   pastEvents: selectorFilterEvents(state.groupEvents.events, 'past')
})
const mapDispatchToProps = {
   setShowMessage,
   loadGroupEvents,
   createEvent
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupEventsContainer)
