import { useEffect, useState } from 'react' 
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { format } from 'date-fns'
import { money }  from '../../../../includes/functions'

import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Divider from '@mui/material/Divider'
import IconErrorOutline from '@mui/icons-material/ErrorOutline'
import IconSuccessOutlined from '@mui/icons-material/CheckCircleOutlineOutlined'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Text from '@mui/material/Typography'

function PaymentHistory({invoiceId, history, updateErrorStatus}) {
   const [records, setRecords] = useState([])
   const [acknowledged, setAcknowledged] = useState(false)


   useEffect(() => {
      if (history && Object.values(history).length) {
         let recs = Object.values(history)   
         let filtered = recs.sort((a, b) => a.dateTime - b.dateTime)
         setRecords(filtered)
      }
   },[history])

   const handleAcknowledge = (paymentId, title, text) => {
      Swal.fire({
         icon: 'warning',
         title: title,
         text: text,
         showCancelButton: false,
         allowOutsideClick: false,
         confirmButtonColor: '#ab47bc',
         confirmButtonText: 'I understand.'
      }).then(() => {
         updateErrorStatus(invoiceId, paymentId)
      })  
   } 

   if(!records.length) return null
   return ( 
      <Card elevation={0} style={{border: '1px solid #cfd8dc', borderRadius:0, marginTop:20}}>
         <CardHeader title='Payment History' titleTypographyProps={{variant: 'h6'}} style={{padding:'10px 15px 10px 15px'}}/>
         <Divider variant='middle' style={{backgroundColor: '#cfd8dc'}} />
         <CardContent style={{padding: 0}}>              
            <List style={{margin:0}}>
               {records.map((rec,i) => {
                  if (rec.status === 'error') {
                     if (!rec.errorAcknowledged && !acknowledged) {
                        handleAcknowledge(rec.paymentId, rec.errorReason, rec.errorMessage)
                        setAcknowledged(true)
                     }
                     return (
                        <div key={i}>
                           <ListItem>
                              <ListItemAvatar>
                                 <Avatar style={{backgroundColor:'#e53935'}}>
                                    <IconErrorOutline/>
                                 </Avatar>
                           </ListItemAvatar>
                              <ListItemText>
                                 <Text variant='caption'><i>{format(rec.dateTime, 'EEE do MMM, yyyy - h:mm a')}</i></Text>
                                 <Text style={{color:'#e53935'}}><b>{rec.errorReason}</b></Text>
                                 <Text style={{color:'#546e7a'}} variant='body2'>{rec.errorMessage}</Text>
                                 <Text style={{color:'#546e7a'}} variant='caption'><i>[ID: {rec.paymentId}]</i></Text>
                              </ListItemText>
                           </ListItem>
                           <Divider component='li' variant='middle' style={{backgroundColor: '#cfd8dc'}} />
                        </div>
                     )
                  } else {
                     return (
                        <div key={i}>
                           <ListItem>
                              <ListItemAvatar>
                                 <Avatar style={{backgroundColor:'#4caf50'}}>
                                    <IconSuccessOutlined/>
                                 </Avatar>
                           </ListItemAvatar>
                              <ListItemText>
                                 <Text variant='caption'><i>{format(rec.dateTime, 'EEE do MMM, yyyy - h:mm a')}</i></Text>
                                 <Text style={{color:'#4caf50'}}><b>PAYMENT COMPLETE</b></Text>
                                 <Text>Invoice Total: <b>{money(rec.paymentAmount, rec.paymentCurrency, false, true)}</b></Text>
                                 <Text style={{color:'#546e7a'}} variant='body2'>Processing Fee: {money(rec.paymentFee, rec.paymentCurrency, false, true)}</Text>
                                 <Text style={{color:'#4caf50'}}>Total Payment: <b>{money(rec.paymentTotal, rec.paymentCurrency, false, true)}</b></Text>
                                 <Divider style={{margin:5}}/>
                                 <Text variant='caption' color='textSecondary' style={{display:'block', lineHeight:1.5}}>
                                    <i>{rec.paymentMethod} ({rec.paymentMethodId})</i><br/>
                                    <i>[ID: {rec.paymentId}]</i><br/>
                                    <i>Via {rec.merchantId}</i>
                                 </Text>
                                 <Divider style={{margin:5}}/>
                                 <div align='right'>
                                    <Button size='small' component={Link} to='/member/stuff/tickets' variant='outlined' style={{color:'#4caf50'}}>View Tickets</Button>
                                 </div>
                              </ListItemText>
                           </ListItem>
                           <Divider component='li' variant='middle' style={{backgroundColor: '#cfd8dc'}} />
                        </div>
                     )
                  }
               })}
            </List>
         </CardContent>
      </Card>
   )
}
export default PaymentHistory
