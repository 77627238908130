import { forwardRef, useEffect, useState } from 'react'
import QRCode from 'qrcode'
import JsBarcode from 'jsbarcode'
import { createCanvas } from 'canvas'
import { format } from 'date-fns'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import Slide from '@mui/material/Slide'
import Text from '@mui/material/Typography'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

function ViewTicket({selectedItem,open,handleClose}){
   const [qrSrc, setQrSrc] = useState(null)
   const [bcSrc, setBcSrc] = useState(null)

   useEffect(() => {
      handleUpdateImages()
   }, [selectedItem])

   const handleUpdateImages = () => {
      let encoded = selectedItem.code
      QRCode.toDataURL(encoded, function (err, url) {
         setQrSrc(url)
      })
      const canvas = createCanvas()
      JsBarcode(canvas, encoded, {
         format: 'CODE128',
         width:1,
         height:40,
         fontSize: 15,
         displayValue: false 
      })
      setBcSrc(canvas.toDataURL())
   }

   return (
      <Dialog open={open} onClose={() => { handleClose(false) }} fullWidth={true} maxWidth='xs' TransitionComponent={Transition} keepMounted>
         <DialogTitle >SecureTix</DialogTitle>
         <DialogContent style={{ textAlign: 'center'}}>
            <Text variant='h5'>{selectedItem.eventName}</Text>          
            <Text variant='body1' color='textSecondary'><i>{format(selectedItem.eventDateTimeStart, 'EEE do MMM, yyyy - h:mm a')}</i></Text> 
            <img alt={'SecureTix id: ' + selectedItem.code} src={qrSrc} style={{ width:'100%', maxWidth: 250, border: '1px solid #cccccc'}} /><br />
            <Text variant='body1'>{selectedItem.code}</Text>
            <Text variant='h4' gutterBottom>{selectedItem.ownerName}</Text>            
            <Divider color='black'/>
            <Text variant='h6' color='textSecondary' gutterBottom>{selectedItem.ticketName}</Text>
            <Divider/>
            <img alt={'SecureTix id: ' + selectedItem.code} src={bcSrc} style={{ width:'100%', maxWidth: 270 }} /><br />           
            <Text variant='body2' color='textSecondary' style={{lineHeight:1.1}}>
               <i>{selectedItem.eventVenueName}<br/>
               {selectedItem.eventVenueAddress}</i>
            </Text>            
         </DialogContent>

         <DialogActions>
            <Button onClick={() => { handleClose(false) }} color='primary'>Close</Button>
         </DialogActions>
      </Dialog>
   )
}
export default ViewTicket
