import { APP_LAST_UPDATED, MEMBER_SUPPORT_LOAD, MEMBER_SUPPORT_ADD_TICKET, MEMBER_SUPPORT_UPDATE_TICKETS } from '../types'
import { chkExpiredData } from '../app/actions'
import { getFunctions, httpsCallable } from 'firebase/functions'

export function loadMemberSupport(force=false) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {    
	   const chkIfExpired = dispatch(chkExpiredData(force, 'memberSupport'))
     	chkIfExpired
     	.then((currentTime) => {
     	// ---------------------------------------------------------------------------    		
			const functions = getFunctions(firebase)
         const loadSupport = httpsCallable(functions, 'memberFetchSupport')
	      loadSupport()
	      .then((returned) => {
				let payload = {
      			supportCategories: returned.data.supportCategories,
					supportGroups: returned.data.supportGroups,
					supportTickets: returned.data.supportTickets
				}
				dispatch({type: MEMBER_SUPPORT_LOAD, payload: payload})
				dispatch({ type: APP_LAST_UPDATED, payload:{key:'memberSupport', value:currentTime} })
		      resolve()
		   })
		   .catch((e) => {
		    	let error = {title:'Error',message:''}
		     	try { error = JSON.parse(e.message) } 
		     	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
		     	reject(error)
		   })
		   // ---------------------------------------------------------------------------
	   })
	   .catch(() => {
	      // Current data still good so do nothing.
	      resolve()
	   })
	})
}

export function openTicket(ticket) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
		const functions = getFunctions(firebase)
		const ticketOpen = httpsCallable(functions, 'memberOpenSupportTicket')
      ticketOpen({ ticket })
      .then((returned) => {
         let payload = {
				id: returned.data.id,
				info: returned.data.info
			}
			dispatch({type: MEMBER_SUPPORT_ADD_TICKET, payload: payload})
      	resolve()
      })
      .catch((e) => {
      	let error = {title:'Error',message:''}
      	try { error = JSON.parse(e.message) } 
      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
      	reject(error)
      })
	})
}

export function updateTicket(ticketId, post) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
		const functions = getFunctions(firebase)
		const ticketUpdate = httpsCallable(functions, 'memberUpdateSupportTicket')
      ticketUpdate({ ticketId, post })
      .then((returned) => {
       	let ticket = getstate().memberSupport.supportTickets[ticketId]
      	ticket.thread[returned.data.recId] = returned.data.recInfo
         let payload = {
				id: ticketId,
				info: ticket
			}
			dispatch({type: MEMBER_SUPPORT_ADD_TICKET, payload: payload})
      	resolve()
      })
      .catch((e) => {
      	let error = {title:'Error',message:''}
      	try { error = JSON.parse(e.message) } 
      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
      	reject(error)
      })
	})
}

export function closeTicket(ticketId) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
		const functions = getFunctions(firebase)
		const ticketClose = httpsCallable(functions, 'memberCloseSupportTicket')
      ticketClose({ ticketId })
      .then((returned) => {
      	let user = getstate().auth.userInfo
      	let ticket = getstate().memberSupport.supportTickets[ticketId]
      	ticket.status = 'closed'
      	ticket.closed = true
      	ticket.closedById = user.id
			ticket.closedByName = user.name
      	ticket.closedTimestamp = Date.now()
         let payload = {
				id: ticketId,
				info: ticket
			}
			dispatch({type: MEMBER_SUPPORT_ADD_TICKET, payload: payload})
      	resolve()
      })
      .catch((e) => {
      	let error = {title:'Error',message:''}
      	try { error = JSON.parse(e.message) } 
      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
      	reject(error)
      })
	})
}

export function deleteTicket(ticketId) {
	return (dispatch, getstate, firebase) => new Promise(function (resolve, reject) {
		const functions = getFunctions(firebase)
		const ticketDelete = httpsCallable(functions, 'memberDeleteSupportTicket')
      ticketDelete({ ticketId })
      .then((returned) => {
      	let tickets = getstate().memberSupport.supportTickets
			delete tickets[ticketId]
			dispatch({type: MEMBER_SUPPORT_UPDATE_TICKETS, payload: tickets})
      	resolve()
      })
      .catch((e) => {
      	let error = {title:'Error',message:''}
      	try { error = JSON.parse(e.message) } 
      	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
      	reject(error)
      })
	})
}	
