import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import IdCard from './IdCard'
import Scanner from './Scanner'

import Box from '@mui/material/Box'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import IconID from '@mui/icons-material/Portrait'
import IconDashboard from '@mui/icons-material/Dashboard'
import IconQRScan from '@mui/icons-material/CropFree'

function BottomNav({ selectedOption }) {
   const [value, setValue] = useState(0)
   const [openId, setOpenId] = useState(false)
   const [openScanner, setOpenScanner] = useState(false)
   const location = useLocation()
   const navigate = useNavigate()

   useEffect(() => {
      handleTabCheck()
   }, [location])

   const handleTabCheck = () => {
   	let dashboardLink = (selectedOption.login === 'member') ? '/member/account/summary' : '/group/dashboard'
      if (location.pathname.includes('/member/account/summary') || location.pathname.includes('/group/dashboard')) {
      	setValue(1)
      } else {
      	setValue(false)
      }
   } 
   const handleTabClick = (value) => {
   	if (value === 0) {
   		setOpenId(true)
   	} else if (value === 1 && selectedOption.login === 'member') {
         navigate('/member/account/summary')
   	} else if (value === 1 && selectedOption.login === 'group') {
         navigate('/group/dashboard')  
      } else if (value === 2) {
      	setOpenScanner(true)
      }
   	setValue(value)
   }
   const handleCloseId = () => {
      setOpenId(false)
      setValue(false)
      handleTabCheck()
   }
   const handleCloseScanner = () => {
   	setOpenScanner(false)
      setValue(false)
      handleTabCheck()
   }

   if(location.pathname.startsWith('/member') || location.pathname.startsWith('/group')) {
      return (
          <Box sx={{ display: { md: 'none', sm: 'block' } }} >
             {(openId) ? (<IdCard selectedOption={selectedOption} open={openId} handleClose={handleCloseId} />):(null)}
             {(openScanner) ? (<Scanner selectedOption={selectedOption} open={openScanner} handleClose={handleCloseScanner} />):(null)}
            <BottomNavigation value={value} onChange={(event, newValue) => { handleTabClick(newValue) }} showLabels style={{ position: 'fixed', bottom: 0, width: '100%' }} >
               <BottomNavigationAction label='ID Card' icon={<IconID />} />
               <BottomNavigationAction label='Dashboard' icon={<IconDashboard />} />
               <BottomNavigationAction label='QR Scanner' icon={<IconQRScan />} />
            </BottomNavigation>
         </Box>
      )
   } else {
      return ( null )
   }
}
export default BottomNav
