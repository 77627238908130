import { useEffect, useState } from 'react' 
import format from 'date-fns/format'

import NextUpdate from '../../components/NextUpdate'

import Alert from '@mui/material/Alert'
import Avatar from '@mui/material/Avatar'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import LinearProgress from '@mui/material/LinearProgress'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function Activity({lastUpdated, activity, loadActivity}) {
   const [loading, setLoading] = useState(false) 

   useEffect(() => {
      handleFetchMemberActivity()
   },[])

   const handleFetchMemberActivity = (force) => {
      setLoading(true)
      loadActivity(force) 
      .finally(() => {
         setLoading(false)
      })
   }
	return (
      <div>
   		<Paper style={{ padding: 10, minHeight:700 }} >
            <Collapse in={loading}> 
               <Alert severity='warning' style={{marginBottom:20}}>
                  Updating Your Activity ...
                  <LinearProgress color='inherit' />   
               </Alert> 
            </Collapse>
	         {(activity.length) ? (
	            <List style={{padding:5, maxWidth:600, margin:'0 auto'}}>
	               {activity.map((activity, key) => {
	                  return (
	                     <div key={key}>
	                        <ListItem alignItems='flex-start'>
	                           <ListItemAvatar>
	                              <Avatar alt={activity.icon} src={'/images/icons/' + activity.icon + '.jpg'} style={{borderRadius:5}} />
	                           </ListItemAvatar>
	                           <ListItemText>
	                              <Text variant='subtitle1' style={{ lineHeight:1.1}}><b>{activity.title}</b></Text>
	                              <Text variant='body2'>{activity.message}</Text>
	                              <Text variant='caption' display='block' color='textSecondary'>
	                                 On: { format(new Date(activity.timestamp), 'eee do MMM y - h:mm a')}
	                              </Text>
	                           </ListItemText>
	                        </ListItem>
	                        <Divider variant='inset' component='li' />
	                     </div>
	                  )
	               })}
	            </List>
	         ) : (
	            <div style={{marginTop:20}}>
                  <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
                     <Text variant='body1' color='textSecondary'>
                        No Activity Recorded
                     </Text>
                  </div>
               </div> 
	         )} 
         </Paper>
         <NextUpdate lastUpdated={lastUpdated} updateNow={handleFetchMemberActivity}/>
      </div>
   )
}
export default Activity
