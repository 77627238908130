import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function Solutions() {
  return (
    <div>
      <Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} >
               <Paper style={{ padding: 10, minHeight:700 }} >
                  <div style={{textAlign:'center'}}>
                     <Text variant='h4'>
                        Solutions & Features
                     </Text> 
                     <Text variant='subtitle1' gutterBottom>
                        Solutions followed by Features
                     </Text> 
                  </div>  
               </Paper>
            </Grid>
         </Grid>
      </Container>
    </div>
  );
}
export default Solutions