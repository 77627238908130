import { useState } from 'react'
import { useTheme, useMediaQuery } from '@mui/material'
import ImagePreview from './ImagePreview'
import ImageEditor from './ImageEditor'

import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Toolbar from '@mui/material/Toolbar'
import Text from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import ImageIcon from '@mui/icons-material/CropOriginal'
import CloseIcon from '@mui/icons-material/Close'

function AddImage({ cb, openUi, closeUi, borderRadius, defaultImage, ratio }) {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const [step, setStep] = useState('default')
    const [image, setImage] = useState(null)
    const [width, setWidth] = useState(null)
    const [height, setHeight] = useState(null)
    let view

    const handlePreviewStep = (image, width, height) => {
        setStep('preview')
        setImage(image)
        setWidth(width)
        setHeight(height)
    }
    const handleChangeImage = () => {
        setStep('default')
        setImage(null)
        setWidth(null)
        setHeight(null)
    }
    const handleSaveImage = () => {
        handleChangeImage()
        cb(image)
        closeUi()
    }

    return (
        <Dialog 
            open={ openUi } 
            fullScreen={ fullScreen } 
            scroll = { (fullScreen) ? 'paper' : 'body' } 
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                  closeUi(event, reason)
                }
            }}
        >
            <AppBar style={{ position: 'relative' }} >
                <Toolbar>
                    <ImageIcon style={{ marginRight: 5 }} />
                    <Text variant='subtitle2' color='inherit' style={{ flex: 1 }}>Image Upload</Text>
                    <IconButton color='inherit' onClick={closeUi}><CloseIcon /></IconButton>
                </Toolbar>
            </AppBar>
                <DialogContent>
                    {(step === 'preview') ? (
<ImagePreview
                image={ image }
                width={ width }
                height={ height }
                borderRadius={ borderRadius }
                changeImage={ handleChangeImage }
                saveImage={ handleSaveImage }
            />
                    ) : (

<ImageEditor
                defaultImage={ defaultImage }
                ratio={ ratio }
                borderRadius={ borderRadius }
                handlePreviewStep={ handlePreviewStep }
            />

                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={ closeUi } variant='contained'>Cancel</Button>
                    <Button onClick={ closeUi } variant='contained' color='primary'>Close</Button>
                </DialogActions>
        </Dialog>
    )
}
export default AddImage
