import Text from '@mui/material/Typography'

function Refunds() {
   return (
      <div>
         <div style={{textAlign:'center', marginTop:10}}><Text variant='h4'>Refund Policy</Text></div>
         <div> 
            <Text variant='body1' style={{marginTop:15}}><b>Refund Policy</b></Text> 
            <Text variant='body2'>
               There are no refunds or exchanges on eTickets. Except for that listed in the Cancellation Policy.
             </Text>

             <Text variant='body1' style={{marginTop:15}}><b>Cancellation Policy</b></Text>
             <Text variant='body2'>
               If the event is cancelled, we will refund the amount you paid for your tickets (minus all processing fees) using the same payment method used for purchase. If the event is postponed, the new date and time will be posted and all tickets would be honored.
             </Text>
             <Text variant='body2'>
               The event promoter reserves the right to refuse entry to any ticket holder for any reason at their discretion. Venue and Talent are subject to change without notice and are not grounds for a refund. Anything advertised by the promoter is not guaranteed by SecureTix.com and is not grounds for refund.
             </Text>

         </div>
      </div>
   )
}
export default Refunds