import { GROUP_EVENTS_LOAD, GROUP_EVENTS_RESET_REDUCER, GROUP_EVENTS_SHOW_MESSAGE, GROUP_EVENTS_ADD_EVENT } from '../types'

const initialState = {
	groupId: null,
	showMessage: true,
	events: {}
}

export default function reducer(state = initialState, action) {
  	switch (action.type) {
  	case GROUP_EVENTS_SHOW_MESSAGE:
         return { 
				...state,
				showMessage: action.payload
			}	
		case GROUP_EVENTS_LOAD:
         return { 
				...state,
				events: {
					...state.events,
					...action.payload
				}
			}
		case GROUP_EVENTS_ADD_EVENT:
			return { 
				...state, 
				events: {
               ...state.events,
               [action.payload.id]: action.payload.event
            }
			}    
		case GROUP_EVENTS_RESET_REDUCER:
			return { 
				...state,
	         groupId: action.payload,
	         events: {}
			}
		default:
			return state
	}
}
