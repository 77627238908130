import { APP_LAST_UPDATED, GROUP_EVENT_LOAD, GROUP_EVENT_UPDATE, GROUP_EVENT_RESET_REDUCER, GROUP_EVENTS_ADD_EVENT } from '../types'
import { chkExpiredData, groupStatusCheck } from '../app/actions'
import { getFunctions, httpsCallable } from 'firebase/functions'

export function loadEvent(eventId) {
	return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {   
      const user = await dispatch(groupStatusCheck('groupEvent', GROUP_EVENT_RESET_REDUCER))  
      const teamMemberId = user.teamMemberId   
      const groupId = user.groupId
  
	   const functions = getFunctions(firebase)
	   const eventLoad = httpsCallable(functions, 'groupFetchEventDetails')
		eventLoad({ teamMemberId, groupId, eventId })
		.then((returned) => {
			dispatch({ type: GROUP_EVENT_LOAD, payload: returned.data }) 
			resolve()		  
		})
		.catch((e) => {
	   	let error = {title:'Error',message:''}
	   	try { error = JSON.parse(e.message) } 
	   	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	   	reject(error)
	   })
	})
}

export function editEventDetails(eventId, eventDetails) {
	return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {   
      const user = await dispatch(groupStatusCheck('groupEvent', GROUP_EVENT_RESET_REDUCER))  
      const teamMemberId = user.teamMemberId   
      const groupId = user.groupId

	   const functions = getFunctions(firebase)
	   const eventUpdateDetails = httpsCallable(functions, 'groupUpdateEventDetails')
		eventUpdateDetails({ teamMemberId, groupId, eventId, eventDetails })
		.then((returned) => {
			dispatch({ type: GROUP_EVENT_UPDATE, payload: eventDetails }) 
			resolve()		  
		})
		.catch((e) => {
	   	let error = {title:'Error',message:''}
	   	try { error = JSON.parse(e.message) } 
	   	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	   	reject(error)
	   })   
	})
}

export function editEventStatus(eventId, option, status) {
	return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {   
      const user = await dispatch(groupStatusCheck('groupEvent', GROUP_EVENT_RESET_REDUCER))  
      const teamMemberId = user.teamMemberId   
      const groupId = user.groupId

	   const functions = getFunctions(firebase)
	   const eventUpdateStatus = httpsCallable(functions, 'groupUpdateEventStatus')
		eventUpdateStatus({ teamMemberId, groupId, eventId, option, status })
		.then((returned) => {
			let update = {[option]:status}
			dispatch({ type: GROUP_EVENT_UPDATE, payload: update })

			// update status in events list here 
      if(option === 'published') {
         let event = getstate().groupEvents.events[eventId]
         event.published = status
         let payload = { id:eventId, event:event }
         dispatch({ type: GROUP_EVENTS_ADD_EVENT, payload: payload })
      }
			resolve()		  
		})
		.catch((e) => {
	   	let error = {title:'Error',message:''}
	   	try { error = JSON.parse(e.message) } 
	   	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	   	reject(error)
	   })   
	})
}

export function ticketTypeAddEdit(eventId, eventTicket) {
	return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {   
      const user = await dispatch(groupStatusCheck('groupEvent', GROUP_EVENT_RESET_REDUCER))  
      const teamMemberId = user.teamMemberId   
      const groupId = user.groupId

	   const functions = getFunctions(firebase)
	   const eventAddEditTicket = httpsCallable(functions, 'groupTicketTypeAddEdit')
		eventAddEditTicket({ teamMemberId, groupId, eventId, eventTicket })
		.then((returned) => {
			let ticket = returned.data
			let event = getstate().groupEvent.event
			if(!event.hasOwnProperty('tickets')){
            event.tickets = {}				
			}
         event.tickets[ticket.ticketId] = ticket  
			dispatch({ type: GROUP_EVENT_UPDATE, payload: event }) 
			resolve()		  
		})
		.catch((e) => {
	   	let error = {title:'Error',message:''}
	   	try { error = JSON.parse(e.message) } 
	   	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	   	reject(error)
	   })   

	})
}

export function ticketTypeCopyDelete(action, ticketId) {
	return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {   
      const user = await dispatch(groupStatusCheck('groupEvent', GROUP_EVENT_RESET_REDUCER))  
      const teamMemberId = user.teamMemberId   
      const groupId = user.groupId

      const functions = getFunctions(firebase)
	   const eventCopyDeleteTicket = httpsCallable(functions, 'groupTicketTypeCopyDelete')
		eventCopyDeleteTicket({ teamMemberId, groupId, action, ticketId })
		.then((returned) => {
			let event = getstate().groupEvent.event
		   let tickets = event.tickets
		   if(action === 'Copy') {
				let ticket = returned.data
				tickets[ticket.ticketId] = ticket  
		   } else {
            delete tickets[ticketId]		   	
		   }
		   dispatch({ type: GROUP_EVENT_UPDATE, payload: tickets }) 
		   resolve()	  
		})
		.catch((e) => {
	   	let error = {title:'Error',message:''}
	   	try { error = JSON.parse(e.message) } 
	   	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	   	reject(error)
	   })   
	})
}

export function editEventImages(eventId, profileImage, headerImage) {
	return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {   
      const user = await dispatch(groupStatusCheck('groupEvent', GROUP_EVENT_RESET_REDUCER))  
      const teamMemberId = user.teamMemberId   
      const groupId = user.groupId

	   const functions = getFunctions(firebase)
	   const eventUpdateImages = httpsCallable(functions, 'groupUpdateEventImages')
		eventUpdateImages({ teamMemberId, groupId, eventId, profileImage, headerImage })
		.then((returned) => {
			console.log('profileURL',returned.data.profileURL)
		   let images = {
		   	profileImage: returned.data.profileURL, 
		   	headerImage: returned.data.headerURL
		   }
         dispatch({ type: GROUP_EVENT_UPDATE, payload: images }) 
			
        // update profile image in events list 
	      let event = getstate().groupEvents.events[eventId]
	      event.profileImage = returned.data.profileURL
	      let payload = { id:eventId, event:event }
	      dispatch({ type: GROUP_EVENTS_ADD_EVENT, payload: payload })
	      resolve()		  
		})
		.catch((e) => {
	   	let error = {title:'Error',message:''}
	   	try { error = JSON.parse(e.message) } 
	   	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	   	reject(error)
	   })
	})
}

export function editEventRegistration(eventId, registration) {
	return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {   
      const user = await dispatch(groupStatusCheck('groupEvent', GROUP_EVENT_RESET_REDUCER))  
      const teamMemberId = user.teamMemberId   
      const groupId = user.groupId

	   const functions = getFunctions(firebase)
	   const eventRegistration = httpsCallable(functions, 'groupUpdateEventRegistration')
		eventRegistration({ teamMemberId, groupId, eventId, registration })
		.then((returned) => { //
			let updatedRegistration = returned.data
			let event = getstate().groupEvent.event
         event.registration = updatedRegistration  
         
         if(!event.hasOwnProperty('registrationForms')){
            event.registrationForms = {}				
			}
         event.registrationForms[updatedRegistration.id] = {
            id: updatedRegistration.id,
            name: updatedRegistration.name,
            description: updatedRegistration.description
         }
			dispatch({ type: GROUP_EVENT_UPDATE, payload: event }) 
			resolve()		  
		})
		.catch((e) => {
	   	let error = {title:'Error',message:''}
	   	try { error = JSON.parse(e.message) } 
	   	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	   	reject(error)
	   }) 
	})
}
export function removeEventRegistration(eventId, registrationFormId) {
	return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {   
      const user = await dispatch(groupStatusCheck('groupEvent', GROUP_EVENT_RESET_REDUCER))  
      const teamMemberId = user.teamMemberId   
      const groupId = user.groupId

	   const functions = getFunctions(firebase)
	   const eventRegistration = httpsCallable(functions, 'groupRemoveEventRegistration')
		eventRegistration({ teamMemberId, groupId, eventId, registrationFormId })
		.then((returned) => { //
			let event = getstate().groupEvent.event
         delete event.registration
			dispatch({ type: GROUP_EVENT_UPDATE, payload: event }) 
			resolve()		  
		})
		.catch((e) => {
	   	let error = {title:'Error',message:''}
	   	try { error = JSON.parse(e.message) } 
	   	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	   	reject(error)
	   }) 
	})
}
export function fetchEventRegistration(registrationFormId) {
	return (dispatch, getstate, firebase) => new Promise(async function (resolve, reject) {   
      const user = await dispatch(groupStatusCheck('groupEvent', GROUP_EVENT_RESET_REDUCER))  
      const teamMemberId = user.teamMemberId   
      const groupId = user.groupId

	   const functions = getFunctions(firebase)
	   const eventRegistration = httpsCallable(functions, 'groupFetchEventRegistration')
		eventRegistration({ teamMemberId, groupId, registrationFormId })
		.then((returned) => { 
			resolve(returned.data)		  
		})
		.catch((e) => {
	   	let error = {title:'Error',message:''}
	   	try { error = JSON.parse(e.message) } 
	   	catch (err) { error.message = (e.message === 'internal') ? 'Error connecting to server.' : e.message }
	   	reject(error)
	   }) 
	})
}

// *********************************************************************
// *********************************************************************
// *********************************************************************

//groupTicketTypeCopyDelete
//groupTicketTypeAddEdit



 


