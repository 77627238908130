import { useEffect, useState } from 'react' 
import ServiceAndPolicies from '../../components/Legal/Index'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'

function CartConfirm({loggedIn, setPaymentDisabled}) {
   const [option1, setOption1] = useState(false)
   const [option2, setOption2] = useState(true)
   const [option3, setOption3] = useState(false)

   useEffect(() => {
      if(option1 && option2 && option3) {
          setPaymentDisabled(false)
      } else {
          setPaymentDisabled(true)
      }
   },[option1,option2,option3])

   if (!loggedIn) { return null }
   return ( 
      <Card elevation={0} style={{border: '1px solid #cfd8dc', borderRadius:0, marginBottom:20}}>
         <CardHeader title='Confirm Purchase' subheader='(Read and check to acknowledge)' titleTypographyProps={{variant: 'h6'}} style={{padding:'10px 15px 10px 15px'}}/>
         <Divider variant='middle' style={{backgroundColor: '#cfd8dc'}} />
         <List>
            <ListItem key='1' dense>
               <Checkbox
                  checked={option1}
                  onChange={() => {setOption1(!option1)}}
                  value='option1'
                  color='primary'
                  tabIndex={1}                      
               />
               <ListItemText>
                  Please enter the correct name on each eTicket, ID may be required at point of entry.
               </ListItemText>
            </ListItem>
         {/*
            <Divider variant='middle' style={{backgroundColor: '#cfd8dc'}} />
            <ListItem key='2' dense>
               <Checkbox
                  checked={option2}
                  onChange={() => {setOption2(!option2)}}
                  value='option2'
                  color='primary'
                  tabIndex={2}                      
               />
               <ListItemText>
                  Please enter the correct email address for each eTicket. All eTickets would be sent to the purchaser (YOUR) email address.
               </ListItemText>
            </ListItem>
         */}
            <Divider variant='middle' style={{backgroundColor: '#cfd8dc'}} />
            <ListItem key='3' dense>
               <Checkbox
                  checked={option3}
                  onChange={() => {setOption3(!option3)}}
                  value='option3'
                  color='primary'
                  tabIndex={3}                      
               />
               <ListItemText style={{ display: 'inline-flex' }}>
                 I agree with the <ServiceAndPolicies tab={0} text={2} /> and <ServiceAndPolicies tab={1} text={3} />. I also understand that eTickets are not refundable except under certain circumstances listed in the <ServiceAndPolicies tab={2} text={4} />.
               </ListItemText>
            </ListItem>
         </List>   
      </Card>
   )
}
export default CartConfirm
