import { useEffect, useState } from 'react' 
import { Routes, Route, Navigate, Outlet, Link, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { setShowMessage, loadGroupAccounts } from '../../../redux/groupAccounts/actions'

import AccessDenied from '../../components/AccessDenied'
import Info from './Info'
import NextUpdate from '../../components/NextUpdate'
import Payins from './Payins'
import Payouts from './Payouts'
import SubNav from '../../components/SubNav'
import Summary from './Summary'

import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

function GroupAccountsContainer({roleId, lastUpdated, showMessage, setShowMessage}) {
   const whoHasAccess = [1]
   const [loading, setLoading] = useState(true)   
   const [showInfo, setShowInfo] = useState(false)
   const [activeTab, setActiveTab] = useState(false) 
   const location = useLocation()

   useEffect(() => {
      switch (true) {
         case location.pathname.includes('/group/accounts/summary'):
            setActiveTab(0)
         break
         case location.pathname.includes('/group/accounts/payins'):
            setActiveTab(1)
         break
         case location.pathname.includes('/group/accounts/payouts'):
            setActiveTab(2)
         break
         default:
            setActiveTab(false)
      }
      handleLoadGroupAccounts()
   },[location])

   const handleLoadGroupAccounts = (force) => {
      console.log('loading group accounts')
      setLoading(false)
   }

   if (!roleId) return null
   if(!whoHasAccess.includes(roleId)) {
      return (<div style={{height:500}}><AccessDenied page='Accounts'/></div>)
   }

   return (
      <Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
         <Info showInfo={showInfo} setShowInfo={setShowInfo} />
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} >
               <SubNav text='Accounts' showInfo={true} setShowInfo={setShowInfo}/>
               <div style={{borderBottom: '2px solid #eee'}}>
                  <Tabs value={activeTab} variant='fullWidth' indicatorColor='primary' textColor='primary'
                     variant='scrollable' scrollButtons={false}>
                     <Tab label='Summary' style={{ maxWidth:200 }} component={Link} to='/group/accounts/summary'/>
                     <Tab label='Payins' style={{ maxWidth:200 }} component={Link} to='/group/accounts/payins' />
                     <Tab label='Payouts' style={{ maxWidth:200 }} component={Link} to='/group/accounts/payouts' />
                  </Tabs>
               </div>
               <Collapse in={loading}> 
                  <Alert severity='warning'>
                     Retrieving Group Accounts ...
                     <LinearProgress color='inherit' />   
                  </Alert> 
               </Collapse>
               <div>
                  <Routes>
                     <Route path='/summary' element={<Summary />} />
                     <Route path='/payins' element={<Payins />} />
                     <Route path='/payouts' element={<Payouts />} />
                     <Route path='*' element={<Navigate to='/group/accounts/summary' />} />
                  </Routes>
                  <Outlet />
               </div>
            </Grid>            
         </Grid> 
         <NextUpdate lastUpdated={lastUpdated} updateNow={handleLoadGroupAccounts}/>
      </Container>          
   )
}
const mapStateToProps = (state) => ({
   roleId: state.auth.loginOptions[state.auth.loginOptionID].roleId,
   lastUpdated: state.app.lastUpdated.groupAccounts,
   showMessage: state.groupAccounts.showMessage
})
const mapDispatchToProps = {
   setShowMessage, 
   loadGroupAccounts
}
export default connect(mapStateToProps, mapDispatchToProps)(GroupAccountsContainer)
