import { useEffect, useState } from 'react' 
import { connect } from 'react-redux'
import { Routes, Route, Navigate, Outlet, Link, useLocation, useParams } from 'react-router-dom'
import { updateProfileInfo, updateProfilePassword, loadNotifications, loadActivity } from '../../../redux/memberProfile/actions'

import Activity from './Activity'
import Info from './info'
import Notifications from './Notifications'
import Profile from './Profile'
import SubNav from '../../components/SubNav'

import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

function MemberProfileContainer({lastUpdatedNotifications, lastUpdatedActivity, profile, notifications, activity, updateProfileInfo, updateProfilePassword, loadNotifications, loadActivity}) {
   const [showInfo, setShowInfo] = useState(false)
   const [activeTab, setActiveTab] = useState(false) 
   let location = useLocation()

   useEffect(() => {
      switch (true) {
         case location.pathname.includes('/member/profile/profile'):
            setActiveTab(0)
         break
         case location.pathname.includes('/member/profile/notifications'):
            setActiveTab(1)
         break
         case location.pathname.includes('/member/profile/activity'):
            setActiveTab(2)
         break
         default:
            setActiveTab(false)
      }
   },[location])

   return (
      <Container maxWidth='lg' style={{ padding: 10, marginBottom:50 }}>
         <Info showInfo={showInfo} setShowInfo={setShowInfo} />
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} >
               <SubNav text='My Profile' showInfo={false} setShowInfo={setShowInfo}/>
               <div style={{borderBottom: '2px solid #eee'}}>
                  <Tabs value={activeTab} variant='fullWidth' indicatorColor='primary' textColor='primary'>
                     <Tab label='Profile' style={{ maxWidth:200 }} component={Link} to='/member/profile/profile'/>
                     <Tab label='Notifications' style={{ maxWidth:200 }} component={Link} to='/member/profile/notifications' />
                     <Tab label='Activity' style={{ maxWidth:200 }} component={Link} to='/member/profile/activity' />
                  </Tabs>
               </div>
               <div>
                  <Routes>
                     <Route path='/profile' element={<Profile 
                        profile={profile}
                        updateProfileInfo={updateProfileInfo}
                        updateProfilePassword={updateProfilePassword}
                     />} />
                     <Route path='/notifications' element={<Notifications 
                        lastUpdated={lastUpdatedNotifications}
                        notifications={notifications}
                        loadNotifications={loadNotifications}
                     />} />
                     <Route path='/activity' element={<Activity 
                        lastUpdated={lastUpdatedActivity} 
                        activity={activity}
                        loadActivity={loadActivity}
                     />} />
                     <Route path='*' element={<Navigate to='/member/profile/profile' />} />
                  </Routes>
                  <Outlet />
               </div>
            </Grid>
         </Grid>
      </Container>
   )
}
const mapStateToProps = (state) => ({
   lastUpdatedNotifications: state.app.lastUpdated.memberNotifications || 0,
   lastUpdatedActivity: state.app.lastUpdated.memberActivity || 0,
   profile: state.auth.userInfo || null,
   notifications: state.memberProfile.notifications || [],
   activity: state.memberProfile.activity || []
})
const mapDispatchToProps = {
   updateProfileInfo, 
   updateProfilePassword,
   loadNotifications, 
   loadActivity
}
export default connect(mapStateToProps, mapDispatchToProps)(MemberProfileContainer)
