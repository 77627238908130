import { useEffect, useState } from 'react' 
import { connect } from 'react-redux'
import { Routes, Route, Outlet, Link, useLocation, Navigate } from 'react-router-dom'
import { openTicket, loadMemberSupport, updateTicket, closeTicket, deleteTicket } from '../../../redux/memberSupport/actions'
import { filterOptions } from '../../../redux/memberSupport/selectors'

import Details from './Details'
import Info from './info'
import List from './List'
import NewTicket from './NewTicket'
import NextUpdate from '../../components/NextUpdate'
import SubNav from '../../components/SubNav'

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import IconFilterList from '@mui/icons-material/FilterList'
import LinearProgress from '@mui/material/LinearProgress'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'

function MemberSupportContainer({ lastUpdated, tickets, groups, categories, selectorFilterOptions, openTicket, loadMemberSupport, updateTicket, closeTicket, deleteTicket }) {
   const [loading, setLoading] = useState(false) 
   const [showInfo, setShowInfo] = useState(false)
   const [activeTab, setActiveTab] = useState(false) 
   const [anchorEl, setAnchorEl] = useState(null) 
   const [filterOptions, setFilterOptions] = useState(selectorFilterOptions)
   const [filterSelected, setFilterSelected] = useState('All')
   const location = useLocation()

   useEffect(() => {
      switch (true) {
         case location.pathname.includes('/member/support/new'):
            setActiveTab(0)
         break
         case location.pathname.includes('/member/support/tickets'):
            setActiveTab(1)
         break
         default:
            setActiveTab(false)
      }
   },[location])

   useEffect(() => {
      handleFetchSupport()
   },[])

   const handleFetchSupport = (force) => {
      setLoading(true)
      loadMemberSupport(force) 
      .finally(() => {
         setLoading(false)
      })
   }
   const handleChangeFilter = (value) => {
      setAnchorEl(null)
      setFilterSelected(value)
   }
   return (
      <Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
			<Info showInfo={showInfo} setShowInfo={setShowInfo} />
         <Grid container justifyContent='center' spacing={2}>
				<Grid item xs={12} sm={12} md={10} >
               <SubNav text='Support Desk' showInfo={false} setShowInfo={setShowInfo}/>
               <div style={{display: 'flex', borderBottom: '2px solid #eee'}}>
                  <Box justifyContent='bottom' sx={{flex: 1}}>
                     <Tabs value={activeTab} variant='fullWidth' indicatorColor='primary' textColor='primary'>
                        <Tab label='New Ticket' style={{ maxWidth:200 }} component={Link} to='/member/support/new'/>
                        <Tab label='My Tickets' style={{ maxWidth:200 }} component={Link} to='/member/support/tickets' />
                     </Tabs>
                  </Box>
                  <Box justifyContent='bottom' display='flex'>
                     <Button onClick={ (e) => { setAnchorEl(e.currentTarget) }} size='small' sx={{ color: 'gray' }}><IconFilterList/>{(filterSelected === 'All') ? 'filter' : 'filtered'}</Button>
                     <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => { setAnchorEl(null) }} dense='true'>
                        {filterOptions.map((i,index) => {
                           return (
                              <MenuItem key={index} selected={(filterSelected === i)}  onClick={() => {handleChangeFilter(i)}}>{i}</MenuItem>
                           )
                        })}
                     </Menu>             
                  </Box>
               </div>           
               <Collapse in={loading}> 
                  <Alert severity='warning'>
                     Retrieving Support Tickets ...
                     <LinearProgress color='inherit' />   
                  </Alert> 
               </Collapse>
               <Routes>
                  <Route path='/new' element={<NewTicket 
                     categories={categories} 
                     groups={groups} 
                     openTicket={openTicket} 
                  />} /> 
                  <Route path='/tickets' element={<List 
                     tickets={tickets}
                     filterSelected={filterSelected}
                     handleChangeFilter={handleChangeFilter}
                     lastUpdated={lastUpdated} 
                     loadMemberSupport={loadMemberSupport}
                  />} />                  
                  <Route path='/details' element={<Details 
                     tickets={tickets}
                     updateTicket={updateTicket}
                     closeTicket={closeTicket}
                     deleteTicket={deleteTicket}
                  />} />
                  <Route path='*' element={<Navigate to='/member/support/new' />} />
               </Routes>
               <Outlet />
               <NextUpdate lastUpdated={lastUpdated} updateNow={handleFetchSupport}/>
            </Grid>
			</Grid>
		</Container>
   )
}
const mapStateToProps = (state) => ({
   lastUpdated: state.app.lastUpdated.memberSupport,
   tickets: state.memberSupport.supportTickets,
   groups: state.memberSupport.supportGroups,
   categories: state.memberSupport.supportCategories,
   selectorFilterOptions: filterOptions(state.memberSupport.supportTickets)
})
const mapDispatchToProps = {
   openTicket, 
	loadMemberSupport, 
   updateTicket, 
   closeTicket,
   deleteTicket
}
export default connect(mapStateToProps, mapDispatchToProps)(MemberSupportContainer)
