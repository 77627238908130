import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { logIn } from '../../redux/auth/actions'
import { chkEmail, chkPassword }  from '../../includes/functions'
import ServiceAndPolicies from '../components/Legal/Index'

import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

function Login({ logIn }) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false) 
  const [message, setMessage] = useState('')
  const [btnText, setBtnText] = useState('login')
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [checked, setChecked] = useState(false)
  let location = useLocation()
  let navigate = useNavigate()

  const handleChange = (f,v) => {
    f(v)
    setMessage('')
  }
  const handleCheckboxToggle = () => {
    setChecked(!checked)
  }
  const handleClickLink = (link) => {  
    navigate(link, {state: location.state})
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    if (!chkEmail(email)) {
      return setMessage('Sorry, please enter a valid email address.')
    }
    if (!chkPassword(password)) {
      return setMessage('Sorry, your password is invalid.')
    }
    if (!checked) {
      return setMessage('Sorry, please agree with the TOS (Terms of Service)')
    }
    setBtnText('logging In')
    setBtnDisabled(true)
    logIn(email.toLowerCase(), password)
    .catch((err) => {
      setBtnText('Login')
      setBtnDisabled(false)
      setMessage(err)
    })
  }
  return (
      <Container maxWidth='xl' style={{ padding: 10, marginBottom: 150, minHeight:400, marginTop:80 }}>
        <Grid container justifyContent='center' spacing={2}>
          <Grid item xs={12} sm={5}>
            <Paper style={{ padding: 20 }} >
              <Text variant='h5' gutterBottom>
                LOGIN
              </Text>
              <Text variant='caption' component='p'>
                Login to your SecureTix account.
              </Text>
              <Text style={{ marginTop: 10, color: 'red', height: 15 }} variant='caption' component='p' align='center'>
                { message }
              </Text>
              <form onSubmit={handleSubmit}>
                <TextField
                  type='email'
                  id='email'
                  label='Email'
                  placeholder='Enter your email address'
                  margin='normal'
                  fullWidth={true}
                  onChange={(e) => {handleChange(setEmail, e.target.value) }}
                />
                <TextField
                  type={showPassword ? 'text' : 'password'}
                  id='password'
                  label='Password'
                  placeholder='Enter your password'
                  margin='normal'
                  fullWidth={true}
                  onChange={(e) => {handleChange(setPassword, e.target.value) }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                      <IconButton  onClick={() => { setShowPassword(!showPassword) }}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <Checkbox checked={checked} onChange={handleCheckboxToggle} color='primary' />
                <ServiceAndPolicies tab={0} text={0} />
                <div style={{ height: 15 }} />
                <Button disabled={ btnDisabled } variant='contained' color='primary'  type='submit'>{ btnText }</Button>
                <div style={{ height: 20 }} />
                <Button style={{marginRight:25}} size='small' color='primary' onClick={() => { handleClickLink('/email/login') }}>
                  Login through Email
                </Button>
                <Button size='small' color='primary' onClick={() => { handleClickLink('/email/register') }}>
                  Register a New Account
                </Button>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Container>
  )
}
const mapDispatchToProps = {
  logIn
}
export default connect(null, mapDispatchToProps)(Login)
