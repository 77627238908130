import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Text from '@mui/material/Typography'

function Step4({name, summary, type, headerImage, profileImage, handleCreateGroup, handleBack}){
   return (
   	<div>
   		<div align='center' style={{ maxWidth: 440, margin: '0 auto', marginBottom: 30, borderBottom: '1px solid #ccc' }}>
				<img src={headerImage} style={{ width: '100%' }} alt='' /><br />
				<Text variant='caption' style={{color: '#a7a8a7'}}>
				  	<i>This is the header image for your group.</i>
				</Text>  
			</div>
   		<div>
				<Card>
					<CardContent style={{display: 'flex',padding: 5}}>
						<span><img src={profileImage} style={{width: 75, height: 75, verticalAlign: 'top', marginRight: 10, border: '1px solid #ccc', borderRadius: 7}} alt="Logo" /></span>
						<span>
							<b>{name}</b>
							<Text variant="caption" display="block" color="textSecondary" ><b>Group Type:</b> {type}</Text>
							<Text variant="caption" display="block" color="textSecondary" ><b>Status:</b> Approved</Text>
							<Text variant="caption" display="block" color="textSecondary" ><b>Role:</b> Owner</Text>
						</span>
					</CardContent>
				</Card>
				<div align='right'>
				 	<Text variant='caption' style={{color: '#a7a8a7'}}>
						<i>Group profile card.</i>
					</Text>
				</div> 
   		</div>
		  	<div align='center' style={{ padding: 30 }}>
				<Button fullWidth style={{ maxWidth: 400, marginBottom: 15 }} variant='outlined' color='primary' onClick={handleBack}>Edit Group Info</Button>
				<Button fullWidth style={{ maxWidth: 400, marginBottom: 15 }} variant='contained' color='primary' onClick={handleCreateGroup}>Save & Create Group</Button>
			</div>
   	</div>
   )
}
export default Step4
