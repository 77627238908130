import { useNavigate, useSearchParams } from 'react-router-dom'

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const pop = searchParams.get('pop') || 0
    
    return (
      <Component navigate={navigate} pop={pop} {...props} />
    )
  }
  return Wrapper
}