import { useLocation, Link } from 'react-router-dom'

import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Text from '@mui/material/Typography'

function PublicFooter() {
   return (
      <div style={{backgroundColor:'#37474f'}}>
         <Container maxWidth='lg' style={{minHeight:150, paddingBottom:50}}>
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={4} md={4}>
               <Stack justifyContent='flex-start' alignItems='flex-start' style={{margin:8, marginBottom:10}}>
                  <img alt='visa/mastercard' src='/images/cardLogos.png' style={{width:'100%', maxWidth:300}} />
               </Stack>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
               <Stack justifyContent='flex-start' alignItems='flex-start' style={{marginBottom:10}}>
               {/*
                  <Button size='small' color='inherit' style={{color:'white'}} component={Link} to='/aboutus'>About Us</Button>
                  <Button size='small' color='inherit' style={{color:'white'}} component={Link} to='/solutions'>Solutions & Features</Button>
                  <Button size='small' color='inherit' style={{color:'white'}} component={Link} to='/faqs'>FAQs &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>
                  <Button size='small' color='inherit' style={{color:'white'}} component={Link} to='/blog'>Blog &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Button>
                  <Button size='small' color='inherit' style={{color:'white'}} component={Link} to='/start'>Sell Tickets</Button>
               */}
                  <Button size='small' color='inherit' style={{color:'white'}} component={Link} to='/delivery-policy'>Delivery Policy</Button>
                  <Button size='small' color='inherit' style={{color:'white'}} component={Link} to='/security-policy'>Security Policy</Button>
                  <Button size='small' color='inherit' style={{color:'white'}} component={Link} to='/legal?page=refunds'>Refund Policy</Button>
               </Stack>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
               <Stack justifyContent='flex-start' alignItems='flex-start' style={{marginBottom:30}}>
                  <Button size='small' color='inherit' style={{color:'white'}} component={Link} to='/legal?page=terms'>Terms of Service</Button>
                  <Button size='small' color='inherit' style={{color:'white'}} component={Link} to='/legal?page=privacy'>Privacy Policy</Button>
                  <Button size='small' color='inherit' style={{color:'white'}} component={Link} to='/legal?page=fees'>Fees & Charges</Button>
                  {/*   
                  <Button size='small' color='inherit' style={{color:'white'}} component={Link} to='/branding'>Logo & Branding</Button>
                  */}
               </Stack>            
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
               <div style={{textAlign:'center', color:'#fff'}}>
                  <Text variant='caption'> 
                    © 2023 Online Solutions Limited, All rights reserved<br/>
                    67 Petra Street, Port of Spain, Trinidad & Tobago<br/>
                    Support@SecureTix.com
                  </Text>
               </div>
            </Grid>
         </Grid>
         </Container>
      </div>
   )
}

function PrivateFooter({ selectedOption }) {
  return (
    <div></div>
    /*
    <div style={{ backgroundColor:'#f1f1f1', paddingBottom:60 }}>
      <Container maxWidth='lg' style={{ padding: 10 }}>
        <Grid container justifyContent='center' spacing={2}>
          <Grid item xs={12} sm={12} md={10} style={{ textAlign:'right' }} >
            <Text variant='caption' color='textSecondary'><i>Copyright 2022 SecureTix.com</i></Text>
          </Grid>
        </Grid>    
    </Container>
    </div>
    */
  )
}

function Footer({ selectedOption }) {
  let location = useLocation()
  if(location.pathname.startsWith('/member') || location.pathname.startsWith('/group')) {
    return (
      <PrivateFooter selectedOption={selectedOption} />
    ) 
  } else {
    return (
      <PublicFooter />
    )    
  }
}
export default Footer
