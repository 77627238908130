import { useState } from 'react'
import swal from 'sweetalert2'
import Loader from '../../components/Loader'
import { chkPassword }  from '../../../includes/functions'

import Button from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Info from '@mui/icons-material/InfoOutlined'
import InputAdornment from '@mui/material/InputAdornment'
import Text from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

function Password({showPwBox, handleShowPwBox, updateProfilePassword}) {
   const [loading, setLoading] = useState(false)
   const [getOldPassword, setGetOldPassword] = useState(false)
   const [oldPassword, setOldPassword] = useState('')
   const [showPassword, setShowPassword] = useState(false) 
   const [showInfo, setShowInfo] = useState(false)
   const [password, setPassword] = useState('')
   const [confirm, setConfirm] = useState('')
   const [message, setMessage] = useState('')

   const handleChange = (f,v) => {
      f(v)
      setMessage('')
   }
	const closeDialog = () => {
		setMessage('')
		setOldPassword('')
		setPassword('')
		setConfirm('')		
      setGetOldPassword(false)
		handleShowPwBox(false)
	}
   const savePassword = () => {
      if (getOldPassword && !chkPassword(oldPassword)) {
        return setMessage('Please enter your Current Password.')
      }
      if (!chkPassword(password)) {
        return setMessage('Please enter a valid Password.')
      }
      if (password !== confirm) {
        return setMessage('Password and Confirm must match.')
      }
      setLoading(true)
      updateProfilePassword(oldPassword, password)
      .then(() => {
         setLoading(false)
         swal.fire({
				position: 'top-end',
				icon: 'success',
				title: 'Password Updated',
				showConfirmButton: false,
				timer: 2000
			})
			closeDialog()
      })
      .catch((error) => {
         setLoading(false)
         setMessage(error)
         if (error === 'Please enter your Current Password.') {
           setGetOldPassword(true)
         }
      })
   }
   return (
      <Dialog style={{zIndex:10}} scroll='body' open={showPwBox} 
       	onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
               closeDialog(event, reason)
            }
         }}
      >
	      <Loader loading={loading} title='Profile Update' text='Updating your password...'/>
			<DialogTitle style={{paddingBottom:0}}>Set Password</DialogTitle>
			<DialogContent>
				{(getOldPassword) ? (
					<div>
						<DialogContentText style={{margin:0}}>
						To set your password you must either enter your current password or re-login through your email.
						</DialogContentText>
					</div>
				) : (
					<DialogContentText style={{margin:0}}>
					Set an optional password to log into your account. If not you can always login to your account 
					through your email.
					</DialogContentText>
				)}
				<form>
					<Grid container justify='center'>
						{(getOldPassword) ? (
			            <Grid item xs={12} sm={12} style={{ padding: 5 }}>
			              <TextField fullWidth size='small' margin='normal' label='Current Password'
			                  type={showPassword ? 'text' : 'password'}
			                  value={oldPassword}
			                  onChange={(e) => { handleChange(setOldPassword, e.target.value) }}
			                  InputProps={{
			                     endAdornment: (
			                        <InputAdornment position='end'>
			                           <IconButton  onClick={() => { setShowPassword(!showPassword) }}>
			                           {showPassword ? <Visibility /> : <VisibilityOff />}
			                           </IconButton>
			                        </InputAdornment>
			                     )
			                  }}
			              />
			            </Grid>
			         ):( null )}
                  <Grid item xs={12} sm={6} style={{ padding: 5 }}>
			            <TextField fullWidth size='small' margin='normal' label='New Password'
			               type={showPassword ? 'text' : 'password'}
			               value={password}
			               onChange={(e) => { handleChange(setPassword, e.target.value) }}
			               InputProps={{
			                  endAdornment: (
			                     <InputAdornment position='end'>
			                        <IconButton  onClick={() => { setShowPassword(!showPassword) }}>
			                        {showPassword ? <Visibility /> : <VisibilityOff />}
			                        </IconButton>
			                     </InputAdornment>
			                  )
			               }}
			            />
			          </Grid>
			          <Grid item xs={12} sm={6} style={{ padding: 5 }}>
			            <TextField fullWidth size='small' margin='normal' label='Confirm'
			               type={showPassword ? 'text' : 'password'}
			               value={confirm}
			               onChange={(e) => { handleChange(setConfirm, e.target.value) }}
			               InputProps={{
			                  endAdornment: (
			                     <InputAdornment position='end'>
			                        <ClickAwayListener onClickAway={() => { setShowInfo(false) }}>
					                     <span>
					                        <Tooltip
					                           PopperProps={{ disablePortal: false }}
					                           onClose={() => { setShowInfo(false) }}
					                           open={showInfo}
					                           disableFocusListener
					                           disableHoverListener
					                           disableTouchListener
					                           placement='top-end'
					                           title='password must be at least 6 characters long.'
					                        > 
					                           <IconButton aria-label='password must be at least 6 characters long.' onClick={() => { setShowInfo(!showInfo) }}>
					                              <Info />
					                           </IconButton>
					                        </Tooltip>
					                     </span>
			                        </ClickAwayListener>
			                     </InputAdornment>
			                  )
			               }}
			            />                    
			         </Grid>
					</Grid>
				</form>
				<div align='center' style={{paddingTop:5}}>
					<Text variant='caption' style={{ color: 'red' }}>{message}</Text>
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => {savePassword()}} variant='contained' color='secondary'>Save Password</Button>
				<Button onClick={closeDialog} color='primary'>Skip</Button>
			</DialogActions>
		</Dialog>

   )
}
export default Password
