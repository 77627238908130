import { Fragment, useEffect, useState } from 'react'
import SwipeableViews from 'react-swipeable-views'

import AddImage from './AddImage'
import RemoveImage from './RemoveImage'

import IconAdd from '@mui/icons-material/Add'
import IconRemove from '@mui/icons-material/Remove'
import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import MobileStepper from '@mui/material/MobileStepper'

function ImageSelector({ imgBorder, imgBorderRadius, imgPadding, imgRatio, images, currentImage, selectImage, returnAllImages, deleteButton, hideSelector }) {
   const [compImages, setCompImages] = useState(images)
   const [activeStep, setActiveStep] = useState(0)
   const [addImage, setAddImage] = useState(false)
   const [removeImage, setRemoveImage] = useState(false)
   const [maxSteps, setMaxSteps] = useState(images.length)
   	
   useEffect(() => {
      if (currentImage) {
         let imagePosition = compImages.indexOf(currentImage) 
         if (imagePosition >= 0) {
            setActiveStep(imagePosition)
            selectImage(currentImage)
         } else{
            addNewImage(currentImage)
         }	
		} else {
			setActiveStep(0)
         selectImage(compImages[0])
		}
   },[compImages, currentImage]) 

// [START ADDIMAGE FUNCTIONS ::::::::::::::::::::::::::::::::::::::::::::::::::::::::
	const addImageOpen = () => {
		setAddImage(true)
	}
	const addImageClose = () => {
		setAddImage(false)
	}
   const addNewImage = (src) => {
		let images = compImages
		images.push(src)
		if(returnAllImages && images.length > 1) { 
			images = images.filter(image => image !== currentImage)
			selectImage(images)
		} else {
			selectImage(src)
		}
		setCompImages(images)
      setActiveStep(images.length-1)
      setMaxSteps(images.length)
	}
// [END ADDIMAGE FUNCTIONS ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

// [START REMOVEIMAGE FUNCTIONS :::::::::::::::::::::::::::::::::::::::::::::::::::::
	const removeImageOpen = () => {
		setRemoveImage(true)
	}
	const removeImageClose = () => {
		setRemoveImage(false)
	}
	const removeSelectedImage = () => {
		let images = compImages
		let step = activeStep
		images.splice(step, 1)
		selectImage(images)
		if(!images.length) {
			images = [currentImage]
		}
      setCompImages(images)
      setActiveStep(images.length-1)
      setMaxSteps(images.length)
		removeImageClose()
	}
// [END REMOVEIMAGE FUNCTIONS :::::::::::::::::::::::::::::::::::::::::::::::::::::::

   const handleStepChange = (step) => {
		if (!returnAllImages) {      
			selectImage(compImages[step])
		}
		setActiveStep(step)
	} 	
	const handleNext = () => {
		if (!returnAllImages) {  
			selectImage(compImages[activeStep+1])
		}
		setActiveStep(activeStep + 1)
	}
	const handleBack = () => {
		if (!returnAllImages) { 
			selectImage(compImages[activeStep-1])
		}
		setActiveStep(activeStep - 1)
	}

	return (
		<Fragment>
			<AddImage 
				defaultImage={compImages[0]}
				ratio={imgRatio}
				borderRadius={imgBorderRadius}
				openUi={addImage} 
				closeUi={addImageClose} 
				cb={addNewImage} 
			/>
			<RemoveImage
				image={compImages[activeStep]}
				borderRadius={imgBorderRadius}
				openUi={removeImage} 
				closeUi={removeImageClose} 
				cb={removeSelectedImage} 
			/>
			<div>
				<div style={{position: 'relative'}}>
					<SwipeableViews index={activeStep} onChangeIndex={handleStepChange} axis='x' enableMouseEvents>
						{compImages.map((image, index) => (
						<div key={index}>
							{Math.abs(activeStep - index) <= 2 ? (
							<img 
								style={{
									overflow: 'hidden',
									display: 'block',
									width: '100%',                        
									padding: imgPadding, 
									borderRadius: imgBorderRadius, 
									border: imgBorder
								}} 
								src={image} 
								alt={index} 
							/>
							) : null}
						</div>
						))}
					</SwipeableViews> 
					{(deleteButton && compImages[activeStep] !== currentImage) ? (
						<Fab onClick={removeImageOpen} size='small' color='primary' style={{position: 'absolute', bottom: 10, right:60}}>
							<IconRemove />
						</Fab>
					) : ( null )}
					<Fab onClick={addImageOpen} size='small' color='secondary' style={{position: 'absolute', bottom: 10, right:10}}>
						<IconAdd />
					</Fab>
				</div>
				{(!hideSelector) ? (
					<div>
					{(maxSteps > 1) ? (
						<MobileStepper
							steps={maxSteps}
							position='static'
							variant='text'
							activeStep={activeStep}
							nextButton={
							<Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
								Next <KeyboardArrowRight />
							</Button>
							}
							backButton={
							<Button size="small" onClick={handleBack} disabled={activeStep === 0}>
								<KeyboardArrowLeft /> Back
							</Button>
							}
						/>
					) : (null)}            
					</div>
				) : (null)}        
			</div>
		</Fragment>
	)
}
export default ImageSelector
