import { useEffect, useState } from 'react' 
import { Link, useNavigate } from 'react-router-dom'
import format from 'date-fns/format'

import Alert from '@mui/material/Alert'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import IconArrowForward from '@mui/icons-material/ArrowForwardIos'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function TicketItem({ticket, id}){
   //unassigned | open | closed | archived 
   const statusColor = {'unassigned':'#999', 'open':'#27e007', 'closed':'red', 'archived':'#000'}
   const navigate = useNavigate()

   const openTicket = (id) => {
      navigate('/group/support/ticket?id=' + id)
   }
   return (
      <div>
         <ListItem button alignItems="flex-start" onClick={() => {openTicket(id)}}>
            <ListItemAvatar>
               <Avatar alt={ticket.memberName} src={ticket.memberThumb} style={{borderRadius:5}} />
            </ListItemAvatar>
            <ListItemText>
               <b>{ticket.memberName}</b>
               <Text style={{lineHeight:.8}} variant='caption' display='block' color='textSecondary'>
                  On: { format(new Date(ticket.timestamp), 'eee do MMM y - h:mm a')}
               </Text>
               <Text style={{marginTop:5, lineHeight:1, color:'#546e7a'}}><b>{ticket.subject}</b></Text>
               <Text style={{lineHeight:1.5, color:statusColor[ticket.status]}}><b>{ticket.status.toUpperCase()}</b></Text>
            </ListItemText>
            <ListItemSecondaryAction>
               <IconArrowForward />
            </ListItemSecondaryAction>
         </ListItem>
         <Divider/>                
      </div>
   )
}

function GroupSupportList({selectedTab, showMessage, setShowMessage, supportTickets, team}){ 
   const [unassignedTickets, setUnassignedTickets] = useState({}) 
   const [openTickets, setOpenTickets] = useState({})
   const [closedTickets, setClosedTickets] = useState({})
   
   useEffect(() => {
      let unassigned = {}
      let opened = {}
      let closed = {}

      if (supportTickets && Object.keys(supportTickets).length) {
         // ** could do some sorting here first **
         Object.keys(supportTickets).forEach(function (i) {
            if (supportTickets[i].status === 'unassigned') {
               unassigned[i] = supportTickets[i]
            }         
            if (supportTickets[i].status === 'open') {
               opened[i] = supportTickets[i]
            } 
            if (supportTickets[i].status === 'closed') {
               closed[i] = supportTickets[i]
            } 
         })
      }
      
      setUnassignedTickets(unassigned)
      setOpenTickets(opened)
      setClosedTickets(closed)      
   },[selectedTab, supportTickets, team])

   return (
      <Paper style={{ padding: 10, minHeight:700 }} >
         <Collapse in={showMessage}>
               <Alert style={{marginBottom:20}} severity='info' onClose={() => setShowMessage(false)}>
                  This is your Support Desk, respond to queries submitted by your members. It can be accessed by the Owner, Admins, Managers and CSRs.  
               </Alert> 
         </Collapse>
      
         {(selectedTab === 'open') ? (
            <div>
               {(Object.keys(unassignedTickets).length) ? (
                  <List style={{width:'100%', padding:5, marginBottom:20}}>
                     {Object.keys(unassignedTickets).map((i) => {
                        return (
                           <TicketItem key={i} ticket={unassignedTickets[i]} id={i} />
                        )
                     })}
                  </List>
               ):(
                  null 
               )}  
               
               {(Object.keys(openTickets).length) ? (
                  <List style={{width:'100%', padding:5}}>
                     {Object.keys(openTickets).map((i) => {
                        return (
                           <TicketItem key={i} ticket={openTickets[i]} id={i} />
                        )
                     })}
                  </List>
               ):(
                  <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
                     <Text variant='body1' color='textSecondary'>NO OPEN TICKETS</Text>
                  </div> 
               )} 
            </div>
         ):(null)}

         {(selectedTab === 'closed') ? (
            <div>
               {(Object.keys(closedTickets).length) ? (
                  <List style={{width:'100%', padding:5}}>
                     {Object.keys(closedTickets).map((i) => {
                        return (
                           <TicketItem key={i} ticket={closedTickets[i]} id={i} />
                        )
                     })}
                  </List>
               ):(
                  <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
                  <Text variant='body1' color='textSecondary'>NO CLOSED TICKETS</Text>
               </div> 
               )}
               <div align='right' style={{ marginTop:20 }}>
                  <Button size='small' variant='outlined' color='secondary' component={Link} to='/group/support/archived'>View Archived Tickets</Button>
               </div> 
            </div>
         ):(null)}
 
      </Paper>
   )
}
export default GroupSupportList
