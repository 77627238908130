import { useState } from 'react'
import { format, isValid } from 'date-fns'
import Swal from 'sweetalert2'

import Loader from '../../components/Loader'
import MyTextArea from '../../components/MyTextArea'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useTheme } from '@mui/material/styles'

import Alert from '@mui/material/Alert'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Divider from '@mui/material/Divider'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import IconClose from '@mui/icons-material/Close'
import IconExpandLess from '@mui/icons-material/ExpandLess'
import IconExpandMore from '@mui/icons-material/ExpandMore'
import IconImage from '@mui/icons-material/CropOriginal'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Text from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Toolbar from '@mui/material/Toolbar'
import useMediaQuery from '@mui/material/useMediaQuery'
    
function EventDetails({eventId, event, editEventDetails}){
   const options = {
      public: 'Anyone can purchase tickets and anyone can attend. Only purchaser info is confirmed.',
      secure: 'Everyone attending must have a SecureTix account. Everyone info is confirmed. ',
      members: 'Only approved members can purchase tickets, but may purchase for guests. Only members info is confirmed.',
      networking: 'Only approved members can purchase tickets, but may purchase for guests with SecureTix accounts. Everyone info is confirmed.',
      private: 'only members can purchase and only members can attend. Everyone info is confirmed.'
   }
   const ticketLimitLabel = {
      public: 'Ticket Limit Per User',
      secure: 'Ticket Limit Per User',
      members: 'Total Guests Per Member',
      networking: 'Total Guests Per Member',
      private: 'Ticket Limit Per Member'
   }

   const theme = useTheme()
   const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
   const [showDetails, setShowDetails] = useState(true)
   const [loading, setLoading] = useState(false) 
   const [openDialog, setOpenDialog] = useState(false)
   const [typeEl, setTypeEl] = useState(null)
   const [disableSubmitButton, setDisableSubmitButton] = useState(true)
   const [message, setMessage] = useState('')
   const [eventName, setEventName] = useState(event.name)
   const [eventStart, setEventStart] = useState(new Date(event.dateTimeStart))
   const [eventEnd, setEventEnd] = useState(new Date(event.dateTimeEnd))
   const [eventVenueName, setEventVenueName] = useState(event.venueName || '')
   const [eventVenueAddress, setEventVenueAddress] = useState(event.venueAddress || '')
   const [eventTagline, setEventTagline] = useState(event.tagline || '')
   const [eventSummary, setEventSummary] = useState(event.summary || '')
   const [eventDescription, setEventDescription] = useState(event.description || '')
   const [eventType, setEventType] = useState(event.type || 'public')
   const [eventTicketLimit, setEventTicketLimit] = useState(event.ticketLimit || 10)

   console.log('event.dateTimeStart.seconds',event.dateTimeStart._seconds*1000)
   //return false
   const handleCancelUpdate = () => {
      setMessage('')
      setEventName(event.name)
      setEventStart(new Date(event.dateTimeStart))
      setEventEnd(new Date(event.dateTimeEnd))
      setEventVenueName(event.venueName || '')
      setEventVenueAddress(event.venueAddress || '')
      setEventTagline(event.tagline || '')
      setEventSummary(event.summary || '')
      setEventDescription(event.description || '')
      setEventType(event.type || 'public')
      setEventTicketLimit(event.ticketLimit || 10)
      setDisableSubmitButton(true)
      setOpenDialog(false)
   }
   const handleChange = (f,v) => {
      f(v)
      setMessage('')
      setTypeEl(null)
      setDisableSubmitButton(false)
   }
   const handleMyTextArea = (x) => {
      setEventDescription(x)
      setMessage('')  
      setTypeEl(null)
      setDisableSubmitButton(false)
   }
   const handleUpdateEvent = () => {
      if (eventName === '') {
         return setMessage('Please Enter Event Name')
      }
      if (!isValid(eventStart)) {
         return setMessage('Invalid Start Date Format - Click Calendar Icon')
      }
      if (!isValid(eventEnd)) {
         return setMessage('Invalid End Date Format - Click Calendar Icon')
      }
      if (eventEnd <= eventStart ) {
         return setMessage('Event Must End After It Starts')
      }
      let eventDetails = {
         name: eventName,
         dateTimeStart: eventStart,
         dateTimeEnd: eventEnd,
         venueName: eventVenueName,
         venueAddress: eventVenueAddress,
         tagline: eventTagline,
         summary: eventSummary,
         description: eventDescription,
         type: eventType,
         ticketLimit: eventTicketLimit
      }
      setLoading(true)
      editEventDetails(eventId, eventDetails)
      .then(() => {
         setLoading(false)
         Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Event Updated!',
            text: 'Your event was updated successfully.',
            showConfirmButton: true
         })
      })
      .catch((e) => {
         setLoading(false)
         handleCancelUpdate()
         Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: e.title,
            text: e.message,
            showConfirmButton: true
         })
      })
      setDisableSubmitButton(true)
      setOpenDialog(false)
   }

   return (
      <div style={{marginTop:20}}>
         <Loader loading={loading}/>
	      <List sx={{ padding:0, width: '100%', borderBottom:'1px solid gray' }}>
	         <ListItem 
	            alignItems='flex-start' 
	            style={{padding:0}}
	            secondaryAction={
	               <IconButton edge='end' onClick={() => { setShowDetails(!showDetails) }}>
	                  {showDetails ? <IconExpandLess /> : <IconExpandMore />} 
	               </IconButton>
	            }
	         >
	            <ListItemText primary={ <Button style={{textTransform:'none'}} onClick={() => { setShowDetails(!showDetails) }}><Text variant='h5' color='text.secondary'>Details</Text></Button>} />
	         </ListItem>
	      </List> 
	      <Collapse in={showDetails}> 
	         <Paper style={{padding:5}}>
	            <div  style={{maxWidth:700, margin:'0 auto'}} >
		            <Grid container justifyContent='center' direction='row' alignItems='flex-start' spacing={0}>
	                  <Grid item xs={4} style={{textAlign:'right'}} >
	                     <Text variant='body1' color='text.disabled'>Name:&nbsp;</Text>
	                  </Grid>
	                  <Grid item xs={8}>                     
	                     <Text variant='body1' color='text.secondary'> {event.name}</Text>                  
	                  </Grid>
	                  <Grid item xs={4} style={{textAlign:'right'}} >
	                     <Text variant='body1' color='text.disabled'>Tagline:&nbsp;</Text>
	                  </Grid>
	                  <Grid item xs={8}>
	                     {(event.tagline) ? (
                           <Text variant='body1' color='text.secondary'>{event.tagline}</Text> 
	                     ):(
                           <Text variant='body1' color='text.disabled'><i>No Tagline</i></Text>  
	                     )}
	                  </Grid>
                     <Grid item xs={4} style={{textAlign:'right'}} >
	                     <Text variant='body1' color='text.disabled'>Start:&nbsp;</Text>
	                  </Grid>
	                  <Grid item xs={8}>                     
	                     <Text variant='body1' color='text.secondary'>{ format(new Date(event.dateTimeStart), 'eee do MMM y - h:mm a')}</Text>                  
	                  </Grid>
                     <Grid item xs={4} style={{textAlign:'right'}} >
	                     <Text variant='body1' color='text.disabled'>End:&nbsp;</Text>
	                  </Grid>
	                  <Grid item xs={8}>                     
	                     <Text variant='body1' color='text.secondary'>{ format(new Date(event.dateTimeEnd), 'eee do MMM y - h:mm a')}</Text>                  
	                  </Grid>
                     <Grid item xs={4} style={{textAlign:'right'}} >
	                     <Text variant='body1' color='text.disabled'>Venue:&nbsp;</Text>
	                  </Grid>
	                  <Grid item xs={8}>                     
	                     {(event.venueName) ? (
                           <Text variant='body1' color='text.secondary'>{event.venueName}</Text> 
	                     ):(
                           <Text variant='body1' color='text.disabled'><i>No Venue Listed</i></Text>  
	                     )}                  
	                  </Grid>
	                  <Grid item xs={4} style={{textAlign:'right'}} >
	                     <Text variant='body1' color='text.disabled'>Address:&nbsp;</Text>
	                  </Grid>
	                  <Grid item xs={8}>                     
	                     {(event.venueAddress) ? (
                           <Text variant='body1' color='text.secondary'>{event.venueAddress}</Text> 
	                     ):(
                           <Text variant='body1' color='text.disabled'><i>No Address Listed</i></Text>  
	                     )}                  
	                  </Grid>
	                  <Grid item xs={4} style={{textAlign:'right'}} >
	                     <Text variant='body1' color='text.disabled'>Summary:&nbsp;</Text>
	                  </Grid>
	                  <Grid item xs={8}>                     
	                     {(event.summary) ? (
                           <Text variant='body1' color='text.secondary'>{event.summary}</Text> 
	                     ):(
                           <Text variant='body1' color='text.disabled'><i>No Summary Listed</i></Text>  
	                     )}                  
	                  </Grid>
	                  <Grid item xs={4} style={{textAlign:'right'}} >
	                     <Text variant='body1' color='text.disabled'>Description:&nbsp;</Text>
	                  </Grid>
	                  <Grid item xs={8}>                     
	                     {(event.description) ? (
                           <div style={{border:'1px solid #f1f1f1', marginBottom:5}}>
                              <div className='MyTextArea ql-editor'  dangerouslySetInnerHTML={{__html: event.description}} />
                           </div>
                        ):(
                           <Text variant='body1' color='text.disabled'><i>No Description Listed</i></Text>  
	                     )}                  
	                  </Grid>
	                  <Grid item xs={12} sm={12} md={12} > 
	                     <Divider />
	                  </Grid>
	                  <Grid item xs={4} style={{textAlign:'right'}} >
	                     <Text variant='body1' color='text.disabled'>Type of Event:&nbsp;</Text>
	                  </Grid>
	                  <Grid item xs={8}>                     
	                     <Text variant='body1' color='text.secondary'>{ event.type.toUpperCase() }</Text>                  
	                  </Grid>
	                  <Grid item xs={4} style={{textAlign:'right'}} >
	                     <Text variant='body1' color='text.disabled'>Ticket Limit:&nbsp;</Text>
	                  </Grid>
	                  <Grid item xs={8}>                     
	                     <Text variant='body1' color='text.secondary'>{ (event.ticketLimit) ? event.ticketLimit : 'No Limit' }</Text>                  
	                  </Grid>
	                  <Grid item xs={12} sm={12} md={12} > 
	                     <Divider />
	                  </Grid>
	                  <Grid item xs={12} sm={12} md={12} >
                        <div style={{padding:10}}>
                           <Alert icon={false} severity='success'>
                              This is a {event.type.toUpperCase()} event, which means:                            
                              {(event.type === 'public') ? ( 
                                 <div>
                                    <ul>
                                      <li>Anyone with a SecureTix account can purchase tickets</li>
                                      <li>Anyone can attend</li>
                                      <li>All tickets go to purchaser</li>
                                      <li>Purchaser becomes a (subscriber) member</li>
                                    </ul>   

                                    {(event.ticketLimit === 0) ? ' There is NO LIMIT to the number of tickets someone can buy.'
                                    : ' Users are allowed to purchase up to ' + event.ticketLimit + ' tickets.'}
                                 </div>
                              ):(null)} 
                              {(event.type === 'secure') ? ( 
                                 <div>
                                    <ul>
                                      <li>Anyone with a SecureTix account can purchase tickets</li>
                                      <li>Anyone with a SecureTix account can attend</li>
                                      <li>Tickets go directly to each ticket holder account</li>
                                      <li>All ticket holders (if new) become (subscriber) members</li>
                                    </ul>
                                    {(event.ticketLimit === 0) ? ' There is NO LIMIT to the number of tickets someone can buy.'
                                    : ' Users are allowed to purchase up to ' + event.ticketLimit + ' tickets.'}
                                 </div>
                              ):(null)} 
                              {(event.type === 'members') ? ( 
                                 <div>
                                    <ul>
                                      <li>Only approved group members can purchase tickets</li>
                                      <li>User can purchase for approved group members (unlimited) and guests</li>
                                      <li>Group member tickets go directly to member</li>
                                      <li>Guests tickets go to purchaser</li>
                                    </ul>
                                   {(event.ticketLimit === 0) ? ' There is NO LIMIT to the number of guest tickets a member can purchase.'
                                   : ' Each member is allowed to purchase up to ' + event.ticketLimit + ' guest tickets.'}
                                 </div>
                              ):(null)}
                              {(event.type === 'networking') ? ( 
                                 <div>
                                    <ul>
                                      <li>Only approved group members can purchase tickets</li>
                                      <li>User can purchase for approved group members (unlimited) and guests</li>
                                      <li>Guests must have a SecureTix account</li>
                                      <li>Tickets go directly to each ticket holder account</li>
                                    </ul>
                                    {(event.ticketLimit === 0) ? ' There is NO LIMIT to the number of guest tickets a member can purchase.'
                                    : ' Each member is allowed to purchase up to ' + event.ticketLimit + ' guest tickets.'}
                                 </div>
                              ):(null)}
                              {(event.type === 'private') ? ( 
                                <div>
                                    <ul>
                                      <li>Only approved group members can purchase tickets</li>
                                      <li>Only approved group members can attend this event</li>
                                      <li>Tickets go directly to each ticket holder account</li>
                                    </ul>
                                   {(event.ticketLimit === 0) ? ' There is NO LIMIT to the number of tickets a member can purchase.'
                                   : ' Member can purchase up to ' + event.ticketLimit + ' tickets.'}
                                </div>
                              ):(null)}

                           </Alert>
                        </div>
                     </Grid>
                  </Grid>  
               </div>	          
               <div style={{textAlign:'right'}}><Button onClick={() => {setOpenDialog(true)}}>Edit Details</Button></div>
            </Paper>
	      </Collapse> 

{/* *************************************************************************************************** */}  

         <Dialog fullWidth={true} maxWidth={'xs'} scroll={'body'} fullScreen={fullScreen} open={openDialog} onClose={handleCancelUpdate}>
            <AppBar position='relative'>
               <Toolbar variant='dense'>
                  <IconImage />
                  <div style={{ flex: 1, marginLeft: 5 }}><Text variant='subtitle2' color='inherit'>Edit Event Details</Text></div>
                  <IconButton color='inherit' onClick={handleCancelUpdate}><IconClose /></IconButton>
               </Toolbar>
            </AppBar>

            <DialogContent>
               <Text variant='h5' gutterBottom>Event Details</Text>
               <Stack spacing={3}>
                  <Divider />
                  <TextField fullWidth 
                     id='name' 
                     label='Name' 
                     value={eventName} 
                     onChange={(e) => { handleChange(setEventName, e.target.value) }}
                  />
                  <TextField fullWidth 
                     id='tagline' 
                     label='Tagline' 
                     value={eventTagline} 
                     onChange={(e) => { handleChange(setEventTagline, e.target.value) }}
                  />
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                     <DateTimePicker
                        fullWidth
                        id='startDateTime'
                        label='Start Date & Time'
                        minutesStep={5}
                        minDate={new Date()}
                        value={eventStart}
                        onChange={date => { handleChange(setEventStart, date) }}
                        renderInput={(params) => <TextField {...params} />}
                     />
                     <DateTimePicker
                        fullWidth
                        id='endDateTime'
                        label='End Date & Time'
                        minutesStep={5}
                        minDate={eventStart}
                        value={eventEnd}
                        onChange={date => { handleChange(setEventEnd, date) }}
                        renderInput={(params) => <TextField {...params} />}
                     />
                  </LocalizationProvider>
                  <Alert icon={false} severity='info'>
                    <strong>Would this event be viewed by the public?</strong><br/>
                    If this event is being viewed by the public, post some more details. 
                  </Alert>
                  <TextField fullWidth 
                     id='venueName' 
                     label='Venue Name' 
                     value={eventVenueName} 
                     onChange={(e) => { handleChange(setEventVenueName, e.target.value) }}
                  />
                  <TextField fullWidth 
                     id='venueAddress' 
                     label='Venue Address' 
                     value={eventVenueAddress} 
                     onChange={(e) => { handleChange(setEventVenueAddress, e.target.value) }}
                  />                
                  <TextField fullWidth 
                     id='summary' 
                     label='Summary' 
                     multiline
                     maxRows={2}
                     value={eventSummary} 
                     onChange={(e) => { handleChange(setEventSummary, e.target.value) }}
                  />
                  
                  <MyTextArea verb='Description' message='Describe your event here...' text={eventDescription}  action={handleMyTextArea}/>

                  <Alert icon={false} severity='info'>
                    <strong>Would you be selling tickets?</strong><br/>
                    If you are, who would you be selling too and how much can they buy?
                  </Alert>  
                  <List style={{backgroundColor:'#fff', border:'1px solid #cccccc', padding:2}}>
                     <ListItem button onClick={(e) => { setTypeEl(e.currentTarget) }} style={{padding:10}}>
                     <ListItemText primary={'Type of Event: ' + eventType.toUpperCase()} secondary={options[eventType]} />
                     {Boolean(typeEl) ? <ExpandLess /> : <ExpandMore />}
                     </ListItem>
                  </List> 
                  <Menu dense='true' anchorEl={typeEl} open={Boolean(typeEl)} onClose={() => {setTypeEl(null)}}>
                     <MenuItem value='public' selected={eventType === 'public' } onClick={() => { handleChange(setEventType, 'public') }}>
                        <ListItemText style={{whiteSpace: 'normal'}} primary='PUBLIC' secondary={options['public']} />
                     </MenuItem>
                     <MenuItem value='secure' selected={eventType === 'secure' } onClick={() => { handleChange(setEventType, 'secure') }}>
                        <ListItemText style={{whiteSpace: 'normal'}} primary='SECURE' secondary={options['secure']} />
                     </MenuItem>
                     <MenuItem value='members' selected={eventType === 'members' } onClick={() => { handleChange(setEventType, 'members') }}>
                        <ListItemText style={{whiteSpace: 'normal'}} primary='MEMBERS' secondary={options['members']} />
                     </MenuItem>
                     <MenuItem value='networking' selected={eventType === 'networking' } onClick={() => { handleChange(setEventType, 'networking') }}>
                        <ListItemText style={{whiteSpace: 'normal'}} primary='NETWORKING' secondary={options['networking']} />
                     </MenuItem>
                     <MenuItem value='private' selected={eventType === 'private' } onClick={() => { handleChange(setEventType, 'private') }}>
                        <ListItemText style={{whiteSpace: 'normal'}} primary='PRIVATE' secondary={options['private']} />
                     </MenuItem>
                  </Menu>
                  <TextField fullWidth
                     select
                     label={ticketLimitLabel[eventType]}
                     value={eventTicketLimit}
                     onChange={(e) => { handleChange(setEventTicketLimit, e.target.value) }}
                  >
                     <MenuItem value={1}>1</MenuItem>
                     <MenuItem value={2}>2</MenuItem>
                     <MenuItem value={3}>3</MenuItem>
                     <MenuItem value={4}>4</MenuItem>
                     <MenuItem value={5}>5</MenuItem>
                     <MenuItem value={10}>10</MenuItem>
                     <MenuItem value={0}>No Limit</MenuItem>
                  </TextField>
                  
               </Stack>
               <div style={{ textAlign:'center', height:20}}><Text style={{color:'red'}}><i>{message}</i></Text></div>
            </DialogContent>
            <DialogActions>
               <Button onClick={handleCancelUpdate} color='primary'>Cancel</Button>
               <Button disabled={disableSubmitButton} onClick={handleUpdateEvent} variant='contained'>Update Event</Button>
            </DialogActions>
         </Dialog>

      </div>
   )
}
export default EventDetails	
