import { useLocation, useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import IconChevronLeft from '@mui/icons-material/ChevronLeft'
import IconCampaign from '@mui/icons-material/EmojiPeople'
import IconDashboard from '@mui/icons-material/DashboardOutlined'
//import IconInvoices from '@mui/icons-material/ArticleOutlined'
import IconMemberships from '@mui/icons-material/GroupAddOutlined'
import IconNotifications from '@mui/icons-material/NotificationsNone'
import IconPortrait from '@mui/icons-material/Portrait'
import IconSupportDesk from '@mui/icons-material/LiveHelpOutlined'
import IconTicket from '@mui/icons-material/ConfirmationNumberOutlined'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'

import IconSettings from '@mui/icons-material/Tune'
import IconEvent from '@mui/icons-material/Event'
import IconMyMemberships from '@mui/icons-material/GroupAdd'
import IconShare from '@mui/icons-material/Share'
import IconAccount from '@mui/icons-material/AccountBalance'
import IconWallet from '@mui/icons-material/AccountBalanceWalletOutlined'
import IconTeam from '@mui/icons-material/GroupsOutlined';



function MemberSideNav({location, selectedOption, handleClickLink}) {
  return (
    <div>
      <div align='center'><img src={ selectedOption.thumb } style={{ width: '75%', borderRadius: 20, border: '1.5px solid #ddd', margin: '10px auto' }} alt='' /></div>
      <List>
        <ListItem button onClick={() => { handleClickLink('/member/dashboard') }}
          sx={{ backgroundColor: (location.includes('/member/dashboard')) ? '#E8E8E8' : 'ffffff' }}
        >
          <ListItemIcon><IconDashboard /></ListItemIcon>
          <ListItemText primary='Dashboard' />
        </ListItem>
        <ListItem button onClick={() => { handleClickLink('/member/wallet') }}
          sx={{ backgroundColor: (location.includes('/member/wallet')) ? '#E8E8E8' : 'ffffff' }}
        >
          <ListItemIcon><IconWallet /></ListItemIcon>
          <ListItemText primary='Wallet' />
        </ListItem>
        <ListItem button onClick={() => { handleClickLink('/member/tickets') }}
          sx={{ backgroundColor: (location.includes('/member/tickets')) ? '#E8E8E8' : 'ffffff' }}
        >
          <ListItemIcon><IconTicket /></ListItemIcon>
          <ListItemText primary='Tickets' />
        </ListItem>
        <ListItem button onClick={() => { handleClickLink('/member/memberships') }}
          sx={{ backgroundColor: (location.includes('/member/memberships')) ? '#E8E8E8' : 'ffffff' }}
        >
          <ListItemIcon><IconMemberships /></ListItemIcon>
          <ListItemText primary='Memberships' />
        </ListItem>
        <ListItem button onClick={() => { handleClickLink('/member/messages') }}
          sx={{ backgroundColor: (location.includes('/member/messages')) ? '#E8E8E8' : 'ffffff' }}
        >
          <ListItemIcon><IconNotifications /></ListItemIcon>
          <ListItemText primary='Messages' />
        </ListItem>        
        <ListItem button onClick={() => { handleClickLink('/member/support') }}
          sx={{ backgroundColor: (location.includes('/member/support')) ? '#E8E8E8' : 'ffffff' }}
        >
          <ListItemIcon><IconSupportDesk /></ListItemIcon>
          <ListItemText primary='Support Desk' />
        </ListItem>
        <ListItem button onClick={() => { handleClickLink('/member/profile') }}
          sx={{ backgroundColor: (location.includes('/member/profile')) ? '#E8E8E8' : 'ffffff' }}
        >
          <ListItemIcon><IconPortrait /></ListItemIcon>
          <ListItemText primary='Profile Settings' />
        </ListItem>
      </List>
    </div>
  )
}
function GroupSideNav({location, selectedOption, handleClickLink}) {
  return (
    <div>
      <div align='center'><img src={ selectedOption.thumb } style={{ width: '75%', borderRadius: 20, border: '1.5px solid #ddd', margin: '10px auto' }} alt='' /></div>
      <List>
        <ListSubheader component='div'>
          { selectedOption.name.toUpperCase() } MENU
        </ListSubheader>
        
        <ListItem button onClick={() => { handleClickLink('/group/dashboard') }}
          sx={{ backgroundColor: (location.includes('/group/dashboard')) ? '#E8E8E8' : 'ffffff' }}
        >
          <ListItemIcon><IconDashboard /></ListItemIcon>
          <ListItemText primary='Dashboard' />
        </ListItem>
        
        <ListItem button onClick={() => { handleClickLink('/group/events') }}
          sx={{ backgroundColor: (location.includes('/group/events')) ? '#E8E8E8' : 'ffffff' }}
        >
          <ListItemIcon><IconEvent /></ListItemIcon>
          <ListItemText primary='Events' />
        </ListItem>
        <ListItem button onClick={() => { handleClickLink('/group/members') }}
          sx={{ backgroundColor: (location.includes('/group/members')) ? '#E8E8E8' : 'ffffff' }}
        >
          <ListItemIcon><IconMyMemberships /></ListItemIcon>
          <ListItemText primary='Members' />
        </ListItem>
        <ListItem button onClick={() => { handleClickLink('/group/support') }}
          sx={{ backgroundColor: (location.includes('/group/support')) ? '#E8E8E8' : 'ffffff' }}
        >
          <ListItemIcon><IconSupportDesk /></ListItemIcon>
          <ListItemText primary='Support Desk' />
        </ListItem>
        <ListItem button onClick={() => { handleClickLink('/group/communicate') }}
          sx={{ backgroundColor: (location.includes('/group/communicate')) ? '#E8E8E8' : 'ffffff' }}
        >
          <ListItemIcon><IconShare /></ListItemIcon>
          <ListItemText primary='Communicate' />
        </ListItem>
        <ListItem button onClick={() => { handleClickLink('/group/accounts') }}
          sx={{ backgroundColor: (location.includes('/group/accounts')) ? '#E8E8E8' : 'ffffff' }}
        >
          <ListItemIcon><IconAccount /></ListItemIcon>
          <ListItemText primary='Accounts' />
        </ListItem>
        <ListItem button onClick={() => { handleClickLink('/group/settings') }}
          sx={{ backgroundColor: (location.includes('/group/settings')) ? '#E8E8E8' : 'ffffff' }}
        >
          <ListItemIcon><IconSettings /></ListItemIcon>
          <ListItemText primary='Group Settings' />
        </ListItem>
      </List>
    </div>
  )
}
function PublicSideNav({location, selectedOption, handleClickLink}) {
  return (
    <div>
      <List>
        <ListItem button onClick={() => { handleClickLink('/aboutus') }}
          sx={{ backgroundColor: (location.includes('/aboutus')) ? '#E8E8E8' : 'ffffff' }}
        >
          <ListItemIcon><IconCampaign /></ListItemIcon>
          <ListItemText primary='About Us' />
        </ListItem>

        <ListItem button onClick={() => { handleClickLink('/solutions') }}
          sx={{ backgroundColor: (location.includes('/solutions')) ? '#E8E8E8' : 'ffffff' }}
        >
          <ListItemIcon><IconCampaign /></ListItemIcon>
          <ListItemText primary='Solutions & Features' />
        </ListItem>

        <ListItem button onClick={() => { handleClickLink('/start') }}
          sx={{ backgroundColor: (location.includes('/start')) ? '#E8E8E8' : 'ffffff' }}
        >
          <ListItemIcon><IconCampaign /></ListItemIcon>
          <ListItemText primary='Create/Manage an Event' />
        </ListItem>

        <ListItem>
          <Button fullWidth size='small' variant='contained' color='secondary' sx={{textTransform:'none'}} onClick={() => {handleClickLink('/events')}}>Upcoming Events</Button>
        </ListItem>

        <ListItem button onClick={() => { handleClickLink('/login') }}
          sx={{ backgroundColor: (location.includes('/login')) ? '#E8E8E8' : 'ffffff' }}
        >
          <ListItemIcon><IconCampaign /></ListItemIcon>
          <ListItemText primary='Login / Register' />
        </ListItem>
      </List>
    </div>
  )
}

function SideNav(props) {
  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent)
  let location = useLocation()
  let navigate = useNavigate()

  const handleClickLink = (link) => {
    props.sideNavToggle()
    navigate(link)  
  }

  return (
    <SwipeableDrawer open={props.sideNavOpen} onClose={props.sideNavToggle} onOpen={props.sideNavToggle} disableBackdropTransition={!iOS} disableDiscovery={iOS}>
      <div style={{width:300, display: 'flex', alignItems: 'center', justifyContent: 'flex-end',margin:5}}>
        <IconButton onClick={() => { props.sideNavToggle() }}>
          <IconChevronLeft />
        </IconButton>
      </div>
      <Divider />
      <div style={{width:300}}>
      {(() => {
        if (location.pathname.startsWith('/member')) {
          return (
            <MemberSideNav location={location.pathname} selectedOption={props.selectedOption} handleClickLink={handleClickLink} />
          )
        } else if (location.pathname.startsWith('/group')) {
          return (
            <GroupSideNav location={location.pathname} selectedOption={props.selectedOption} handleClickLink={handleClickLink} />
          )
        } else {
          return (
            <PublicSideNav location={location.pathname} selectedOption={props.selectedOption} handleClickLink={handleClickLink} />
          )
        }
      })()}
      </div>
    </SwipeableDrawer>
  )
}
export default SideNav
