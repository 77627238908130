import { Fragment, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import AppBar from '@mui/material/AppBar'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import IconAccountCircle from '@mui/icons-material/AccountCircle'
import IconLogout from '@mui/icons-material/Logout'
import IconMenu from '@mui/icons-material/Menu'
import IconMoreVert from '@mui/icons-material/MoreVert'
import IconSettings from '@mui/icons-material/Tune'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Text from '@mui/material/Typography'
import Toolbar from '@mui/material/Toolbar'


import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse'
import IconSwitch from '@mui/icons-material/AutorenewOutlined';
import IconRegister from '@mui/icons-material/AppRegistrationOutlined';
import IconLogin from '@mui/icons-material/LoginOutlined';
import IconSupportDesk from '@mui/icons-material/LiveHelpOutlined'
import IconPortrait from '@mui/icons-material/Portrait'
import IconStuff from '@mui/icons-material/CategoryOutlined';  //InterestsOutlined
import IconEmailLogin from '@mui/icons-material/ForwardToInbox';

function PublicNav() {
   const [menuEl, setMenuEl] = useState(null)
   let navigate = useNavigate()

   const handleClickLink = (link) => {
      setMenuEl(null)
      navigate(link)  
   }
   return (
      <AppBar position='static'>
         <Container maxWidth='xl'>
            <Toolbar disableGutters variant='dense'>
               <Box sx={{ flexGrow: 1 }}>
                  <Button size='small' color='inherit' component={ Link } to={'/'}>
                     <Text variant='h6' noWrap sx={{textTransform: 'none'}}>SecureTix</Text>
                  </Button>
               </Box>
               <IconButton color='inherit' onClick={ (event) => { setMenuEl(event.currentTarget) }}><IconAccountCircle /></IconButton>
               <Menu dense='true' anchorEl={menuEl} open={Boolean(menuEl)} onClose={() => { setMenuEl(null) }}>
                  <MenuItem onClick={() => {handleClickLink('/login')}} >
                     <ListItemAvatar><Avatar><IconLogin /></Avatar></ListItemAvatar>
                     <ListItemText primary='Login' secondary='Login via email & password' />
                  </MenuItem> 
                  <MenuItem onClick={() => {handleClickLink('/email/login')}} >
                     <ListItemAvatar><Avatar><IconEmailLogin /></Avatar></ListItemAvatar>
                     <ListItemText primary='Login' secondary='Login via email link' />
                  </MenuItem> 
                  <MenuItem onClick={() => {handleClickLink('/email/register')}} >
                     <ListItemAvatar><Avatar><IconRegister /></Avatar></ListItemAvatar>
                     <ListItemText primary='Register' secondary='Register or login via email' />
                  </MenuItem> 
               </Menu>
            </Toolbar>
         </Container>
      </AppBar>  
   )
}

function MemberNav({sideNavToggle, loginOptions, selectedOption, handleOptionChange, logOut}) {
  const [menuEl, setMenuEl] = useState(null)
  const [openGroupsList, setOpenGroupsList] = useState(false)
  let navigate = useNavigate()
  let location = useLocation()

  const handleClickLink = (link) => {
    setMenuEl(null)
    navigate(link)  
  }
  const handleMenuItemClick = (option) => {
    setMenuEl(null)
    handleOptionChange(option)
  }
   return (
      <AppBar position='static'>
         <Container maxWidth='xl'>
            <Toolbar disableGutters variant='dense'>

               <Box sx={{ flexGrow: 1 }}>
                 <Button size='small' color='inherit' component={ Link } to={'/'}>
                   <Text variant='h6' noWrap sx={{textTransform: 'none'}}>SecureTix</Text>
                 </Button>
               </Box>
               <Button size='large' sx={{padding:0}} color='inherit' endIcon={<IconMoreVert />} onClick={ (event) => { setMenuEl(event.currentTarget) }}>
                 <Avatar sx={{ border: '1.5px solid #ddd', margin: 0, color:'#fff', bgcolor:'orange' }} alt={ selectedOption.name } src={ selectedOption.thumb }>
                  { selectedOption.initials }
                 </Avatar> 
               </Button>
               <Menu dense='true' anchorEl={menuEl} open={Boolean(menuEl)} onClose={() => { setMenuEl(null) }}>
                  {(loginOptions && loginOptions.length > 1) ? (
                     <div>
                        <MenuItem onClick={() => { setOpenGroupsList(!openGroupsList)}} >
                           <ListItemAvatar><Avatar><IconSwitch /></Avatar></ListItemAvatar>
                           <ListItemText primary={<b>Switch To Group Page</b>} />
                           {openGroupsList ? <ExpandLess /> : <ExpandMore />}
                        </MenuItem>
                        <Collapse in={openGroupsList} timeout="auto" unmountOnExit>
                           {loginOptions.slice(1).map((option, index) => (
                           <MenuItem
                              sx={{ pl: 4 }}
                              key={index}
                              selected={option.name === selectedOption.name && option.role === selectedOption.role }
                              onClick={(event) => {handleMenuItemClick(index+1)}}
                           >
                              <ListItemAvatar><Avatar alt={ option.name } src={ option.thumb }>{ option.initials }</Avatar></ListItemAvatar>
                              <ListItemText primary={option.name} secondary={option.role ? 'Role: ' + option.role.replace(/_/g, ' ') : 'Go to my Account'} />
                           </MenuItem>
                           ))} 
                        </Collapse>
                     </div>
                  ):(null)} 
                  <Divider style={{margin:0}}/> 
                  <MenuItem onClick={() => { handleClickLink('/member/account') }} 
                     sx={{ backgroundColor: (location.pathname.includes('/member/account')) ? '#E8E8E8' : 'ffffff' }}
                  >
                     <ListItemAvatar>
                        <Avatar sx={{ border: '1.5px solid #ddd', margin: 0, color:'#fff', bgcolor:'orange' }} alt={ selectedOption.name } src={ selectedOption.thumb }>
                           { selectedOption.initials }
                        </Avatar> 
                     </ListItemAvatar>
                     <ListItemText primary='My Account' secondary='Summary | My Feed | Memberships' />
                  </MenuItem>
                  <Divider style={{margin:0}}/> 
                  <MenuItem onClick={() => { handleClickLink('/member/stuff/') }} 
                     sx={{ backgroundColor: (location.pathname.includes('/member/stuff')) ? '#E8E8E8' : 'ffffff' }}
                  >
                     <ListItemAvatar><Avatar><IconStuff /></Avatar></ListItemAvatar>
                     <ListItemText primary='My Stuff' secondary='Wallet | Tickets | Invoices' />
                  </MenuItem>
                  <Divider style={{margin:0}}/> 
                  <MenuItem onClick={() => {handleClickLink('/member/profile')}} 
                     sx={{ backgroundColor: (location.pathname.includes('/member/profile')) ? '#E8E8E8' : 'ffffff' }}
                  >
                     <ListItemAvatar><Avatar><IconPortrait /></Avatar></ListItemAvatar>
                     <ListItemText primary='My Profile' secondary='Profile | Notifications | Activity' />
                  </MenuItem> 
                  <Divider style={{margin:0}}/> 
                  <MenuItem onClick={() => {handleClickLink('/member/support')}} 
                    sx={{ backgroundColor: (location.pathname.includes('/member/support')) ? '#E8E8E8' : 'ffffff' }}
                  >
                     <ListItemAvatar><Avatar><IconSupportDesk /></Avatar></ListItemAvatar>
                     <ListItemText primary='Support Desk' secondary='Membership and App Support' />
                  </MenuItem>  
                  <Divider style={{margin:0}}/> 
                  <MenuItem onClick={() => { logOut() }} >
                     <ListItemAvatar><Avatar><IconLogout /></Avatar></ListItemAvatar>
                     <ListItemText primary='Logout' />
                  </MenuItem>
               </Menu>  
            </Toolbar>
         </Container>
      </AppBar>  
   )
}

function GroupNav({sideNavToggle, selectedOption, handleOptionChange, logOut}) {
   const [menuEl, setMenuEl] = useState(null)
   let navigate = useNavigate()
   let location = useLocation()

   const handleGoToPage = (link) => {
      setMenuEl(null)
      navigate(link)  
   }
   const handleSwitchToMyAccount = () => {
     handleOptionChange(0)
    setMenuEl(null)
    navigate('/member/')
   }
   return (
      <AppBar position='static' color='secondary'>
         <Container maxWidth='xl'>
            <Toolbar disableGutters variant='dense'>
               <Box sx={{ flexGrow: 1 }}>
                  <Button size='large' color='inherit' startIcon={<IconMenu />} onClick={ sideNavToggle }>Menu</Button>
               </Box>
               <Box sx={{ flexGrow: 1 }}>
                  <Button size='small' color='inherit' component={ Link } to={'/'}>
                     <Text variant='h6' noWrap sx={{textTransform: 'none'}}>SecureTix</Text>
                  </Button>
               </Box>
               <Button size='large' sx={{padding:0}} color='inherit' endIcon={<IconMoreVert />} onClick={ (event) => { setMenuEl(event.currentTarget) }}>
                 <Avatar sx={{ border: '1.5px solid #ddd', margin: 0, color:'#fff', bgcolor:'orange' }} alt={ selectedOption.name } src={ selectedOption.thumb }>
                  { selectedOption.initials }
                 </Avatar> 
               </Button>
               <Menu dense='true' anchorEl={menuEl} open={Boolean(menuEl)} onClose={() => { setMenuEl(null) }}>
                  <Text variant='body2' component='p' sx={{paddingLeft: 1, color: '#999'}}>
                     Logged in as:
                  </Text>
                  <Divider />
                  <MenuItem onClick={(event) => { handleGoToPage('/group/dashboard') }}
                     sx={{ backgroundColor: (location.pathname.includes('/group/')) ? '#E8E8E8' : 'ffffff' }}
                  >
                     <ListItemAvatar><Avatar alt={ selectedOption.name } src={ selectedOption.thumb }>{ selectedOption.initials }</Avatar></ListItemAvatar>
                     <ListItemText primary={selectedOption.name} secondary={selectedOption.role ? 'Role: ' + selectedOption.role.replace(/_/g, ' ') : 'Go to my Account'} />
                  </MenuItem>
                  <Divider style={{margin:0}}/> 
                  <MenuItem onClick={() => {handleSwitchToMyAccount()}} >
                     <ListItemAvatar><Avatar><IconSwitch /></Avatar></ListItemAvatar>
                     <ListItemText primary={<b>Switch To My Account</b>} />
                  </MenuItem>
                  <Divider style={{margin:0}}/>   
                  <MenuItem onClick={() => { logOut() }} >
                     <ListItemAvatar><Avatar><IconLogout /></Avatar></ListItemAvatar>
                     <ListItemText primary='Logout' />
                  </MenuItem>
               </Menu> 
            </Toolbar>
         </Container>
      </AppBar>  
   )
}

function TopNav(props) {
   const [activeNav, setActiveNav] = useState('public') 

   useEffect(() => {
      switch (true) {
         case !props.loggedIn:
            setActiveNav('public')
            break
         case props.selectedOption.login === 'group':
            setActiveNav('group')
            break
         default:
            setActiveNav('member')
      }
   },[props])

   if (activeNav === 'public') {
      return ( <PublicNav/> )
   }
   if (activeNav === 'member') {
      return (
         <MemberNav 
            sideNavToggle={props.sideNavToggle} 
            loginOptions={props.loginOptions} 
            selectedOption={props.selectedOption} 
            handleOptionChange={props.handleOptionChange} 
            logOut={props.logOut}
         />
      )
   }
   if (activeNav === 'group') {
      return (
         <GroupNav 
            sideNavToggle={props.sideNavToggle} 
            selectedOption={props.selectedOption} 
            handleOptionChange={props.handleOptionChange} 
            logOut={props.logOut}
         />
      )
   }
}
export default TopNav
