import { useEffect, useState } from 'react' 
import { useSearchParams } from 'react-router-dom'

import NextUpdate from '../../components/NextUpdate'
import TicketsCard from './ticketCard/'

import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import IconExpandLess from '@mui/icons-material/ExpandLess'
import IconExpandMore from '@mui/icons-material/ExpandMore'
import LinearProgress from '@mui/material/LinearProgress'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function Tickets({lastUpdated, tickets, loadTickets, updateNameOnTicket, transferTicket, profileLookup}) {
   const [loading, setLoading] = useState(false) 
   const [searchParams] = useSearchParams()

   const [showTickets, setShowTickets] = useState(true)
   const [showItems, setShowItems] = useState(true)
   const [showTransfers, setShowTransfers] = useState(true)
   const [showExpired, setShowExpired] = useState(true)

   const [activeTickets, setActiveTickets] = useState([]) 
   const [activeItems, setActiveItems] = useState([]) 
   const [transferedItems, setTransferedItems] = useState([]) 
   const [expiredItems, setExpiredItems] = useState([]) 

   useEffect(() => {
      let force = (searchParams.get('force')) ? true : false
      if (window.localStorage.getItem('forceLoadTickets')) {
         force = true
         window.localStorage.removeItem('forceLoadTickets')
      }
      handleFetchMemberTickets(force)
   },[])
   useEffect(() => {
      handleFilterMemberTickets()
   },[tickets])

   const handleFetchMemberTickets = (force) => {
      setLoading(true)
      loadTickets(force) 
      .finally(() => {
         setLoading(false)
      })
   }
   const handleFilterMemberTickets = (force) => {
      if (tickets && Object.values(tickets).length) {
         let recs = Object.values(tickets)   
         let sorted = recs.sort((a, b) => b.eventDateTimeStart - a.eventDateTimeStart)

         // Active Tickets
         let activeFiltered = sorted.filter((rec) => rec.type === 'ticket')
         activeFiltered = activeFiltered.filter((rec) => rec.status === 'valid')
         setActiveTickets(activeFiltered)
         
         // Active Items
         let activeItemsFiltered = sorted.filter((rec) => rec.type === 'item')
         activeItemsFiltered = activeItemsFiltered.filter((rec) => rec.status === 'valid')
         setActiveItems(activeItemsFiltered)

         // Transfered Items 
         let transferedFiltered = sorted.filter((rec) => rec.status === 'transfered')
         setTransferedItems(transferedFiltered)

         // Expired Items 
         let expiredFiltered = sorted.filter((rec) => rec.status === 'expired')
         let redeemedFiltered = sorted.filter((rec) => rec.status === 'redeemed')
         let allItems = expiredFiltered.concat(redeemedFiltered)
         setExpiredItems(allItems) 
      } else {
         setActiveTickets([])
         setActiveItems([])
         setTransferedItems([])
         setExpiredItems([])
      }
   }

   return (
      <div>
         <Paper style={{ padding: 10, minHeight:700 }} >
            <Collapse in={loading}> 
               <Alert severity='warning' style={{marginBottom:20}}>
                  Updating Your Tickets ...
                  <LinearProgress color='inherit' />   
               </Alert> 
            </Collapse>

            <div style={{marginTop:20}}>
               <List sx={{ padding:0, width: '100%', borderBottom:'1px solid #b9b9b9' }}>
                  <ListItem disablePadding onClick={() => { setShowTickets(!showTickets) }} 
                     secondaryAction={
                         <IconButton edge='end' onClick={() => { setShowTickets(!showTickets) }}>
                            {showTickets ? <IconExpandLess /> : <IconExpandMore />} 
                         </IconButton>
                     }
                  >
                  <ListItemButton style={{padding:0}}>
                  <ListItemText
                     primary={<Text variant='h5' color='textSecondary'>Active Tickets</Text>}
                     secondary={<Text variant='body2' style={{color:'#b9b9b9'}}><i>Valid tickets that have not yet been used.</i></Text>} 
                  />
                  </ListItemButton>
                  </ListItem>
               </List> 
               <Collapse in={showTickets}> 
                  <TicketsCard
                     ticketType='active' 
                     tickets={activeTickets} 
                     message='No Active Tickets' 
                     updateNameOnTicket={updateNameOnTicket}
                     transferTicket={transferTicket}
                     profileLookup={profileLookup}
                  />                  
               </Collapse>
            </div>

            {(activeItems.length) ? (
               <div style={{marginTop:20}}>
                  <List sx={{ padding:0, width: '100%', borderBottom:'1px solid #b9b9b9' }}>
                     <ListItem disablePadding onClick={() => { setShowItems(!showItems) }}
                        secondaryAction={
                            <IconButton edge='end' onClick={() => { setShowItems(!showItems) }}>
                               {showItems ? <IconExpandLess /> : <IconExpandMore />} 
                            </IconButton>
                        }
                     >
                     <ListItemButton style={{padding:0}}>
                     <ListItemText
                        primary={<Text variant='h5' color='textSecondary'>Active Items</Text>}
                        secondary={<Text variant='body2' style={{color:'#b9b9b9'}}><i>Items not delivered or redeemed as yet.</i></Text>} 
                     />
                     </ListItemButton>
                     </ListItem>
                  </List> 
                  <Collapse in={showItems}> 
                     <TicketsCard 
                        ticketType='active'
                        tickets={activeItems} 
                        message='No Active Items'
                        updateNameOnTicket={updateNameOnTicket}
                        transferTicket={transferTicket}
                        profileLookup={profileLookup}
                     />
                  </Collapse>
               </div>
            ):( null )}

            {(transferedItems.length) ? (
               <div style={{marginTop:20}}>
                  <List sx={{ padding:0, width: '100%', borderBottom:'1px solid #b9b9b9' }}>
                     <ListItem disablePadding onClick={() => { setShowTransfers(!showTransfers) }}
                        secondaryAction={
                            <IconButton edge='end' onClick={() => { setShowTransfers(!showTransfers) }}>
                               {showTransfers ? <IconExpandLess /> : <IconExpandMore />} 
                            </IconButton>
                        }
                     >
                     <ListItemButton style={{padding:0}}>
                     <ListItemText
                        primary={<Text variant='h5' color='textSecondary'>Transfers</Text>}
                        secondary={<Text variant='body2' style={{color:'#b9b9b9'}}><i>Tickets and Items transfered from you to someone else.</i></Text>} 
                     />
                     </ListItemButton>
                     </ListItem>
                  </List> 
                  <Collapse in={showTransfers}> 
                     <TicketsCard 
                        ticketType='transfered' 
                        tickets={transferedItems} 
                        message='No Transfered Tickets'
                     />
                  </Collapse>
               </div>
            ):( null )}

            {(expiredItems.length) ? (
               <div style={{marginTop:20, marginBottom:50}}>
                  <List sx={{ padding:0, width: '100%', borderBottom:'1px solid #b9b9b9' }}>
                     <ListItem disablePadding onClick={() => { setShowExpired(!showExpired) }}
                        secondaryAction={
                            <IconButton edge='end' onClick={() => { setShowExpired(!showExpired) }}>
                               {showExpired ? <IconExpandLess /> : <IconExpandMore />} 
                            </IconButton>
                        }
                     >
                     <ListItemButton style={{padding:0}}>
                     <ListItemText
                        primary={<Text variant='h5' color='textSecondary'>Expired</Text>}
                        secondary={<Text variant='body2' style={{color:'#b9b9b9'}}><i>Tickets and Items that have been redeemed or expired.</i></Text>} 
                     />
                     </ListItemButton>
                     </ListItem>
                  </List> 
                  <Collapse in={showExpired}> 
                     <TicketsCard 
                        ticketType='expired' 
                        tickets={expiredItems} 
                        message='No Redeemed or Expired Tickets'
                     />
                  </Collapse>
               </div>
            ):( null )}
         </Paper>
         <NextUpdate lastUpdated={lastUpdated} updateNow={handleFetchMemberTickets}/>
      </div>
   )
}
export default Tickets
