import { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import Alert from '@mui/material/Alert'
import Avatar from '@mui/material/Avatar'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionActions from '@mui/material/AccordionActions'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import IconClear from '@mui/icons-material/Clear'
import IconDot from '@mui/icons-material/FiberManualRecord'
import IconExpandMore from '@mui/icons-material/ExpandMore'
import IconTime from '@mui/icons-material/AccessTime'
import LinearProgress from '@mui/material/LinearProgress'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Text from '@mui/material/Typography'

function Team({teamMembers, removeTeamMember}){
   const [showMessage, setShowMessage] = useState(true)
   const [expanded, setExpanded] = useState(false)
   const [searchParams] = useSearchParams()
   const role = searchParams.get('role') 
   let navigate = useNavigate()
   
   useEffect(() => {
      if (role) { setExpanded(role) }
   },[])

   const handleRemoveTeamMember = (id) => {
      //
      //removeTeamMember(id)
      //.then()
   }
   const handleNewMember = (i) => {
      navigate('/group/settings/new-team-member?role=' + i)
   }
   const handleChangePanel = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false)
   }

   return (
      <div>
         <Paper style={{ padding: 20, minHeight:800 }} >
            <Grid container spacing={2}>
               <Grid item xs={12}>
                  <div style={{maxWidth:800, margin:'0 auto'}}>
                     <Collapse in={showMessage} style={{marginBottom:20}}>
                        <Alert severity='info' onClose={() => setShowMessage(false)}>
                           This is your group Team. Click the INFO icon (top right) for details on each roles' access. Only Admins and the group Owner have access to this page.
                        </Alert> 
                     </Collapse>
                  
                     {(Object.keys(teamMembers).length) ? (
                        Object.keys(teamMembers).map((i) => {
                           console.log(i)
                           return (
                              <Accordion key={i} expanded={expanded === i} onChange={handleChangePanel(i)}>
                                 <AccordionSummary expandIcon={<IconExpandMore />}>
                                   <Text variant='h6' style={{color:'#999'}}>{i.replace(/_/g, ' ')}</Text>
                                   <div style={{flexGrow: 1, textAlign:'right', marginRight:5}}>
                                   <Text variant='h6' style={{color:'#b0b0b0'}}>{teamMembers[i].activeCount + ' / ' + teamMembers[i].totalCount}</Text>
                                   </div>
                                 </AccordionSummary> 
                                 <AccordionDetails style={{padding:0}}>
                                 
                                    <TableContainer sx={{ maxWidth:700, margin:'0 auto' }}>
                                       <Table size='small'>
                                          <TableBody>
                                          {Object.keys(teamMembers[i].active).map((x) => {
                                             return (
                                                <TableRow key={x} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                   <TableCell align='right' sx={{ width: 24 }} ><Avatar sx={{ width: 24, height: 24 }} alt={teamMembers[i].active[x].memberName} src={teamMembers[i].active[x].memberThumb} /></TableCell>
                                                   <TableCell>{teamMembers[i].active[x].memberName}</TableCell>
                                                   <TableCell align='right'>{teamMembers[i].active[x].status}</TableCell>
                                                   <TableCell align='right' sx={{ width: 24 }}><IconButton><IconClear fontSize="small"/></IconButton></TableCell>  
                                                </TableRow>
                                             )                                         
                                          })}
                                          {Object.keys(teamMembers[i].pending).map((x) => {
                                             return (
                                                <TableRow key={x} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                   <TableCell align='right' sx={{ width: 24 }} ><Avatar sx={{ width: 24, height: 24 }} alt={teamMembers[i].pending[x].memberName} src={teamMembers[i].pending[x].memberThumb} /></TableCell>
                                                   <TableCell>{teamMembers[i].pending[x].memberName}</TableCell>
                                                   <TableCell align='right'>{teamMembers[i].pending[x].status}</TableCell>
                                                   <TableCell align='right' sx={{ width: 24 }}><IconButton><IconClear fontSize="small"/></IconButton></TableCell>  
                                                </TableRow>
                                             )                                         
                                          })}

                                          </TableBody>
                                       </Table>
                                    </TableContainer>
                                    {(i !== 'Owner') ? (
                                    <div style={{textAlign: 'right', paddingRight: 2}}><Button onClick={() => {handleNewMember(i)}}>Add {i.replace(/_/g, ' ')}</Button></div>
                                    ):( null)}
                                 </AccordionDetails>
                              </Accordion>
                           )
                        })
                     ):( null)}
                     
                     <div style={{marginTop:10, textAlign:'right'}}>
                        <Button variant='outlined' component={Link} to='/group/settings/new-team-member'>Add Team Member</Button>
                     </div>
                  </div> 
               </Grid>    
            </Grid>
         </Paper>            
      </div>
   )
}
export default Team
