import { forwardRef } from 'react'
import { format } from 'date-fns'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import Slide from '@mui/material/Slide'
import Text from '@mui/material/Typography'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

function ViewHistory({selectedItem,open,handleClose}){
   return (
      <Dialog open={open} onClose={() => { handleClose(false) }} fullWidth={true} maxWidth='xs' TransitionComponent={Transition} keepMounted>
         <DialogTitle >SecureTix | Ticket History</DialogTitle>
         <DialogContent style={{ textAlign: 'center'}}>
            <Text color='textSecondary' gutterBottom>               
               <b>{selectedItem.ticketName}</b><br />
               {selectedItem.code}<br />
            </Text> 
            <Divider/>
            {(selectedItem.history && selectedItem.history.length) ? (
               <div style={{padding:10, marginBottom:50, marginTop:15}}>
               {selectedItem.history.map((rec, index) => {
                  return (
                     <div key={index} style={{textAlign:'left'}}>
                        <Text variant='body2' color='textSecondary'><i>{format(rec.dateTime, 'EEE do MMM, yyyy - h:mm a')}</i></Text>
                        <Text gutterBottom>{rec.message}</Text>
                        <Divider style={{marginBottom:20}}/> 
                     </div>
                  )
               })}
               </div>
            ):(
               <div style={{padding:10, marginBottom:50}}><Text>No History Recorded</Text></div>
            )}
         </DialogContent>
         <DialogActions>
            <Button onClick={() => { handleClose(false) }} color='primary'>Close</Button>
         </DialogActions>
      </Dialog>
   )
}
export default ViewHistory
