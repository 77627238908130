import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function Inactive(){
   return (
      <Paper style={{ padding: 10, minHeight:700 }} >

         <Text variant='h5' color='textSecondary'>Drafts</Text>
         <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
            <Text variant='body1' color='textSecondary'>
               Draft posts, newsletters, etc.
            </Text>
         </div>

         <Text variant='h5' color='textSecondary'>Expired</Text>
         <div align='center' style={{margin:0, padding:50, backgroundColor:'#f1f1f1'}}>
            <Text variant='body1' color='textSecondary'>
               Expired posts, newsletters, etc.
            </Text>
         </div>
      </Paper>
   )
}
export default Inactive
