import { MEMBER_ACCOUNT_FEED_LOAD, MEMBER_ACCOUNT_MEMBERSHIPS_AND_ROLES_LOAD, MEMBER_ACCOUNT_MEMBERSHIPS_UPDATE, MEMBER_ACCOUNT_ROLES_UPDATE } from '../types'

const initialState = {
	summary: {},
	feed: {},
	memberships: {},
	roles: {}
}

export default function reducer(state = initialState, action) {
  	switch (action.type) {
		case MEMBER_ACCOUNT_FEED_LOAD:
         return { 
				...state,
				feed: action.payload
			}
		case MEMBER_ACCOUNT_MEMBERSHIPS_AND_ROLES_LOAD:
         return { 
				...state,
				memberships: action.payload.memberships,
				roles: action.payload.roles
			}
		case MEMBER_ACCOUNT_MEMBERSHIPS_UPDATE:
         return { 
				...state,
				memberships: {
               ...state.memberships,
               [action.payload.id]: action.payload.details
            }
			}
		case MEMBER_ACCOUNT_ROLES_UPDATE:
         return { 
				...state,
				roles: {
               ...state.roles,
               [action.payload.id]: action.payload.details
            }
			}
		default:
			return state
	}
}
