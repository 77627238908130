import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { updateProfileInfo, updateProfilePassword } from '../../../redux/auth/actions'

import SubNav from '../../components/SubNav'
import Password from './Password'
import Profile from './Profile'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'

function MemberSetupContainer({ profileInfo, updateProfileInfo, updateProfilePassword }) {
   const [showPwBox, setShowPwBox] = useState(true)
   let navigate = useNavigate()
   const [searchParams] = useSearchParams()
   let url = searchParams.get('nextURL')  
   let nextURL = (url) ? url : '/member/account'

	const skip = () => {
		navigate(nextURL)
	}
	const handleShowPwBox = (status) => {
		setShowPwBox(status)
	}

  return (
    <Container maxWidth='lg' style={{ padding: 10, marginBottom: 60 }}>
			<Grid container justifyContent='center' spacing={2}>
				<Grid item xs={12} sm={12} md={10} >
          <SubNav text='Setup Your Profile'/>
					<Profile skip={skip} handleShowPwBox={handleShowPwBox} profileInfo={profileInfo} updateProfileInfo={updateProfileInfo}/>
          <Password showPwBox={showPwBox} handleShowPwBox={handleShowPwBox} updateProfilePassword={updateProfilePassword}/>     
        </Grid>
			</Grid>
		</Container>
  )
}
const mapStateToProps = (state) => ({
	profileInfo: state.auth.userInfo || {}
})
const mapDispatchToProps = {
	updateProfileInfo,
	updateProfilePassword
}
export default connect(mapStateToProps, mapDispatchToProps)(MemberSetupContainer)
