import { forwardRef, useEffect, useState } from 'react'
import { QrReader } from 'react-qr-reader'  // https://github.com/react-qr-reader/react-qr-reader

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

// good QR Code articles : 
//    - https://simbathesailor.dev/qrcode-scan-web-reactjs/
//    - https://www.geeksforgeeks.org/web-api-webrtc-getusermedia-method/
//    - reset browser permission - chrome://settings/content

function Scanner({selectedOption,open,handleClose}){
   const [permission, setPermission] = useState('pending')
   const [data, setData] = useState(null)

   useEffect(() => {
      if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
         const constraints = { audio: false, video: { facingMode: { exact: 'user' } } }  //environment
         navigator.mediaDevices.getUserMedia(constraints) 
         .then((stream) => {
            /* use the stream */
            setPermission('granted')
         })
         .catch((err) => {
            /* handle the error */
            setPermission('denied')
         })
      } else {
         setPermission('unavailable')
      }
   }, [])

   const scanSuccess = (value) => {
      console.log('value: ', value)
      setPermission('results') 
   }
   const scanError = (error) => {
      console.log('error: ', error)
   }

   return (
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth='xs' TransitionComponent={Transition} keepMounted>
     
         <DialogTitle >SecureTix</DialogTitle>
         <DialogContent>
            {(permission === 'granted') ? ( 
               <Box>
                  <QrReader style={{ width: '100%' }} constraints={{facingMode: 'environment'}}
                     onResult={(result, error) => {
                        if (!!result) {
                           scanSuccess(result?.text);
                        }
                        if (!!error) {
                           scanError(error)
                        }                      
                     }}
                  />
                  <div style={{ textAlign: 'center'}}>
                      scan: {data}
                  </div> 
               </Box>
            ) : (null)}

            {(permission === 'pending') ? (
               <Box>
                  pending permission
               </Box>
            ) : (null)}

            {(permission === 'denied') ? (
               <Box>
                  permission denied
               </Box>
            ) : (null)}
            
            {(permission === 'unavailable') ? (
               <Box>
                  the scanner on this device seems to be unavailable.
               </Box>
            ) : (null)}
         
            {(permission === 'results') ? (
               <Box>
                  complete!
               </Box>
            ) : (null)}
         </DialogContent>

         <DialogActions>
            <Button onClick={handleClose} color='primary'>Close</Button>
         </DialogActions>
      </Dialog>
   )
}
export default Scanner
