import { useEffect, useState } from 'react' 
import { format, isValid } from 'date-fns'

import ImageSelector from '../../components/ImageSelector'
import countryList from '../../../includes/countryList'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DatePicker from '@mui/lab/DatePicker'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Text from '@mui/material/Typography'
import TextField from '@mui/material/TextField'

function ConfirmProfile({profileInfo, updateProfileInfo, handleCloseDialog}) {
   const [profileStep, setProfileStep] = useState('display') // display, edit, processing, error
   const [message, setMessage] = useState('')
   const [disableButton, setDisableButton] = useState(true)
   const [firstName, setFirstName] = useState('')
   const [lastName, setLastName] = useState('')
   const [gender, setGender] = useState('')
   const [dob, setDob] = useState(null)
   const [cell, setCell] = useState('')
   const [location, setLocation] = useState('TT')
   const [nationality, setNationality] = useState('TT')
   const [selectedImage, setSelectedImage] = useState(null) // ImageSelector variable
   const images = [ '/images/profile/default.jpg' ] // ImageSelector variable
   
   useEffect(() => {
      let p = profileInfo
      let firstName = (p.firstName) ? p.firstName : '' 
      let lastName = (p.lastName) ? p.lastName : '' 
      let gender = (p.gender) ? p.gender : '' 
      let dob = (p.dob) ? new Date(p.dob) : null
      let cell = (p.cell) ? p.cell : ''
      let location = (p.location) ? p.location : 'TT'
      let nationality = (p.nationality) ? p.nationality : 'TT' 
      let selectedImage = (p.image) ? p.image : null      
      setFirstName(firstName)
      setLastName(lastName)
      setGender(gender)
      setDob(dob)
      setCell(cell)
      setLocation(location)
      setNationality(nationality)
      setSelectedImage(selectedImage)
   },[profileInfo])

   // [START]: ImageSelector Function :::::::::
    const handleSelectImage = (src) => {
      if (src !== profileInfo.image) {
        setDisableButton(false)
      } else {
        setDisableButton(true)
      } 
      setSelectedImage(src)
    }
   // [END]: ImageSelector Function :::::::::::
   
   const handleChange = (f,v) => {
      f(v)
      setMessage('')
   }
   const updateUser = () => {
      // firstName and lastName cannot be blank
      if (firstName.length < 1 || lastName.length < 1) {
         return setMessage('Sorry, both First and Last names are required.')
      }
      // format date properly
      let d = isValid(dob) ? format(dob,'MM/dd/yyyy') : null 
      // dob cannot be blank
      if (!d) {
         return setMessage('Sorry, Date of Birth is required.')
      }
      // gender cannot be blank
      if (!gender) {
         return setMessage('Sorry, Gender is required.')
      }

      // set user variables
      let user = {
         image: selectedImage,
         firstName: firstName,
         lastName: lastName,
         dob: d,
         gender: gender,
         cell: cell,
         location: location,
         nationality: nationality
      }
      setProfileStep('processing')
      updateProfileInfo(user)
      .then(() => {
         setProfileStep('display')
      })
      .catch((e) => {
         setMessage(e.message)
         setProfileStep('error')
      })
   }

   return (
      <div>
         <Text variant='subtitle2' color='text.secondary'>
            Buyer Profile
         </Text> 
         <Text variant='body2' color='text.secondary'> 
            Please confirm/update your profile before proceeding.
            As stated in our TOS, some events may required a valid profile, and an incomplete 
            profile may invalidate your tickets.
         </Text> 

         {(profileStep === 'processing') ? (
            <div style={{paddingTop:50, paddingBottom:50, maxWidth:400, margin:'0 auto'}}>
              Processing your request...
              <LinearProgress color='inherit' />
            </div>
         ):( null )} 
         
         {(profileStep === 'error') ? (
            <div style={{paddingTop:50, paddingBottom:50, maxWidth:400, margin:'0 auto'}}>
              <Alert severity='error'>
                  <AlertTitle>Profile Error</AlertTitle>
                  {message || 'Sorry, an error occured, please try again.'}
               </Alert>
               <div style={{ marginTop:20 }}><Button onClick={() => { setProfileStep('edit') }} variant='outlined' color='secondary'>try again</Button></div>
            </div>
         ):( null )}

         {(profileStep === 'display') ? (
            <Grid container justifyContent='center' spacing={2} style={{marginTop:2}}>
               <Grid item xs={12} sm={5}>
                  <div style={{padding:5, margin:'0 auto', maxWidth:250}}>
                     <img src={profileInfo.image} style={{width:'100%', padding:5, border:'1px solid #999', borderRadius:'5%'}} />
                  </div>
               </Grid >
               <Grid item xs={12} sm={7}>
                  <div style={{padding:5, margin:'0 auto', maxWidth:300}}>
                     <Text><b>Email:</b> {profileInfo.email}</Text>
                     <Text><b>Name:</b> {profileInfo.name}</Text>
                     <Text><b>Gender:</b> {(profileInfo.gender) ? profileInfo.gender : 'unknown'}</Text>
                     <Text><b>Date of Birth:</b> {(profileInfo.dob) ? profileInfo.dob : 'unknown'}</Text>
                     <Text><b>Nationality:</b> {(profileInfo.nationality) ? countryList.filter(x => x.code === profileInfo.nationality).map(i => i.name)[0] : 'unknown'}</Text>
                     <Text><b>Location:</b> {(profileInfo.location) ? countryList.filter(x => x.code === profileInfo.location).map(i => i.name)[0] : 'unknown'}</Text>
                     <Stack spacing={2} style={{marginTop:10, marginBottom:20}}>
                        <Button onClick={() => { setProfileStep('edit') }} fullWidth variant='outlined' size='small' color='secondary'>Edit / Update Profile</Button>
                        <Button onClick={() => { handleCloseDialog() }} fullWidth variant='contained' size='small' color='primary'>Confirm & Continue</Button>
                     </Stack>
                  </div> 
               </Grid >
            </Grid >
         ):( null )} 

         {(profileStep === 'edit') ? (
            <Grid container justifyContent='center' spacing={2} style={{marginTop:2}}>
               <Grid item xs={12} sm={6}>
                   {/** ImageSelector Starts Here */}
                   <div align='center' style={{ maxWidth: 220, padding:10, margin:'0 auto'}}>
                     <ImageSelector 
                       imgBorder='1px solid #ccc'
                       imgBorderRadius={10}
                       imgPadding={5}
                       imgRatio='1:1' 
                       images={images}
                       currentImage={selectedImage}
                       selectImage={handleSelectImage}
                     />
                   </div>
                   {/** ImageSelector Ends Here */} 
               </Grid >
               <Grid item xs={12} sm={6}>
                  <div style={{ maxWidth:250, margin:'0 auto'}}>
                     <TextField fullWidth size='small' label='First Name (required)' name='firstName' margin='dense'
                        value={firstName} onChange={(e) => {handleChange(setFirstName, e.target.value) }}
                     />
                     <TextField fullWidth size='small' label='Last Name (required)' name='lastName' margin='dense'
                        value={lastName} onChange={(e) => {handleChange(setLastName, e.target.value) }}
                     />
                     <TextField select fullWidth size='small' label='Gender' name='lastName' margin='dense'
                        value={gender} onChange={(e) => {handleChange(setGender, e.target.value) }}
                     >
                       <MenuItem value=''><em>Gender</em></MenuItem>
                       <MenuItem value='Male'>Male</MenuItem>
                       <MenuItem value='Female'>Female</MenuItem> 
                     </TextField>
                     <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker label='Date of Birth'
                          inputFormat='MM/dd/yyyy'
                          value={dob}
                          onChange={(e) => {handleChange(setDob, e) }}
                          renderInput={(params) => <TextField fullWidth size='small' margin='dense' {...params} />}
                        />
                     </LocalizationProvider>
                     <TextField fullWidth size='small' label='Cell' name='cell' type='number' margin='dense'
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        value={cell} onChange={(e) => {handleChange(setCell, e.target.value) }}
                     />
                     <TextField select fullWidth size='small' label='Nationality' name='nationality' margin='dense'
                        value={nationality} onChange={(e) => {handleChange(setNationality, e.target.value) }}
                     >
                        <MenuItem value=''><em>Nationality</em></MenuItem>
                        {countryList.map((c, i) => {
                          return(<MenuItem key={i} value={c.code}>{c.name}</MenuItem>)
                        })}
                     </TextField>
                     <TextField select fullWidth size='small' label='Location' name='location' margin='dense'
                        value={location} onChange={(e) => {handleChange(setLocation, e.target.value) }}
                     >
                        <MenuItem value=''><em>Location</em></MenuItem>
                        {countryList.map((c, i) => {
                          return(<MenuItem key={i} value={c.code}>{c.name}</MenuItem>)
                        })}
                     </TextField>
                  </div>
               </Grid >               
               <Grid item xs={12} sm={12}>
                  <div align='center'>
                     <Text style={{ marginBottom:10, color: 'red', height: 15 }} component='p' align='center'>
                        <i>{ message }</i>
                     </Text>
                  </div>
                  <Stack direction='row' justifyContent='center' spacing={2} style={{marginBottom:20}}>
                     <Button variant='outlined' onClick={() => { setProfileStep('display') }}>Cancel</Button>
                     <Button variant='contained' onClick={() => { updateUser() }}>Update Profile</Button>
                  </Stack>                  
               </Grid>
            </Grid >
         ):( null )} 
      </div>
   )
}
export default ConfirmProfile
