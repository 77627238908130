import { useState } from 'react' 

import AddMembersSingle from './components/AddMembersSingle'
import AddMembersMultiple from './components/AddMembersMultiple'
import AddMembersUpload from './components/AddMembersUpload'

import Alert from '@mui/material/Alert'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Text from '@mui/material/Typography'

function Add({addMembers}){
   const [showMessage, setShowMessage] = useState(true)
   const [expanded, setExpanded] = useState(false)

   const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false)
   }

   return (
      <div>
         {(showMessage) ? (
            <Alert severity='info' onClose={() => {setShowMessage(false)}}>
               This action <u><b>would not</b></u> send emails to the addresses you enter here. they would only
               be notified if they have, or when they create a SecureTix account.
            </Alert>    
      
         ) : ( null )} 
         <div style={{marginTop:20}} >
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
               <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                  <Text><b>Add One</b><br/>
                     <span style={{color:'#999'}}>Add one member at a time. Requires their full name and email address.</span>
                  </Text>
               </AccordionSummary>
               <AccordionDetails>
                  <AddMembersSingle addMembers={addMembers}/>
               </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
               <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                  <Text>
                     <b>Add Multiple</b><br/>
                     <span style={{color:'#999'}}>Add multiple members at a time. Requires email addresses only.</span>
                  </Text>
               </AccordionSummary>
               <AccordionDetails>
                  <AddMembersMultiple addMembers={addMembers}/>
               </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
               <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                  <Text>
                     <b>Upload List</b><br/>
                     <span style={{color:'#999'}}>Upload a file containing your members information.</span>
                  </Text>
               </AccordionSummary>
               <AccordionDetails>
                  <AddMembersUpload/>
               </AccordionDetails>
            </Accordion>


       
         </div>
      </div>
   )
}
export default Add
