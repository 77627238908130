import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Text from '@mui/material/Typography'

function Faqs() {
  return (
    <div>
      <Container maxWidth='lg' style={{ padding: 10, marginBottom: 50 }}>
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} >
               <Paper style={{ padding: 10, minHeight:700 }} >
                  <div style={{textAlign:'center'}}>
                     <Text variant='h4'>
                        FAQs
                     </Text> 
                     <Text variant='subtitle1'>
                        Frequently asked Questions about our service and features.
                     </Text> 
                     <Text variant='subtitle1'>
                        Support Desk to answer questions not answered in the FQSs
                     </Text> 
                  </div>  
               </Paper>
            </Grid>
         </Grid>
      </Container>
    </div>
  );
}
export default Faqs